/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICheckRestrictionsModelModel,
    ICheckRestrictionsModelModelFromJSON,
    ICheckRestrictionsModelModelFromJSONTyped,
    ICheckRestrictionsModelModelToJSON,
} from './ICheckRestrictionsModelModel';
import {
    IDSOfferMediaModelModel,
    IDSOfferMediaModelModelFromJSON,
    IDSOfferMediaModelModelFromJSONTyped,
    IDSOfferMediaModelModelToJSON,
} from './IDSOfferMediaModelModel';
import {
    IDSRewardApplicabilityModel,
    IDSRewardApplicabilityModelFromJSON,
    IDSRewardApplicabilityModelFromJSONTyped,
    IDSRewardApplicabilityModelToJSON,
} from './IDSRewardApplicabilityModel';
import {
    LocationRestrictionsModelModel,
    LocationRestrictionsModelModelFromJSON,
    LocationRestrictionsModelModelFromJSONTyped,
    LocationRestrictionsModelModelToJSON,
} from './LocationRestrictionsModelModel';
import {
    TChannelsModel,
    TChannelsModelFromJSON,
    TChannelsModelFromJSONTyped,
    TChannelsModelToJSON,
} from './TChannelsModel';
import {
    TOfferStatusUnionModel,
    TOfferStatusUnionModelFromJSON,
    TOfferStatusUnionModelFromJSONTyped,
    TOfferStatusUnionModelToJSON,
} from './TOfferStatusUnionModel';
import {
    TOfferTypeModel,
    TOfferTypeModelFromJSON,
    TOfferTypeModelFromJSONTyped,
    TOfferTypeModelToJSON,
} from './TOfferTypeModel';

/**
 * 
 * @export
 * @interface IDSOfferModel
 */
export interface IDSOfferModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof IDSOfferModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    userOfferId: string;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    posDiscountId: string;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    name: string;
    /**
     * 
     * @type {TOfferTypeModel}
     * @memberof IDSOfferModel
     */
    type: TOfferTypeModel;
    /**
     * 
     * @type {boolean}
     * @memberof IDSOfferModel
     */
    isCheckLevel: boolean;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    displayExpirationDate?: string;
    /**
     * 
     * @type {Date}
     * @memberof IDSOfferModel
     */
    optInDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof IDSOfferModel
     */
    daysValid?: number;
    /**
     * 
     * @type {number}
     * @memberof IDSOfferModel
     */
    offerPriority?: number;
    /**
     * 
     * @type {number}
     * @memberof IDSOfferModel
     */
    points?: number;
    /**
     * 
     * @type {Date}
     * @memberof IDSOfferModel
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof IDSOfferModel
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    terms: string;
    /**
     * 
     * @type {Array<TChannelsModel>}
     * @memberof IDSOfferModel
     */
    channels: Array<TChannelsModel>;
    /**
     * 
     * @type {TOfferStatusUnionModel}
     * @memberof IDSOfferModel
     */
    status?: TOfferStatusUnionModel;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    termsLanguageCode: string;
    /**
     * 
     * @type {IDSOfferMediaModelModel}
     * @memberof IDSOfferModel
     */
    media?: IDSOfferMediaModelModel;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferModel
     */
    description?: string;
    /**
     * 
     * @type {LocationRestrictionsModelModel}
     * @memberof IDSOfferModel
     */
    locationRestrictions?: LocationRestrictionsModelModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof IDSOfferModel
     */
    applicableLocations?: Array<string>;
    /**
     * 
     * @type {IDSRewardApplicabilityModel}
     * @memberof IDSOfferModel
     */
    applicability?: IDSRewardApplicabilityModel;
    /**
     * 
     * @type {ICheckRestrictionsModelModel}
     * @memberof IDSOfferModel
     */
    checkRestrictions?: ICheckRestrictionsModelModel;
}

export function IDSOfferModelFromJSON(json: any): IDSOfferModel {
    return IDSOfferModelFromJSONTyped(json, false);
}

export function IDSOfferModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDSOfferModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userOfferId': json['userOfferId'],
        'posDiscountId': json['posDiscountId'],
        'name': json['name'],
        'type': TOfferTypeModelFromJSON(json['type']),
        'isCheckLevel': json['isCheckLevel'],
        'displayExpirationDate': !exists(json, 'displayExpirationDate') ? undefined : json['displayExpirationDate'],
        'optInDate': !exists(json, 'optInDate') ? undefined : (new Date(json['optInDate'])),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'daysValid': !exists(json, 'daysValid') ? undefined : json['daysValid'],
        'offerPriority': !exists(json, 'offerPriority') ? undefined : json['offerPriority'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'terms': json['terms'],
        'channels': ((json['channels'] as Array<any>).map(TChannelsModelFromJSON)),
        'status': !exists(json, 'status') ? undefined : TOfferStatusUnionModelFromJSON(json['status']),
        'termsLanguageCode': json['termsLanguageCode'],
        'media': !exists(json, 'media') ? undefined : IDSOfferMediaModelModelFromJSON(json['media']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'locationRestrictions': !exists(json, 'locationRestrictions') ? undefined : LocationRestrictionsModelModelFromJSON(json['locationRestrictions']),
        'applicableLocations': !exists(json, 'applicableLocations') ? undefined : json['applicableLocations'],
        'applicability': !exists(json, 'applicability') ? undefined : IDSRewardApplicabilityModelFromJSON(json['applicability']),
        'checkRestrictions': !exists(json, 'checkRestrictions') ? undefined : ICheckRestrictionsModelModelFromJSON(json['checkRestrictions']),
    };
}

export function IDSOfferModelToJSON(value?: IDSOfferModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userOfferId': value.userOfferId,
        'posDiscountId': value.posDiscountId,
        'name': value.name,
        'type': TOfferTypeModelToJSON(value.type),
        'isCheckLevel': value.isCheckLevel,
        'displayExpirationDate': value.displayExpirationDate,
        'optInDate': value.optInDate === undefined ? undefined : (value.optInDate.toISOString()),
        'code': value.code,
        'daysValid': value.daysValid,
        'offerPriority': value.offerPriority,
        'points': value.points,
        'startDate': (value.startDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'terms': value.terms,
        'channels': ((value.channels as Array<any>).map(TChannelsModelToJSON)),
        'status': TOfferStatusUnionModelToJSON(value.status),
        'termsLanguageCode': value.termsLanguageCode,
        'media': IDSOfferMediaModelModelToJSON(value.media),
        'description': value.description,
        'locationRestrictions': LocationRestrictionsModelModelToJSON(value.locationRestrictions),
        'applicableLocations': value.applicableLocations,
        'applicability': IDSRewardApplicabilityModelToJSON(value.applicability),
        'checkRestrictions': ICheckRestrictionsModelModelToJSON(value.checkRestrictions),
    };
}

