/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NumericValueModel,
    NumericValueModelFromJSON,
    NumericValueModelFromJSONTyped,
    NumericValueModelToJSON,
} from './NumericValueModel';

/**
 * 
 * @export
 * @interface MacroNutrientModel
 */
export interface MacroNutrientModel {
    /**
     * 
     * @type {string}
     * @memberof MacroNutrientModel
     */
    label?: string;
    /**
     * 
     * @type {NumericValueModel}
     * @memberof MacroNutrientModel
     */
    weight?: NumericValueModel;
    /**
     * 
     * @type {number}
     * @memberof MacroNutrientModel
     */
    percentageDailyValue?: number;
}

export function MacroNutrientModelFromJSON(json: any): MacroNutrientModel {
    return MacroNutrientModelFromJSONTyped(json, false);
}

export function MacroNutrientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MacroNutrientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'weight': !exists(json, 'weight') ? undefined : NumericValueModelFromJSON(json['weight']),
        'percentageDailyValue': !exists(json, 'percentageDailyValue') ? undefined : json['percentageDailyValue'],
    };
}

export function MacroNutrientModelToJSON(value?: MacroNutrientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'weight': NumericValueModelToJSON(value.weight),
        'percentageDailyValue': value.percentageDailyValue,
    };
}

