/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DineInOrderPaymentTypeModel,
    DineInOrderPaymentTypeModelFromJSON,
    DineInOrderPaymentTypeModelFromJSONTyped,
    DineInOrderPaymentTypeModelToJSON,
} from './DineInOrderPaymentTypeModel';

/**
 * 
 * @export
 * @interface IDineInPayOrderPaymentModel
 */
export interface IDineInPayOrderPaymentModel {
    /**
     * 
     * @type {DineInOrderPaymentTypeModel}
     * @memberof IDineInPayOrderPaymentModel
     */
    type: DineInOrderPaymentTypeModel;
    /**
     * 
     * @type {number}
     * @memberof IDineInPayOrderPaymentModel
     */
    amount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IDineInPayOrderPaymentModel
     */
    paymentKeys: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderPaymentModel
     */
    sessionKey: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderPaymentModel
     */
    cardIssuer?: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderPaymentModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderPaymentModel
     */
    cardHolderName?: string;
}

export function IDineInPayOrderPaymentModelFromJSON(json: any): IDineInPayOrderPaymentModel {
    return IDineInPayOrderPaymentModelFromJSONTyped(json, false);
}

export function IDineInPayOrderPaymentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDineInPayOrderPaymentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': DineInOrderPaymentTypeModelFromJSON(json['type']),
        'amount': json['amount'],
        'paymentKeys': json['paymentKeys'],
        'sessionKey': json['sessionKey'],
        'cardIssuer': !exists(json, 'cardIssuer') ? undefined : json['cardIssuer'],
        'maskedCardNumber': !exists(json, 'maskedCardNumber') ? undefined : json['maskedCardNumber'],
        'cardHolderName': !exists(json, 'cardHolderName') ? undefined : json['cardHolderName'],
    };
}

export function IDineInPayOrderPaymentModelToJSON(value?: IDineInPayOrderPaymentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': DineInOrderPaymentTypeModelToJSON(value.type),
        'amount': value.amount,
        'paymentKeys': value.paymentKeys,
        'sessionKey': value.sessionKey,
        'cardIssuer': value.cardIssuer,
        'maskedCardNumber': value.maskedCardNumber,
        'cardHolderName': value.cardHolderName,
    };
}

