/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationsByStateOrProvinceModel,
    ILocationsByStateOrProvinceModelFromJSON,
    ILocationsByStateOrProvinceModelFromJSONTyped,
    ILocationsByStateOrProvinceModelToJSON,
} from './ILocationsByStateOrProvinceModel';

/**
 * 
 * @export
 * @interface ILocationsByCountryModel
 */
export interface ILocationsByCountryModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationsByCountryModel
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationsByCountryModel
     */
    countryName: string;
    /**
     * 
     * @type {number}
     * @memberof ILocationsByCountryModel
     */
    count: number;
    /**
     * 
     * @type {Array<ILocationsByStateOrProvinceModel>}
     * @memberof ILocationsByCountryModel
     */
    statesOrProvinces?: Array<ILocationsByStateOrProvinceModel>;
}

export function ILocationsByCountryModelFromJSON(json: any): ILocationsByCountryModel {
    return ILocationsByCountryModelFromJSONTyped(json, false);
}

export function ILocationsByCountryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationsByCountryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': json['countryCode'],
        'countryName': json['countryName'],
        'count': json['count'],
        'statesOrProvinces': !exists(json, 'statesOrProvinces') ? undefined : ((json['statesOrProvinces'] as Array<any>).map(ILocationsByStateOrProvinceModelFromJSON)),
    };
}

export function ILocationsByCountryModelToJSON(value?: ILocationsByCountryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': value.countryCode,
        'countryName': value.countryName,
        'count': value.count,
        'statesOrProvinces': value.statesOrProvinces === undefined ? undefined : ((value.statesOrProvinces as Array<any>).map(ILocationsByStateOrProvinceModelToJSON)),
    };
}

