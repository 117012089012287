/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TallyDiscountModel
 */
export interface TallyDiscountModel {
    /**
     * 
     * @type {number}
     * @memberof TallyDiscountModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof TallyDiscountModel
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TallyDiscountModel
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof TallyDiscountModel
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TallyDiscountModel
     */
    applied?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TallyDiscountModel
     */
    menuItemId?: string;
}

export function TallyDiscountModelFromJSON(json: any): TallyDiscountModel {
    return TallyDiscountModelFromJSONTyped(json, false);
}

export function TallyDiscountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyDiscountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'applied': !exists(json, 'applied') ? undefined : json['applied'],
        'menuItemId': !exists(json, 'menuItemId') ? undefined : json['menuItemId'],
    };
}

export function TallyDiscountModelToJSON(value?: TallyDiscountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'name': value.name,
        'amount': value.amount,
        'quantity': value.quantity,
        'applied': value.applied,
        'menuItemId': value.menuItemId,
    };
}

