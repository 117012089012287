/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplePayInfoHeaderModel,
    ApplePayInfoHeaderModelFromJSON,
    ApplePayInfoHeaderModelFromJSONTyped,
    ApplePayInfoHeaderModelToJSON,
} from './ApplePayInfoHeaderModel';

/**
 * 
 * @export
 * @interface ApplePayInfoModel
 */
export interface ApplePayInfoModel {
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoModel
     */
    data: string;
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoModel
     */
    decryptAlias?: string;
    /**
     * 
     * @type {ApplePayInfoHeaderModel}
     * @memberof ApplePayInfoModel
     */
    header: ApplePayInfoHeaderModel;
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoModel
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoModel
     */
    version: string;
}

export function ApplePayInfoModelFromJSON(json: any): ApplePayInfoModel {
    return ApplePayInfoModelFromJSONTyped(json, false);
}

export function ApplePayInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplePayInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'decryptAlias': !exists(json, 'decryptAlias') ? undefined : json['decryptAlias'],
        'header': ApplePayInfoHeaderModelFromJSON(json['header']),
        'signature': json['signature'],
        'version': json['version'],
    };
}

export function ApplePayInfoModelToJSON(value?: ApplePayInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'decryptAlias': value.decryptAlias,
        'header': ApplePayInfoHeaderModelToJSON(value.header),
        'signature': value.signature,
        'version': value.version,
    };
}

