/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IHistoryOrderItemDiscountItemModel,
    IHistoryOrderItemDiscountItemModelFromJSON,
    IHistoryOrderItemDiscountItemModelFromJSONTyped,
    IHistoryOrderItemDiscountItemModelToJSON,
} from './IHistoryOrderItemDiscountItemModel';
import {
    IHistoryOrderItemFeeModel,
    IHistoryOrderItemFeeModelFromJSON,
    IHistoryOrderItemFeeModelFromJSONTyped,
    IHistoryOrderItemFeeModelToJSON,
} from './IHistoryOrderItemFeeModel';
import {
    IOrderFulfillmentModel,
    IOrderFulfillmentModelFromJSON,
    IOrderFulfillmentModelFromJSONTyped,
    IOrderFulfillmentModelToJSON,
} from './IOrderFulfillmentModel';
import {
    IOrderPaymentModel,
    IOrderPaymentModelFromJSON,
    IOrderPaymentModelFromJSONTyped,
    IOrderPaymentModelToJSON,
} from './IOrderPaymentModel';
import {
    IOrderProductModel,
    IOrderProductModelFromJSON,
    IOrderProductModelFromJSONTyped,
    IOrderProductModelToJSON,
} from './IOrderProductModel';
import {
    OrderStatusEnumModel,
    OrderStatusEnumModelFromJSON,
    OrderStatusEnumModelFromJSONTyped,
    OrderStatusEnumModelToJSON,
} from './OrderStatusEnumModel';

/**
 * 
 * @export
 * @interface IOrderModel
 */
export interface IOrderModel {
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    idempotentId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    confirmationNumber?: string;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof IOrderModel
     */
    dateTime?: Date;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof IOrderModel
     */
    pickupTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    pickupTimeType?: string;
    /**
     * 
     * @type {Array<IHistoryOrderItemDiscountItemModel>}
     * @memberof IOrderModel
     */
    discounts?: Array<IHistoryOrderItemDiscountItemModel>;
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    statusText?: string;
    /**
     * 
     * @type {OrderStatusEnumModel}
     * @memberof IOrderModel
     */
    orderStatus?: OrderStatusEnumModel;
    /**
     * 
     * @type {string}
     * @memberof IOrderModel
     */
    statusCode?: string;
    /**
     * 
     * @type {Array<IOrderPaymentModel>}
     * @memberof IOrderModel
     */
    paymentMethods: Array<IOrderPaymentModel>;
    /**
     * 
     * @type {IOrderFulfillmentModel}
     * @memberof IOrderModel
     */
    fulfillment: IOrderFulfillmentModel;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    subTotalBeforeDiscounts?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    discountsAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    subTotalAfterDiscounts?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    total?: number;
    /**
     * 
     * @type {Array<IHistoryOrderItemFeeModel>}
     * @memberof IOrderModel
     */
    fees?: Array<IHistoryOrderItemFeeModel>;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    driverTip?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    storeTip?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderModel
     */
    points?: number;
    /**
     * 
     * @type {Array<IOrderProductModel>}
     * @memberof IOrderModel
     */
    products?: Array<IOrderProductModel>;
}

export function IOrderModelFromJSON(json: any): IOrderModel {
    return IOrderModelFromJSONTyped(json, false);
}

export function IOrderModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idempotentId': !exists(json, 'idempotentId') ? undefined : json['idempotentId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'confirmationNumber': !exists(json, 'confirmationNumber') ? undefined : json['confirmationNumber'],
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
        'pickupTime': !exists(json, 'pickupTime') ? undefined : (new Date(json['pickupTime'])),
        'pickupTimeType': !exists(json, 'pickupTimeType') ? undefined : json['pickupTimeType'],
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(IHistoryOrderItemDiscountItemModelFromJSON)),
        'statusText': !exists(json, 'statusText') ? undefined : json['statusText'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : OrderStatusEnumModelFromJSON(json['orderStatus']),
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'paymentMethods': ((json['paymentMethods'] as Array<any>).map(IOrderPaymentModelFromJSON)),
        'fulfillment': IOrderFulfillmentModelFromJSON(json['fulfillment']),
        'subTotalBeforeDiscounts': !exists(json, 'subTotalBeforeDiscounts') ? undefined : json['subTotalBeforeDiscounts'],
        'discountsAmount': !exists(json, 'discountsAmount') ? undefined : json['discountsAmount'],
        'subTotalAfterDiscounts': !exists(json, 'subTotalAfterDiscounts') ? undefined : json['subTotalAfterDiscounts'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'fees': !exists(json, 'fees') ? undefined : ((json['fees'] as Array<any>).map(IHistoryOrderItemFeeModelFromJSON)),
        'driverTip': !exists(json, 'driverTip') ? undefined : json['driverTip'],
        'storeTip': !exists(json, 'storeTip') ? undefined : json['storeTip'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(IOrderProductModelFromJSON)),
    };
}

export function IOrderModelToJSON(value?: IOrderModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idempotentId': value.idempotentId,
        'customerId': value.customerId,
        'confirmationNumber': value.confirmationNumber,
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
        'pickupTime': value.pickupTime === undefined ? undefined : (value.pickupTime.toISOString()),
        'pickupTimeType': value.pickupTimeType,
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(IHistoryOrderItemDiscountItemModelToJSON)),
        'statusText': value.statusText,
        'orderStatus': OrderStatusEnumModelToJSON(value.orderStatus),
        'statusCode': value.statusCode,
        'paymentMethods': ((value.paymentMethods as Array<any>).map(IOrderPaymentModelToJSON)),
        'fulfillment': IOrderFulfillmentModelToJSON(value.fulfillment),
        'subTotalBeforeDiscounts': value.subTotalBeforeDiscounts,
        'discountsAmount': value.discountsAmount,
        'subTotalAfterDiscounts': value.subTotalAfterDiscounts,
        'tax': value.tax,
        'total': value.total,
        'fees': value.fees === undefined ? undefined : ((value.fees as Array<any>).map(IHistoryOrderItemFeeModelToJSON)),
        'driverTip': value.driverTip,
        'storeTip': value.storeTip,
        'subTotal': value.subTotal,
        'points': value.points,
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(IOrderProductModelToJSON)),
    };
}

