/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IGenericPageMetadataModel
 */
export interface IGenericPageMetadataModel {
    /**
     * 
     * @type {number}
     * @memberof IGenericPageMetadataModel
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof IGenericPageMetadataModel
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof IGenericPageMetadataModel
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IGenericPageMetadataModel
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IGenericPageMetadataModel
     */
    isLastPage?: boolean;
}

export function IGenericPageMetadataModelFromJSON(json: any): IGenericPageMetadataModel {
    return IGenericPageMetadataModelFromJSONTyped(json, false);
}

export function IGenericPageMetadataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGenericPageMetadataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'isLastPage': !exists(json, 'isLastPage') ? undefined : json['isLastPage'],
    };
}

export function IGenericPageMetadataModelToJSON(value?: IGenericPageMetadataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'isLastPage': value.isLastPage,
    };
}

