/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ButtonTypeEnumModel,
    ButtonTypeEnumModelFromJSON,
    ButtonTypeEnumModelFromJSONTyped,
    ButtonTypeEnumModelToJSON,
} from './ButtonTypeEnumModel';
import {
    FulfillmentTypeEnumModel,
    FulfillmentTypeEnumModelFromJSON,
    FulfillmentTypeEnumModelFromJSONTyped,
    FulfillmentTypeEnumModelToJSON,
} from './FulfillmentTypeEnumModel';
import {
    WorkflowEnumModel,
    WorkflowEnumModelFromJSON,
    WorkflowEnumModelFromJSONTyped,
    WorkflowEnumModelToJSON,
} from './WorkflowEnumModel';

/**
 * 
 * @export
 * @interface ICreditCardPaymentInitBodyModel
 */
export interface ICreditCardPaymentInitBodyModel {
    /**
     * 
     * @type {string}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    customerId?: string;
    /**
     * Location Id
     * @type {string}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    locationId: string;
    /**
     * 
     * @type {WorkflowEnumModel}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    workflow?: WorkflowEnumModel;
    /**
     * 
     * @type {string}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    styles?: string;
    /**
     * 
     * @type {ButtonTypeEnumModel}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    submitButtonText?: ButtonTypeEnumModel;
    /**
     * 
     * @type {boolean}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    isOnFile?: boolean;
    /**
     * 
     * @type {FulfillmentTypeEnumModel}
     * @memberof ICreditCardPaymentInitBodyModel
     */
    fulfillmentType?: FulfillmentTypeEnumModel;
}

export function ICreditCardPaymentInitBodyModelFromJSON(json: any): ICreditCardPaymentInitBodyModel {
    return ICreditCardPaymentInitBodyModelFromJSONTyped(json, false);
}

export function ICreditCardPaymentInitBodyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICreditCardPaymentInitBodyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'locationId': json['locationId'],
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowEnumModelFromJSON(json['workflow']),
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'submitButtonText': !exists(json, 'submitButtonText') ? undefined : ButtonTypeEnumModelFromJSON(json['submitButtonText']),
        'isOnFile': !exists(json, 'isOnFile') ? undefined : json['isOnFile'],
        'fulfillmentType': !exists(json, 'fulfillmentType') ? undefined : FulfillmentTypeEnumModelFromJSON(json['fulfillmentType']),
    };
}

export function ICreditCardPaymentInitBodyModelToJSON(value?: ICreditCardPaymentInitBodyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'locationId': value.locationId,
        'workflow': WorkflowEnumModelToJSON(value.workflow),
        'styles': value.styles,
        'submitButtonText': ButtonTypeEnumModelToJSON(value.submitButtonText),
        'isOnFile': value.isOnFile,
        'fulfillmentType': FulfillmentTypeEnumModelToJSON(value.fulfillmentType),
    };
}

