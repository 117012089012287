/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SodiumWarningAreaEnumModel,
    SodiumWarningAreaEnumModelFromJSON,
    SodiumWarningAreaEnumModelFromJSONTyped,
    SodiumWarningAreaEnumModelToJSON,
} from './SodiumWarningAreaEnumModel';

/**
 * 
 * @export
 * @interface ILegalWarningAdditionalPropertiesModel
 */
export interface ILegalWarningAdditionalPropertiesModel {
    /**
     * 
     * @type {number}
     * @memberof ILegalWarningAdditionalPropertiesModel
     */
    threshold: number;
    /**
     * 
     * @type {string}
     * @memberof ILegalWarningAdditionalPropertiesModel
     */
    units: string;
    /**
     * 
     * @type {SodiumWarningAreaEnumModel}
     * @memberof ILegalWarningAdditionalPropertiesModel
     */
    area: SodiumWarningAreaEnumModel;
}

export function ILegalWarningAdditionalPropertiesModelFromJSON(json: any): ILegalWarningAdditionalPropertiesModel {
    return ILegalWarningAdditionalPropertiesModelFromJSONTyped(json, false);
}

export function ILegalWarningAdditionalPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILegalWarningAdditionalPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threshold': json['threshold'],
        'units': json['units'],
        'area': SodiumWarningAreaEnumModelFromJSON(json['area']),
    };
}

export function ILegalWarningAdditionalPropertiesModelToJSON(value?: ILegalWarningAdditionalPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'threshold': value.threshold,
        'units': value.units,
        'area': SodiumWarningAreaEnumModelToJSON(value.area),
    };
}

