/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyDSModifierGroupModel,
    TallyDSModifierGroupModelFromJSON,
    TallyDSModifierGroupModelFromJSONTyped,
    TallyDSModifierGroupModelToJSON,
} from './TallyDSModifierGroupModel';
import {
    TallyDSModifierMetadataModel,
    TallyDSModifierMetadataModelFromJSON,
    TallyDSModifierMetadataModelFromJSONTyped,
    TallyDSModifierMetadataModelToJSON,
} from './TallyDSModifierMetadataModel';
import {
    TallyModifierActionCodeModel,
    TallyModifierActionCodeModelFromJSON,
    TallyModifierActionCodeModelFromJSONTyped,
    TallyModifierActionCodeModelToJSON,
} from './TallyModifierActionCodeModel';
import {
    TallyPriceTypeModel,
    TallyPriceTypeModelFromJSON,
    TallyPriceTypeModelFromJSONTyped,
    TallyPriceTypeModelToJSON,
} from './TallyPriceTypeModel';

/**
 * 
 * @export
 * @interface TallyDSModifierModel
 */
export interface TallyDSModifierModel {
    /**
     * 
     * @type {string}
     * @memberof TallyDSModifierModel
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof TallyDSModifierModel
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof TallyDSModifierModel
     */
    quantity: number;
    /**
     * 
     * @type {TallyPriceTypeModel}
     * @memberof TallyDSModifierModel
     */
    priceType?: TallyPriceTypeModel;
    /**
     * 
     * @type {TallyModifierActionCodeModel}
     * @memberof TallyDSModifierModel
     */
    actionCode?: TallyModifierActionCodeModel;
    /**
     * 
     * @type {string}
     * @memberof TallyDSModifierModel
     */
    sizeGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDSModifierModel
     */
    sizeGroupName?: string;
    /**
     * 
     * @type {Array<TallyDSModifierGroupModel>}
     * @memberof TallyDSModifierModel
     */
    modifierGroups?: Array<TallyDSModifierGroupModel>;
    /**
     * 
     * @type {number}
     * @memberof TallyDSModifierModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {TallyDSModifierMetadataModel}
     * @memberof TallyDSModifierModel
     */
    metadata?: TallyDSModifierMetadataModel;
}

export function TallyDSModifierModelFromJSON(json: any): TallyDSModifierModel {
    return TallyDSModifierModelFromJSONTyped(json, false);
}

export function TallyDSModifierModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyDSModifierModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'price': json['price'],
        'quantity': json['quantity'],
        'priceType': !exists(json, 'priceType') ? undefined : TallyPriceTypeModelFromJSON(json['priceType']),
        'actionCode': !exists(json, 'actionCode') ? undefined : TallyModifierActionCodeModelFromJSON(json['actionCode']),
        'sizeGroupId': !exists(json, 'sizeGroupId') ? undefined : json['sizeGroupId'],
        'sizeGroupName': !exists(json, 'sizeGroupName') ? undefined : json['sizeGroupName'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(TallyDSModifierGroupModelFromJSON)),
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'metadata': !exists(json, 'metadata') ? undefined : TallyDSModifierMetadataModelFromJSON(json['metadata']),
    };
}

export function TallyDSModifierModelToJSON(value?: TallyDSModifierModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'price': value.price,
        'quantity': value.quantity,
        'priceType': TallyPriceTypeModelToJSON(value.priceType),
        'actionCode': TallyModifierActionCodeModelToJSON(value.actionCode),
        'sizeGroupId': value.sizeGroupId,
        'sizeGroupName': value.sizeGroupName,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(TallyDSModifierGroupModelToJSON)),
        'lineItemId': value.lineItemId,
        'metadata': TallyDSModifierMetadataModelToJSON(value.metadata),
    };
}

