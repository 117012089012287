/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyDSModifierGroupModel,
    TallyDSModifierGroupModelFromJSON,
    TallyDSModifierGroupModelFromJSONTyped,
    TallyDSModifierGroupModelToJSON,
} from './TallyDSModifierGroupModel';
import {
    TallyDSModifierMetadataModel,
    TallyDSModifierMetadataModelFromJSON,
    TallyDSModifierMetadataModelFromJSONTyped,
    TallyDSModifierMetadataModelToJSON,
} from './TallyDSModifierMetadataModel';
import {
    TallyPriceTypeModel,
    TallyPriceTypeModelFromJSON,
    TallyPriceTypeModelFromJSONTyped,
    TallyPriceTypeModelToJSON,
} from './TallyPriceTypeModel';

/**
 * 
 * @export
 * @interface TallyDSProductModel
 */
export interface TallyDSProductModel {
    /**
     * 
     * @type {number}
     * @memberof TallyDSProductModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof TallyDSProductModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof TallyDSProductModel
     */
    price: number;
    /**
     * 
     * @type {TallyPriceTypeModel}
     * @memberof TallyDSProductModel
     */
    priceType?: TallyPriceTypeModel;
    /**
     * 
     * @type {string}
     * @memberof TallyDSProductModel
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof TallyDSProductModel
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof TallyDSProductModel
     */
    note?: string;
    /**
     * 
     * @type {Array<TallyDSModifierGroupModel>}
     * @memberof TallyDSProductModel
     */
    modifierGroups?: Array<TallyDSModifierGroupModel>;
    /**
     * 
     * @type {Array<TallyDSProductModel>}
     * @memberof TallyDSProductModel
     */
    childItems?: Array<TallyDSProductModel>;
    /**
     * 
     * @type {string}
     * @memberof TallyDSProductModel
     */
    udpRecommendationId?: string;
    /**
     * 
     * @type {TallyDSModifierMetadataModel}
     * @memberof TallyDSProductModel
     */
    metadata?: TallyDSModifierMetadataModel;
}

export function TallyDSProductModelFromJSON(json: any): TallyDSProductModel {
    return TallyDSProductModelFromJSONTyped(json, false);
}

export function TallyDSProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyDSProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'priceType': !exists(json, 'priceType') ? undefined : TallyPriceTypeModelFromJSON(json['priceType']),
        'productId': json['productId'],
        'quantity': json['quantity'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(TallyDSModifierGroupModelFromJSON)),
        'childItems': !exists(json, 'childItems') ? undefined : ((json['childItems'] as Array<any>).map(TallyDSProductModelFromJSON)),
        'udpRecommendationId': !exists(json, 'udpRecommendationId') ? undefined : json['udpRecommendationId'],
        'metadata': !exists(json, 'metadata') ? undefined : TallyDSModifierMetadataModelFromJSON(json['metadata']),
    };
}

export function TallyDSProductModelToJSON(value?: TallyDSProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'description': value.description,
        'price': value.price,
        'priceType': TallyPriceTypeModelToJSON(value.priceType),
        'productId': value.productId,
        'quantity': value.quantity,
        'note': value.note,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(TallyDSModifierGroupModelToJSON)),
        'childItems': value.childItems === undefined ? undefined : ((value.childItems as Array<any>).map(TallyDSProductModelToJSON)),
        'udpRecommendationId': value.udpRecommendationId,
        'metadata': TallyDSModifierMetadataModelToJSON(value.metadata),
    };
}

