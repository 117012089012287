/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WalletAccountTypesModel,
    WalletAccountTypesModelFromJSON,
    WalletAccountTypesModelFromJSONTyped,
    WalletAccountTypesModelToJSON,
} from './WalletAccountTypesModel';

/**
 * EDIT CREDIT|GIFT CARD
 * @export
 * @interface IEditCardRequestModel
 */
export interface IEditCardRequestModel {
    /**
     * 
     * @type {string}
     * @memberof IEditCardRequestModel
     */
    nickname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IEditCardRequestModel
     */
    preferred?: boolean;
    /**
     * 
     * @type {WalletAccountTypesModel}
     * @memberof IEditCardRequestModel
     */
    accountType?: WalletAccountTypesModel;
}

export function IEditCardRequestModelFromJSON(json: any): IEditCardRequestModel {
    return IEditCardRequestModelFromJSONTyped(json, false);
}

export function IEditCardRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IEditCardRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
        'accountType': !exists(json, 'accountType') ? undefined : WalletAccountTypesModelFromJSON(json['accountType']),
    };
}

export function IEditCardRequestModelToJSON(value?: IEditCardRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nickname': value.nickname,
        'preferred': value.preferred,
        'accountType': WalletAccountTypesModelToJSON(value.accountType),
    };
}

