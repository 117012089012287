/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SizeGroupModel
 */
export interface SizeGroupModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupModel
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SizeGroupModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof SizeGroupModel
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupModel
     */
    parentGroupId?: string;
}

export function SizeGroupModelFromJSON(json: any): SizeGroupModel {
    return SizeGroupModelFromJSONTyped(json, false);
}

export function SizeGroupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SizeGroupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'parentGroupId': !exists(json, 'parentGroupId') ? undefined : json['parentGroupId'],
    };
}

export function SizeGroupModelToJSON(value?: SizeGroupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'metadata': value.metadata,
        'sequence': value.sequence,
        'parentGroupId': value.parentGroupId,
    };
}

