/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SellingChannelNamesModel {
    Weboa = 'WEBOA',
    Mobile = 'MOBILE',
    Choice = 'CHOICE',
    Store = 'STORE'
}

export function SellingChannelNamesModelFromJSON(json: any): SellingChannelNamesModel {
    return SellingChannelNamesModelFromJSONTyped(json, false);
}

export function SellingChannelNamesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellingChannelNamesModel {
    return json as SellingChannelNamesModel;
}

export function SellingChannelNamesModelToJSON(value?: SellingChannelNamesModel | null): any {
    return value as any;
}

