/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IAvailableTimesModel,
    IAvailableTimesModelFromJSON,
    IAvailableTimesModelFromJSONTyped,
    IAvailableTimesModelToJSON,
} from './IAvailableTimesModel';

/**
 * 
 * @export
 * @interface LocationPickupAndDeliveryResponseModel
 */
export interface LocationPickupAndDeliveryResponseModel {
    /**
     * 
     * @type {IAvailableTimesModel}
     * @memberof LocationPickupAndDeliveryResponseModel
     */
    pickup: IAvailableTimesModel;
    /**
     * 
     * @type {IAvailableTimesModel}
     * @memberof LocationPickupAndDeliveryResponseModel
     */
    delivery: IAvailableTimesModel;
}

export function LocationPickupAndDeliveryResponseModelFromJSON(json: any): LocationPickupAndDeliveryResponseModel {
    return LocationPickupAndDeliveryResponseModelFromJSONTyped(json, false);
}

export function LocationPickupAndDeliveryResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationPickupAndDeliveryResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pickup': IAvailableTimesModelFromJSON(json['pickup']),
        'delivery': IAvailableTimesModelFromJSON(json['delivery']),
    };
}

export function LocationPickupAndDeliveryResponseModelToJSON(value?: LocationPickupAndDeliveryResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pickup': IAvailableTimesModelToJSON(value.pickup),
        'delivery': IAvailableTimesModelToJSON(value.delivery),
    };
}

