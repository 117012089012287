/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandIdEnumModel,
    BrandIdEnumModelFromJSON,
    BrandIdEnumModelFromJSONTyped,
    BrandIdEnumModelToJSON,
} from './BrandIdEnumModel';
import {
    IOrderSubmitResponseErrorModel,
    IOrderSubmitResponseErrorModelFromJSON,
    IOrderSubmitResponseErrorModelFromJSONTyped,
    IOrderSubmitResponseErrorModelToJSON,
} from './IOrderSubmitResponseErrorModel';
import {
    OrderResponseFulfillmentModel,
    OrderResponseFulfillmentModelFromJSON,
    OrderResponseFulfillmentModelFromJSONTyped,
    OrderResponseFulfillmentModelToJSON,
} from './OrderResponseFulfillmentModel';
import {
    OrderSubmitRequestStatusModel,
    OrderSubmitRequestStatusModelFromJSON,
    OrderSubmitRequestStatusModelFromJSONTyped,
    OrderSubmitRequestStatusModelToJSON,
} from './OrderSubmitRequestStatusModel';

/**
 * 
 * @export
 * @interface OrderResponseModel
 */
export interface OrderResponseModel {
    /**
     * 
     * @type {BrandIdEnumModel}
     * @memberof OrderResponseModel
     */
    brandId: BrandIdEnumModel;
    /**
     * 
     * @type {OrderSubmitRequestStatusModel}
     * @memberof OrderResponseModel
     */
    requestStatus: OrderSubmitRequestStatusModel;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseModel
     */
    idempotentId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseModel
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseModel
     */
    orderStatus?: string;
    /**
     * 
     * @type {OrderResponseFulfillmentModel}
     * @memberof OrderResponseModel
     */
    fulfillment: OrderResponseFulfillmentModel;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseModel
     */
    errorMessage?: string;
    /**
     * 
     * @type {Array<IOrderSubmitResponseErrorModel>}
     * @memberof OrderResponseModel
     */
    errors?: Array<IOrderSubmitResponseErrorModel>;
}

export function OrderResponseModelFromJSON(json: any): OrderResponseModel {
    return OrderResponseModelFromJSONTyped(json, false);
}

export function OrderResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brandId': BrandIdEnumModelFromJSON(json['brandId']),
        'requestStatus': OrderSubmitRequestStatusModelFromJSON(json['requestStatus']),
        'idempotentId': json['idempotentId'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : json['orderStatus'],
        'fulfillment': OrderResponseFulfillmentModelFromJSON(json['fulfillment']),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(IOrderSubmitResponseErrorModelFromJSON)),
    };
}

export function OrderResponseModelToJSON(value?: OrderResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brandId': BrandIdEnumModelToJSON(value.brandId),
        'requestStatus': OrderSubmitRequestStatusModelToJSON(value.requestStatus),
        'idempotentId': value.idempotentId,
        'orderId': value.orderId,
        'orderStatus': value.orderStatus,
        'fulfillment': OrderResponseFulfillmentModelToJSON(value.fulfillment),
        'errorMessage': value.errorMessage,
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(IOrderSubmitResponseErrorModelToJSON)),
    };
}

