/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TTallyErrorCodeModel {
    QuantitiesModifiersNotValid = 'QUANTITIES_MODIFIERS_NOT_VALID',
    InvalidOrder = 'INVALID_ORDER',
    ProductsNotAvailable = 'PRODUCTS_NOT_AVAILABLE',
    OrderTimeNotValid = 'ORDER_TIME_NOT_VALID',
    ExceededMaxOrderAmount = 'EXCEEDED_MAX_ORDER_AMOUNT',
    LocationNotAvailable = 'LOCATION_NOT_AVAILABLE',
    QuantitiesModifierGroupNotValid = 'QUANTITIES_MODIFIER_GROUP_NOT_VALID',
    DeliveryProviderDeliveryTimeNotAvailable = 'DELIVERY_PROVIDER_DELIVERY_TIME_NOT_AVAILABLE',
    TimeValidationError = 'TIME_VALIDATION_ERROR',
    PosException154 = 'POS_EXCEPTION_154',
    NotFound = 'NOT_FOUND'
}

export function TTallyErrorCodeModelFromJSON(json: any): TTallyErrorCodeModel {
    return TTallyErrorCodeModelFromJSONTyped(json, false);
}

export function TTallyErrorCodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TTallyErrorCodeModel {
    return json as TTallyErrorCodeModel;
}

export function TTallyErrorCodeModelToJSON(value?: TTallyErrorCodeModel | null): any {
    return value as any;
}

