/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCatalogCertificateModel,
    TCatalogCertificateModelFromJSON,
    TCatalogCertificateModelFromJSONTyped,
    TCatalogCertificateModelToJSON,
} from './TCatalogCertificateModel';
import {
    TCatalogRewardCategoryCodeDescriptionModel,
    TCatalogRewardCategoryCodeDescriptionModelFromJSON,
    TCatalogRewardCategoryCodeDescriptionModelFromJSONTyped,
    TCatalogRewardCategoryCodeDescriptionModelToJSON,
} from './TCatalogRewardCategoryCodeDescriptionModel';

/**
 * 
 * @export
 * @interface ICatalogRewardCategoryModel
 */
export interface ICatalogRewardCategoryModel {
    /**
     * 
     * @type {number}
     * @memberof ICatalogRewardCategoryModel
     */
    totalCount: number;
    /**
     * 
     * @type {TCatalogRewardCategoryCodeDescriptionModel}
     * @memberof ICatalogRewardCategoryModel
     */
    description: TCatalogRewardCategoryCodeDescriptionModel;
    /**
     * 
     * @type {Array<TCatalogCertificateModel>}
     * @memberof ICatalogRewardCategoryModel
     */
    certificates: Array<TCatalogCertificateModel>;
}

export function ICatalogRewardCategoryModelFromJSON(json: any): ICatalogRewardCategoryModel {
    return ICatalogRewardCategoryModelFromJSONTyped(json, false);
}

export function ICatalogRewardCategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICatalogRewardCategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'description': TCatalogRewardCategoryCodeDescriptionModelFromJSON(json['description']),
        'certificates': ((json['certificates'] as Array<any>).map(TCatalogCertificateModelFromJSON)),
    };
}

export function ICatalogRewardCategoryModelToJSON(value?: ICatalogRewardCategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'description': TCatalogRewardCategoryCodeDescriptionModelToJSON(value.description),
        'certificates': ((value.certificates as Array<any>).map(TCatalogCertificateModelToJSON)),
    };
}

