/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderPaymentDetailsModel,
    OrderPaymentDetailsModelFromJSON,
    OrderPaymentDetailsModelFromJSONTyped,
    OrderPaymentDetailsModelToJSON,
} from './OrderPaymentDetailsModel';
import {
    PaymentTypeModel,
    PaymentTypeModelFromJSON,
    PaymentTypeModelFromJSONTyped,
    PaymentTypeModelToJSON,
} from './PaymentTypeModel';

/**
 * 
 * @export
 * @interface OrderPaymentModel
 */
export interface OrderPaymentModel {
    /**
     * 
     * @type {PaymentTypeModel}
     * @memberof OrderPaymentModel
     */
    type: PaymentTypeModel;
    /**
     * 
     * @type {OrderPaymentDetailsModel}
     * @memberof OrderPaymentModel
     */
    details?: OrderPaymentDetailsModel;
}

export function OrderPaymentModelFromJSON(json: any): OrderPaymentModel {
    return OrderPaymentModelFromJSONTyped(json, false);
}

export function OrderPaymentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPaymentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': PaymentTypeModelFromJSON(json['type']),
        'details': !exists(json, 'details') ? undefined : OrderPaymentDetailsModelFromJSON(json['details']),
    };
}

export function OrderPaymentModelToJSON(value?: OrderPaymentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': PaymentTypeModelToJSON(value.type),
        'details': OrderPaymentDetailsModelToJSON(value.details),
    };
}

