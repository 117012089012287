/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILegalWarningModel,
    ILegalWarningModelFromJSON,
    ILegalWarningModelFromJSONTyped,
    ILegalWarningModelToJSON,
} from './ILegalWarningModel';
import {
    ILocationAdditionalFeaturesModel,
    ILocationAdditionalFeaturesModelFromJSON,
    ILocationAdditionalFeaturesModelFromJSONTyped,
    ILocationAdditionalFeaturesModelToJSON,
} from './ILocationAdditionalFeaturesModel';
import {
    ILocationContactDetailsModel,
    ILocationContactDetailsModelFromJSON,
    ILocationContactDetailsModelFromJSONTyped,
    ILocationContactDetailsModelToJSON,
} from './ILocationContactDetailsModel';
import {
    ILocationDetailsModel,
    ILocationDetailsModelFromJSON,
    ILocationDetailsModelFromJSONTyped,
    ILocationDetailsModelToJSON,
} from './ILocationDetailsModel';
import {
    ILocationDistanceModel,
    ILocationDistanceModelFromJSON,
    ILocationDistanceModelFromJSONTyped,
    ILocationDistanceModelToJSON,
} from './ILocationDistanceModel';
import {
    IServiceTypeModel,
    IServiceTypeModelFromJSON,
    IServiceTypeModelFromJSONTyped,
    IServiceTypeModelToJSON,
} from './IServiceTypeModel';
import {
    IServicesModel,
    IServicesModelFromJSON,
    IServicesModelFromJSONTyped,
    IServicesModelToJSON,
} from './IServicesModel';
import {
    OrderLimitsDtoModelModel,
    OrderLimitsDtoModelModelFromJSON,
    OrderLimitsDtoModelModelFromJSONTyped,
    OrderLimitsDtoModelModelToJSON,
} from './OrderLimitsDtoModelModel';
import {
    SEORestaurantSchemaModel,
    SEORestaurantSchemaModelFromJSON,
    SEORestaurantSchemaModelFromJSONTyped,
    SEORestaurantSchemaModelToJSON,
} from './SEORestaurantSchemaModel';
import {
    TLocationStatusModel,
    TLocationStatusModelFromJSON,
    TLocationStatusModelFromJSONTyped,
    TLocationStatusModelToJSON,
} from './TLocationStatusModel';

/**
 * 
 * @export
 * @interface ILocationWithDetailsModel
 */
export interface ILocationWithDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationWithDetailsModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationWithDetailsModel
     */
    brandName?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationWithDetailsModel
     */
    displayName?: string;
    /**
     * 
     * @type {TLocationStatusModel}
     * @memberof ILocationWithDetailsModel
     */
    status?: TLocationStatusModel;
    /**
     * 
     * @type {string}
     * @memberof ILocationWithDetailsModel
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationWithDetailsModel
     */
    isDigitallyEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationWithDetailsModel
     */
    isTippingEnabled?: boolean;
    /**
     * 
     * @type {ILocationDistanceModel}
     * @memberof ILocationWithDetailsModel
     */
    distance?: ILocationDistanceModel;
    /**
     * 
     * @type {ILocationContactDetailsModel}
     * @memberof ILocationWithDetailsModel
     */
    contactDetails: ILocationContactDetailsModel;
    /**
     * 
     * @type {Array<IServicesModel>}
     * @memberof ILocationWithDetailsModel
     */
    services?: Array<IServicesModel>;
    /**
     * 
     * @type {ILocationDetailsModel}
     * @memberof ILocationWithDetailsModel
     */
    details?: ILocationDetailsModel;
    /**
     * 
     * @type {Array<IServiceTypeModel>}
     * @memberof ILocationWithDetailsModel
     */
    features?: Array<IServiceTypeModel>;
    /**
     * 
     * @type {ILocationAdditionalFeaturesModel}
     * @memberof ILocationWithDetailsModel
     */
    additionalFeatures: ILocationAdditionalFeaturesModel;
    /**
     * 
     * @type {Array<ILegalWarningModel>}
     * @memberof ILocationWithDetailsModel
     */
    legalWarnings?: Array<ILegalWarningModel>;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationWithDetailsModel
     */
    isOnlineOrderAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationWithDetailsModel
     */
    isClosed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ILocationWithDetailsModel
     */
    url?: string;
    /**
     * 
     * @type {SEORestaurantSchemaModel}
     * @memberof ILocationWithDetailsModel
     */
    schema?: SEORestaurantSchemaModel;
    /**
     * 
     * @type {OrderLimitsDtoModelModel}
     * @memberof ILocationWithDetailsModel
     */
    orderLimits?: OrderLimitsDtoModelModel;
}

export function ILocationWithDetailsModelFromJSON(json: any): ILocationWithDetailsModel {
    return ILocationWithDetailsModelFromJSONTyped(json, false);
}

export function ILocationWithDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationWithDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'status': !exists(json, 'status') ? undefined : TLocationStatusModelFromJSON(json['status']),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'isDigitallyEnabled': !exists(json, 'isDigitallyEnabled') ? undefined : json['isDigitallyEnabled'],
        'isTippingEnabled': !exists(json, 'isTippingEnabled') ? undefined : json['isTippingEnabled'],
        'distance': !exists(json, 'distance') ? undefined : ILocationDistanceModelFromJSON(json['distance']),
        'contactDetails': ILocationContactDetailsModelFromJSON(json['contactDetails']),
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(IServicesModelFromJSON)),
        'details': !exists(json, 'details') ? undefined : ILocationDetailsModelFromJSON(json['details']),
        'features': !exists(json, 'features') ? undefined : ((json['features'] as Array<any>).map(IServiceTypeModelFromJSON)),
        'additionalFeatures': ILocationAdditionalFeaturesModelFromJSON(json['additionalFeatures']),
        'legalWarnings': !exists(json, 'legalWarnings') ? undefined : ((json['legalWarnings'] as Array<any>).map(ILegalWarningModelFromJSON)),
        'isOnlineOrderAvailable': json['isOnlineOrderAvailable'],
        'isClosed': json['isClosed'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'schema': !exists(json, 'schema') ? undefined : SEORestaurantSchemaModelFromJSON(json['schema']),
        'orderLimits': !exists(json, 'orderLimits') ? undefined : OrderLimitsDtoModelModelFromJSON(json['orderLimits']),
    };
}

export function ILocationWithDetailsModelToJSON(value?: ILocationWithDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'brandName': value.brandName,
        'displayName': value.displayName,
        'status': TLocationStatusModelToJSON(value.status),
        'timezone': value.timezone,
        'isDigitallyEnabled': value.isDigitallyEnabled,
        'isTippingEnabled': value.isTippingEnabled,
        'distance': ILocationDistanceModelToJSON(value.distance),
        'contactDetails': ILocationContactDetailsModelToJSON(value.contactDetails),
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(IServicesModelToJSON)),
        'details': ILocationDetailsModelToJSON(value.details),
        'features': value.features === undefined ? undefined : ((value.features as Array<any>).map(IServiceTypeModelToJSON)),
        'additionalFeatures': ILocationAdditionalFeaturesModelToJSON(value.additionalFeatures),
        'legalWarnings': value.legalWarnings === undefined ? undefined : ((value.legalWarnings as Array<any>).map(ILegalWarningModelToJSON)),
        'isOnlineOrderAvailable': value.isOnlineOrderAvailable,
        'isClosed': value.isClosed,
        'url': value.url,
        'schema': SEORestaurantSchemaModelToJSON(value.schema),
        'orderLimits': OrderLimitsDtoModelModelToJSON(value.orderLimits),
    };
}

