/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface INotificationBodyRequestModel
 */
export interface INotificationBodyRequestModel {
    /**
     * 
     * @type {string}
     * @memberof INotificationBodyRequestModel
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof INotificationBodyRequestModel
     */
    orderId: string;
}

export function INotificationBodyRequestModelFromJSON(json: any): INotificationBodyRequestModel {
    return INotificationBodyRequestModelFromJSONTyped(json, false);
}

export function INotificationBodyRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): INotificationBodyRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'orderId': json['orderId'],
    };
}

export function INotificationBodyRequestModelToJSON(value?: INotificationBodyRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'orderId': value.orderId,
    };
}

