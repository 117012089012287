/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentTypeEnumModel {
    Card = 'Card',
    GiftCard = 'GiftCard',
    CardOnFile = 'CardOnFile'
}

export function PaymentTypeEnumModelFromJSON(json: any): PaymentTypeEnumModel {
    return PaymentTypeEnumModelFromJSONTyped(json, false);
}

export function PaymentTypeEnumModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTypeEnumModel {
    return json as PaymentTypeEnumModel;
}

export function PaymentTypeEnumModelToJSON(value?: PaymentTypeEnumModel | null): any {
    return value as any;
}

