/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IIntervalModel,
    IIntervalModelFromJSON,
    IIntervalModelFromJSONTyped,
    IIntervalModelToJSON,
} from './IIntervalModel';
import {
    OtherPriceModel,
    OtherPriceModelFromJSON,
    OtherPriceModelFromJSONTyped,
    OtherPriceModelToJSON,
} from './OtherPriceModel';

/**
 * 
 * @export
 * @interface PriceModel
 */
export interface PriceModel {
    /**
     * 
     * @type {number}
     * @memberof PriceModel
     */
    currentPrice?: number;
    /**
     * 
     * @type {{ [key: string]: IIntervalModel; }}
     * @memberof PriceModel
     */
    validity?: { [key: string]: IIntervalModel; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PriceModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: OtherPriceModel; }}
     * @memberof PriceModel
     */
    otherPrices?: { [key: string]: OtherPriceModel; };
}

export function PriceModelFromJSON(json: any): PriceModel {
    return PriceModelFromJSONTyped(json, false);
}

export function PriceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPrice': !exists(json, 'currentPrice') ? undefined : json['currentPrice'],
        'validity': !exists(json, 'validity') ? undefined : (mapValues(json['validity'], IIntervalModelFromJSON)),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'otherPrices': !exists(json, 'otherPrices') ? undefined : (mapValues(json['otherPrices'], OtherPriceModelFromJSON)),
    };
}

export function PriceModelToJSON(value?: PriceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPrice': value.currentPrice,
        'validity': value.validity === undefined ? undefined : (mapValues(value.validity, IIntervalModelToJSON)),
        'metadata': value.metadata,
        'otherPrices': value.otherPrices === undefined ? undefined : (mapValues(value.otherPrices, OtherPriceModelToJSON)),
    };
}

