/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IVisitRegisterRequestCustomerPhoneNumberModel,
    IVisitRegisterRequestCustomerPhoneNumberModelFromJSON,
    IVisitRegisterRequestCustomerPhoneNumberModelFromJSONTyped,
    IVisitRegisterRequestCustomerPhoneNumberModelToJSON,
} from './IVisitRegisterRequestCustomerPhoneNumberModel';

/**
 * 
 * @export
 * @interface IVisitRegisterRequestCustomerModel
 */
export interface IVisitRegisterRequestCustomerModel {
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestCustomerModel
     */
    profileAvatarUrl?: string;
    /**
     * 
     * @type {IVisitRegisterRequestCustomerPhoneNumberModel}
     * @memberof IVisitRegisterRequestCustomerModel
     */
    phoneNumber?: IVisitRegisterRequestCustomerPhoneNumberModel;
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestCustomerModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestCustomerModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestCustomerModel
     */
    id?: string;
}

export function IVisitRegisterRequestCustomerModelFromJSON(json: any): IVisitRegisterRequestCustomerModel {
    return IVisitRegisterRequestCustomerModelFromJSONTyped(json, false);
}

export function IVisitRegisterRequestCustomerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterRequestCustomerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profileAvatarUrl': !exists(json, 'profileAvatarUrl') ? undefined : json['profileAvatarUrl'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : IVisitRegisterRequestCustomerPhoneNumberModelFromJSON(json['phoneNumber']),
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': json['firstName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function IVisitRegisterRequestCustomerModelToJSON(value?: IVisitRegisterRequestCustomerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profileAvatarUrl': value.profileAvatarUrl,
        'phoneNumber': IVisitRegisterRequestCustomerPhoneNumberModelToJSON(value.phoneNumber),
        'lastName': value.lastName,
        'firstName': value.firstName,
        'id': value.id,
    };
}

