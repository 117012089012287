/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IVisitRegisterResponseFulfillmentModel,
    IVisitRegisterResponseFulfillmentModelFromJSON,
    IVisitRegisterResponseFulfillmentModelFromJSONTyped,
    IVisitRegisterResponseFulfillmentModelToJSON,
} from './IVisitRegisterResponseFulfillmentModel';
import {
    IVisitRegisterResponseOrderModel,
    IVisitRegisterResponseOrderModelFromJSON,
    IVisitRegisterResponseOrderModelFromJSONTyped,
    IVisitRegisterResponseOrderModelToJSON,
} from './IVisitRegisterResponseOrderModel';

/**
 * 
 * @export
 * @interface IVisitRegisterResponseModel
 */
export interface IVisitRegisterResponseModel {
    /**
     * 
     * @type {IVisitRegisterResponseOrderModel}
     * @memberof IVisitRegisterResponseModel
     */
    order: IVisitRegisterResponseOrderModel;
    /**
     * 
     * @type {IVisitRegisterResponseFulfillmentModel}
     * @memberof IVisitRegisterResponseModel
     */
    fulfillment: IVisitRegisterResponseFulfillmentModel;
}

export function IVisitRegisterResponseModelFromJSON(json: any): IVisitRegisterResponseModel {
    return IVisitRegisterResponseModelFromJSONTyped(json, false);
}

export function IVisitRegisterResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': IVisitRegisterResponseOrderModelFromJSON(json['order']),
        'fulfillment': IVisitRegisterResponseFulfillmentModelFromJSON(json['fulfillment']),
    };
}

export function IVisitRegisterResponseModelToJSON(value?: IVisitRegisterResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': IVisitRegisterResponseOrderModelToJSON(value.order),
        'fulfillment': IVisitRegisterResponseFulfillmentModelToJSON(value.fulfillment),
    };
}

