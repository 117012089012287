/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Certificate status
 * @export
 * @enum {string}
 */
export enum TCertificateStatusModel {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Cancelled = 'CANCELLED',
    Expired = 'EXPIRED',
    Redeemed = 'REDEEMED',
    Deleted = 'DELETED',
    PendingCancellation = 'PENDING_CANCELLATION'
}

export function TCertificateStatusModelFromJSON(json: any): TCertificateStatusModel {
    return TCertificateStatusModelFromJSONTyped(json, false);
}

export function TCertificateStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCertificateStatusModel {
    return json as TCertificateStatusModel;
}

export function TCertificateStatusModelToJSON(value?: TCertificateStatusModel | null): any {
    return value as any;
}

