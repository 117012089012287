/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SEORestaurantSchemaAddressModel,
    SEORestaurantSchemaAddressModelFromJSON,
    SEORestaurantSchemaAddressModelFromJSONTyped,
    SEORestaurantSchemaAddressModelToJSON,
} from './SEORestaurantSchemaAddressModel';

/**
 * 
 * @export
 * @interface SEORestaurantSchemaModel
 */
export interface SEORestaurantSchemaModel {
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaModel
     */
    context: SEORestaurantSchemaModelContextEnum;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaModel
     */
    type: SEORestaurantSchemaModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaModel
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaModel
     */
    priceRange: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SEORestaurantSchemaModel
     */
    servesCuisine: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaModel
     */
    telephone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SEORestaurantSchemaModel
     */
    openingHours?: Array<string>;
    /**
     * 
     * @type {SEORestaurantSchemaAddressModel}
     * @memberof SEORestaurantSchemaModel
     */
    address: SEORestaurantSchemaAddressModel;
}

/**
* @export
* @enum {string}
*/
export enum SEORestaurantSchemaModelContextEnum {
    HttpsSchemaOrg = 'https://schema.org'
}/**
* @export
* @enum {string}
*/
export enum SEORestaurantSchemaModelTypeEnum {
    Restaurant = 'Restaurant'
}

export function SEORestaurantSchemaModelFromJSON(json: any): SEORestaurantSchemaModel {
    return SEORestaurantSchemaModelFromJSONTyped(json, false);
}

export function SEORestaurantSchemaModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SEORestaurantSchemaModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['@context'],
        'type': json['@type'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'name': json['name'],
        'priceRange': json['priceRange'],
        'servesCuisine': json['servesCuisine'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'openingHours': !exists(json, 'openingHours') ? undefined : json['openingHours'],
        'address': SEORestaurantSchemaAddressModelFromJSON(json['address']),
    };
}

export function SEORestaurantSchemaModelToJSON(value?: SEORestaurantSchemaModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '@context': value.context,
        '@type': value.type,
        'image': value.image,
        'name': value.name,
        'priceRange': value.priceRange,
        'servesCuisine': value.servesCuisine,
        'telephone': value.telephone,
        'openingHours': value.openingHours,
        'address': SEORestaurantSchemaAddressModelToJSON(value.address),
    };
}

