/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorTypeINTERNALModel {
    Internal = 'INTERNAL'
}

export function TErrorTypeINTERNALModelFromJSON(json: any): TErrorTypeINTERNALModel {
    return TErrorTypeINTERNALModelFromJSONTyped(json, false);
}

export function TErrorTypeINTERNALModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorTypeINTERNALModel {
    return json as TErrorTypeINTERNALModel;
}

export function TErrorTypeINTERNALModelToJSON(value?: TErrorTypeINTERNALModel | null): any {
    return value as any;
}

