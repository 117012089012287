/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IActivateOfferModelModel
 */
export interface IActivateOfferModelModel {
    /**
     * 
     * @type {string}
     * @memberof IActivateOfferModelModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof IActivateOfferModelModel
     */
    operation: IActivateOfferModelModelOperationEnum;
}

/**
* @export
* @enum {string}
*/
export enum IActivateOfferModelModelOperationEnum {
    Activate = 'ACTIVATE'
}

export function IActivateOfferModelModelFromJSON(json: any): IActivateOfferModelModel {
    return IActivateOfferModelModelFromJSONTyped(json, false);
}

export function IActivateOfferModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IActivateOfferModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'operation': json['operation'],
    };
}

export function IActivateOfferModelModelToJSON(value?: IActivateOfferModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'operation': value.operation,
    };
}

