/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IGiftCardArbysBalanceRequestModel,
    IGiftCardArbysBalanceRequestModelFromJSON,
    IGiftCardArbysBalanceRequestModelFromJSONTyped,
    IGiftCardArbysBalanceRequestModelToJSON,
} from './IGiftCardArbysBalanceRequestModel';
import {
    IGiftCardBWWBalanceRequestModel,
    IGiftCardBWWBalanceRequestModelFromJSON,
    IGiftCardBWWBalanceRequestModelFromJSONTyped,
    IGiftCardBWWBalanceRequestModelToJSON,
} from './IGiftCardBWWBalanceRequestModel';
import {
    IGiftCardSncBalanceRequestModel,
    IGiftCardSncBalanceRequestModelFromJSON,
    IGiftCardSncBalanceRequestModelFromJSONTyped,
    IGiftCardSncBalanceRequestModelToJSON,
} from './IGiftCardSncBalanceRequestModel';

/**
 * 
 * @export
 * @interface IGiftCardBalanceRequestModel
 */
export interface IGiftCardBalanceRequestModel {
    /**
     * Card Number
     * @type {string}
     * @memberof IGiftCardBalanceRequestModel
     */
    cardNumber: string;
    /**
     * Card Pin
     * @type {string}
     * @memberof IGiftCardBalanceRequestModel
     */
    cardPin: string;
    /**
     * location Id
     * @type {string}
     * @memberof IGiftCardBalanceRequestModel
     */
    locationId: string;
    /**
     * session Key
     * FreedomPay session key
     * @type {string}
     * @memberof IGiftCardBalanceRequestModel
     */
    sessionKey: string;
    /**
     * payment Key
     * FreedomPay payment Key
     * @type {string}
     * @memberof IGiftCardBalanceRequestModel
     */
    paymentKey: string;
    /**
     * Fullfilment type
     * @type {string}
     * @memberof IGiftCardBalanceRequestModel
     */
    fulfillmentType: string;
}

export function IGiftCardBalanceRequestModelFromJSON(json: any): IGiftCardBalanceRequestModel {
    return IGiftCardBalanceRequestModelFromJSONTyped(json, false);
}

export function IGiftCardBalanceRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGiftCardBalanceRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardNumber': json['cardNumber'],
        'cardPin': json['cardPin'],
        'locationId': json['locationId'],
        'sessionKey': json['sessionKey'],
        'paymentKey': json['paymentKey'],
        'fulfillmentType': json['fulfillmentType'],
    };
}

export function IGiftCardBalanceRequestModelToJSON(value?: IGiftCardBalanceRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cardNumber': value.cardNumber,
        'cardPin': value.cardPin,
        'locationId': value.locationId,
        'sessionKey': value.sessionKey,
        'paymentKey': value.paymentKey,
        'fulfillmentType': value.fulfillmentType,
    };
}

