/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IActivityHistoryTransactionProductsModel
 */
export interface IActivityHistoryTransactionProductsModel {
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionProductsModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof IActivityHistoryTransactionProductsModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionProductsModel
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionProductsModel
     */
    price?: number;
    /**
     * 
     * @type {Array<IActivityHistoryTransactionProductsModel>}
     * @memberof IActivityHistoryTransactionProductsModel
     */
    modifiers?: Array<IActivityHistoryTransactionProductsModel>;
}

export function IActivityHistoryTransactionProductsModelFromJSON(json: any): IActivityHistoryTransactionProductsModel {
    return IActivityHistoryTransactionProductsModelFromJSONTyped(json, false);
}

export function IActivityHistoryTransactionProductsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IActivityHistoryTransactionProductsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'modifiers': !exists(json, 'modifiers') ? undefined : ((json['modifiers'] as Array<any>).map(IActivityHistoryTransactionProductsModelFromJSON)),
    };
}

export function IActivityHistoryTransactionProductsModelToJSON(value?: IActivityHistoryTransactionProductsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'description': value.description,
        'quantity': value.quantity,
        'price': value.price,
        'modifiers': value.modifiers === undefined ? undefined : ((value.modifiers as Array<any>).map(IActivityHistoryTransactionProductsModelToJSON)),
    };
}

