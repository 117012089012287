/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationByStateOrProvinceDetailsModel,
    ILocationByStateOrProvinceDetailsModelFromJSON,
    ILocationByStateOrProvinceDetailsModelFromJSONTyped,
    ILocationByStateOrProvinceDetailsModelToJSON,
} from './ILocationByStateOrProvinceDetailsModel';

/**
 * 
 * @export
 * @interface ILocationsByCityModel
 */
export interface ILocationsByCityModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationsByCityModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationsByCityModel
     */
    displayName: string;
    /**
     * 
     * @type {number}
     * @memberof ILocationsByCityModel
     */
    count: number;
    /**
     * 
     * @type {Array<ILocationByStateOrProvinceDetailsModel>}
     * @memberof ILocationsByCityModel
     */
    locations?: Array<ILocationByStateOrProvinceDetailsModel>;
}

export function ILocationsByCityModelFromJSON(json: any): ILocationsByCityModel {
    return ILocationsByCityModelFromJSONTyped(json, false);
}

export function ILocationsByCityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationsByCityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayName': json['displayName'],
        'count': json['count'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(ILocationByStateOrProvinceDetailsModelFromJSON)),
    };
}

export function ILocationsByCityModelToJSON(value?: ILocationsByCityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'count': value.count,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(ILocationByStateOrProvinceDetailsModelToJSON)),
    };
}

