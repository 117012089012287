/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDiscountRewardsCertificateModel,
    IDiscountRewardsCertificateModelFromJSON,
    IDiscountRewardsCertificateModelFromJSONTyped,
    IDiscountRewardsCertificateModelToJSON,
} from './IDiscountRewardsCertificateModel';
import {
    IDiscountRewardsOfferModel,
    IDiscountRewardsOfferModelFromJSON,
    IDiscountRewardsOfferModelFromJSONTyped,
    IDiscountRewardsOfferModelToJSON,
} from './IDiscountRewardsOfferModel';
import {
    IDiscountRewardsOrderModel,
    IDiscountRewardsOrderModelFromJSON,
    IDiscountRewardsOrderModelFromJSONTyped,
    IDiscountRewardsOrderModelToJSON,
} from './IDiscountRewardsOrderModel';

/**
 * 
 * @export
 * @interface IDiscountRewardsModelModel
 */
export interface IDiscountRewardsModelModel {
    /**
     * 
     * @type {Array<IDiscountRewardsOfferModel>}
     * @memberof IDiscountRewardsModelModel
     */
    offers: Array<IDiscountRewardsOfferModel>;
    /**
     * 
     * @type {Array<IDiscountRewardsCertificateModel>}
     * @memberof IDiscountRewardsModelModel
     */
    certificates: Array<IDiscountRewardsCertificateModel>;
    /**
     * 
     * @type {IDiscountRewardsOrderModel}
     * @memberof IDiscountRewardsModelModel
     */
    order: IDiscountRewardsOrderModel;
}

export function IDiscountRewardsModelModelFromJSON(json: any): IDiscountRewardsModelModel {
    return IDiscountRewardsModelModelFromJSONTyped(json, false);
}

export function IDiscountRewardsModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDiscountRewardsModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offers': ((json['offers'] as Array<any>).map(IDiscountRewardsOfferModelFromJSON)),
        'certificates': ((json['certificates'] as Array<any>).map(IDiscountRewardsCertificateModelFromJSON)),
        'order': IDiscountRewardsOrderModelFromJSON(json['order']),
    };
}

export function IDiscountRewardsModelModelToJSON(value?: IDiscountRewardsModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offers': ((value.offers as Array<any>).map(IDiscountRewardsOfferModelToJSON)),
        'certificates': ((value.certificates as Array<any>).map(IDiscountRewardsCertificateModelToJSON)),
        'order': IDiscountRewardsOrderModelToJSON(value.order),
    };
}

