/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ICheckAmountModelModel
 */
export interface ICheckAmountModelModel {
    /**
     * 
     * @type {string}
     * @memberof ICheckAmountModelModel
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof ICheckAmountModelModel
     */
    currencyCode?: string;
}

export function ICheckAmountModelModelFromJSON(json: any): ICheckAmountModelModel {
    return ICheckAmountModelModelFromJSONTyped(json, false);
}

export function ICheckAmountModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICheckAmountModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
    };
}

export function ICheckAmountModelModelToJSON(value?: ICheckAmountModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currencyCode': value.currencyCode,
    };
}

