/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDropOffModel,
    IDropOffModelFromJSON,
    IDropOffModelFromJSONTyped,
    IDropOffModelToJSON,
} from './IDropOffModel';
import {
    IPickupModel,
    IPickupModelFromJSON,
    IPickupModelFromJSONTyped,
    IPickupModelToJSON,
} from './IPickupModel';

/**
 * 
 * @export
 * @interface IDeliveryLocationsSearchResponseModel
 */
export interface IDeliveryLocationsSearchResponseModel {
    /**
     * 
     * @type {IDropOffModel}
     * @memberof IDeliveryLocationsSearchResponseModel
     */
    dropOff: IDropOffModel;
    /**
     * 
     * @type {IPickupModel}
     * @memberof IDeliveryLocationsSearchResponseModel
     */
    pickUp: IPickupModel;
    /**
     * `true` if dropoff address is within delivery area, `false` if not
     * @type {boolean}
     * @memberof IDeliveryLocationsSearchResponseModel
     */
    isInDeliveryZone: boolean;
}

export function IDeliveryLocationsSearchResponseModelFromJSON(json: any): IDeliveryLocationsSearchResponseModel {
    return IDeliveryLocationsSearchResponseModelFromJSONTyped(json, false);
}

export function IDeliveryLocationsSearchResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDeliveryLocationsSearchResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dropOff': IDropOffModelFromJSON(json['dropOff']),
        'pickUp': IPickupModelFromJSON(json['pickUp']),
        'isInDeliveryZone': json['isInDeliveryZone'],
    };
}

export function IDeliveryLocationsSearchResponseModelToJSON(value?: IDeliveryLocationsSearchResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dropOff': IDropOffModelToJSON(value.dropOff),
        'pickUp': IPickupModelToJSON(value.pickUp),
        'isInDeliveryZone': value.isInDeliveryZone,
    };
}

