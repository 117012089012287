/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IHistoryOrderItemDiscountItemAppliedItemModel,
    IHistoryOrderItemDiscountItemAppliedItemModelFromJSON,
    IHistoryOrderItemDiscountItemAppliedItemModelFromJSONTyped,
    IHistoryOrderItemDiscountItemAppliedItemModelToJSON,
} from './IHistoryOrderItemDiscountItemAppliedItemModel';

/**
 * 
 * @export
 * @interface IHistoryOrderItemDiscountItemModel
 */
export interface IHistoryOrderItemDiscountItemModel {
    /**
     * 
     * @type {number}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    omsOfferCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    certificateId?: string;
    /**
     * 
     * @type {string}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    posDiscountId: string;
    /**
     * 
     * @type {number}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    loyaltyId?: number;
    /**
     * 
     * @type {Array<IHistoryOrderItemDiscountItemAppliedItemModel>}
     * @memberof IHistoryOrderItemDiscountItemModel
     */
    appliedItems?: Array<IHistoryOrderItemDiscountItemAppliedItemModel>;
}

export function IHistoryOrderItemDiscountItemModelFromJSON(json: any): IHistoryOrderItemDiscountItemModel {
    return IHistoryOrderItemDiscountItemModelFromJSONTyped(json, false);
}

export function IHistoryOrderItemDiscountItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IHistoryOrderItemDiscountItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'omsOfferCode': !exists(json, 'omsOfferCode') ? undefined : json['omsOfferCode'],
        'certificateId': !exists(json, 'certificateId') ? undefined : json['certificateId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'posDiscountId': json['posDiscountId'],
        'loyaltyId': !exists(json, 'loyaltyId') ? undefined : json['loyaltyId'],
        'appliedItems': !exists(json, 'appliedItems') ? undefined : ((json['appliedItems'] as Array<any>).map(IHistoryOrderItemDiscountItemAppliedItemModelFromJSON)),
    };
}

export function IHistoryOrderItemDiscountItemModelToJSON(value?: IHistoryOrderItemDiscountItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'omsOfferCode': value.omsOfferCode,
        'certificateId': value.certificateId,
        'name': value.name,
        'posDiscountId': value.posDiscountId,
        'loyaltyId': value.loyaltyId,
        'appliedItems': value.appliedItems === undefined ? undefined : ((value.appliedItems as Array<any>).map(IHistoryOrderItemDiscountItemAppliedItemModelToJSON)),
    };
}

