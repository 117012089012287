/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileOfferRegisterRequestModel
 */
export interface ProfileOfferRegisterRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileOfferRegisterRequestModel
     */
    pid: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOfferRegisterRequestModel
     */
    socd?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOfferRegisterRequestModel
     */
    cacd?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOfferRegisterRequestModel
     */
    cocd?: string;
}

export function ProfileOfferRegisterRequestModelFromJSON(json: any): ProfileOfferRegisterRequestModel {
    return ProfileOfferRegisterRequestModelFromJSONTyped(json, false);
}

export function ProfileOfferRegisterRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileOfferRegisterRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pid': json['pid'],
        'socd': !exists(json, 'socd') ? undefined : json['socd'],
        'cacd': !exists(json, 'cacd') ? undefined : json['cacd'],
        'cocd': !exists(json, 'cocd') ? undefined : json['cocd'],
    };
}

export function ProfileOfferRegisterRequestModelToJSON(value?: ProfileOfferRegisterRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pid': value.pid,
        'socd': value.socd,
        'cacd': value.cacd,
        'cocd': value.cocd,
    };
}

