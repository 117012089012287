/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDetailsModel,
    DiscountDetailsModelFromJSON,
    DiscountDetailsModelFromJSONTyped,
    DiscountDetailsModelToJSON,
} from './DiscountDetailsModel';
import {
    TallyDiscountTypeModel,
    TallyDiscountTypeModelFromJSON,
    TallyDiscountTypeModelFromJSONTyped,
    TallyDiscountTypeModelToJSON,
} from './TallyDiscountTypeModel';

/**
 * 
 * @export
 * @interface DiscountRequestModel
 */
export interface DiscountRequestModel {
    /**
     * 
     * @type {TallyDiscountTypeModel}
     * @memberof DiscountRequestModel
     */
    discountType?: TallyDiscountTypeModel;
    /**
     * 
     * @type {Array<DiscountDetailsModel>}
     * @memberof DiscountRequestModel
     */
    discountDetails?: Array<DiscountDetailsModel>;
}

export function DiscountRequestModelFromJSON(json: any): DiscountRequestModel {
    return DiscountRequestModelFromJSONTyped(json, false);
}

export function DiscountRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountType': !exists(json, 'discountType') ? undefined : TallyDiscountTypeModelFromJSON(json['discountType']),
        'discountDetails': !exists(json, 'discountDetails') ? undefined : ((json['discountDetails'] as Array<any>).map(DiscountDetailsModelFromJSON)),
    };
}

export function DiscountRequestModelToJSON(value?: DiscountRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountType': TallyDiscountTypeModelToJSON(value.discountType),
        'discountDetails': value.discountDetails === undefined ? undefined : ((value.discountDetails as Array<any>).map(DiscountDetailsModelToJSON)),
    };
}

