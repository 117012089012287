/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDetailsDSResponseModel,
    DiscountDetailsDSResponseModelFromJSON,
    DiscountDetailsDSResponseModelFromJSONTyped,
    DiscountDetailsDSResponseModelToJSON,
} from './DiscountDetailsDSResponseModel';

/**
 * 
 * @export
 * @interface DiscountDSResponseModel
 */
export interface DiscountDSResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DiscountDSResponseModel
     */
    discountType?: string;
    /**
     * 
     * @type {Array<DiscountDetailsDSResponseModel>}
     * @memberof DiscountDSResponseModel
     */
    discountDetails: Array<DiscountDetailsDSResponseModel>;
}

export function DiscountDSResponseModelFromJSON(json: any): DiscountDSResponseModel {
    return DiscountDSResponseModelFromJSONTyped(json, false);
}

export function DiscountDSResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDSResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountType': !exists(json, 'discountType') ? undefined : json['discountType'],
        'discountDetails': ((json['discountDetails'] as Array<any>).map(DiscountDetailsDSResponseModelFromJSON)),
    };
}

export function DiscountDSResponseModelToJSON(value?: DiscountDSResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountType': value.discountType,
        'discountDetails': ((value.discountDetails as Array<any>).map(DiscountDetailsDSResponseModelToJSON)),
    };
}

