/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ISurveyQuestionModel,
    ISurveyQuestionModelFromJSON,
    ISurveyQuestionModelFromJSONTyped,
    ISurveyQuestionModelToJSON,
} from './ISurveyQuestionModel';
import {
    ISurveyReplyModel,
    ISurveyReplyModelFromJSON,
    ISurveyReplyModelFromJSONTyped,
    ISurveyReplyModelToJSON,
} from './ISurveyReplyModel';

/**
 * 
 * @export
 * @interface ISurveyModel
 */
export interface ISurveyModel {
    /**
     * 
     * @type {string}
     * @memberof ISurveyModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISurveyModel
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof ISurveyModel
     */
    hasResponded: boolean;
    /**
     * 
     * @type {Array<ISurveyQuestionModel>}
     * @memberof ISurveyModel
     */
    questions: Array<ISurveyQuestionModel>;
    /**
     * 
     * @type {Array<ISurveyReplyModel>}
     * @memberof ISurveyModel
     */
    replies: Array<ISurveyReplyModel>;
}

export function ISurveyModelFromJSON(json: any): ISurveyModel {
    return ISurveyModelFromJSONTyped(json, false);
}

export function ISurveyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ISurveyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'hasResponded': json['hasResponded'],
        'questions': ((json['questions'] as Array<any>).map(ISurveyQuestionModelFromJSON)),
        'replies': ((json['replies'] as Array<any>).map(ISurveyReplyModelFromJSON)),
    };
}

export function ISurveyModelToJSON(value?: ISurveyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'hasResponded': value.hasResponded,
        'questions': ((value.questions as Array<any>).map(ISurveyQuestionModelToJSON)),
        'replies': ((value.replies as Array<any>).map(ISurveyReplyModelToJSON)),
    };
}

