/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OtherPriceTypeEnumModel {
    Original = 'ORIGINAL',
    Promo = 'PROMO',
    HappyHour = 'HAPPY_HOUR',
    Meal = 'MEAL'
}

export function OtherPriceTypeEnumModelFromJSON(json: any): OtherPriceTypeEnumModel {
    return OtherPriceTypeEnumModelFromJSONTyped(json, false);
}

export function OtherPriceTypeEnumModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherPriceTypeEnumModel {
    return json as OtherPriceTypeEnumModel;
}

export function OtherPriceTypeEnumModelToJSON(value?: OtherPriceTypeEnumModel | null): any {
    return value as any;
}

