/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IAccountInfoBannerFieldsModel
 */
export interface IAccountInfoBannerFieldsModel {
    /**
     * Title
     * @type {string}
     * @memberof IAccountInfoBannerFieldsModel
     */
    title: string;
    /**
     * Header
     * @type {string}
     * @memberof IAccountInfoBannerFieldsModel
     */
    header: string;
    /**
     * Description
     * @type {string}
     * @memberof IAccountInfoBannerFieldsModel
     */
    description?: string;
    /**
     * Background Color
     * @type {string}
     * @memberof IAccountInfoBannerFieldsModel
     */
    backgroundColor?: string;
}

export function IAccountInfoBannerFieldsModelFromJSON(json: any): IAccountInfoBannerFieldsModel {
    return IAccountInfoBannerFieldsModelFromJSONTyped(json, false);
}

export function IAccountInfoBannerFieldsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAccountInfoBannerFieldsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'header': json['header'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'backgroundColor': !exists(json, 'backgroundColor') ? undefined : json['backgroundColor'],
    };
}

export function IAccountInfoBannerFieldsModelToJSON(value?: IAccountInfoBannerFieldsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'header': value.header,
        'description': value.description,
        'backgroundColor': value.backgroundColor,
    };
}

