/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyModifierActionCodeModel,
    TallyModifierActionCodeModelFromJSON,
    TallyModifierActionCodeModelFromJSONTyped,
    TallyModifierActionCodeModelToJSON,
} from './TallyModifierActionCodeModel';
import {
    TallyModifierGroupModel,
    TallyModifierGroupModelFromJSON,
    TallyModifierGroupModelFromJSONTyped,
    TallyModifierGroupModelToJSON,
} from './TallyModifierGroupModel';
import {
    TallyPriceTypeModel,
    TallyPriceTypeModelFromJSON,
    TallyPriceTypeModelFromJSONTyped,
    TallyPriceTypeModelToJSON,
} from './TallyPriceTypeModel';

/**
 * 
 * @export
 * @interface TallyModifierModel
 */
export interface TallyModifierModel {
    /**
     * 
     * @type {string}
     * @memberof TallyModifierModel
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof TallyModifierModel
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof TallyModifierModel
     */
    quantity: number;
    /**
     * 
     * @type {TallyPriceTypeModel}
     * @memberof TallyModifierModel
     */
    priceType?: TallyPriceTypeModel;
    /**
     * 
     * @type {TallyModifierActionCodeModel}
     * @memberof TallyModifierModel
     */
    actionCode?: TallyModifierActionCodeModel;
    /**
     * 
     * @type {string}
     * @memberof TallyModifierModel
     */
    sizeGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyModifierModel
     */
    sizeGroupName?: string;
    /**
     * 
     * @type {Array<TallyModifierGroupModel>}
     * @memberof TallyModifierModel
     */
    modifierGroups?: Array<TallyModifierGroupModel>;
    /**
     * 
     * @type {number}
     * @memberof TallyModifierModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof TallyModifierModel
     */
    itemGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyModifierModel
     */
    sourceProductId?: string;
}

export function TallyModifierModelFromJSON(json: any): TallyModifierModel {
    return TallyModifierModelFromJSONTyped(json, false);
}

export function TallyModifierModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyModifierModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'price': json['price'],
        'quantity': json['quantity'],
        'priceType': !exists(json, 'priceType') ? undefined : TallyPriceTypeModelFromJSON(json['priceType']),
        'actionCode': !exists(json, 'actionCode') ? undefined : TallyModifierActionCodeModelFromJSON(json['actionCode']),
        'sizeGroupId': !exists(json, 'sizeGroupId') ? undefined : json['sizeGroupId'],
        'sizeGroupName': !exists(json, 'sizeGroupName') ? undefined : json['sizeGroupName'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(TallyModifierGroupModelFromJSON)),
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'itemGroupId': !exists(json, 'itemGroupId') ? undefined : json['itemGroupId'],
        'sourceProductId': !exists(json, 'sourceProductId') ? undefined : json['sourceProductId'],
    };
}

export function TallyModifierModelToJSON(value?: TallyModifierModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'price': value.price,
        'quantity': value.quantity,
        'priceType': TallyPriceTypeModelToJSON(value.priceType),
        'actionCode': TallyModifierActionCodeModelToJSON(value.actionCode),
        'sizeGroupId': value.sizeGroupId,
        'sizeGroupName': value.sizeGroupName,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(TallyModifierGroupModelToJSON)),
        'lineItemId': value.lineItemId,
        'itemGroupId': value.itemGroupId,
        'sourceProductId': value.sourceProductId,
    };
}

