/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDeliveryLocationAddressModel,
    IDeliveryLocationAddressModelFromJSON,
    IDeliveryLocationAddressModelFromJSONTyped,
    IDeliveryLocationAddressModelToJSON,
} from './IDeliveryLocationAddressModel';
import {
    ILocationServiceHoursModel,
    ILocationServiceHoursModelFromJSON,
    ILocationServiceHoursModelFromJSONTyped,
    ILocationServiceHoursModelToJSON,
} from './ILocationServiceHoursModel';
import {
    IPickupDetailsModel,
    IPickupDetailsModelFromJSON,
    IPickupDetailsModelFromJSONTyped,
    IPickupDetailsModelToJSON,
} from './IPickupDetailsModel';
import {
    IPickupDistanceModel,
    IPickupDistanceModelFromJSON,
    IPickupDistanceModelFromJSONTyped,
    IPickupDistanceModelToJSON,
} from './IPickupDistanceModel';
import {
    IPickupMaximumDeliveryRadiusModel,
    IPickupMaximumDeliveryRadiusModelFromJSON,
    IPickupMaximumDeliveryRadiusModelFromJSONTyped,
    IPickupMaximumDeliveryRadiusModelToJSON,
} from './IPickupMaximumDeliveryRadiusModel';

/**
 * Pickup location address of the delivery
 * @export
 * @interface IPickupModel
 */
export interface IPickupModel {
    /**
     * ID of pickup location
     * @type {number}
     * @memberof IPickupModel
     */
    id?: number;
    /**
     * Name of pickup location
     * @type {string}
     * @memberof IPickupModel
     */
    name?: string;
    /**
     * 
     * @type {IPickupDetailsModel}
     * @memberof IPickupModel
     */
    details: IPickupDetailsModel;
    /**
     * 
     * @type {IPickupDistanceModel}
     * @memberof IPickupModel
     */
    distance: IPickupDistanceModel;
    /**
     * 
     * @type {IPickupMaximumDeliveryRadiusModel}
     * @memberof IPickupModel
     */
    maximumDeliveryRadius: IPickupMaximumDeliveryRadiusModel;
    /**
     * Max delivery fee of pickup location
     * @type {number}
     * @memberof IPickupModel
     */
    maximumDeliveryFee?: number;
    /**
     * 
     * @type {IDeliveryLocationAddressModel}
     * @memberof IPickupModel
     */
    address?: IDeliveryLocationAddressModel;
    /**
     * Phone number of pickup location
     * @type {string}
     * @memberof IPickupModel
     */
    phone?: string;
    /**
     * `true` if delivery is enabled for pickup location, `false` if not
     * @type {boolean}
     * @memberof IPickupModel
     */
    isDeliveryEnabled: boolean;
    /**
     * All delivery services of pickup location
     * @type {Array<string>}
     * @memberof IPickupModel
     */
    deliveryServices?: Array<string>;
    /**
     * Preferred delivery services for pickup location
     * @type {Array<string>}
     * @memberof IPickupModel
     */
    deliveryServicesPreferred?: Array<string>;
    /**
     * UTC offset of pickup location
     * @type {string}
     * @memberof IPickupModel
     */
    utcOffset?: string;
    /**
     * 
     * @type {{ [key: string]: ILocationServiceHoursModel; }}
     * @memberof IPickupModel
     */
    hoursByDay: { [key: string]: ILocationServiceHoursModel; };
}

export function IPickupModelFromJSON(json: any): IPickupModel {
    return IPickupModelFromJSONTyped(json, false);
}

export function IPickupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPickupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'details': IPickupDetailsModelFromJSON(json['details']),
        'distance': IPickupDistanceModelFromJSON(json['distance']),
        'maximumDeliveryRadius': IPickupMaximumDeliveryRadiusModelFromJSON(json['maximumDeliveryRadius']),
        'maximumDeliveryFee': !exists(json, 'maximumDeliveryFee') ? undefined : json['maximumDeliveryFee'],
        'address': !exists(json, 'address') ? undefined : IDeliveryLocationAddressModelFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'isDeliveryEnabled': json['isDeliveryEnabled'],
        'deliveryServices': !exists(json, 'deliveryServices') ? undefined : json['deliveryServices'],
        'deliveryServicesPreferred': !exists(json, 'deliveryServicesPreferred') ? undefined : json['deliveryServicesPreferred'],
        'utcOffset': !exists(json, 'utcOffset') ? undefined : json['utcOffset'],
        'hoursByDay': (mapValues(json['hoursByDay'], ILocationServiceHoursModelFromJSON)),
    };
}

export function IPickupModelToJSON(value?: IPickupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'details': IPickupDetailsModelToJSON(value.details),
        'distance': IPickupDistanceModelToJSON(value.distance),
        'maximumDeliveryRadius': IPickupMaximumDeliveryRadiusModelToJSON(value.maximumDeliveryRadius),
        'maximumDeliveryFee': value.maximumDeliveryFee,
        'address': IDeliveryLocationAddressModelToJSON(value.address),
        'phone': value.phone,
        'isDeliveryEnabled': value.isDeliveryEnabled,
        'deliveryServices': value.deliveryServices,
        'deliveryServicesPreferred': value.deliveryServicesPreferred,
        'utcOffset': value.utcOffset,
        'hoursByDay': (mapValues(value.hoursByDay, ILocationServiceHoursModelToJSON)),
    };
}

