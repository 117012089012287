/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IPickupMaximumDeliveryRadiusModel
 */
export interface IPickupMaximumDeliveryRadiusModel {
    /**
     * Hardcoded to 'mi'
     * @type {string}
     * @memberof IPickupMaximumDeliveryRadiusModel
     */
    unit?: string;
    /**
     * Max delivery radius of pickup location
     * @type {number}
     * @memberof IPickupMaximumDeliveryRadiusModel
     */
    amount?: number;
}

export function IPickupMaximumDeliveryRadiusModelFromJSON(json: any): IPickupMaximumDeliveryRadiusModel {
    return IPickupMaximumDeliveryRadiusModelFromJSONTyped(json, false);
}

export function IPickupMaximumDeliveryRadiusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPickupMaximumDeliveryRadiusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function IPickupMaximumDeliveryRadiusModelToJSON(value?: IPickupMaximumDeliveryRadiusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unit': value.unit,
        'amount': value.amount,
    };
}

