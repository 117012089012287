/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Applicable channels
 * @export
 * @enum {string}
 */
export enum TChannelsModel {
    Weboa = 'WEBOA',
    Mobile = 'MOBILE',
    Store = 'STORE'
}

export function TChannelsModelFromJSON(json: any): TChannelsModel {
    return TChannelsModelFromJSONTyped(json, false);
}

export function TChannelsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TChannelsModel {
    return json as TChannelsModel;
}

export function TChannelsModelToJSON(value?: TChannelsModel | null): any {
    return value as any;
}

