/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardMenuIdModel,
    IRewardMenuIdModelFromJSON,
    IRewardMenuIdModelFromJSONTyped,
    IRewardMenuIdModelToJSON,
} from './IRewardMenuIdModel';
import {
    IRewardMenuIdsModel,
    IRewardMenuIdsModelFromJSON,
    IRewardMenuIdsModelFromJSONTyped,
    IRewardMenuIdsModelToJSON,
} from './IRewardMenuIdsModel';

/**
 * 
 * @export
 * @interface IDSRewardApplicabilityModel
 */
export interface IDSRewardApplicabilityModel {
    /**
     * 
     * @type {boolean}
     * @memberof IDSRewardApplicabilityModel
     */
    isIncludesAll: boolean;
    /**
     * 
     * @type {number}
     * @memberof IDSRewardApplicabilityModel
     */
    buyCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IDSRewardApplicabilityModel
     */
    getCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IDSRewardApplicabilityModel
     */
    maxRecurrence?: number;
    /**
     * 
     * @type {string}
     * @memberof IDSRewardApplicabilityModel
     */
    percent?: string;
    /**
     * 
     * @type {string}
     * @memberof IDSRewardApplicabilityModel
     */
    price?: string;
    /**
     * 
     * @type {Array<IRewardMenuIdModel>}
     * @memberof IDSRewardApplicabilityModel
     */
    eligibleIds?: Array<IRewardMenuIdModel>;
    /**
     * 
     * @type {Array<IRewardMenuIdsModel>}
     * @memberof IDSRewardApplicabilityModel
     */
    buyIds?: Array<IRewardMenuIdsModel>;
    /**
     * 
     * @type {Array<IRewardMenuIdsModel>}
     * @memberof IDSRewardApplicabilityModel
     */
    getIds?: Array<IRewardMenuIdsModel>;
}

export function IDSRewardApplicabilityModelFromJSON(json: any): IDSRewardApplicabilityModel {
    return IDSRewardApplicabilityModelFromJSONTyped(json, false);
}

export function IDSRewardApplicabilityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDSRewardApplicabilityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isIncludesAll': json['isIncludesAll'],
        'buyCount': !exists(json, 'buyCount') ? undefined : json['buyCount'],
        'getCount': !exists(json, 'getCount') ? undefined : json['getCount'],
        'maxRecurrence': !exists(json, 'maxRecurrence') ? undefined : json['maxRecurrence'],
        'percent': !exists(json, 'percent') ? undefined : json['percent'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'eligibleIds': !exists(json, 'eligibleIds') ? undefined : ((json['eligibleIds'] as Array<any>).map(IRewardMenuIdModelFromJSON)),
        'buyIds': !exists(json, 'buyIds') ? undefined : ((json['buyIds'] as Array<any>).map(IRewardMenuIdsModelFromJSON)),
        'getIds': !exists(json, 'getIds') ? undefined : ((json['getIds'] as Array<any>).map(IRewardMenuIdsModelFromJSON)),
    };
}

export function IDSRewardApplicabilityModelToJSON(value?: IDSRewardApplicabilityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isIncludesAll': value.isIncludesAll,
        'buyCount': value.buyCount,
        'getCount': value.getCount,
        'maxRecurrence': value.maxRecurrence,
        'percent': value.percent,
        'price': value.price,
        'eligibleIds': value.eligibleIds === undefined ? undefined : ((value.eligibleIds as Array<any>).map(IRewardMenuIdModelToJSON)),
        'buyIds': value.buyIds === undefined ? undefined : ((value.buyIds as Array<any>).map(IRewardMenuIdsModelToJSON)),
        'getIds': value.getIds === undefined ? undefined : ((value.getIds as Array<any>).map(IRewardMenuIdsModelToJSON)),
    };
}

