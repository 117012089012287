/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDineInItemModel,
    IDineInItemModelFromJSON,
    IDineInItemModelFromJSONTyped,
    IDineInItemModelToJSON,
} from './IDineInItemModel';

/**
 * 
 * @export
 * @interface IDineInOrderDetailsItemModel
 */
export interface IDineInOrderDetailsItemModel {
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsItemModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsItemModel
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsItemModel
     */
    quantity?: number;
    /**
     * 
     * @type {Array<IDineInItemModel>}
     * @memberof IDineInOrderDetailsItemModel
     */
    childItems?: Array<IDineInItemModel>;
}

export function IDineInOrderDetailsItemModelFromJSON(json: any): IDineInOrderDetailsItemModel {
    return IDineInOrderDetailsItemModelFromJSONTyped(json, false);
}

export function IDineInOrderDetailsItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDineInOrderDetailsItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'childItems': !exists(json, 'childItems') ? undefined : ((json['childItems'] as Array<any>).map(IDineInItemModelFromJSON)),
    };
}

export function IDineInOrderDetailsItemModelToJSON(value?: IDineInOrderDetailsItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'price': value.price,
        'quantity': value.quantity,
        'childItems': value.childItems === undefined ? undefined : ((value.childItems as Array<any>).map(IDineInItemModelToJSON)),
    };
}

