/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IWalletGiftCardModel,
    IWalletGiftCardModelFromJSON,
    IWalletGiftCardModelFromJSONTyped,
    IWalletGiftCardModelToJSON,
} from './IWalletGiftCardModel';
import {
    IWalletPaymentCardModel,
    IWalletPaymentCardModelFromJSON,
    IWalletPaymentCardModelFromJSONTyped,
    IWalletPaymentCardModelToJSON,
} from './IWalletPaymentCardModel';
import {
    WalletAccountTypesModel,
    WalletAccountTypesModelFromJSON,
    WalletAccountTypesModelFromJSONTyped,
    WalletAccountTypesModelToJSON,
} from './WalletAccountTypesModel';

/**
 * 
 * @export
 * @interface IWalletAccountModel
 */
export interface IWalletAccountModel {
    /**
     * 
     * @type {WalletAccountTypesModel}
     * @memberof IWalletAccountModel
     */
    accountType: WalletAccountTypesModel;
    /**
     * 
     * @type {IWalletPaymentCardModel}
     * @memberof IWalletAccountModel
     */
    paymentCard?: IWalletPaymentCardModel;
    /**
     * 
     * @type {IWalletGiftCardModel}
     * @memberof IWalletAccountModel
     */
    giftCard?: IWalletGiftCardModel;
    /**
     * 
     * @type {boolean}
     * @memberof IWalletAccountModel
     */
    preferred?: boolean;
}

export function IWalletAccountModelFromJSON(json: any): IWalletAccountModel {
    return IWalletAccountModelFromJSONTyped(json, false);
}

export function IWalletAccountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IWalletAccountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountType': WalletAccountTypesModelFromJSON(json['accountType']),
        'paymentCard': !exists(json, 'paymentCard') ? undefined : IWalletPaymentCardModelFromJSON(json['paymentCard']),
        'giftCard': !exists(json, 'giftCard') ? undefined : IWalletGiftCardModelFromJSON(json['giftCard']),
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
    };
}

export function IWalletAccountModelToJSON(value?: IWalletAccountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountType': WalletAccountTypesModelToJSON(value.accountType),
        'paymentCard': IWalletPaymentCardModelToJSON(value.paymentCard),
        'giftCard': IWalletGiftCardModelToJSON(value.giftCard),
        'preferred': value.preferred,
    };
}

