/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IApplyPromocodeRequestModel
 */
export interface IApplyPromocodeRequestModel {
    /**
     * 
     * @type {string}
     * @memberof IApplyPromocodeRequestModel
     */
    promocode: string;
    /**
     * 
     * @type {string}
     * @memberof IApplyPromocodeRequestModel
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof IApplyPromocodeRequestModel
     */
    timezoneId: string;
}

export function IApplyPromocodeRequestModelFromJSON(json: any): IApplyPromocodeRequestModel {
    return IApplyPromocodeRequestModelFromJSONTyped(json, false);
}

export function IApplyPromocodeRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApplyPromocodeRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promocode': json['promocode'],
        'locationId': json['locationId'],
        'timezoneId': json['timezoneId'],
    };
}

export function IApplyPromocodeRequestModelToJSON(value?: IApplyPromocodeRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promocode': value.promocode,
        'locationId': value.locationId,
        'timezoneId': value.timezoneId,
    };
}

