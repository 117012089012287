/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IAvailableTimeModel,
    IAvailableTimeModelFromJSON,
    IAvailableTimeModelFromJSONTyped,
    IAvailableTimeModelToJSON,
} from './IAvailableTimeModel';

/**
 * 
 * @export
 * @interface IAvailableTimesModel
 */
export interface IAvailableTimesModel {
    /**
     * 
     * @type {{ [key: string]: Array<IAvailableTimeModel>; }}
     * @memberof IAvailableTimesModel
     */
    byDay?: { [key: string]: Array<IAvailableTimeModel>; };
}

export function IAvailableTimesModelFromJSON(json: any): IAvailableTimesModel {
    return IAvailableTimesModelFromJSONTyped(json, false);
}

export function IAvailableTimesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAvailableTimesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'byDay': !exists(json, 'byDay') ? undefined : json['byDay'],
    };
}

export function IAvailableTimesModelToJSON(value?: IAvailableTimesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'byDay': value.byDay,
    };
}

