/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IPhoneModel,
    IPhoneModelFromJSON,
    IPhoneModelFromJSONTyped,
    IPhoneModelToJSON,
} from './IPhoneModel';
import {
    IPreferencesModel,
    IPreferencesModelFromJSON,
    IPreferencesModelFromJSONTyped,
    IPreferencesModelToJSON,
} from './IPreferencesModel';
import {
    IReferenceModel,
    IReferenceModelFromJSON,
    IReferenceModelFromJSONTyped,
    IReferenceModelToJSON,
} from './IReferenceModel';
import {
    ITermsAndConditionsModel,
    ITermsAndConditionsModelFromJSON,
    ITermsAndConditionsModelFromJSONTyped,
    ITermsAndConditionsModelToJSON,
} from './ITermsAndConditionsModel';

/**
 * 
 * @export
 * @interface IUpdateCustomerAccountRequestFromClientModel
 */
export interface IUpdateCustomerAccountRequestFromClientModel {
    /**
     * 
     * @type {string}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    birthDate?: string;
    /**
     * 
     * @type {Array<IReferenceModel>}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    references?: Array<IReferenceModel>;
    /**
     * 
     * @type {boolean}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    isDefaultTandCAccepted?: boolean;
    /**
     * 
     * @type {Array<IPhoneModel>}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    phones?: Array<IPhoneModel>;
    /**
     * 
     * @type {IPreferencesModel}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    preferences?: IPreferencesModel;
    /**
     * 
     * @type {ITermsAndConditionsModel}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    termsAndConditions?: ITermsAndConditionsModel;
    /**
     * 
     * @type {string}
     * @memberof IUpdateCustomerAccountRequestFromClientModel
     */
    profileAvatarUrl?: string;
}

export function IUpdateCustomerAccountRequestFromClientModelFromJSON(json: any): IUpdateCustomerAccountRequestFromClientModel {
    return IUpdateCustomerAccountRequestFromClientModelFromJSONTyped(json, false);
}

export function IUpdateCustomerAccountRequestFromClientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IUpdateCustomerAccountRequestFromClientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'references': !exists(json, 'references') ? undefined : ((json['references'] as Array<any>).map(IReferenceModelFromJSON)),
        'isDefaultTandCAccepted': !exists(json, 'isDefaultTandCAccepted') ? undefined : json['isDefaultTandCAccepted'],
        'phones': !exists(json, 'phones') ? undefined : ((json['phones'] as Array<any>).map(IPhoneModelFromJSON)),
        'preferences': !exists(json, 'preferences') ? undefined : IPreferencesModelFromJSON(json['preferences']),
        'termsAndConditions': !exists(json, 'termsAndConditions') ? undefined : ITermsAndConditionsModelFromJSON(json['termsAndConditions']),
        'profileAvatarUrl': !exists(json, 'profileAvatarUrl') ? undefined : json['profileAvatarUrl'],
    };
}

export function IUpdateCustomerAccountRequestFromClientModelToJSON(value?: IUpdateCustomerAccountRequestFromClientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'birthDate': value.birthDate,
        'references': value.references === undefined ? undefined : ((value.references as Array<any>).map(IReferenceModelToJSON)),
        'isDefaultTandCAccepted': value.isDefaultTandCAccepted,
        'phones': value.phones === undefined ? undefined : ((value.phones as Array<any>).map(IPhoneModelToJSON)),
        'preferences': IPreferencesModelToJSON(value.preferences),
        'termsAndConditions': ITermsAndConditionsModelToJSON(value.termsAndConditions),
        'profileAvatarUrl': value.profileAvatarUrl,
    };
}

