/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IDineInItemModel
 */
export interface IDineInItemModel {
    /**
     * 
     * @type {string}
     * @memberof IDineInItemModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof IDineInItemModel
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInItemModel
     */
    quantity?: number;
}

export function IDineInItemModelFromJSON(json: any): IDineInItemModel {
    return IDineInItemModelFromJSONTyped(json, false);
}

export function IDineInItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDineInItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function IDineInItemModelToJSON(value?: IDineInItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'price': value.price,
        'quantity': value.quantity,
    };
}

