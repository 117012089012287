/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplePayInfoModel,
    ApplePayInfoModelFromJSON,
    ApplePayInfoModelFromJSONTyped,
    ApplePayInfoModelToJSON,
} from './ApplePayInfoModel';
import {
    GooglePayInfoModel,
    GooglePayInfoModelFromJSON,
    GooglePayInfoModelFromJSONTyped,
    GooglePayInfoModelToJSON,
} from './GooglePayInfoModel';

/**
 * 
 * @export
 * @interface OrderPaymentDetailsModel
 */
export interface OrderPaymentDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    giftCardPin?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    giftCardNumber?: string;
    /**
     * 
     * @type {GooglePayInfoModel}
     * @memberof OrderPaymentDetailsModel
     */
    googlePayInfo?: GooglePayInfoModel;
    /**
     * 
     * @type {ApplePayInfoModel}
     * @memberof OrderPaymentDetailsModel
     */
    applePayInfo?: ApplePayInfoModel;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    billingPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    cardToken?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentDetailsModel
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    cardHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    cardIssuer?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDetailsModel
     */
    sessionKey?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPaymentDetailsModel
     */
    keys?: Array<string>;
}

export function OrderPaymentDetailsModelFromJSON(json: any): OrderPaymentDetailsModel {
    return OrderPaymentDetailsModelFromJSONTyped(json, false);
}

export function OrderPaymentDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPaymentDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'giftCardPin': !exists(json, 'giftCardPin') ? undefined : json['giftCardPin'],
        'giftCardNumber': !exists(json, 'giftCardNumber') ? undefined : json['giftCardNumber'],
        'googlePayInfo': !exists(json, 'googlePayInfo') ? undefined : GooglePayInfoModelFromJSON(json['googlePayInfo']),
        'applePayInfo': !exists(json, 'applePayInfo') ? undefined : ApplePayInfoModelFromJSON(json['applePayInfo']),
        'billingPostalCode': !exists(json, 'billingPostalCode') ? undefined : json['billingPostalCode'],
        'cardToken': !exists(json, 'cardToken') ? undefined : json['cardToken'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'amount': json['amount'],
        'cardHolderName': !exists(json, 'cardHolderName') ? undefined : json['cardHolderName'],
        'maskedCardNumber': !exists(json, 'maskedCardNumber') ? undefined : json['maskedCardNumber'],
        'cardIssuer': !exists(json, 'cardIssuer') ? undefined : json['cardIssuer'],
        'sessionKey': !exists(json, 'sessionKey') ? undefined : json['sessionKey'],
        'keys': !exists(json, 'keys') ? undefined : json['keys'],
    };
}

export function OrderPaymentDetailsModelToJSON(value?: OrderPaymentDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'giftCardPin': value.giftCardPin,
        'giftCardNumber': value.giftCardNumber,
        'googlePayInfo': GooglePayInfoModelToJSON(value.googlePayInfo),
        'applePayInfo': ApplePayInfoModelToJSON(value.applePayInfo),
        'billingPostalCode': value.billingPostalCode,
        'cardToken': value.cardToken,
        'description': value.description,
        'amount': value.amount,
        'cardHolderName': value.cardHolderName,
        'maskedCardNumber': value.maskedCardNumber,
        'cardIssuer': value.cardIssuer,
        'sessionKey': value.sessionKey,
        'keys': value.keys,
    };
}

