/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderLimitsDtoModelMaxAllowedItemsModel,
    OrderLimitsDtoModelMaxAllowedItemsModelFromJSON,
    OrderLimitsDtoModelMaxAllowedItemsModelFromJSONTyped,
    OrderLimitsDtoModelMaxAllowedItemsModelToJSON,
} from './OrderLimitsDtoModelMaxAllowedItemsModel';

/**
 * 
 * @export
 * @interface OrderLimitsDtoModelModel
 */
export interface OrderLimitsDtoModelModel {
    /**
     * 
     * @type {number}
     * @memberof OrderLimitsDtoModelModel
     */
    maxAllowedPerProduct?: number;
    /**
     * 
     * @type {OrderLimitsDtoModelMaxAllowedItemsModel}
     * @memberof OrderLimitsDtoModelModel
     */
    maxAllowedItems?: OrderLimitsDtoModelMaxAllowedItemsModel;
}

export function OrderLimitsDtoModelModelFromJSON(json: any): OrderLimitsDtoModelModel {
    return OrderLimitsDtoModelModelFromJSONTyped(json, false);
}

export function OrderLimitsDtoModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLimitsDtoModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxAllowedPerProduct': !exists(json, 'maxAllowedPerProduct') ? undefined : json['maxAllowedPerProduct'],
        'maxAllowedItems': !exists(json, 'maxAllowedItems') ? undefined : OrderLimitsDtoModelMaxAllowedItemsModelFromJSON(json['maxAllowedItems']),
    };
}

export function OrderLimitsDtoModelModelToJSON(value?: OrderLimitsDtoModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maxAllowedPerProduct': value.maxAllowedPerProduct,
        'maxAllowedItems': OrderLimitsDtoModelMaxAllowedItemsModelToJSON(value.maxAllowedItems),
    };
}

