/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IVisitRegisterRequestFullfillmentVisitModel,
    IVisitRegisterRequestFullfillmentVisitModelFromJSON,
    IVisitRegisterRequestFullfillmentVisitModelFromJSONTyped,
    IVisitRegisterRequestFullfillmentVisitModelToJSON,
} from './IVisitRegisterRequestFullfillmentVisitModel';

/**
 * 
 * @export
 * @interface IVisitRegisterRequestFullfillmentModel
 */
export interface IVisitRegisterRequestFullfillmentModel {
    /**
     * 
     * @type {IVisitRegisterRequestFullfillmentVisitModel}
     * @memberof IVisitRegisterRequestFullfillmentModel
     */
    visit: IVisitRegisterRequestFullfillmentVisitModel;
}

export function IVisitRegisterRequestFullfillmentModelFromJSON(json: any): IVisitRegisterRequestFullfillmentModel {
    return IVisitRegisterRequestFullfillmentModelFromJSONTyped(json, false);
}

export function IVisitRegisterRequestFullfillmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterRequestFullfillmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visit': IVisitRegisterRequestFullfillmentVisitModelFromJSON(json['visit']),
    };
}

export function IVisitRegisterRequestFullfillmentModelToJSON(value?: IVisitRegisterRequestFullfillmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visit': IVisitRegisterRequestFullfillmentVisitModelToJSON(value.visit),
    };
}

