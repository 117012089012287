/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDiscountRewardsFulfillmentModel,
    IDiscountRewardsFulfillmentModelFromJSON,
    IDiscountRewardsFulfillmentModelFromJSONTyped,
    IDiscountRewardsFulfillmentModelToJSON,
} from './IDiscountRewardsFulfillmentModel';
import {
    TallyDSProductModel,
    TallyDSProductModelFromJSON,
    TallyDSProductModelFromJSONTyped,
    TallyDSProductModelToJSON,
} from './TallyDSProductModel';

/**
 * 
 * @export
 * @interface IDiscountRewardsOrderModel
 */
export interface IDiscountRewardsOrderModel {
    /**
     * 
     * @type {string}
     * @memberof IDiscountRewardsOrderModel
     */
    subTotal: string;
    /**
     * 
     * @type {IDiscountRewardsFulfillmentModel}
     * @memberof IDiscountRewardsOrderModel
     */
    fulfillment: IDiscountRewardsFulfillmentModel;
    /**
     * 
     * @type {Array<TallyDSProductModel>}
     * @memberof IDiscountRewardsOrderModel
     */
    items: Array<TallyDSProductModel>;
}

export function IDiscountRewardsOrderModelFromJSON(json: any): IDiscountRewardsOrderModel {
    return IDiscountRewardsOrderModelFromJSONTyped(json, false);
}

export function IDiscountRewardsOrderModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDiscountRewardsOrderModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subTotal': json['subTotal'],
        'fulfillment': IDiscountRewardsFulfillmentModelFromJSON(json['fulfillment']),
        'items': ((json['items'] as Array<any>).map(TallyDSProductModelFromJSON)),
    };
}

export function IDiscountRewardsOrderModelToJSON(value?: IDiscountRewardsOrderModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subTotal': value.subTotal,
        'fulfillment': IDiscountRewardsFulfillmentModelToJSON(value.fulfillment),
        'items': ((value.items as Array<any>).map(TallyDSProductModelToJSON)),
    };
}

