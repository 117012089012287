/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderStatusEnumModel {
    Received = 'RECEIVED',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    ReadyForPickup = 'READY_FOR_PICKUP',
    PreparingFood = 'PREPARING_FOOD'
}

export function OrderStatusEnumModelFromJSON(json: any): OrderStatusEnumModel {
    return OrderStatusEnumModelFromJSONTyped(json, false);
}

export function OrderStatusEnumModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatusEnumModel {
    return json as OrderStatusEnumModel;
}

export function OrderStatusEnumModelToJSON(value?: OrderStatusEnumModel | null): any {
    return value as any;
}

