/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDeliveryProviderModel,
    IDeliveryProviderModelFromJSON,
    IDeliveryProviderModelFromJSONTyped,
    IDeliveryProviderModelToJSON,
} from './IDeliveryProviderModel';
import {
    ILocationContactInfoModel,
    ILocationContactInfoModelFromJSON,
    ILocationContactInfoModelFromJSONTyped,
    ILocationContactInfoModelToJSON,
} from './ILocationContactInfoModel';
import {
    ILocationCoordinatesModel,
    ILocationCoordinatesModelFromJSON,
    ILocationCoordinatesModelFromJSONTyped,
    ILocationCoordinatesModelToJSON,
} from './ILocationCoordinatesModel';
import {
    ILocationServiceHoursModel,
    ILocationServiceHoursModelFromJSON,
    ILocationServiceHoursModelFromJSONTyped,
    ILocationServiceHoursModelToJSON,
} from './ILocationServiceHoursModel';
import {
    IServiceTypeModel,
    IServiceTypeModelFromJSON,
    IServiceTypeModelFromJSONTyped,
    IServiceTypeModelToJSON,
} from './IServiceTypeModel';
import {
    IServicesModel,
    IServicesModelFromJSON,
    IServicesModelFromJSONTyped,
    IServicesModelToJSON,
} from './IServicesModel';
import {
    TLocationStatusModel,
    TLocationStatusModelFromJSON,
    TLocationStatusModelFromJSONTyped,
    TLocationStatusModelToJSON,
} from './TLocationStatusModel';
import {
    TServiceTypeModel,
    TServiceTypeModelFromJSON,
    TServiceTypeModelFromJSONTyped,
    TServiceTypeModelToJSON,
} from './TServiceTypeModel';

/**
 * 
 * @export
 * @interface ILocationByStateOrProvinceDetailsModel
 */
export interface ILocationByStateOrProvinceDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    url?: string;
    /**
     * 
     * @type {ILocationContactInfoModel}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    contactDetails: ILocationContactInfoModel;
    /**
     * 
     * @type {string}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    addressMapLink?: string;
    /**
     * 
     * @type {Array<TServiceTypeModel>}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    services?: Array<TServiceTypeModel>;
    /**
     * 
     * @type {Array<IServiceTypeModel>}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    features?: Array<IServiceTypeModel>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    paymentMethods?: Array<any>;
    /**
     * 
     * @type {{ [key: string]: ILocationServiceHoursModel; }}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    hoursByDay?: { [key: string]: ILocationServiceHoursModel; };
    /**
     * 
     * @type {ILocationCoordinatesModel}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    details?: ILocationCoordinatesModel;
    /**
     * 
     * @type {string}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    isOnlineOrderAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    isClosed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    isOffline?: boolean;
    /**
     * 
     * @type {TLocationStatusModel}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    status?: TLocationStatusModel;
    /**
     * 
     * @type {Array<IServicesModel>}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    serviceDetails?: Array<IServicesModel>;
    /**
     * 
     * @type {Array<IDeliveryProviderModel>}
     * @memberof ILocationByStateOrProvinceDetailsModel
     */
    deliveryProviders?: Array<IDeliveryProviderModel>;
}

export function ILocationByStateOrProvinceDetailsModelFromJSON(json: any): ILocationByStateOrProvinceDetailsModel {
    return ILocationByStateOrProvinceDetailsModelFromJSONTyped(json, false);
}

export function ILocationByStateOrProvinceDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationByStateOrProvinceDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'displayName': json['displayName'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'contactDetails': ILocationContactInfoModelFromJSON(json['contactDetails']),
        'addressMapLink': !exists(json, 'addressMapLink') ? undefined : json['addressMapLink'],
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(TServiceTypeModelFromJSON)),
        'features': !exists(json, 'features') ? undefined : ((json['features'] as Array<any>).map(IServiceTypeModelFromJSON)),
        'paymentMethods': !exists(json, 'paymentMethods') ? undefined : json['paymentMethods'],
        'hoursByDay': !exists(json, 'hoursByDay') ? undefined : (mapValues(json['hoursByDay'], ILocationServiceHoursModelFromJSON)),
        'details': !exists(json, 'details') ? undefined : ILocationCoordinatesModelFromJSON(json['details']),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'isOnlineOrderAvailable': json['isOnlineOrderAvailable'],
        'isClosed': json['isClosed'],
        'isOffline': !exists(json, 'isOffline') ? undefined : json['isOffline'],
        'status': !exists(json, 'status') ? undefined : TLocationStatusModelFromJSON(json['status']),
        'serviceDetails': !exists(json, 'serviceDetails') ? undefined : ((json['serviceDetails'] as Array<any>).map(IServicesModelFromJSON)),
        'deliveryProviders': !exists(json, 'deliveryProviders') ? undefined : ((json['deliveryProviders'] as Array<any>).map(IDeliveryProviderModelFromJSON)),
    };
}

export function ILocationByStateOrProvinceDetailsModelToJSON(value?: ILocationByStateOrProvinceDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'url': value.url,
        'contactDetails': ILocationContactInfoModelToJSON(value.contactDetails),
        'addressMapLink': value.addressMapLink,
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(TServiceTypeModelToJSON)),
        'features': value.features === undefined ? undefined : ((value.features as Array<any>).map(IServiceTypeModelToJSON)),
        'paymentMethods': value.paymentMethods,
        'hoursByDay': value.hoursByDay === undefined ? undefined : (mapValues(value.hoursByDay, ILocationServiceHoursModelToJSON)),
        'details': ILocationCoordinatesModelToJSON(value.details),
        'timezone': value.timezone,
        'isOnlineOrderAvailable': value.isOnlineOrderAvailable,
        'isClosed': value.isClosed,
        'isOffline': value.isOffline,
        'status': TLocationStatusModelToJSON(value.status),
        'serviceDetails': value.serviceDetails === undefined ? undefined : ((value.serviceDetails as Array<any>).map(IServicesModelToJSON)),
        'deliveryProviders': value.deliveryProviders === undefined ? undefined : ((value.deliveryProviders as Array<any>).map(IDeliveryProviderModelToJSON)),
    };
}

