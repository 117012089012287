/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationWithDetailsModel,
    ILocationWithDetailsModelFromJSON,
    ILocationWithDetailsModelFromJSONTyped,
    ILocationWithDetailsModelToJSON,
} from './ILocationWithDetailsModel';
import {
    IOrderFulfillmentContactDetailsModel,
    IOrderFulfillmentContactDetailsModelFromJSON,
    IOrderFulfillmentContactDetailsModelFromJSONTyped,
    IOrderFulfillmentContactDetailsModelToJSON,
} from './IOrderFulfillmentContactDetailsModel';

/**
 * 
 * @export
 * @interface IOrderFulfillmentModel
 */
export interface IOrderFulfillmentModel {
    /**
     * 
     * @type {string}
     * @memberof IOrderFulfillmentModel
     */
    type?: string;
    /**
     * 
     * @type {ILocationWithDetailsModel}
     * @memberof IOrderFulfillmentModel
     */
    location?: ILocationWithDetailsModel;
    /**
     * 
     * @type {IOrderFulfillmentContactDetailsModel}
     * @memberof IOrderFulfillmentModel
     */
    contactDetails?: IOrderFulfillmentContactDetailsModel;
}

export function IOrderFulfillmentModelFromJSON(json: any): IOrderFulfillmentModel {
    return IOrderFulfillmentModelFromJSONTyped(json, false);
}

export function IOrderFulfillmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderFulfillmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'location': !exists(json, 'location') ? undefined : ILocationWithDetailsModelFromJSON(json['location']),
        'contactDetails': !exists(json, 'contactDetails') ? undefined : IOrderFulfillmentContactDetailsModelFromJSON(json['contactDetails']),
    };
}

export function IOrderFulfillmentModelToJSON(value?: IOrderFulfillmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'location': ILocationWithDetailsModelToJSON(value.location),
        'contactDetails': IOrderFulfillmentContactDetailsModelToJSON(value.contactDetails),
    };
}

