/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IHoursModel,
    IHoursModelFromJSON,
    IHoursModelFromJSONTyped,
    IHoursModelToJSON,
} from './IHoursModel';
import {
    TServiceTypeModel,
    TServiceTypeModelFromJSON,
    TServiceTypeModelFromJSONTyped,
    TServiceTypeModelToJSON,
} from './TServiceTypeModel';

/**
 * 
 * @export
 * @interface IServicesModel
 */
export interface IServicesModel {
    /**
     * 
     * @type {TServiceTypeModel}
     * @memberof IServicesModel
     */
    type: TServiceTypeModel;
    /**
     * 
     * @type {Array<IHoursModel>}
     * @memberof IServicesModel
     */
    hours: Array<IHoursModel>;
    /**
     * 
     * @type {string}
     * @memberof IServicesModel
     */
    channel?: string;
}

export function IServicesModelFromJSON(json: any): IServicesModel {
    return IServicesModelFromJSONTyped(json, false);
}

export function IServicesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IServicesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TServiceTypeModelFromJSON(json['type']),
        'hours': ((json['hours'] as Array<any>).map(IHoursModelFromJSON)),
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
    };
}

export function IServicesModelToJSON(value?: IServicesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TServiceTypeModelToJSON(value.type),
        'hours': ((value.hours as Array<any>).map(IHoursModelToJSON)),
        'channel': value.channel,
    };
}

