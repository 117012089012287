/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICustomerPersonalizationFactModel,
    ICustomerPersonalizationFactModelFromJSON,
    ICustomerPersonalizationFactModelFromJSONTyped,
    ICustomerPersonalizationFactModelToJSON,
} from './ICustomerPersonalizationFactModel';

/**
 * 
 * @export
 * @interface ICustomerPersonalizationRequestModel
 */
export interface ICustomerPersonalizationRequestModel {
    /**
     * 
     * @type {Array<ICustomerPersonalizationFactModel>}
     * @memberof ICustomerPersonalizationRequestModel
     */
    facts: Array<ICustomerPersonalizationFactModel>;
}

export function ICustomerPersonalizationRequestModelFromJSON(json: any): ICustomerPersonalizationRequestModel {
    return ICustomerPersonalizationRequestModelFromJSONTyped(json, false);
}

export function ICustomerPersonalizationRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICustomerPersonalizationRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facts': ((json['facts'] as Array<any>).map(ICustomerPersonalizationFactModelFromJSON)),
    };
}

export function ICustomerPersonalizationRequestModelToJSON(value?: ICustomerPersonalizationRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facts': ((value.facts as Array<any>).map(ICustomerPersonalizationFactModelToJSON)),
    };
}

