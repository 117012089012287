/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IOrderHistoryItemModifierGroupItemModel,
    IOrderHistoryItemModifierGroupItemModelFromJSON,
    IOrderHistoryItemModifierGroupItemModelFromJSONTyped,
    IOrderHistoryItemModifierGroupItemModelToJSON,
} from './IOrderHistoryItemModifierGroupItemModel';

/**
 * 
 * @export
 * @interface IOrderHistoryItemModifierGroupItemModifierModel
 */
export interface IOrderHistoryItemModifierGroupItemModifierModel {
    /**
     * 
     * @type {number}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    modifierActionCode?: number;
    /**
     * 
     * @type {string}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    modifierActionCodeName?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    displayName?: string;
    /**
     * 
     * @type {Array<IOrderHistoryItemModifierGroupItemModel>}
     * @memberof IOrderHistoryItemModifierGroupItemModifierModel
     */
    modifierGroups?: Array<IOrderHistoryItemModifierGroupItemModel>;
}

export function IOrderHistoryItemModifierGroupItemModifierModelFromJSON(json: any): IOrderHistoryItemModifierGroupItemModifierModel {
    return IOrderHistoryItemModifierGroupItemModifierModelFromJSONTyped(json, false);
}

export function IOrderHistoryItemModifierGroupItemModifierModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderHistoryItemModifierGroupItemModifierModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'modifierActionCode': !exists(json, 'modifierActionCode') ? undefined : json['modifierActionCode'],
        'modifierActionCodeName': !exists(json, 'modifierActionCodeName') ? undefined : json['modifierActionCodeName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(IOrderHistoryItemModifierGroupItemModelFromJSON)),
    };
}

export function IOrderHistoryItemModifierGroupItemModifierModelToJSON(value?: IOrderHistoryItemModifierGroupItemModifierModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'productId': value.productId,
        'price': value.price,
        'quantity': value.quantity,
        'modifierActionCode': value.modifierActionCode,
        'modifierActionCodeName': value.modifierActionCodeName,
        'description': value.description,
        'displayName': value.displayName,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(IOrderHistoryItemModifierGroupItemModelToJSON)),
    };
}

