/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IBeerModel
 */
export interface IBeerModel {
    /**
     * 
     * @type {string}
     * @memberof IBeerModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IBeerModel
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof IBeerModel
     */
    alcoholPercentage: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IBeerModel
     */
    caloriesBySize: { [key: string]: number; };
}

export function IBeerModelFromJSON(json: any): IBeerModel {
    return IBeerModelFromJSONTyped(json, false);
}

export function IBeerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IBeerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'category': json['category'],
        'alcoholPercentage': json['alcoholPercentage'],
        'caloriesBySize': json['caloriesBySize'],
    };
}

export function IBeerModelToJSON(value?: IBeerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'category': value.category,
        'alcoholPercentage': value.alcoholPercentage,
        'caloriesBySize': value.caloriesBySize,
    };
}

