/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICheckAmountModelModel,
    ICheckAmountModelModelFromJSON,
    ICheckAmountModelModelFromJSONTyped,
    ICheckAmountModelModelToJSON,
} from './ICheckAmountModelModel';

/**
 * 
 * @export
 * @interface ICheckRestrictionsModelModel
 */
export interface ICheckRestrictionsModelModel {
    /**
     * 
     * @type {ICheckAmountModelModel}
     * @memberof ICheckRestrictionsModelModel
     */
    minimumCheckAmount?: ICheckAmountModelModel;
    /**
     * 
     * @type {ICheckAmountModelModel}
     * @memberof ICheckRestrictionsModelModel
     */
    maximumCheckAmount?: ICheckAmountModelModel;
}

export function ICheckRestrictionsModelModelFromJSON(json: any): ICheckRestrictionsModelModel {
    return ICheckRestrictionsModelModelFromJSONTyped(json, false);
}

export function ICheckRestrictionsModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICheckRestrictionsModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minimumCheckAmount': !exists(json, 'minimumCheckAmount') ? undefined : ICheckAmountModelModelFromJSON(json['minimumCheckAmount']),
        'maximumCheckAmount': !exists(json, 'maximumCheckAmount') ? undefined : ICheckAmountModelModelFromJSON(json['maximumCheckAmount']),
    };
}

export function ICheckRestrictionsModelModelToJSON(value?: ICheckRestrictionsModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minimumCheckAmount': ICheckAmountModelModelToJSON(value.minimumCheckAmount),
        'maximumCheckAmount': ICheckAmountModelModelToJSON(value.maximumCheckAmount),
    };
}

