/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderPaymentModel,
    OrderPaymentModelFromJSON,
    OrderPaymentModelFromJSONTyped,
    OrderPaymentModelToJSON,
} from './OrderPaymentModel';
import {
    TallyRequestModel,
    TallyRequestModelFromJSON,
    TallyRequestModelFromJSONTyped,
    TallyRequestModelToJSON,
} from './TallyRequestModel';

/**
 * 
 * @export
 * @interface OrderRequestModel
 */
export interface OrderRequestModel {
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    deviceId: string;
    /**
     * 
     * @type {TallyRequestModel}
     * @memberof OrderRequestModel
     */
    orderData: TallyRequestModel;
    /**
     * 
     * @type {Array<OrderPaymentModel>}
     * @memberof OrderRequestModel
     */
    payments: Array<OrderPaymentModel>;
}

export function OrderRequestModelFromJSON(json: any): OrderRequestModel {
    return OrderRequestModelFromJSONTyped(json, false);
}

export function OrderRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'orderData': TallyRequestModelFromJSON(json['orderData']),
        'payments': ((json['payments'] as Array<any>).map(OrderPaymentModelFromJSON)),
    };
}

export function OrderRequestModelToJSON(value?: OrderRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'orderData': TallyRequestModelToJSON(value.orderData),
        'payments': ((value.payments as Array<any>).map(OrderPaymentModelToJSON)),
    };
}

