/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ILocationDistanceModel
 */
export interface ILocationDistanceModel {
    /**
     * 
     * @type {number}
     * @memberof ILocationDistanceModel
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ILocationDistanceModel
     */
    unit: string;
}

export function ILocationDistanceModelFromJSON(json: any): ILocationDistanceModel {
    return ILocationDistanceModelFromJSONTyped(json, false);
}

export function ILocationDistanceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationDistanceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'unit': json['unit'],
    };
}

export function ILocationDistanceModelToJSON(value?: ILocationDistanceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'unit': value.unit,
    };
}

