/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IServiceTypeModel {
    Wifi = 'WIFI',
    BlazingRewards = 'BLAZING_REWARDS',
    GoLocation = 'GO_LOCATION',
    ExpressLocation = 'EXPRESS_LOCATION',
    UfcViewing = 'UFC_VIEWING',
    Delivery = 'DELIVERY',
    GeneralLocation = 'GENERAL_LOCATION',
    OnlineOrdering = 'ONLINE_ORDERING',
    DriveIn = 'DRIVE_IN',
    DriveThru = 'DRIVE_THRU'
}

export function IServiceTypeModelFromJSON(json: any): IServiceTypeModel {
    return IServiceTypeModelFromJSONTyped(json, false);
}

export function IServiceTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IServiceTypeModel {
    return json as IServiceTypeModel;
}

export function IServiceTypeModelToJSON(value?: IServiceTypeModel | null): any {
    return value as any;
}

