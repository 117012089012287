/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SEORestaurantSchemaAddressModel
 */
export interface SEORestaurantSchemaAddressModel {
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaAddressModel
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaAddressModel
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaAddressModel
     */
    addressLocality: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaAddressModel
     */
    addressRegion: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaAddressModel
     */
    addressCountry: string;
    /**
     * 
     * @type {string}
     * @memberof SEORestaurantSchemaAddressModel
     */
    type: SEORestaurantSchemaAddressModelTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum SEORestaurantSchemaAddressModelTypeEnum {
    PostalAddress = 'PostalAddress'
}

export function SEORestaurantSchemaAddressModelFromJSON(json: any): SEORestaurantSchemaAddressModel {
    return SEORestaurantSchemaAddressModelFromJSONTyped(json, false);
}

export function SEORestaurantSchemaAddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SEORestaurantSchemaAddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streetAddress': json['streetAddress'],
        'postalCode': json['postalCode'],
        'addressLocality': json['addressLocality'],
        'addressRegion': json['addressRegion'],
        'addressCountry': json['addressCountry'],
        'type': json['@type'],
    };
}

export function SEORestaurantSchemaAddressModelToJSON(value?: SEORestaurantSchemaAddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streetAddress': value.streetAddress,
        'postalCode': value.postalCode,
        'addressLocality': value.addressLocality,
        'addressRegion': value.addressRegion,
        'addressCountry': value.addressCountry,
        '@type': value.type,
    };
}

