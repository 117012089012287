/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCatalogRewardStatusModel,
    TCatalogRewardStatusModelFromJSON,
    TCatalogRewardStatusModelFromJSONTyped,
    TCatalogRewardStatusModelToJSON,
} from './TCatalogRewardStatusModel';
import {
    TCatalogRewardTypeModel,
    TCatalogRewardTypeModelFromJSON,
    TCatalogRewardTypeModelFromJSONTyped,
    TCatalogRewardTypeModelToJSON,
} from './TCatalogRewardTypeModel';

/**
 * 
 * @export
 * @interface TCatalogCertificateModel
 */
export interface TCatalogCertificateModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof TCatalogCertificateModel
     */
    id: string;
    /**
     * 
     * @type {TCatalogRewardTypeModel}
     * @memberof TCatalogCertificateModel
     */
    type?: TCatalogRewardTypeModel;
    /**
     * Reward Type
     * @type {string}
     * @memberof TCatalogCertificateModel
     */
    code?: string;
    /**
     * 
     * @type {TCatalogRewardStatusModel}
     * @memberof TCatalogCertificateModel
     */
    status?: TCatalogRewardStatusModel;
    /**
     * Reward Title
     * @type {string}
     * @memberof TCatalogCertificateModel
     */
    title: string;
    /**
     * Number of points fixed for this reward
     * @type {number}
     * @memberof TCatalogCertificateModel
     */
    points: number;
    /**
     * Full Image Name with URL
     * @type {string}
     * @memberof TCatalogCertificateModel
     */
    imageUrl?: string;
    /**
     * Reward Start Date
     * @type {string}
     * @memberof TCatalogCertificateModel
     */
    startDateTime?: string;
    /**
     * Reward End Date
     * @type {string}
     * @memberof TCatalogCertificateModel
     */
    endDateTime?: string;
}

export function TCatalogCertificateModelFromJSON(json: any): TCatalogCertificateModel {
    return TCatalogCertificateModelFromJSONTyped(json, false);
}

export function TCatalogCertificateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCatalogCertificateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : TCatalogRewardTypeModelFromJSON(json['type']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'status': !exists(json, 'status') ? undefined : TCatalogRewardStatusModelFromJSON(json['status']),
        'title': json['title'],
        'points': json['points'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : json['startDateTime'],
        'endDateTime': !exists(json, 'endDateTime') ? undefined : json['endDateTime'],
    };
}

export function TCatalogCertificateModelToJSON(value?: TCatalogCertificateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': TCatalogRewardTypeModelToJSON(value.type),
        'code': value.code,
        'status': TCatalogRewardStatusModelToJSON(value.status),
        'title': value.title,
        'points': value.points,
        'imageUrl': value.imageUrl,
        'startDateTime': value.startDateTime,
        'endDateTime': value.endDateTime,
    };
}

