/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IValidatePhoneNumberRequestModel
 */
export interface IValidatePhoneNumberRequestModel {
    /**
     * 
     * @type {string}
     * @memberof IValidatePhoneNumberRequestModel
     */
    signupPhoneNumber: string;
}

export function IValidatePhoneNumberRequestModelFromJSON(json: any): IValidatePhoneNumberRequestModel {
    return IValidatePhoneNumberRequestModelFromJSONTyped(json, false);
}

export function IValidatePhoneNumberRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IValidatePhoneNumberRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signupPhoneNumber': json['signupPhoneNumber'],
    };
}

export function IValidatePhoneNumberRequestModelToJSON(value?: IValidatePhoneNumberRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signupPhoneNumber': value.signupPhoneNumber,
    };
}

