/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationResponseStatusModel {
    Success = 'SUCCESS',
    Failed = 'FAILED'
}

export function NotificationResponseStatusModelFromJSON(json: any): NotificationResponseStatusModel {
    return NotificationResponseStatusModelFromJSONTyped(json, false);
}

export function NotificationResponseStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationResponseStatusModel {
    return json as NotificationResponseStatusModel;
}

export function NotificationResponseStatusModelToJSON(value?: NotificationResponseStatusModel | null): any {
    return value as any;
}

