/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentTypeModel,
    PaymentTypeModelFromJSON,
    PaymentTypeModelFromJSONTyped,
    PaymentTypeModelToJSON,
} from './PaymentTypeModel';

/**
 * 
 * @export
 * @interface IOrderPaymentModel
 */
export interface IOrderPaymentModel {
    /**
     * 
     * @type {PaymentTypeModel}
     * @memberof IOrderPaymentModel
     */
    type?: PaymentTypeModel;
    /**
     * 
     * @type {string}
     * @memberof IOrderPaymentModel
     */
    cardIssuer?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderPaymentModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof IOrderPaymentModel
     */
    amount?: number;
}

export function IOrderPaymentModelFromJSON(json: any): IOrderPaymentModel {
    return IOrderPaymentModelFromJSONTyped(json, false);
}

export function IOrderPaymentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderPaymentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : PaymentTypeModelFromJSON(json['type']),
        'cardIssuer': !exists(json, 'cardIssuer') ? undefined : json['cardIssuer'],
        'maskedCardNumber': !exists(json, 'maskedCardNumber') ? undefined : json['maskedCardNumber'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function IOrderPaymentModelToJSON(value?: IOrderPaymentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': PaymentTypeModelToJSON(value.type),
        'cardIssuer': value.cardIssuer,
        'maskedCardNumber': value.maskedCardNumber,
        'amount': value.amount,
    };
}

