/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailabilityModel,
    AvailabilityModelFromJSON,
    AvailabilityModelFromJSONTyped,
    AvailabilityModelToJSON,
} from './AvailabilityModel';
import {
    ItemModifierGroupModel,
    ItemModifierGroupModelFromJSON,
    ItemModifierGroupModelFromJSONTyped,
    ItemModifierGroupModelToJSON,
} from './ItemModifierGroupModel';
import {
    NutritionModel,
    NutritionModelFromJSON,
    NutritionModelFromJSONTyped,
    NutritionModelToJSON,
} from './NutritionModel';
import {
    PriceModel,
    PriceModelFromJSON,
    PriceModelFromJSONTyped,
    PriceModelToJSON,
} from './PriceModel';

/**
 * 
 * @export
 * @interface ItemModel
 */
export interface ItemModel {
    /**
     * 
     * @type {string}
     * @memberof ItemModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemModel
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ItemModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemModel
     */
    categoryIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemModel
     */
    sizeGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemModel
     */
    itemGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemModel
     */
    productGroupId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemModel
     */
    makeItAMeal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemModel
     */
    hasChildItems?: boolean;
    /**
     * 
     * @type {AvailabilityModel}
     * @memberof ItemModel
     */
    availability?: AvailabilityModel;
    /**
     * 
     * @type {number}
     * @memberof ItemModel
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemModel
     */
    description?: string;
    /**
     * 
     * @type {PriceModel}
     * @memberof ItemModel
     */
    price?: PriceModel;
    /**
     * 
     * @type {Array<ItemModifierGroupModel>}
     * @memberof ItemModel
     */
    itemModifierGroups?: Array<ItemModifierGroupModel>;
    /**
     * 
     * @type {Array<ItemModifierGroupModel>}
     * @memberof ItemModel
     */
    relatedItemGroups?: Array<ItemModifierGroupModel>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ItemModel
     */
    upsellRelationships?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {NutritionModel}
     * @memberof ItemModel
     */
    nutrition?: NutritionModel;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ItemModel
     */
    tags?: { [key: string]: string; };
}

export function ItemModelFromJSON(json: any): ItemModel {
    return ItemModelFromJSONTyped(json, false);
}

export function ItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'categoryIds': !exists(json, 'categoryIds') ? undefined : json['categoryIds'],
        'sizeGroupId': !exists(json, 'sizeGroupId') ? undefined : json['sizeGroupId'],
        'itemGroupId': !exists(json, 'itemGroupId') ? undefined : json['itemGroupId'],
        'productGroupId': !exists(json, 'productGroupId') ? undefined : json['productGroupId'],
        'makeItAMeal': !exists(json, 'makeItAMeal') ? undefined : json['makeItAMeal'],
        'hasChildItems': !exists(json, 'hasChildItems') ? undefined : json['hasChildItems'],
        'availability': !exists(json, 'availability') ? undefined : AvailabilityModelFromJSON(json['availability']),
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : PriceModelFromJSON(json['price']),
        'itemModifierGroups': !exists(json, 'itemModifierGroups') ? undefined : ((json['itemModifierGroups'] as Array<any>).map(ItemModifierGroupModelFromJSON)),
        'relatedItemGroups': !exists(json, 'relatedItemGroups') ? undefined : ((json['relatedItemGroups'] as Array<any>).map(ItemModifierGroupModelFromJSON)),
        'upsellRelationships': !exists(json, 'upsellRelationships') ? undefined : json['upsellRelationships'],
        'nutrition': !exists(json, 'nutrition') ? undefined : NutritionModelFromJSON(json['nutrition']),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function ItemModelToJSON(value?: ItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'metadata': value.metadata,
        'categoryIds': value.categoryIds,
        'sizeGroupId': value.sizeGroupId,
        'itemGroupId': value.itemGroupId,
        'productGroupId': value.productGroupId,
        'makeItAMeal': value.makeItAMeal,
        'hasChildItems': value.hasChildItems,
        'availability': AvailabilityModelToJSON(value.availability),
        'sequence': value.sequence,
        'description': value.description,
        'price': PriceModelToJSON(value.price),
        'itemModifierGroups': value.itemModifierGroups === undefined ? undefined : ((value.itemModifierGroups as Array<any>).map(ItemModifierGroupModelToJSON)),
        'relatedItemGroups': value.relatedItemGroups === undefined ? undefined : ((value.relatedItemGroups as Array<any>).map(ItemModifierGroupModelToJSON)),
        'upsellRelationships': value.upsellRelationships,
        'nutrition': NutritionModelToJSON(value.nutrition),
        'tags': value.tags,
    };
}

