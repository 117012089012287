/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCatalogCertificateModel,
    TCatalogCertificateModelFromJSON,
    TCatalogCertificateModelFromJSONTyped,
    TCatalogCertificateModelToJSON,
} from './TCatalogCertificateModel';
import {
    TCatalogCertificatesByCategoryModel,
    TCatalogCertificatesByCategoryModelFromJSON,
    TCatalogCertificatesByCategoryModelFromJSONTyped,
    TCatalogCertificatesByCategoryModelToJSON,
} from './TCatalogCertificatesByCategoryModel';

/**
 * 
 * @export
 * @interface IGetCustomerAccountRewardsCatalogResponseModel
 */
export interface IGetCustomerAccountRewardsCatalogResponseModel {
    /**
     * 
     * @type {TCatalogCertificatesByCategoryModel}
     * @memberof IGetCustomerAccountRewardsCatalogResponseModel
     */
    certificatesByCategory: TCatalogCertificatesByCategoryModel;
    /**
     * 
     * @type {Array<TCatalogCertificateModel>}
     * @memberof IGetCustomerAccountRewardsCatalogResponseModel
     */
    recommendations: Array<TCatalogCertificateModel>;
}

export function IGetCustomerAccountRewardsCatalogResponseModelFromJSON(json: any): IGetCustomerAccountRewardsCatalogResponseModel {
    return IGetCustomerAccountRewardsCatalogResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerAccountRewardsCatalogResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerAccountRewardsCatalogResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'certificatesByCategory': TCatalogCertificatesByCategoryModelFromJSON(json['certificatesByCategory']),
        'recommendations': ((json['recommendations'] as Array<any>).map(TCatalogCertificateModelFromJSON)),
    };
}

export function IGetCustomerAccountRewardsCatalogResponseModelToJSON(value?: IGetCustomerAccountRewardsCatalogResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'certificatesByCategory': TCatalogCertificatesByCategoryModelToJSON(value.certificatesByCategory),
        'recommendations': ((value.recommendations as Array<any>).map(TCatalogCertificateModelToJSON)),
    };
}

