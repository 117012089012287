/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICheckAmountModel,
    ICheckAmountModelFromJSON,
    ICheckAmountModelFromJSONTyped,
    ICheckAmountModelToJSON,
} from './ICheckAmountModel';

/**
 * 
 * @export
 * @interface ICheckRestrictionsModel
 */
export interface ICheckRestrictionsModel {
    /**
     * 
     * @type {ICheckAmountModel}
     * @memberof ICheckRestrictionsModel
     */
    minimumCheckAmount?: ICheckAmountModel;
    /**
     * 
     * @type {ICheckAmountModel}
     * @memberof ICheckRestrictionsModel
     */
    maximumCheckAmount?: ICheckAmountModel;
}

export function ICheckRestrictionsModelFromJSON(json: any): ICheckRestrictionsModel {
    return ICheckRestrictionsModelFromJSONTyped(json, false);
}

export function ICheckRestrictionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICheckRestrictionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minimumCheckAmount': !exists(json, 'minimumCheckAmount') ? undefined : ICheckAmountModelFromJSON(json['minimumCheckAmount']),
        'maximumCheckAmount': !exists(json, 'maximumCheckAmount') ? undefined : ICheckAmountModelFromJSON(json['maximumCheckAmount']),
    };
}

export function ICheckRestrictionsModelToJSON(value?: ICheckRestrictionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minimumCheckAmount': ICheckAmountModelToJSON(value.minimumCheckAmount),
        'maximumCheckAmount': ICheckAmountModelToJSON(value.maximumCheckAmount),
    };
}

