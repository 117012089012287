/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IGenericPageMetadataModel,
    IGenericPageMetadataModelFromJSON,
    IGenericPageMetadataModelFromJSONTyped,
    IGenericPageMetadataModelToJSON,
} from './IGenericPageMetadataModel';
import {
    IOrderModel,
    IOrderModelFromJSON,
    IOrderModelFromJSONTyped,
    IOrderModelToJSON,
} from './IOrderModel';

/**
 * 
 * @export
 * @interface IGetCustomerOrderHistoryResponseModel
 */
export interface IGetCustomerOrderHistoryResponseModel {
    /**
     * 
     * @type {IGenericPageMetadataModel}
     * @memberof IGetCustomerOrderHistoryResponseModel
     */
    metadata: IGenericPageMetadataModel;
    /**
     * 
     * @type {Array<IOrderModel>}
     * @memberof IGetCustomerOrderHistoryResponseModel
     */
    orders: Array<IOrderModel>;
}

export function IGetCustomerOrderHistoryResponseModelFromJSON(json: any): IGetCustomerOrderHistoryResponseModel {
    return IGetCustomerOrderHistoryResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerOrderHistoryResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerOrderHistoryResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': IGenericPageMetadataModelFromJSON(json['metadata']),
        'orders': ((json['orders'] as Array<any>).map(IOrderModelFromJSON)),
    };
}

export function IGetCustomerOrderHistoryResponseModelToJSON(value?: IGetCustomerOrderHistoryResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': IGenericPageMetadataModelToJSON(value.metadata),
        'orders': ((value.orders as Array<any>).map(IOrderModelToJSON)),
    };
}

