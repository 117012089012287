/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TServiceTypeModel {
    CurbsidePickup = 'CURBSIDE_PICKUP',
    OrderAhead = 'ORDER_AHEAD',
    HappyHour = 'HAPPY_HOUR',
    LateNightHappyHour = 'LATE_NIGHT_HAPPY_HOUR',
    DriveThrough = 'DRIVE_THROUGH',
    Stall = 'STALL',
    Outer = 'OUTER',
    Pickup = 'PICKUP',
    Delivery = 'DELIVERY',
    Store = 'STORE',
    HolidayHours = 'HOLIDAY_HOURS'
}

export function TServiceTypeModelFromJSON(json: any): TServiceTypeModel {
    return TServiceTypeModelFromJSONTyped(json, false);
}

export function TServiceTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TServiceTypeModel {
    return json as TServiceTypeModel;
}

export function TServiceTypeModelToJSON(value?: TServiceTypeModel | null): any {
    return value as any;
}

