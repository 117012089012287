/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IDSOfferMediaModelModel
 */
export interface IDSOfferMediaModelModel {
    /**
     * 
     * @type {string}
     * @memberof IDSOfferMediaModelModel
     */
    imageName?: string;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferMediaModelModel
     */
    fullImageName?: string;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferMediaModelModel
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IDSOfferMediaModelModel
     */
    language?: string;
}

export function IDSOfferMediaModelModelFromJSON(json: any): IDSOfferMediaModelModel {
    return IDSOfferMediaModelModelFromJSONTyped(json, false);
}

export function IDSOfferMediaModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDSOfferMediaModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageName': !exists(json, 'imageName') ? undefined : json['imageName'],
        'fullImageName': !exists(json, 'fullImageName') ? undefined : json['fullImageName'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function IDSOfferMediaModelModelToJSON(value?: IDSOfferMediaModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageName': value.imageName,
        'fullImageName': value.fullImageName,
        'imageUrl': value.imageUrl,
        'language': value.language,
    };
}

