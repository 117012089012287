/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IWalletGiftCardWithBalanceModel
 */
export interface IWalletGiftCardWithBalanceModel {
    /**
     * 
     * @type {string}
     * @memberof IWalletGiftCardWithBalanceModel
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletGiftCardWithBalanceModel
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof IWalletGiftCardWithBalanceModel
     */
    balance: number;
    /**
     * 
     * @type {boolean}
     * @memberof IWalletGiftCardWithBalanceModel
     */
    isDefault: boolean;
}

export function IWalletGiftCardWithBalanceModelFromJSON(json: any): IWalletGiftCardWithBalanceModel {
    return IWalletGiftCardWithBalanceModelFromJSONTyped(json, false);
}

export function IWalletGiftCardWithBalanceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IWalletGiftCardWithBalanceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'number': json['number'],
        'balance': json['balance'],
        'isDefault': json['isDefault'],
    };
}

export function IWalletGiftCardWithBalanceModelToJSON(value?: IWalletGiftCardWithBalanceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'number': value.number,
        'balance': value.balance,
        'isDefault': value.isDefault,
    };
}

