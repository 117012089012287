/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DineInOrderPaymentTypeModel {
    Credit = 'CREDIT',
    Giftcard = 'GIFTCARD',
    Google = 'GOOGLE',
    Apple = 'APPLE',
    Other = 'OTHER'
}

export function DineInOrderPaymentTypeModelFromJSON(json: any): DineInOrderPaymentTypeModel {
    return DineInOrderPaymentTypeModelFromJSONTyped(json, false);
}

export function DineInOrderPaymentTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DineInOrderPaymentTypeModel {
    return json as DineInOrderPaymentTypeModel;
}

export function DineInOrderPaymentTypeModelToJSON(value?: DineInOrderPaymentTypeModel | null): any {
    return value as any;
}

