/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    THourDayOfWeekModel,
    THourDayOfWeekModelFromJSON,
    THourDayOfWeekModelFromJSONTyped,
    THourDayOfWeekModelToJSON,
} from './THourDayOfWeekModel';

/**
 * 
 * @export
 * @interface IHoursModel
 */
export interface IHoursModel {
    /**
     * 
     * @type {THourDayOfWeekModel}
     * @memberof IHoursModel
     */
    dayOfWeek: THourDayOfWeekModel;
    /**
     * 
     * @type {string}
     * @memberof IHoursModel
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof IHoursModel
     */
    endTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IHoursModel
     */
    isTwentyFourHourService?: boolean;
}

export function IHoursModelFromJSON(json: any): IHoursModel {
    return IHoursModelFromJSONTyped(json, false);
}

export function IHoursModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IHoursModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': THourDayOfWeekModelFromJSON(json['dayOfWeek']),
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'isTwentyFourHourService': !exists(json, 'isTwentyFourHourService') ? undefined : json['isTwentyFourHourService'],
    };
}

export function IHoursModelToJSON(value?: IHoursModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayOfWeek': THourDayOfWeekModelToJSON(value.dayOfWeek),
        'startTime': value.startTime,
        'endTime': value.endTime,
        'isTwentyFourHourService': value.isTwentyFourHourService,
    };
}

