/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ISurveyRespondModel
 */
export interface ISurveyRespondModel {
    /**
     * 
     * @type {string}
     * @memberof ISurveyRespondModel
     */
    questionId: string;
    /**
     * 
     * @type {string}
     * @memberof ISurveyRespondModel
     */
    answerId: string;
}

export function ISurveyRespondModelFromJSON(json: any): ISurveyRespondModel {
    return ISurveyRespondModelFromJSONTyped(json, false);
}

export function ISurveyRespondModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ISurveyRespondModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': json['questionId'],
        'answerId': json['answerId'],
    };
}

export function ISurveyRespondModelToJSON(value?: ISurveyRespondModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'answerId': value.answerId,
    };
}

