/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IVisitRegisterRequestCustomerPhoneNumberModel
 */
export interface IVisitRegisterRequestCustomerPhoneNumberModel {
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestCustomerPhoneNumberModel
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestCustomerPhoneNumberModel
     */
    countryCode?: string;
}

export function IVisitRegisterRequestCustomerPhoneNumberModelFromJSON(json: any): IVisitRegisterRequestCustomerPhoneNumberModel {
    return IVisitRegisterRequestCustomerPhoneNumberModelFromJSONTyped(json, false);
}

export function IVisitRegisterRequestCustomerPhoneNumberModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterRequestCustomerPhoneNumberModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
    };
}

export function IVisitRegisterRequestCustomerPhoneNumberModelToJSON(value?: IVisitRegisterRequestCustomerPhoneNumberModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'countryCode': value.countryCode,
    };
}

