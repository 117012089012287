/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactDetailDtoModelTypeEnumModel {
    Public = 'PUBLIC',
    CustomerService = 'CUSTOMER_SERVICE',
    StoreOperations = 'STORE_OPERATIONS',
    Store = 'STORE'
}

export function ContactDetailDtoModelTypeEnumModelFromJSON(json: any): ContactDetailDtoModelTypeEnumModel {
    return ContactDetailDtoModelTypeEnumModelFromJSONTyped(json, false);
}

export function ContactDetailDtoModelTypeEnumModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDetailDtoModelTypeEnumModel {
    return json as ContactDetailDtoModelTypeEnumModel;
}

export function ContactDetailDtoModelTypeEnumModelToJSON(value?: ContactDetailDtoModelTypeEnumModel | null): any {
    return value as any;
}

