/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Offer Type
 * @export
 * @enum {string}
 */
export enum TOfferTypeModel {
    SetPrice = 'SET_PRICE',
    FixedAmount = 'FIXED_AMOUNT',
    BuyXGetYSetPrice = 'BUY_X_GET_Y_SET_PRICE',
    BuyXGetYPercent = 'BUY_X_GET_Y_PERCENT',
    BuyXGetYFixed = 'BUY_X_GET_Y_FIXED',
    Percent = 'PERCENT',
    Unknown = 'UNKNOWN'
}

export function TOfferTypeModelFromJSON(json: any): TOfferTypeModel {
    return TOfferTypeModelFromJSONTyped(json, false);
}

export function TOfferTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TOfferTypeModel {
    return json as TOfferTypeModel;
}

export function TOfferTypeModelToJSON(value?: TOfferTypeModel | null): any {
    return value as any;
}

