/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardsDiscountOfferDSResponseModel,
    IRewardsDiscountOfferDSResponseModelFromJSON,
    IRewardsDiscountOfferDSResponseModelFromJSONTyped,
    IRewardsDiscountOfferDSResponseModelToJSON,
} from './IRewardsDiscountOfferDSResponseModel';
import {
    IRewardsDiscountOrderDSResponseModel,
    IRewardsDiscountOrderDSResponseModelFromJSON,
    IRewardsDiscountOrderDSResponseModelFromJSONTyped,
    IRewardsDiscountOrderDSResponseModelToJSON,
} from './IRewardsDiscountOrderDSResponseModel';

/**
 * 
 * @export
 * @interface IRewardsDiscountDSResponseModel
 */
export interface IRewardsDiscountDSResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountDSResponseModel
     */
    customerId: string;
    /**
     * 
     * @type {IRewardsDiscountOrderDSResponseModel}
     * @memberof IRewardsDiscountDSResponseModel
     */
    order: IRewardsDiscountOrderDSResponseModel;
    /**
     * 
     * @type {Array<IRewardsDiscountOfferDSResponseModel>}
     * @memberof IRewardsDiscountDSResponseModel
     */
    offers?: Array<IRewardsDiscountOfferDSResponseModel>;
}

export function IRewardsDiscountDSResponseModelFromJSON(json: any): IRewardsDiscountDSResponseModel {
    return IRewardsDiscountDSResponseModelFromJSONTyped(json, false);
}

export function IRewardsDiscountDSResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountDSResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'order': IRewardsDiscountOrderDSResponseModelFromJSON(json['order']),
        'offers': !exists(json, 'offers') ? undefined : ((json['offers'] as Array<any>).map(IRewardsDiscountOfferDSResponseModelFromJSON)),
    };
}

export function IRewardsDiscountDSResponseModelToJSON(value?: IRewardsDiscountDSResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'order': IRewardsDiscountOrderDSResponseModelToJSON(value.order),
        'offers': value.offers === undefined ? undefined : ((value.offers as Array<any>).map(IRewardsDiscountOfferDSResponseModelToJSON)),
    };
}

