/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ISignUpModelModel
 */
export interface ISignUpModelModel {
    /**
     * first name
     * @type {string}
     * @memberof ISignUpModelModel
     */
    firstName: string;
    /**
     * last name
     * @type {string}
     * @memberof ISignUpModelModel
     */
    lastName: string;
    /**
     * email
     * @type {string}
     * @memberof ISignUpModelModel
     */
    email: string;
    /**
     * birth date
     * @type {string}
     * @memberof ISignUpModelModel
     */
    birthDate?: string;
    /**
     * postal code
     * @type {string}
     * @memberof ISignUpModelModel
     */
    postalCode: string;
    /**
     * location name
     * @type {string}
     * @memberof ISignUpModelModel
     */
    locationName?: string;
    /**
     * location Id
     * @type {string}
     * @memberof ISignUpModelModel
     */
    locationId: string;
}

export function ISignUpModelModelFromJSON(json: any): ISignUpModelModel {
    return ISignUpModelModelFromJSONTyped(json, false);
}

export function ISignUpModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ISignUpModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'postalCode': json['postalCode'],
        'locationName': !exists(json, 'locationName') ? undefined : json['locationName'],
        'locationId': json['locationId'],
    };
}

export function ISignUpModelModelToJSON(value?: ISignUpModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'birthDate': value.birthDate,
        'postalCode': value.postalCode,
        'locationName': value.locationName,
        'locationId': value.locationId,
    };
}

