/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorCodeMISSINGCREDENTIALSModel {
    MissingCredentials = 'MISSING_CREDENTIALS'
}

export function TErrorCodeMISSINGCREDENTIALSModelFromJSON(json: any): TErrorCodeMISSINGCREDENTIALSModel {
    return TErrorCodeMISSINGCREDENTIALSModelFromJSONTyped(json, false);
}

export function TErrorCodeMISSINGCREDENTIALSModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorCodeMISSINGCREDENTIALSModel {
    return json as TErrorCodeMISSINGCREDENTIALSModel;
}

export function TErrorCodeMISSINGCREDENTIALSModelToJSON(value?: TErrorCodeMISSINGCREDENTIALSModel | null): any {
    return value as any;
}

