/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDSResponseModel,
    DiscountDSResponseModelFromJSON,
    DiscountDSResponseModelFromJSONTyped,
    DiscountDSResponseModelToJSON,
} from './DiscountDSResponseModel';
import {
    FeeItemModel,
    FeeItemModelFromJSON,
    FeeItemModelFromJSONTyped,
    FeeItemModelToJSON,
} from './FeeItemModel';
import {
    TallyProductModel,
    TallyProductModelFromJSON,
    TallyProductModelFromJSONTyped,
    TallyProductModelToJSON,
} from './TallyProductModel';
import {
    TallyResponseFulfillmentModel,
    TallyResponseFulfillmentModelFromJSON,
    TallyResponseFulfillmentModelFromJSONTyped,
    TallyResponseFulfillmentModelToJSON,
} from './TallyResponseFulfillmentModel';

/**
 * 
 * @export
 * @interface TallyResponseModel
 */
export interface TallyResponseModel {
    /**
     * 
     * @type {string}
     * @memberof TallyResponseModel
     */
    bagId?: string;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    subTotalBeforeDiscounts: number;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    discountsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    subTotalAfterDiscounts: number;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    total: number;
    /**
     * 
     * @type {string}
     * @memberof TallyResponseModel
     */
    currencyCode: string;
    /**
     * 
     * @type {Array<TallyProductModel>}
     * @memberof TallyResponseModel
     */
    products: Array<TallyProductModel>;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    driverTip: number;
    /**
     * 
     * @type {number}
     * @memberof TallyResponseModel
     */
    serverTip: number;
    /**
     * 
     * @type {Array<FeeItemModel>}
     * @memberof TallyResponseModel
     */
    fees: Array<FeeItemModel>;
    /**
     * 
     * @type {TallyResponseFulfillmentModel}
     * @memberof TallyResponseModel
     */
    fulfillment: TallyResponseFulfillmentModel;
    /**
     * 
     * @type {DiscountDSResponseModel}
     * @memberof TallyResponseModel
     */
    discounts?: DiscountDSResponseModel;
    /**
     * 
     * @type {string}
     * @memberof TallyResponseModel
     */
    sellingChannel?: string;
}

export function TallyResponseModelFromJSON(json: any): TallyResponseModel {
    return TallyResponseModelFromJSONTyped(json, false);
}

export function TallyResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bagId': !exists(json, 'bagId') ? undefined : json['bagId'],
        'subTotalBeforeDiscounts': json['subTotalBeforeDiscounts'],
        'discountsAmount': json['discountsAmount'],
        'subTotalAfterDiscounts': json['subTotalAfterDiscounts'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'total': json['total'],
        'currencyCode': json['currencyCode'],
        'products': ((json['products'] as Array<any>).map(TallyProductModelFromJSON)),
        'driverTip': json['driverTip'],
        'serverTip': json['serverTip'],
        'fees': ((json['fees'] as Array<any>).map(FeeItemModelFromJSON)),
        'fulfillment': TallyResponseFulfillmentModelFromJSON(json['fulfillment']),
        'discounts': !exists(json, 'discounts') ? undefined : DiscountDSResponseModelFromJSON(json['discounts']),
        'sellingChannel': !exists(json, 'sellingChannel') ? undefined : json['sellingChannel'],
    };
}

export function TallyResponseModelToJSON(value?: TallyResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bagId': value.bagId,
        'subTotalBeforeDiscounts': value.subTotalBeforeDiscounts,
        'discountsAmount': value.discountsAmount,
        'subTotalAfterDiscounts': value.subTotalAfterDiscounts,
        'tax': value.tax,
        'total': value.total,
        'currencyCode': value.currencyCode,
        'products': ((value.products as Array<any>).map(TallyProductModelToJSON)),
        'driverTip': value.driverTip,
        'serverTip': value.serverTip,
        'fees': ((value.fees as Array<any>).map(FeeItemModelToJSON)),
        'fulfillment': TallyResponseFulfillmentModelToJSON(value.fulfillment),
        'discounts': DiscountDSResponseModelToJSON(value.discounts),
        'sellingChannel': value.sellingChannel,
    };
}

