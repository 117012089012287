/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceModel,
    PriceModelFromJSON,
    PriceModelFromJSONTyped,
    PriceModelToJSON,
} from './PriceModel';

/**
 * 
 * @export
 * @interface ItemModifierModel
 */
export interface ItemModifierModel {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ItemModifierModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ItemModifierModel
     */
    sequence?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierModel
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierModel
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierModel
     */
    recipe?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierModel
     */
    defaultQuantity?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ItemModifierModel
     */
    tags?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemModifierModel
     */
    actionCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemModifierModel
     */
    itemId?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemModifierModel
     */
    itemGroupId?: string;
    /**
     * 
     * @type {PriceModel}
     * @memberof ItemModifierModel
     */
    overridePrice?: PriceModel;
    /**
     * 
     * @type {Array<ItemModifierModel>}
     * @memberof ItemModifierModel
     */
    itemModifiers?: Array<ItemModifierModel>;
}

export function ItemModifierModelFromJSON(json: any): ItemModifierModel {
    return ItemModifierModelFromJSONTyped(json, false);
}

export function ItemModifierModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemModifierModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'recipe': !exists(json, 'recipe') ? undefined : json['recipe'],
        'defaultQuantity': !exists(json, 'defaultQuantity') ? undefined : json['defaultQuantity'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'actionCodes': !exists(json, 'actionCodes') ? undefined : json['actionCodes'],
        'itemId': !exists(json, 'itemId') ? undefined : json['itemId'],
        'itemGroupId': !exists(json, 'itemGroupId') ? undefined : json['itemGroupId'],
        'overridePrice': !exists(json, 'overridePrice') ? undefined : PriceModelFromJSON(json['overridePrice']),
        'itemModifiers': !exists(json, 'itemModifiers') ? undefined : ((json['itemModifiers'] as Array<any>).map(ItemModifierModelFromJSON)),
    };
}

export function ItemModifierModelToJSON(value?: ItemModifierModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': value.metadata,
        'sequence': value.sequence,
        'max': value.max,
        'min': value.min,
        'recipe': value.recipe,
        'defaultQuantity': value.defaultQuantity,
        'tags': value.tags,
        'actionCodes': value.actionCodes,
        'itemId': value.itemId,
        'itemGroupId': value.itemGroupId,
        'overridePrice': PriceModelToJSON(value.overridePrice),
        'itemModifiers': value.itemModifiers === undefined ? undefined : ((value.itemModifiers as Array<any>).map(ItemModifierModelToJSON)),
    };
}

