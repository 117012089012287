/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationModel,
    ILocationModelFromJSON,
    ILocationModelFromJSONTyped,
    ILocationModelToJSON,
} from './ILocationModel';
import {
    IMarketingModel,
    IMarketingModelFromJSON,
    IMarketingModelFromJSONTyped,
    IMarketingModelToJSON,
} from './IMarketingModel';

/**
 * 
 * @export
 * @interface IPreferencesModel
 */
export interface IPreferencesModel {
    /**
     * 
     * @type {string}
     * @memberof IPreferencesModel
     */
    postalCode?: string;
    /**
     * 
     * @type {Array<IMarketingModel>}
     * @memberof IPreferencesModel
     */
    marketing?: Array<IMarketingModel>;
    /**
     * 
     * @type {Array<ILocationModel>}
     * @memberof IPreferencesModel
     */
    locations?: Array<ILocationModel>;
}

export function IPreferencesModelFromJSON(json: any): IPreferencesModel {
    return IPreferencesModelFromJSONTyped(json, false);
}

export function IPreferencesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPreferencesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'marketing': !exists(json, 'marketing') ? undefined : ((json['marketing'] as Array<any>).map(IMarketingModelFromJSON)),
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(ILocationModelFromJSON)),
    };
}

export function IPreferencesModelToJSON(value?: IPreferencesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postalCode': value.postalCode,
        'marketing': value.marketing === undefined ? undefined : ((value.marketing as Array<any>).map(IMarketingModelToJSON)),
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(ILocationModelToJSON)),
    };
}

