/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardsDiscountItemDSResponseModel,
    IRewardsDiscountItemDSResponseModelFromJSON,
    IRewardsDiscountItemDSResponseModelFromJSONTyped,
    IRewardsDiscountItemDSResponseModelToJSON,
} from './IRewardsDiscountItemDSResponseModel';

/**
 * 
 * @export
 * @interface IRewardsDiscountItemDSResponseModifierGroupsModel
 */
export interface IRewardsDiscountItemDSResponseModifierGroupsModel {
    /**
     * 
     * @type {Array<IRewardsDiscountItemDSResponseModel>}
     * @memberof IRewardsDiscountItemDSResponseModifierGroupsModel
     */
    modifiers?: Array<IRewardsDiscountItemDSResponseModel>;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountItemDSResponseModifierGroupsModel
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountItemDSResponseModifierGroupsModel
     */
    description: string;
}

export function IRewardsDiscountItemDSResponseModifierGroupsModelFromJSON(json: any): IRewardsDiscountItemDSResponseModifierGroupsModel {
    return IRewardsDiscountItemDSResponseModifierGroupsModelFromJSONTyped(json, false);
}

export function IRewardsDiscountItemDSResponseModifierGroupsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountItemDSResponseModifierGroupsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modifiers': !exists(json, 'modifiers') ? undefined : ((json['modifiers'] as Array<any>).map(IRewardsDiscountItemDSResponseModelFromJSON)),
        'productId': json['productId'],
        'description': json['description'],
    };
}

export function IRewardsDiscountItemDSResponseModifierGroupsModelToJSON(value?: IRewardsDiscountItemDSResponseModifierGroupsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modifiers': value.modifiers === undefined ? undefined : ((value.modifiers as Array<any>).map(IRewardsDiscountItemDSResponseModelToJSON)),
        'productId': value.productId,
        'description': value.description,
    };
}

