/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TTallyErrorMessageModel {
    SomeProductsContainInvalidQuantitiesPleaseModifyTheQuantitiesAndTryAgain = 'Some products contain invalid quantities. Please modify the quantities and try again.',
    SomeItemsAreInvalid = '“Some items are invalid.',
    SomeProductsAreUnavailablePleaseRemoveSomeProductSAndTryAgain = 'Some products are unavailable. Please remove some product(s) and try again.',
    WeApologizeWeDoNotHaveAnyAvailableTimeslotsForTheTimeYouChosePleaseSwitchYourOrderToPickupOrSelectANewDeliveryTimeToProceedWithYourOrder = 'We apologize. We do not have any available timeslots for the time you chose. Please switch your order to pickup or select a new delivery time to proceed with your order.',
    WeApologizeYourOrderExceedsOrderMaxValueIfYouStillWantToPlaceYourOrderCallUsAtPhoneNumberOrRemoveItemsFromYourBag = 'We apologize. Your order exceeds ${orderMaxValue}. If you still want to place your order, call us at {phoneNumber}, or remove items from your bag.',
    TheRequestedLocationIsNotAvailablePleaseSelectAnotherLocationAndTryAgain = 'The requested location is not available. Please select another location and try again.',
    TheEarliestDeliveryTimeIsEarliestTimeOnTheSelectedDatePleaseSelectANewDateTimeAndTryAgain = 'The earliest delivery time is {earliestTime} on the selected date. Please select a new date/time and try again.',
    PreviouslySelectedTimeIsNotValidPleaseSelectANewTime = 'Previously selected time is not valid. Please select a new time.',
    DateAndTimeMustBeValidPleaseSelectANewTime = 'Date and time must be valid. Please select a new time.',
    OnlineOrderingIsTemporarilyUnavailableAtThisLocationPleaseTryCallingYourLocalSportsBarToPlaceAnOrder = 'Online ordering is temporarily unavailable at this location. Please try calling your local sports bar to place an order.'
}

export function TTallyErrorMessageModelFromJSON(json: any): TTallyErrorMessageModel {
    return TTallyErrorMessageModelFromJSONTyped(json, false);
}

export function TTallyErrorMessageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TTallyErrorMessageModel {
    return json as TTallyErrorMessageModel;
}

export function TTallyErrorMessageModelToJSON(value?: TTallyErrorMessageModel | null): any {
    return value as any;
}

