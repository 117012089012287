/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleBillingAddressFormatEnumModel,
    GoogleBillingAddressFormatEnumModelFromJSON,
    GoogleBillingAddressFormatEnumModelFromJSONTyped,
    GoogleBillingAddressFormatEnumModelToJSON,
} from './GoogleBillingAddressFormatEnumModel';
import {
    GoogleButtonColorEnumModel,
    GoogleButtonColorEnumModelFromJSON,
    GoogleButtonColorEnumModelFromJSONTyped,
    GoogleButtonColorEnumModelToJSON,
} from './GoogleButtonColorEnumModel';
import {
    GoogleButtonTypeEnumModel,
    GoogleButtonTypeEnumModelFromJSON,
    GoogleButtonTypeEnumModelFromJSONTyped,
    GoogleButtonTypeEnumModelToJSON,
} from './GoogleButtonTypeEnumModel';
import {
    GoogleInitResponseTypeEnumModel,
    GoogleInitResponseTypeEnumModelFromJSON,
    GoogleInitResponseTypeEnumModelFromJSONTyped,
    GoogleInitResponseTypeEnumModelToJSON,
} from './GoogleInitResponseTypeEnumModel';

/**
 * 
 * @export
 * @interface IGooglePaymentInitBodyModel
 */
export interface IGooglePaymentInitBodyModel {
    /**
     * Location Id
     * @type {string}
     * @memberof IGooglePaymentInitBodyModel
     */
    locationId: string;
    /**
     * 
     * @type {number}
     * @memberof IGooglePaymentInitBodyModel
     */
    totalAmount: number;
    /**
     * 
     * @type {string}
     * @memberof IGooglePaymentInitBodyModel
     */
    fulfillmentType?: string;
    /**
     * 
     * @type {string}
     * @memberof IGooglePaymentInitBodyModel
     */
    styles?: string;
    /**
     * 
     * @type {GoogleButtonTypeEnumModel}
     * @memberof IGooglePaymentInitBodyModel
     */
    submitButtonType?: GoogleButtonTypeEnumModel;
    /**
     * 
     * @type {GoogleButtonColorEnumModel}
     * @memberof IGooglePaymentInitBodyModel
     */
    submitButtonColor?: GoogleButtonColorEnumModel;
    /**
     * 
     * @type {string}
     * @memberof IGooglePaymentInitBodyModel
     */
    cartId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IGooglePaymentInitBodyModel
     */
    isBillingAddressRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IGooglePaymentInitBodyModel
     */
    isPhoneNumberRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IGooglePaymentInitBodyModel
     */
    isEmailRequired?: boolean;
    /**
     * 
     * @type {GoogleBillingAddressFormatEnumModel}
     * @memberof IGooglePaymentInitBodyModel
     */
    billingAddressFormat?: GoogleBillingAddressFormatEnumModel;
    /**
     * 
     * @type {GoogleInitResponseTypeEnumModel}
     * @memberof IGooglePaymentInitBodyModel
     */
    responseType?: GoogleInitResponseTypeEnumModel;
}

export function IGooglePaymentInitBodyModelFromJSON(json: any): IGooglePaymentInitBodyModel {
    return IGooglePaymentInitBodyModelFromJSONTyped(json, false);
}

export function IGooglePaymentInitBodyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGooglePaymentInitBodyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'totalAmount': json['totalAmount'],
        'fulfillmentType': !exists(json, 'fulfillmentType') ? undefined : json['fulfillmentType'],
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'submitButtonType': !exists(json, 'submitButtonType') ? undefined : GoogleButtonTypeEnumModelFromJSON(json['submitButtonType']),
        'submitButtonColor': !exists(json, 'submitButtonColor') ? undefined : GoogleButtonColorEnumModelFromJSON(json['submitButtonColor']),
        'cartId': !exists(json, 'cartId') ? undefined : json['cartId'],
        'isBillingAddressRequired': !exists(json, 'isBillingAddressRequired') ? undefined : json['isBillingAddressRequired'],
        'isPhoneNumberRequired': !exists(json, 'isPhoneNumberRequired') ? undefined : json['isPhoneNumberRequired'],
        'isEmailRequired': !exists(json, 'isEmailRequired') ? undefined : json['isEmailRequired'],
        'billingAddressFormat': !exists(json, 'billingAddressFormat') ? undefined : GoogleBillingAddressFormatEnumModelFromJSON(json['billingAddressFormat']),
        'responseType': !exists(json, 'responseType') ? undefined : GoogleInitResponseTypeEnumModelFromJSON(json['responseType']),
    };
}

export function IGooglePaymentInitBodyModelToJSON(value?: IGooglePaymentInitBodyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'totalAmount': value.totalAmount,
        'fulfillmentType': value.fulfillmentType,
        'styles': value.styles,
        'submitButtonType': GoogleButtonTypeEnumModelToJSON(value.submitButtonType),
        'submitButtonColor': GoogleButtonColorEnumModelToJSON(value.submitButtonColor),
        'cartId': value.cartId,
        'isBillingAddressRequired': value.isBillingAddressRequired,
        'isPhoneNumberRequired': value.isPhoneNumberRequired,
        'isEmailRequired': value.isEmailRequired,
        'billingAddressFormat': GoogleBillingAddressFormatEnumModelToJSON(value.billingAddressFormat),
        'responseType': GoogleInitResponseTypeEnumModelToJSON(value.responseType),
    };
}

