/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ITransformedCustomerIdResponseForClientModel
 */
export interface ITransformedCustomerIdResponseForClientModel {
    /**
     * 
     * @type {string}
     * @memberof ITransformedCustomerIdResponseForClientModel
     */
    idpCustomerId: string;
}

export function ITransformedCustomerIdResponseForClientModelFromJSON(json: any): ITransformedCustomerIdResponseForClientModel {
    return ITransformedCustomerIdResponseForClientModelFromJSONTyped(json, false);
}

export function ITransformedCustomerIdResponseForClientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ITransformedCustomerIdResponseForClientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpCustomerId': json['idpCustomerId'],
    };
}

export function ITransformedCustomerIdResponseForClientModelToJSON(value?: ITransformedCustomerIdResponseForClientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpCustomerId': value.idpCustomerId,
    };
}

