/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TallyModifierActionCodeModel {
    Add = 'ADD',
    No = 'NO',
    Side = 'SIDE',
    Extra = 'EXTRA',
    Regular = 'REGULAR',
    Light = 'LIGHT'
}

export function TallyModifierActionCodeModelFromJSON(json: any): TallyModifierActionCodeModel {
    return TallyModifierActionCodeModelFromJSONTyped(json, false);
}

export function TallyModifierActionCodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyModifierActionCodeModel {
    return json as TallyModifierActionCodeModel;
}

export function TallyModifierActionCodeModelToJSON(value?: TallyModifierActionCodeModel | null): any {
    return value as any;
}

