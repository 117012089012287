/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IProductGroupModel
 */
export interface IProductGroupModel {
    /**
     * 
     * @type {string}
     * @memberof IProductGroupModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductGroupModel
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IProductGroupModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof IProductGroupModel
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof IProductGroupModel
     */
    parentGroupId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IProductGroupModel
     */
    isCombo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProductGroupModel
     */
    isPromo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IProductGroupModel
     */
    productGroupType?: string;
}

export function IProductGroupModelFromJSON(json: any): IProductGroupModel {
    return IProductGroupModelFromJSONTyped(json, false);
}

export function IProductGroupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IProductGroupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'parentGroupId': !exists(json, 'parentGroupId') ? undefined : json['parentGroupId'],
        'isCombo': !exists(json, 'isCombo') ? undefined : json['isCombo'],
        'isPromo': !exists(json, 'isPromo') ? undefined : json['isPromo'],
        'productGroupType': !exists(json, 'productGroupType') ? undefined : json['productGroupType'],
    };
}

export function IProductGroupModelToJSON(value?: IProductGroupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'metadata': value.metadata,
        'sequence': value.sequence,
        'parentGroupId': value.parentGroupId,
        'isCombo': value.isCombo,
        'isPromo': value.isPromo,
        'productGroupType': value.productGroupType,
    };
}

