/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyFulfillmentTypeModel,
    TallyFulfillmentTypeModelFromJSON,
    TallyFulfillmentTypeModelFromJSONTyped,
    TallyFulfillmentTypeModelToJSON,
} from './TallyFulfillmentTypeModel';

/**
 * 
 * @export
 * @interface OrderResponseFulfillmentModel
 */
export interface OrderResponseFulfillmentModel {
    /**
     * 
     * @type {string}
     * @memberof OrderResponseFulfillmentModel
     */
    deliveryTrackingUrl?: string;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof OrderResponseFulfillmentModel
     */
    time?: Date;
    /**
     * 
     * @type {TallyFulfillmentTypeModel}
     * @memberof OrderResponseFulfillmentModel
     */
    type: TallyFulfillmentTypeModel;
}

export function OrderResponseFulfillmentModelFromJSON(json: any): OrderResponseFulfillmentModel {
    return OrderResponseFulfillmentModelFromJSONTyped(json, false);
}

export function OrderResponseFulfillmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResponseFulfillmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryTrackingUrl': !exists(json, 'deliveryTrackingUrl') ? undefined : json['deliveryTrackingUrl'],
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'type': TallyFulfillmentTypeModelFromJSON(json['type']),
    };
}

export function OrderResponseFulfillmentModelToJSON(value?: OrderResponseFulfillmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryTrackingUrl': value.deliveryTrackingUrl,
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'type': TallyFulfillmentTypeModelToJSON(value.type),
    };
}

