/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IAccountPaymentMethodModel
 */
export interface IAccountPaymentMethodModel {
    /**
     * Label (Nickname)
     * @type {string}
     * @memberof IAccountPaymentMethodModel
     */
    label?: string;
    /**
     * Card Holder Name
     * @type {string}
     * @memberof IAccountPaymentMethodModel
     */
    cardHolderName: string;
    /**
     * Card Type
     * @type {string}
     * @memberof IAccountPaymentMethodModel
     */
    type: string;
    /**
     * Last Four Digits (Masked Card Number)
     * @type {string}
     * @memberof IAccountPaymentMethodModel
     */
    lastFourDigits: string;
    /**
     * Expiration Date
     * @type {string}
     * @memberof IAccountPaymentMethodModel
     */
    expirationDate: string;
    /**
     * Is Card Default (preferred)
     * @type {boolean}
     * @memberof IAccountPaymentMethodModel
     */
    isDefault: boolean;
    /**
     * Is Card Expired
     * @type {boolean}
     * @memberof IAccountPaymentMethodModel
     */
    isExpired: boolean;
    /**
     * Token
     * @type {string}
     * @memberof IAccountPaymentMethodModel
     */
    token: string;
}

export function IAccountPaymentMethodModelFromJSON(json: any): IAccountPaymentMethodModel {
    return IAccountPaymentMethodModelFromJSONTyped(json, false);
}

export function IAccountPaymentMethodModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAccountPaymentMethodModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'cardHolderName': json['cardHolderName'],
        'type': json['type'],
        'lastFourDigits': json['lastFourDigits'],
        'expirationDate': json['expirationDate'],
        'isDefault': json['isDefault'],
        'isExpired': json['isExpired'],
        'token': json['token'],
    };
}

export function IAccountPaymentMethodModelToJSON(value?: IAccountPaymentMethodModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'cardHolderName': value.cardHolderName,
        'type': value.type,
        'lastFourDigits': value.lastFourDigits,
        'expirationDate': value.expirationDate,
        'isDefault': value.isDefault,
        'isExpired': value.isExpired,
        'token': value.token,
    };
}

