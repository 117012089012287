/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TApplePaymentFulfillmentTypesModel,
    TApplePaymentFulfillmentTypesModelFromJSON,
    TApplePaymentFulfillmentTypesModelFromJSONTyped,
    TApplePaymentFulfillmentTypesModelToJSON,
} from './TApplePaymentFulfillmentTypesModel';

/**
 * 
 * @export
 * @interface IApplePaymentInitBodyModel
 */
export interface IApplePaymentInitBodyModel {
    /**
     * Location Id
     * @type {string}
     * @memberof IApplePaymentInitBodyModel
     */
    locationId: string;
    /**
     * Total Amount
     * @type {number}
     * @memberof IApplePaymentInitBodyModel
     */
    totalAmount: number;
    /**
     * 
     * @type {TApplePaymentFulfillmentTypesModel}
     * @memberof IApplePaymentInitBodyModel
     */
    fulfillmentType?: TApplePaymentFulfillmentTypesModel;
    /**
     * Submit Button Type
     * @type {string}
     * @memberof IApplePaymentInitBodyModel
     */
    submitButtonType?: string;
    /**
     * Submit Button Color
     * @type {string}
     * @memberof IApplePaymentInitBodyModel
     */
    submitButtonColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IApplePaymentInitBodyModel
     */
    isAutoFinalizePayment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IApplePaymentInitBodyModel
     */
    styles?: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaymentInitBodyModel
     */
    cartId?: string;
}

export function IApplePaymentInitBodyModelFromJSON(json: any): IApplePaymentInitBodyModel {
    return IApplePaymentInitBodyModelFromJSONTyped(json, false);
}

export function IApplePaymentInitBodyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApplePaymentInitBodyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'totalAmount': json['totalAmount'],
        'fulfillmentType': !exists(json, 'fulfillmentType') ? undefined : TApplePaymentFulfillmentTypesModelFromJSON(json['fulfillmentType']),
        'submitButtonType': !exists(json, 'submitButtonType') ? undefined : json['submitButtonType'],
        'submitButtonColor': !exists(json, 'submitButtonColor') ? undefined : json['submitButtonColor'],
        'isAutoFinalizePayment': !exists(json, 'isAutoFinalizePayment') ? undefined : json['isAutoFinalizePayment'],
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'cartId': !exists(json, 'cartId') ? undefined : json['cartId'],
    };
}

export function IApplePaymentInitBodyModelToJSON(value?: IApplePaymentInitBodyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'totalAmount': value.totalAmount,
        'fulfillmentType': TApplePaymentFulfillmentTypesModelToJSON(value.fulfillmentType),
        'submitButtonType': value.submitButtonType,
        'submitButtonColor': value.submitButtonColor,
        'isAutoFinalizePayment': value.isAutoFinalizePayment,
        'styles': value.styles,
        'cartId': value.cartId,
    };
}

