/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryModel,
    CategoryModelFromJSON,
    CategoryModelFromJSONTyped,
    CategoryModelToJSON,
} from './CategoryModel';
import {
    IProductGroupModel,
    IProductGroupModelFromJSON,
    IProductGroupModelFromJSONTyped,
    IProductGroupModelToJSON,
} from './IProductGroupModel';
import {
    ItemGroupModel,
    ItemGroupModelFromJSON,
    ItemGroupModelFromJSONTyped,
    ItemGroupModelToJSON,
} from './ItemGroupModel';
import {
    ProductModel,
    ProductModelFromJSON,
    ProductModelFromJSONTyped,
    ProductModelToJSON,
} from './ProductModel';
import {
    SizeGroupModel,
    SizeGroupModelFromJSON,
    SizeGroupModelFromJSONTyped,
    SizeGroupModelToJSON,
} from './SizeGroupModel';

/**
 * 
 * @export
 * @interface IMenuModel
 */
export interface IMenuModel {
    /**
     * 
     * @type {{ [key: string]: IProductGroupModel; }}
     * @memberof IMenuModel
     */
    productGroups: { [key: string]: IProductGroupModel; };
    /**
     * 
     * @type {{ [key: string]: ItemGroupModel; }}
     * @memberof IMenuModel
     */
    itemGroups: { [key: string]: ItemGroupModel; };
    /**
     * 
     * @type {{ [key: string]: SizeGroupModel; }}
     * @memberof IMenuModel
     */
    sizeGroups: { [key: string]: SizeGroupModel; };
    /**
     * 
     * @type {{ [key: string]: ProductModel; }}
     * @memberof IMenuModel
     */
    products: { [key: string]: ProductModel; };
    /**
     * 
     * @type {{ [key: string]: CategoryModel; }}
     * @memberof IMenuModel
     */
    categories: { [key: string]: CategoryModel; };
}

export function IMenuModelFromJSON(json: any): IMenuModel {
    return IMenuModelFromJSONTyped(json, false);
}

export function IMenuModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMenuModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productGroups': (mapValues(json['productGroups'], IProductGroupModelFromJSON)),
        'itemGroups': (mapValues(json['itemGroups'], ItemGroupModelFromJSON)),
        'sizeGroups': (mapValues(json['sizeGroups'], SizeGroupModelFromJSON)),
        'products': (mapValues(json['products'], ProductModelFromJSON)),
        'categories': (mapValues(json['categories'], CategoryModelFromJSON)),
    };
}

export function IMenuModelToJSON(value?: IMenuModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productGroups': (mapValues(value.productGroups, IProductGroupModelToJSON)),
        'itemGroups': (mapValues(value.itemGroups, ItemGroupModelToJSON)),
        'sizeGroups': (mapValues(value.sizeGroups, SizeGroupModelToJSON)),
        'products': (mapValues(value.products, ProductModelToJSON)),
        'categories': (mapValues(value.categories, CategoryModelToJSON)),
    };
}

