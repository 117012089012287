/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TActivityTypeModel {
    Adjustment = 'ADJUSTMENT',
    BaseEarn = 'BASE_EARN',
    CertificateRedemption = 'CERTIFICATE_REDEMPTION',
    CancelledCertificate = 'CANCELLED_CERTIFICATE',
    Bonus = 'BONUS'
}

export function TActivityTypeModelFromJSON(json: any): TActivityTypeModel {
    return TActivityTypeModelFromJSONTyped(json, false);
}

export function TActivityTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TActivityTypeModel {
    return json as TActivityTypeModel;
}

export function TActivityTypeModelToJSON(value?: TActivityTypeModel | null): any {
    return value as any;
}

