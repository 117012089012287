/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyDiscountModel,
    TallyDiscountModelFromJSON,
    TallyDiscountModelFromJSONTyped,
    TallyDiscountModelToJSON,
} from './TallyDiscountModel';
import {
    TallyModifierGroupModel,
    TallyModifierGroupModelFromJSON,
    TallyModifierGroupModelFromJSONTyped,
    TallyModifierGroupModelToJSON,
} from './TallyModifierGroupModel';
import {
    TallyPriceTypeModel,
    TallyPriceTypeModelFromJSON,
    TallyPriceTypeModelFromJSONTyped,
    TallyPriceTypeModelToJSON,
} from './TallyPriceTypeModel';

/**
 * 
 * @export
 * @interface TallyProductModel
 */
export interface TallyProductModel {
    /**
     * 
     * @type {number}
     * @memberof TallyProductModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof TallyProductModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyProductModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof TallyProductModel
     */
    price: number;
    /**
     * 
     * @type {TallyPriceTypeModel}
     * @memberof TallyProductModel
     */
    priceType?: TallyPriceTypeModel;
    /**
     * 
     * @type {string}
     * @memberof TallyProductModel
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof TallyProductModel
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof TallyProductModel
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyProductModel
     */
    ssRecommendationId?: string;
    /**
     * 
     * @type {Array<TallyModifierGroupModel>}
     * @memberof TallyProductModel
     */
    modifierGroups?: Array<TallyModifierGroupModel>;
    /**
     * 
     * @type {Array<TallyProductModel>}
     * @memberof TallyProductModel
     */
    childItems?: Array<TallyProductModel>;
    /**
     * 
     * @type {Array<TallyDiscountModel>}
     * @memberof TallyProductModel
     */
    discounts?: Array<TallyDiscountModel>;
    /**
     * 
     * @type {string}
     * @memberof TallyProductModel
     */
    sourceProductId?: string;
}

export function TallyProductModelFromJSON(json: any): TallyProductModel {
    return TallyProductModelFromJSONTyped(json, false);
}

export function TallyProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'priceType': !exists(json, 'priceType') ? undefined : TallyPriceTypeModelFromJSON(json['priceType']),
        'productId': json['productId'],
        'quantity': json['quantity'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'ssRecommendationId': !exists(json, 'ssRecommendationId') ? undefined : json['ssRecommendationId'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(TallyModifierGroupModelFromJSON)),
        'childItems': !exists(json, 'childItems') ? undefined : ((json['childItems'] as Array<any>).map(TallyProductModelFromJSON)),
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(TallyDiscountModelFromJSON)),
        'sourceProductId': !exists(json, 'sourceProductId') ? undefined : json['sourceProductId'],
    };
}

export function TallyProductModelToJSON(value?: TallyProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'priceType': TallyPriceTypeModelToJSON(value.priceType),
        'productId': value.productId,
        'quantity': value.quantity,
        'note': value.note,
        'ssRecommendationId': value.ssRecommendationId,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(TallyModifierGroupModelToJSON)),
        'childItems': value.childItems === undefined ? undefined : ((value.childItems as Array<any>).map(TallyProductModelToJSON)),
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(TallyDiscountModelToJSON)),
        'sourceProductId': value.sourceProductId,
    };
}

