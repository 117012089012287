/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GoogleInitResponseTypeEnumModel {
    Dictionary = 'Dictionary',
    Raw = 'Raw'
}

export function GoogleInitResponseTypeEnumModelFromJSON(json: any): GoogleInitResponseTypeEnumModel {
    return GoogleInitResponseTypeEnumModelFromJSONTyped(json, false);
}

export function GoogleInitResponseTypeEnumModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleInitResponseTypeEnumModel {
    return json as GoogleInitResponseTypeEnumModel;
}

export function GoogleInitResponseTypeEnumModelToJSON(value?: GoogleInitResponseTypeEnumModel | null): any {
    return value as any;
}

