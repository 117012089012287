/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILegalWarningAdditionalPropertiesModel,
    ILegalWarningAdditionalPropertiesModelFromJSON,
    ILegalWarningAdditionalPropertiesModelFromJSONTyped,
    ILegalWarningAdditionalPropertiesModelToJSON,
} from './ILegalWarningAdditionalPropertiesModel';
import {
    LegalWarningTypeEnumModel,
    LegalWarningTypeEnumModelFromJSON,
    LegalWarningTypeEnumModelFromJSONTyped,
    LegalWarningTypeEnumModelToJSON,
} from './LegalWarningTypeEnumModel';

/**
 * 
 * @export
 * @interface ILegalWarningModel
 */
export interface ILegalWarningModel {
    /**
     * 
     * @type {LegalWarningTypeEnumModel}
     * @memberof ILegalWarningModel
     */
    legalWarningType: LegalWarningTypeEnumModel;
    /**
     * 
     * @type {boolean}
     * @memberof ILegalWarningModel
     */
    isActive: boolean;
    /**
     * 
     * @type {ILegalWarningAdditionalPropertiesModel}
     * @memberof ILegalWarningModel
     */
    additionalProperties: ILegalWarningAdditionalPropertiesModel;
}

export function ILegalWarningModelFromJSON(json: any): ILegalWarningModel {
    return ILegalWarningModelFromJSONTyped(json, false);
}

export function ILegalWarningModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILegalWarningModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'legalWarningType': LegalWarningTypeEnumModelFromJSON(json['legalWarningType']),
        'isActive': json['isActive'],
        'additionalProperties': ILegalWarningAdditionalPropertiesModelFromJSON(json['additionalProperties']),
    };
}

export function ILegalWarningModelToJSON(value?: ILegalWarningModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'legalWarningType': LegalWarningTypeEnumModelToJSON(value.legalWarningType),
        'isActive': value.isActive,
        'additionalProperties': ILegalWarningAdditionalPropertiesModelToJSON(value.additionalProperties),
    };
}

