/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ButtonTypeEnumModel,
    ButtonTypeEnumModelFromJSON,
    ButtonTypeEnumModelFromJSONTyped,
    ButtonTypeEnumModelToJSON,
} from './ButtonTypeEnumModel';
import {
    CardIconTypeEnumModel,
    CardIconTypeEnumModelFromJSON,
    CardIconTypeEnumModelFromJSONTyped,
    CardIconTypeEnumModelToJSON,
} from './CardIconTypeEnumModel';
import {
    PaymentTypeEnumModel,
    PaymentTypeEnumModelFromJSON,
    PaymentTypeEnumModelFromJSONTyped,
    PaymentTypeEnumModelToJSON,
} from './PaymentTypeEnumModel';
import {
    WorkflowEnumModel,
    WorkflowEnumModelFromJSON,
    WorkflowEnumModelFromJSONTyped,
    WorkflowEnumModelToJSON,
} from './WorkflowEnumModel';

/**
 * 
 * @export
 * @interface IWalletInitParamsModel
 */
export interface IWalletInitParamsModel {
    /**
     * 
     * @type {PaymentTypeEnumModel}
     * @memberof IWalletInitParamsModel
     */
    paymentType?: PaymentTypeEnumModel;
    /**
     * 
     * @type {string}
     * @memberof IWalletInitParamsModel
     */
    customerId?: string;
    /**
     * 
     * @type {WorkflowEnumModel}
     * @memberof IWalletInitParamsModel
     */
    workflow?: WorkflowEnumModel;
    /**
     * 
     * @type {string}
     * @memberof IWalletInitParamsModel
     */
    styles: string;
    /**
     * 
     * @type {ButtonTypeEnumModel}
     * @memberof IWalletInitParamsModel
     */
    buttonType?: ButtonTypeEnumModel;
    /**
     * 
     * @type {CardIconTypeEnumModel}
     * @memberof IWalletInitParamsModel
     */
    cardIconDisplayType?: CardIconTypeEnumModel;
}

export function IWalletInitParamsModelFromJSON(json: any): IWalletInitParamsModel {
    return IWalletInitParamsModelFromJSONTyped(json, false);
}

export function IWalletInitParamsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IWalletInitParamsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentType': !exists(json, 'paymentType') ? undefined : PaymentTypeEnumModelFromJSON(json['paymentType']),
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowEnumModelFromJSON(json['workflow']),
        'styles': json['styles'],
        'buttonType': !exists(json, 'buttonType') ? undefined : ButtonTypeEnumModelFromJSON(json['buttonType']),
        'cardIconDisplayType': !exists(json, 'cardIconDisplayType') ? undefined : CardIconTypeEnumModelFromJSON(json['cardIconDisplayType']),
    };
}

export function IWalletInitParamsModelToJSON(value?: IWalletInitParamsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentType': PaymentTypeEnumModelToJSON(value.paymentType),
        'customerId': value.customerId,
        'workflow': WorkflowEnumModelToJSON(value.workflow),
        'styles': value.styles,
        'buttonType': ButtonTypeEnumModelToJSON(value.buttonType),
        'cardIconDisplayType': CardIconTypeEnumModelToJSON(value.cardIconDisplayType),
    };
}

