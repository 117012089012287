/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ILocationAddressModel
 */
export interface ILocationAddressModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    line2: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    line3: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    stateProvinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressModel
     */
    referencePoint?: string;
}

export function ILocationAddressModelFromJSON(json: any): ILocationAddressModel {
    return ILocationAddressModelFromJSONTyped(json, false);
}

export function ILocationAddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationAddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line1': json['line1'],
        'line2': json['line2'],
        'line3': json['line3'],
        'postalCode': json['postalCode'],
        'stateProvinceCode': json['stateProvinceCode'],
        'countryCode': json['countryCode'],
        'city': json['city'],
        'referencePoint': !exists(json, 'referencePoint') ? undefined : json['referencePoint'],
    };
}

export function ILocationAddressModelToJSON(value?: ILocationAddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line1': value.line1,
        'line2': value.line2,
        'line3': value.line3,
        'postalCode': value.postalCode,
        'stateProvinceCode': value.stateProvinceCode,
        'countryCode': value.countryCode,
        'city': value.city,
        'referencePoint': value.referencePoint,
    };
}

