/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IContactUsRequestModel
 */
export interface IContactUsRequestModel {
    /**
     * first name
     * @type {string}
     * @memberof IContactUsRequestModel
     */
    firstName: string;
    /**
     * last name
     * @type {string}
     * @memberof IContactUsRequestModel
     */
    lastName: string;
    /**
     * Phone number
     * @type {string}
     * @memberof IContactUsRequestModel
     */
    phoneNumber: string;
    /**
     * email
     * @type {string}
     * @memberof IContactUsRequestModel
     */
    email: string;
    /**
     * reason
     * @type {string}
     * @memberof IContactUsRequestModel
     */
    reason: string;
    /**
     * message
     * @type {string}
     * @memberof IContactUsRequestModel
     */
    message: string;
}

export function IContactUsRequestModelFromJSON(json: any): IContactUsRequestModel {
    return IContactUsRequestModelFromJSONTyped(json, false);
}

export function IContactUsRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IContactUsRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
        'email': json['email'],
        'reason': json['reason'],
        'message': json['message'],
    };
}

export function IContactUsRequestModelToJSON(value?: IContactUsRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'reason': value.reason,
        'message': value.message,
    };
}

