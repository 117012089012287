/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardApplicabilityModel,
    IRewardApplicabilityModelFromJSON,
    IRewardApplicabilityModelFromJSONTyped,
    IRewardApplicabilityModelToJSON,
} from './IRewardApplicabilityModel';
import {
    TCertificateStatusModel,
    TCertificateStatusModelFromJSON,
    TCertificateStatusModelFromJSONTyped,
    TCertificateStatusModelToJSON,
} from './TCertificateStatusModel';

/**
 * 
 * @export
 * @interface ICertificateModel
 */
export interface ICertificateModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof ICertificateModel
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof ICertificateModel
     */
    priceInPoints?: number;
    /**
     * 
     * @type {Date}
     * @memberof ICertificateModel
     */
    expirationDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ICertificateModel
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ICertificateModel
     */
    imageUrl?: string;
    /**
     * 
     * @type {IRewardApplicabilityModel}
     * @memberof ICertificateModel
     */
    applicability?: IRewardApplicabilityModel;
    /**
     * 
     * @type {string}
     * @memberof ICertificateModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ICertificateModel
     */
    category?: string;
    /**
     * 
     * @type {TCertificateStatusModel}
     * @memberof ICertificateModel
     */
    status?: TCertificateStatusModel;
    /**
     * 
     * @type {string}
     * @memberof ICertificateModel
     */
    type?: string;
}

export function ICertificateModelFromJSON(json: any): ICertificateModel {
    return ICertificateModelFromJSONTyped(json, false);
}

export function ICertificateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICertificateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'priceInPoints': !exists(json, 'priceInPoints') ? undefined : json['priceInPoints'],
        'expirationDateTime': !exists(json, 'expirationDateTime') ? undefined : (new Date(json['expirationDateTime'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'applicability': !exists(json, 'applicability') ? undefined : IRewardApplicabilityModelFromJSON(json['applicability']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'status': !exists(json, 'status') ? undefined : TCertificateStatusModelFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ICertificateModelToJSON(value?: ICertificateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'priceInPoints': value.priceInPoints,
        'expirationDateTime': value.expirationDateTime === undefined ? undefined : (value.expirationDateTime.toISOString()),
        'title': value.title,
        'imageUrl': value.imageUrl,
        'applicability': IRewardApplicabilityModelToJSON(value.applicability),
        'code': value.code,
        'category': value.category,
        'status': TCertificateStatusModelToJSON(value.status),
        'type': value.type,
    };
}

