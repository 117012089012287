/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountDetailsDSResponseAppliedModel
 */
export interface DiscountDetailsDSResponseAppliedModel {
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsDSResponseAppliedModel
     */
    failureMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsDSResponseAppliedModel
     */
    failureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDetailsDSResponseAppliedModel
     */
    success?: boolean;
}

export function DiscountDetailsDSResponseAppliedModelFromJSON(json: any): DiscountDetailsDSResponseAppliedModel {
    return DiscountDetailsDSResponseAppliedModelFromJSONTyped(json, false);
}

export function DiscountDetailsDSResponseAppliedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDetailsDSResponseAppliedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureMessage': !exists(json, 'failureMessage') ? undefined : json['failureMessage'],
        'failureCode': !exists(json, 'failureCode') ? undefined : json['failureCode'],
        'success': !exists(json, 'success') ? undefined : json['success'],
    };
}

export function DiscountDetailsDSResponseAppliedModelToJSON(value?: DiscountDetailsDSResponseAppliedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureMessage': value.failureMessage,
        'failureCode': value.failureCode,
        'success': value.success,
    };
}

