/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDetailsTypeModel,
    DiscountDetailsTypeModelFromJSON,
    DiscountDetailsTypeModelFromJSONTyped,
    DiscountDetailsTypeModelToJSON,
} from './DiscountDetailsTypeModel';
import {
    ICheckRestrictionsModel,
    ICheckRestrictionsModelFromJSON,
    ICheckRestrictionsModelFromJSONTyped,
    ICheckRestrictionsModelToJSON,
} from './ICheckRestrictionsModel';
import {
    IHoursModel,
    IHoursModelFromJSON,
    IHoursModelFromJSONTyped,
    IHoursModelToJSON,
} from './IHoursModel';
import {
    IRewardApplicabilityModel,
    IRewardApplicabilityModelFromJSON,
    IRewardApplicabilityModelFromJSONTyped,
    IRewardApplicabilityModelToJSON,
} from './IRewardApplicabilityModel';
import {
    LocationRestrictionsModel,
    LocationRestrictionsModelFromJSON,
    LocationRestrictionsModelFromJSONTyped,
    LocationRestrictionsModelToJSON,
} from './LocationRestrictionsModel';
import {
    TOfferTypeModel,
    TOfferTypeModelFromJSON,
    TOfferTypeModelFromJSONTyped,
    TOfferTypeModelToJSON,
} from './TOfferTypeModel';

/**
 * 
 * @export
 * @interface AutoDiscountOfferModel
 */
export interface AutoDiscountOfferModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof AutoDiscountOfferModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDiscountOfferModel
     */
    userOfferId: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDiscountOfferModel
     */
    name: string;
    /**
     * 
     * @type {TOfferTypeModel}
     * @memberof AutoDiscountOfferModel
     */
    type: TOfferTypeModel;
    /**
     * 
     * @type {Date}
     * @memberof AutoDiscountOfferModel
     */
    startDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof AutoDiscountOfferModel
     */
    endDateTime: Date;
    /**
     * 
     * @type {IRewardApplicabilityModel}
     * @memberof AutoDiscountOfferModel
     */
    applicability?: IRewardApplicabilityModel;
    /**
     * 
     * @type {LocationRestrictionsModel}
     * @memberof AutoDiscountOfferModel
     */
    locationRestrictions?: LocationRestrictionsModel;
    /**
     * 
     * @type {ICheckRestrictionsModel}
     * @memberof AutoDiscountOfferModel
     */
    checkRestrictions?: ICheckRestrictionsModel;
    /**
     * 
     * @type {Array<IHoursModel>}
     * @memberof AutoDiscountOfferModel
     */
    redemptionTimeRanges?: Array<IHoursModel>;
    /**
     * 
     * @type {string}
     * @memberof AutoDiscountOfferModel
     */
    posDiscountId: string;
    /**
     * 
     * @type {DiscountDetailsTypeModel}
     * @memberof AutoDiscountOfferModel
     */
    discountDetailsType?: DiscountDetailsTypeModel;
}

export function AutoDiscountOfferModelFromJSON(json: any): AutoDiscountOfferModel {
    return AutoDiscountOfferModelFromJSONTyped(json, false);
}

export function AutoDiscountOfferModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDiscountOfferModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userOfferId': json['userOfferId'],
        'name': json['name'],
        'type': TOfferTypeModelFromJSON(json['type']),
        'startDateTime': (new Date(json['startDateTime'])),
        'endDateTime': (new Date(json['endDateTime'])),
        'applicability': !exists(json, 'applicability') ? undefined : IRewardApplicabilityModelFromJSON(json['applicability']),
        'locationRestrictions': !exists(json, 'locationRestrictions') ? undefined : LocationRestrictionsModelFromJSON(json['locationRestrictions']),
        'checkRestrictions': !exists(json, 'checkRestrictions') ? undefined : ICheckRestrictionsModelFromJSON(json['checkRestrictions']),
        'redemptionTimeRanges': !exists(json, 'redemptionTimeRanges') ? undefined : ((json['redemptionTimeRanges'] as Array<any>).map(IHoursModelFromJSON)),
        'posDiscountId': json['posDiscountId'],
        'discountDetailsType': !exists(json, 'discountDetailsType') ? undefined : DiscountDetailsTypeModelFromJSON(json['discountDetailsType']),
    };
}

export function AutoDiscountOfferModelToJSON(value?: AutoDiscountOfferModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userOfferId': value.userOfferId,
        'name': value.name,
        'type': TOfferTypeModelToJSON(value.type),
        'startDateTime': (value.startDateTime.toISOString()),
        'endDateTime': (value.endDateTime.toISOString()),
        'applicability': IRewardApplicabilityModelToJSON(value.applicability),
        'locationRestrictions': LocationRestrictionsModelToJSON(value.locationRestrictions),
        'checkRestrictions': ICheckRestrictionsModelToJSON(value.checkRestrictions),
        'redemptionTimeRanges': value.redemptionTimeRanges === undefined ? undefined : ((value.redemptionTimeRanges as Array<any>).map(IHoursModelToJSON)),
        'posDiscountId': value.posDiscountId,
        'discountDetailsType': DiscountDetailsTypeModelToJSON(value.discountDetailsType),
    };
}

