/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDetailsAppliedItemsModel,
    DiscountDetailsAppliedItemsModelFromJSON,
    DiscountDetailsAppliedItemsModelFromJSONTyped,
    DiscountDetailsAppliedItemsModelToJSON,
} from './DiscountDetailsAppliedItemsModel';
import {
    DiscountDetailsTypeModel,
    DiscountDetailsTypeModelFromJSON,
    DiscountDetailsTypeModelFromJSONTyped,
    DiscountDetailsTypeModelToJSON,
} from './DiscountDetailsTypeModel';

/**
 * 
 * @export
 * @interface DiscountDetailsModel
 */
export interface DiscountDetailsModel {
    /**
     * 
     * @type {DiscountDetailsTypeModel}
     * @memberof DiscountDetailsModel
     */
    type?: DiscountDetailsTypeModel;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsModel
     */
    externalProductId?: string;
    /**
     * 
     * @type {Array<DiscountDetailsAppliedItemsModel>}
     * @memberof DiscountDetailsModel
     */
    appliedItems?: Array<DiscountDetailsAppliedItemsModel>;
}

export function DiscountDetailsModelFromJSON(json: any): DiscountDetailsModel {
    return DiscountDetailsModelFromJSONTyped(json, false);
}

export function DiscountDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : DiscountDetailsTypeModelFromJSON(json['type']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'externalProductId': !exists(json, 'externalProductId') ? undefined : json['externalProductId'],
        'appliedItems': !exists(json, 'appliedItems') ? undefined : ((json['appliedItems'] as Array<any>).map(DiscountDetailsAppliedItemsModelFromJSON)),
    };
}

export function DiscountDetailsModelToJSON(value?: DiscountDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': DiscountDetailsTypeModelToJSON(value.type),
        'code': value.code,
        'name': value.name,
        'externalProductId': value.externalProductId,
        'appliedItems': value.appliedItems === undefined ? undefined : ((value.appliedItems as Array<any>).map(DiscountDetailsAppliedItemsModelToJSON)),
    };
}

