/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoDiscountOfferModel,
    AutoDiscountOfferModelFromJSON,
    AutoDiscountOfferModelFromJSONTyped,
    AutoDiscountOfferModelToJSON,
} from './AutoDiscountOfferModel';
import {
    ICertificateModel,
    ICertificateModelFromJSON,
    ICertificateModelFromJSONTyped,
    ICertificateModelToJSON,
} from './ICertificateModel';
import {
    TOffersByStatusModel,
    TOffersByStatusModelFromJSON,
    TOffersByStatusModelFromJSONTyped,
    TOffersByStatusModelToJSON,
} from './TOffersByStatusModel';

/**
 * 
 * @export
 * @interface IGetCustomerAccountRewardsV2ResponseModel
 */
export interface IGetCustomerAccountRewardsV2ResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    totalCount: number;
    /**
     * 
     * @type {Array<TOffersByStatusModel>}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    offers?: Array<TOffersByStatusModel>;
    /**
     * 
     * @type {Array<ICertificateModel>}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    certificates?: Array<ICertificateModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    locationsWithOffers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    locationsWithoutOffers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    allLocationsHaveOffers?: boolean;
    /**
     * 
     * @type {Array<AutoDiscountOfferModel>}
     * @memberof IGetCustomerAccountRewardsV2ResponseModel
     */
    autodiscounts: Array<AutoDiscountOfferModel>;
}

export function IGetCustomerAccountRewardsV2ResponseModelFromJSON(json: any): IGetCustomerAccountRewardsV2ResponseModel {
    return IGetCustomerAccountRewardsV2ResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerAccountRewardsV2ResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerAccountRewardsV2ResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'offers': !exists(json, 'offers') ? undefined : ((json['offers'] as Array<any>).map(TOffersByStatusModelFromJSON)),
        'certificates': !exists(json, 'certificates') ? undefined : ((json['certificates'] as Array<any>).map(ICertificateModelFromJSON)),
        'locationsWithOffers': !exists(json, 'locationsWithOffers') ? undefined : json['locationsWithOffers'],
        'locationsWithoutOffers': !exists(json, 'locationsWithoutOffers') ? undefined : json['locationsWithoutOffers'],
        'allLocationsHaveOffers': !exists(json, 'allLocationsHaveOffers') ? undefined : json['allLocationsHaveOffers'],
        'autodiscounts': ((json['autodiscounts'] as Array<any>).map(AutoDiscountOfferModelFromJSON)),
    };
}

export function IGetCustomerAccountRewardsV2ResponseModelToJSON(value?: IGetCustomerAccountRewardsV2ResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'offers': value.offers === undefined ? undefined : ((value.offers as Array<any>).map(TOffersByStatusModelToJSON)),
        'certificates': value.certificates === undefined ? undefined : ((value.certificates as Array<any>).map(ICertificateModelToJSON)),
        'locationsWithOffers': value.locationsWithOffers,
        'locationsWithoutOffers': value.locationsWithoutOffers,
        'allLocationsHaveOffers': value.allLocationsHaveOffers,
        'autodiscounts': ((value.autodiscounts as Array<any>).map(AutoDiscountOfferModelToJSON)),
    };
}

