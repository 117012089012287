/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TLocationStatusModel {
    Open = 'OPEN',
    Closed = 'CLOSED',
    TempClosed = 'TEMP_CLOSED'
}

export function TLocationStatusModelFromJSON(json: any): TLocationStatusModel {
    return TLocationStatusModelFromJSONTyped(json, false);
}

export function TLocationStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TLocationStatusModel {
    return json as TLocationStatusModel;
}

export function TLocationStatusModelToJSON(value?: TLocationStatusModel | null): any {
    return value as any;
}

