/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorCodePHONENUMBERALREADYUSEDModel {
    PhoneNumberAlreadyUsed = 'PHONE_NUMBER_ALREADY_USED'
}

export function TErrorCodePHONENUMBERALREADYUSEDModelFromJSON(json: any): TErrorCodePHONENUMBERALREADYUSEDModel {
    return TErrorCodePHONENUMBERALREADYUSEDModelFromJSONTyped(json, false);
}

export function TErrorCodePHONENUMBERALREADYUSEDModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorCodePHONENUMBERALREADYUSEDModel {
    return json as TErrorCodePHONENUMBERALREADYUSEDModel;
}

export function TErrorCodePHONENUMBERALREADYUSEDModelToJSON(value?: TErrorCodePHONENUMBERALREADYUSEDModel | null): any {
    return value as any;
}

