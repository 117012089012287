/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IOrderProductModifiersModel
 */
export interface IOrderProductModifiersModel {
    /**
     * 
     * @type {string}
     * @memberof IOrderProductModifiersModel
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModifiersModel
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModifiersModel
     */
    totalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModifiersModel
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof IOrderProductModifiersModel
     */
    actionCode?: string;
}

export function IOrderProductModifiersModelFromJSON(json: any): IOrderProductModifiersModel {
    return IOrderProductModifiersModelFromJSONTyped(json, false);
}

export function IOrderProductModifiersModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderProductModifiersModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'unitPrice': !exists(json, 'unitPrice') ? undefined : json['unitPrice'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'actionCode': !exists(json, 'actionCode') ? undefined : json['actionCode'],
    };
}

export function IOrderProductModifiersModelToJSON(value?: IOrderProductModifiersModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'unitPrice': value.unitPrice,
        'totalPrice': value.totalPrice,
        'quantity': value.quantity,
        'actionCode': value.actionCode,
    };
}

