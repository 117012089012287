/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BeerMenuResponseModel,
    BeerMenuResponseModelFromJSON,
    BeerMenuResponseModelToJSON,
    CustomerOrderStatusesModel,
    CustomerOrderStatusesModelFromJSON,
    CustomerOrderStatusesModelToJSON,
    GetLocationMenuTypeIdEnumModel,
    GetLocationMenuTypeIdEnumModelFromJSON,
    GetLocationMenuTypeIdEnumModelToJSON,
    GetNationalMenuTypeIdEnumModel,
    GetNationalMenuTypeIdEnumModelFromJSON,
    GetNationalMenuTypeIdEnumModelToJSON,
    HealthCheckResponseModel,
    HealthCheckResponseModelFromJSON,
    HealthCheckResponseModelToJSON,
    IAccountInfoBannerFieldsModel,
    IAccountInfoBannerFieldsModelFromJSON,
    IAccountInfoBannerFieldsModelToJSON,
    IActivateOfferModelModel,
    IActivateOfferModelModelFromJSON,
    IActivateOfferModelModelToJSON,
    IAddCardRequestModel,
    IAddCardRequestModelFromJSON,
    IAddCardRequestModelToJSON,
    IAddEditWalletAcountResponseModel,
    IAddEditWalletAcountResponseModelFromJSON,
    IAddEditWalletAcountResponseModelToJSON,
    IApplePaySessionResponseModel,
    IApplePaySessionResponseModelFromJSON,
    IApplePaySessionResponseModelToJSON,
    IApplePaymentInitBodyModel,
    IApplePaymentInitBodyModelFromJSON,
    IApplePaymentInitBodyModelToJSON,
    IApplyPromocodeRequestModel,
    IApplyPromocodeRequestModelFromJSON,
    IApplyPromocodeRequestModelToJSON,
    ICheckInWithLocationModelModel,
    ICheckInWithLocationModelModelFromJSON,
    ICheckInWithLocationModelModelToJSON,
    ICheckinModel,
    ICheckinModelFromJSON,
    ICheckinModelToJSON,
    IClaimMissingPointsFromClientModel,
    IClaimMissingPointsFromClientModelFromJSON,
    IClaimMissingPointsFromClientModelToJSON,
    IClaimMissingPointsResponseModel,
    IClaimMissingPointsResponseModelFromJSON,
    IClaimMissingPointsResponseModelToJSON,
    IConfigurationItemModel,
    IConfigurationItemModelFromJSON,
    IConfigurationItemModelToJSON,
    IContactUsRequestModel,
    IContactUsRequestModelFromJSON,
    IContactUsRequestModelToJSON,
    ICreateCustomerAccountRequestFromClientModel,
    ICreateCustomerAccountRequestFromClientModelFromJSON,
    ICreateCustomerAccountRequestFromClientModelToJSON,
    ICreditCardPaymentInitBodyModel,
    ICreditCardPaymentInitBodyModelFromJSON,
    ICreditCardPaymentInitBodyModelToJSON,
    ICustomerAvatarResponseModel,
    ICustomerAvatarResponseModelFromJSON,
    ICustomerAvatarResponseModelToJSON,
    ICustomerPersonalizationRequestModel,
    ICustomerPersonalizationRequestModelFromJSON,
    ICustomerPersonalizationRequestModelToJSON,
    ICustomerPersonalizationResponseModel,
    ICustomerPersonalizationResponseModelFromJSON,
    ICustomerPersonalizationResponseModelToJSON,
    ICustomerRewardsV2BodyModel,
    ICustomerRewardsV2BodyModelFromJSON,
    ICustomerRewardsV2BodyModelToJSON,
    IDeleteCardRequestModel,
    IDeleteCardRequestModelFromJSON,
    IDeleteCardRequestModelToJSON,
    IDeliveryLocationsSearchResponseModel,
    IDeliveryLocationsSearchResponseModelFromJSON,
    IDeliveryLocationsSearchResponseModelToJSON,
    IDineInOrderDetailsModel,
    IDineInOrderDetailsModelFromJSON,
    IDineInOrderDetailsModelToJSON,
    IDineInPayOrderRequestModel,
    IDineInPayOrderRequestModelFromJSON,
    IDineInPayOrderRequestModelToJSON,
    IDiscountRewardsModelModel,
    IDiscountRewardsModelModelFromJSON,
    IDiscountRewardsModelModelToJSON,
    IEditCardRequestModel,
    IEditCardRequestModelFromJSON,
    IEditCardRequestModelToJSON,
    IEmailVerificationAccountResponseModel,
    IEmailVerificationAccountResponseModelFromJSON,
    IEmailVerificationAccountResponseModelToJSON,
    IError400ExternalResponseModel,
    IError400ExternalResponseModelFromJSON,
    IError400ExternalResponseModelToJSON,
    IError400ResponseModel,
    IError400ResponseModelFromJSON,
    IError400ResponseModelToJSON,
    IError401ResponseModel,
    IError401ResponseModelFromJSON,
    IError401ResponseModelToJSON,
    IError404ResponseModel,
    IError404ResponseModelFromJSON,
    IError404ResponseModelToJSON,
    IError500ExternalInternalResponseModel,
    IError500ExternalInternalResponseModelFromJSON,
    IError500ExternalInternalResponseModelToJSON,
    IError500ExternalResponseModel,
    IError500ExternalResponseModelFromJSON,
    IError500ExternalResponseModelToJSON,
    IError500ExternalResponseQRModel,
    IError500ExternalResponseQRModelFromJSON,
    IError500ExternalResponseQRModelToJSON,
    IError500GenericResponseModel,
    IError500GenericResponseModelFromJSON,
    IError500GenericResponseModelToJSON,
    IError500InternalResponseModel,
    IError500InternalResponseModelFromJSON,
    IError500InternalResponseModelToJSON,
    IError503UnavailableResponseModel,
    IError503UnavailableResponseModelFromJSON,
    IError503UnavailableResponseModelToJSON,
    IGetCustomerAccountLoyaltyPointsResponseModel,
    IGetCustomerAccountLoyaltyPointsResponseModelFromJSON,
    IGetCustomerAccountLoyaltyPointsResponseModelToJSON,
    IGetCustomerAccountPaymentMethodsResponseModel,
    IGetCustomerAccountPaymentMethodsResponseModelFromJSON,
    IGetCustomerAccountPaymentMethodsResponseModelToJSON,
    IGetCustomerAccountRewardsCatalogResponseModel,
    IGetCustomerAccountRewardsCatalogResponseModelFromJSON,
    IGetCustomerAccountRewardsCatalogResponseModelToJSON,
    IGetCustomerAccountRewardsResponseModel,
    IGetCustomerAccountRewardsResponseModelFromJSON,
    IGetCustomerAccountRewardsResponseModelToJSON,
    IGetCustomerAccountRewardsV2ResponseModel,
    IGetCustomerAccountRewardsV2ResponseModelFromJSON,
    IGetCustomerAccountRewardsV2ResponseModelToJSON,
    IGetCustomerOrderHistoryResponseModel,
    IGetCustomerOrderHistoryResponseModelFromJSON,
    IGetCustomerOrderHistoryResponseModelToJSON,
    IGetCustomerRewardsActivityHistoryResponseModel,
    IGetCustomerRewardsActivityHistoryResponseModelFromJSON,
    IGetCustomerRewardsActivityHistoryResponseModelToJSON,
    IGetProfileIdRequestModel,
    IGetProfileIdRequestModelFromJSON,
    IGetProfileIdRequestModelToJSON,
    IGetProfileIdResponseModel,
    IGetProfileIdResponseModelFromJSON,
    IGetProfileIdResponseModelToJSON,
    IGiftCardBalanceRequestModel,
    IGiftCardBalanceRequestModelFromJSON,
    IGiftCardBalanceRequestModelToJSON,
    IGiftCardBalanceResponseModel,
    IGiftCardBalanceResponseModelFromJSON,
    IGiftCardBalanceResponseModelToJSON,
    IGiftcardPaymentInitBodyModel,
    IGiftcardPaymentInitBodyModelFromJSON,
    IGiftcardPaymentInitBodyModelToJSON,
    IGooglePaymentInitBodyModel,
    IGooglePaymentInitBodyModelFromJSON,
    IGooglePaymentInitBodyModelToJSON,
    ILocationSlotModel,
    ILocationSlotModelFromJSON,
    ILocationSlotModelToJSON,
    ILocationUrlsResponseModel,
    ILocationUrlsResponseModelFromJSON,
    ILocationUrlsResponseModelToJSON,
    ILocationWithDetailsModel,
    ILocationWithDetailsModelFromJSON,
    ILocationWithDetailsModelToJSON,
    ILocationsPageModel,
    ILocationsPageModelFromJSON,
    ILocationsPageModelToJSON,
    IMenuModel,
    IMenuModelFromJSON,
    IMenuModelToJSON,
    INotificationBodyRequestModel,
    INotificationBodyRequestModelFromJSON,
    INotificationBodyRequestModelToJSON,
    IOfferModel,
    IOfferModelFromJSON,
    IOfferModelToJSON,
    IOneClickRegisterOfferResponseModel,
    IOneClickRegisterOfferResponseModelFromJSON,
    IOneClickRegisterOfferResponseModelToJSON,
    IOrderModel,
    IOrderModelFromJSON,
    IOrderModelToJSON,
    IOrderVerifyRequestModel,
    IOrderVerifyRequestModelFromJSON,
    IOrderVerifyRequestModelToJSON,
    IOrderVerifyResponseModel,
    IOrderVerifyResponseModelFromJSON,
    IOrderVerifyResponseModelToJSON,
    IPaymentInitResponseModel,
    IPaymentInitResponseModelFromJSON,
    IPaymentInitResponseModelToJSON,
    IPhoneVerificationResponseModel,
    IPhoneVerificationResponseModelFromJSON,
    IPhoneVerificationResponseModelToJSON,
    IProfanityVerificationDataFromClientModel,
    IProfanityVerificationDataFromClientModelFromJSON,
    IProfanityVerificationDataFromClientModelToJSON,
    IPurchaseCustomerAccountRewardResponseModel,
    IPurchaseCustomerAccountRewardResponseModelFromJSON,
    IPurchaseCustomerAccountRewardResponseModelToJSON,
    IRewardsDiscountDSResponseModel,
    IRewardsDiscountDSResponseModelFromJSON,
    IRewardsDiscountDSResponseModelToJSON,
    IRewardsDiscountRequestModel,
    IRewardsDiscountRequestModelFromJSON,
    IRewardsDiscountRequestModelToJSON,
    ISignUpModelModel,
    ISignUpModelModelFromJSON,
    ISignUpModelModelToJSON,
    ISlotServiceTypeModel,
    ISlotServiceTypeModelFromJSON,
    ISlotServiceTypeModelToJSON,
    ISurveyModel,
    ISurveyModelFromJSON,
    ISurveyModelToJSON,
    ISurveyRespondModel,
    ISurveyRespondModelFromJSON,
    ISurveyRespondModelToJSON,
    ITallyError500ExternalResponseModel,
    ITallyError500ExternalResponseModelFromJSON,
    ITallyError500ExternalResponseModelToJSON,
    ITransformedCustomerIdResponseForClientModel,
    ITransformedCustomerIdResponseForClientModelFromJSON,
    ITransformedCustomerIdResponseForClientModelToJSON,
    ITransformedDiscountRewardsResponseForClientModel,
    ITransformedDiscountRewardsResponseForClientModelFromJSON,
    ITransformedDiscountRewardsResponseForClientModelToJSON,
    ITransformedGetCustomerAccountResponseForClientModel,
    ITransformedGetCustomerAccountResponseForClientModelFromJSON,
    ITransformedGetCustomerAccountResponseForClientModelToJSON,
    IUpdateCustomerAccountRequestFromClientModel,
    IUpdateCustomerAccountRequestFromClientModelFromJSON,
    IUpdateCustomerAccountRequestFromClientModelToJSON,
    IValidatePhoneNumberRequestModel,
    IValidatePhoneNumberRequestModelFromJSON,
    IValidatePhoneNumberRequestModelToJSON,
    IVisitRegisterRequestModel,
    IVisitRegisterRequestModelFromJSON,
    IVisitRegisterRequestModelToJSON,
    IVisitRegisterResponseModel,
    IVisitRegisterResponseModelFromJSON,
    IVisitRegisterResponseModelToJSON,
    IWalletInitParamsModel,
    IWalletInitParamsModelFromJSON,
    IWalletInitParamsModelToJSON,
    LocationPickupAndDeliveryResponseModel,
    LocationPickupAndDeliveryResponseModelFromJSON,
    LocationPickupAndDeliveryResponseModelToJSON,
    MobivityRequestModel,
    MobivityRequestModelFromJSON,
    MobivityRequestModelToJSON,
    NotificationResponseModel,
    NotificationResponseModelFromJSON,
    NotificationResponseModelToJSON,
    OrderFulfillmentTypesModel,
    OrderFulfillmentTypesModelFromJSON,
    OrderFulfillmentTypesModelToJSON,
    OrderRequestModel,
    OrderRequestModelFromJSON,
    OrderRequestModelToJSON,
    OrderResponseModel,
    OrderResponseModelFromJSON,
    OrderResponseModelToJSON,
    OrderV2RequestModel,
    OrderV2RequestModelFromJSON,
    OrderV2RequestModelToJSON,
    ProductsRecommendationRequestModel,
    ProductsRecommendationRequestModelFromJSON,
    ProductsRecommendationRequestModelToJSON,
    ProductsRecommendationResponseModel,
    ProductsRecommendationResponseModelFromJSON,
    ProductsRecommendationResponseModelToJSON,
    ProfileOfferRegisterRequestModel,
    ProfileOfferRegisterRequestModelFromJSON,
    ProfileOfferRegisterRequestModelToJSON,
    SellingChannelNamesModel,
    SellingChannelNamesModelFromJSON,
    SellingChannelNamesModelToJSON,
    TAllLocationsResponseDataModel,
    TAllLocationsResponseDataModelFromJSON,
    TAllLocationsResponseDataModelToJSON,
    TApplePaymentSellingChannelsModel,
    TApplePaymentSellingChannelsModelFromJSON,
    TApplePaymentSellingChannelsModelToJSON,
    TOfferStatusModel,
    TOfferStatusModelFromJSON,
    TOfferStatusModelToJSON,
    TallyRequestModel,
    TallyRequestModelFromJSON,
    TallyRequestModelToJSON,
    TallyResponseModel,
    TallyResponseModelFromJSON,
    TallyResponseModelToJSON,
    VersionResponseModel,
    VersionResponseModelFromJSON,
    VersionResponseModelToJSON,
    WalletAccountTypesModel,
    WalletAccountTypesModelFromJSON,
    WalletAccountTypesModelToJSON,
} from '../models';

export interface ActivateCustomerOfferRequest {
    rewardId: string;
    iActivateOfferModelModel: IActivateOfferModelModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface AddCardRequest {
    iAddCardRequestModel: IAddCardRequestModel;
}

export interface AddMultipleGiftCardsRequest {
    iAddCardRequestModel: Array<IAddCardRequestModel>;
}

export interface ApplyPromocodeRequest {
    iApplyPromocodeRequestModel: IApplyPromocodeRequestModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface CancelCertificateRequest {
    rewardId: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface ClaimMissingPointsRequest {
    iClaimMissingPointsFromClientModel: IClaimMissingPointsFromClientModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface CreateCustomerAccountRequest {
    iCreateCustomerAccountRequestFromClientModel: ICreateCustomerAccountRequestFromClientModel;
    authorization?: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface CustomerCheckinRequest {
    iCheckInWithLocationModelModel: ICheckInWithLocationModelModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface CustomerNameProfanityVerificationRequest {
    iProfanityVerificationDataFromClientModel: IProfanityVerificationDataFromClientModel;
}

export interface CustomerSignUpRequest {
    iSignUpModelModel: ISignUpModelModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface CustomerSignUpwithTextMessageRequest {
    mobivityRequestModel: MobivityRequestModel;
}

export interface DeleteCardRequest {
    iDeleteCardRequestModel: IDeleteCardRequestModel;
}

export interface DeleteCustomerAccountRequest {
    sellingChannel?: SellingChannelNamesModel;
}

export interface DineInContactlessPaymentRequest {
    iDineInPayOrderRequestModel: IDineInPayOrderRequestModel;
}

export interface DineInOrderRequest {
    orderId: string;
    locationId: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface DiscountCustomerAccountRewardsRequest {
    iDiscountRewardsModelModel: IDiscountRewardsModelModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface EditCardRequest {
    token: string;
    iEditCardRequestModel: IEditCardRequestModel;
}

export interface FetchRecommendedItemsRequest {
    productsRecommendationRequestModel: ProductsRecommendationRequestModel;
    sellingChannel?: SellingChannelNamesModel;
    menuType?: string;
}

export interface GetAccountBannerRequest {
    locale?: string;
}

export interface GetAccountRewardsDiscountRequest {
    iRewardsDiscountRequestModel: IRewardsDiscountRequestModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetBalanceRequest {
    iGiftCardBalanceRequestModel: IGiftCardBalanceRequestModel;
}

export interface GetBeerMenuRequest {
    locationId: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetCustomerAccountRequest {
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetCustomerAccountLoyaltyPointsRequest {
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetCustomerAccountRewardsRequest {
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetCustomerAccountRewardsCatalogRequest {
    sellingChannel?: SellingChannelNamesModel;
    pointsBalance?: number;
}

export interface GetCustomerRewardsRequest {
    iCustomerRewardsV2BodyModel: ICustomerRewardsV2BodyModel;
    sellingChannel?: SellingChannelNamesModel;
    status?: TOfferStatusModel;
    locale?: string;
}

export interface GetCustomerRewardsActivityHistoryRequest {
    sellingChannel?: SellingChannelNamesModel;
    dateFrom?: string;
}

export interface GetLocalMenuRequest {
    menuType: GetLocationMenuTypeIdEnumModel;
    locationId: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetLocationAvailableTimesRequest {
    locationId: string;
}

export interface GetLocationByIdRequest {
    locationId: string;
    includeRestaurantSchema?: boolean;
}

export interface GetNationalMenuRequest {
    menuType: GetNationalMenuTypeIdEnumModel;
    sellingChannel?: SellingChannelNamesModel;
    locale?: string;
}

export interface GetNearestSlotRequest {
    locationId: string;
    serviceType?: ISlotServiceTypeModel;
}

export interface GetOrdersHistoryRequest {
    sellingChannel?: SellingChannelNamesModel;
    type?: OrderFulfillmentTypesModel;
    status?: CustomerOrderStatusesModel;
    limit?: number;
    page?: number;
}

export interface GetPaymentMethodRequest {
    type?: WalletAccountTypesModel;
}

export interface GetPersonalizationActionsRequest {
    iCustomerPersonalizationRequestModel: ICustomerPersonalizationRequestModel;
    sellingChannel?: SellingChannelNamesModel;
    locale?: string;
}

export interface GetProfileIdRequest {
    iGetProfileIdRequestModel: IGetProfileIdRequestModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetQRCodeForOfferRequest {
    userOfferId: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface GetSlotsRequest {
    locationId: string;
    menuId: string;
    numberOfDays: string;
}

export interface GetSurveyRequest {
    sellingChannel?: SellingChannelNamesModel;
}

export interface GlobalConfigurationRequest {
    locale?: string;
}

export interface InitApplePaymentRequest {
    iApplePaymentInitBodyModel: IApplePaymentInitBodyModel;
    sellingChannel?: TApplePaymentSellingChannelsModel;
}

export interface InitCreditCardPaymentRequest {
    iCreditCardPaymentInitBodyModel: ICreditCardPaymentInitBodyModel;
}

export interface InitGiftCardPaymentRequest {
    iGiftcardPaymentInitBodyModel: IGiftcardPaymentInitBodyModel;
}

export interface InitGooglePaymentRequest {
    iGooglePaymentInitBodyModel: IGooglePaymentInitBodyModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface InitWalletRequest {
    iWalletInitParamsModel: IWalletInitParamsModel;
}

export interface InternalRequest {
    idempotentId: string;
}

export interface OneClickRegisterOfferRequest {
    profileOfferRegisterRequestModel: ProfileOfferRegisterRequestModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface OrderRequest {
    orderRequestModel: OrderRequestModel;
    sellingChannel?: SellingChannelNamesModel;
    fulfillmentType?: string;
}

export interface OrderV2Request {
    orderV2RequestModel: OrderV2RequestModel;
    fulfillmentType?: string;
}

export interface PostContactUsRequest {
    iContactUsRequestModel: IContactUsRequestModel;
}

export interface PurchaseCustomerOfferRequest {
    rewardId: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface RegisterVisitRequest {
    iVisitRegisterRequestModel: IVisitRegisterRequestModel;
}

export interface SearchDeliveryLocationRequest {
    googlePlaceId?: string;
    latitude?: number;
    longitude?: number;
}

export interface SearchLocationRequest {
    latitude: number;
    longitude: number;
    radius?: number;
    limit?: number;
    page?: number;
    locale?: string;
}

export interface SearchLocationUrlsRequest {
    locale?: string;
}

export interface SearchLocationsRequest {
    countryCode?: string;
    stateOrProvinceCode?: string;
}

export interface SendSmsRequest {
    iNotificationBodyRequestModel: INotificationBodyRequestModel;
}

export interface TallyRequest {
    tallyRequestModel: TallyRequestModel;
    sellingChannel?: SellingChannelNamesModel;
    fulfillmentType?: string;
}

export interface UpdateCustomerAccountRequest {
    iUpdateCustomerAccountRequestFromClientModel: IUpdateCustomerAccountRequestFromClientModel;
    sellingChannel?: SellingChannelNamesModel;
}

export interface UpdateSurveyRequest {
    surveyId: string;
    iSurveyRespondModel: Array<ISurveyRespondModel>;
    sellingChannel?: SellingChannelNamesModel;
}

export interface UploadCustomerAvatarRequest {
    file: Blob;
    sellingChannel?: SellingChannelNamesModel;
}

export interface VerifyRequest {
    iOrderVerifyRequestModel: IOrderVerifyRequestModel;
    cartId?: string;
}

export interface VerifyEmailRequest {
    cid: string;
    sellingChannel?: SellingChannelNamesModel;
}

export interface VerifyPhoneNumberRequest {
    iValidatePhoneNumberRequestModel: IValidatePhoneNumberRequestModel;
    sellingChannel?: SellingChannelNamesModel;
}

/**
 * 
 */
export class WebExperienceApi extends runtime.BaseAPI {

    /**
     * Activate offer
     */
    async activateCustomerOfferRaw(requestParameters: ActivateCustomerOfferRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rewardId === null || requestParameters.rewardId === undefined) {
            throw new runtime.RequiredError('rewardId','Required parameter requestParameters.rewardId was null or undefined when calling activateCustomerOffer.');
        }

        if (requestParameters.iActivateOfferModelModel === null || requestParameters.iActivateOfferModelModel === undefined) {
            throw new runtime.RequiredError('iActivateOfferModelModel','Required parameter requestParameters.iActivateOfferModelModel was null or undefined when calling activateCustomerOffer.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/{rewardId}`.replace(`{${"rewardId"}}`, encodeURIComponent(String(requestParameters.rewardId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IActivateOfferModelModelToJSON(requestParameters.iActivateOfferModelModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate offer
     */
    async activateCustomerOffer(requestParameters: ActivateCustomerOfferRequest, initOverrides?: RequestInit): Promise<void> {
        await this.activateCustomerOfferRaw(requestParameters, initOverrides);
    }

    /**
     * Add credit | gift card
     */
    async addCardRaw(requestParameters: AddCardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IAddEditWalletAcountResponseModel>> {
        if (requestParameters.iAddCardRequestModel === null || requestParameters.iAddCardRequestModel === undefined) {
            throw new runtime.RequiredError('iAddCardRequestModel','Required parameter requestParameters.iAddCardRequestModel was null or undefined when calling addCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IAddCardRequestModelToJSON(requestParameters.iAddCardRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IAddEditWalletAcountResponseModelFromJSON(jsonValue));
    }

    /**
     * Add credit | gift card
     */
    async addCard(requestParameters: AddCardRequest, initOverrides?: RequestInit): Promise<IAddEditWalletAcountResponseModel> {
        const response = await this.addCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add multiple gift cards Used to save gift cards on checkout after successfull order submit Doesn\'t return errors for not added cards
     */
    async addMultipleGiftCardsRaw(requestParameters: AddMultipleGiftCardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IAddEditWalletAcountResponseModel>>> {
        if (requestParameters.iAddCardRequestModel === null || requestParameters.iAddCardRequestModel === undefined) {
            throw new runtime.RequiredError('iAddCardRequestModel','Required parameter requestParameters.iAddCardRequestModel was null or undefined when calling addMultipleGiftCards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/payment-method/gift-cards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.iAddCardRequestModel.map(IAddCardRequestModelToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IAddEditWalletAcountResponseModelFromJSON));
    }

    /**
     * Add multiple gift cards Used to save gift cards on checkout after successfull order submit Doesn\'t return errors for not added cards
     */
    async addMultipleGiftCards(requestParameters: AddMultipleGiftCardsRequest, initOverrides?: RequestInit): Promise<Array<IAddEditWalletAcountResponseModel>> {
        const response = await this.addMultipleGiftCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Apply promocode
     */
    async applyPromocodeRaw(requestParameters: ApplyPromocodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IOfferModel>> {
        if (requestParameters.iApplyPromocodeRequestModel === null || requestParameters.iApplyPromocodeRequestModel === undefined) {
            throw new runtime.RequiredError('iApplyPromocodeRequestModel','Required parameter requestParameters.iApplyPromocodeRequestModel was null or undefined when calling applyPromocode.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/customer/account/promocode/apply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IApplyPromocodeRequestModelToJSON(requestParameters.iApplyPromocodeRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IOfferModelFromJSON(jsonValue));
    }

    /**
     * Apply promocode
     */
    async applyPromocode(requestParameters: ApplyPromocodeRequest, initOverrides?: RequestInit): Promise<IOfferModel> {
        const response = await this.applyPromocodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel / Return Certificate with provided Certificate ID to regain points *
     */
    async cancelCertificateRaw(requestParameters: CancelCertificateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.rewardId === null || requestParameters.rewardId === undefined) {
            throw new runtime.RequiredError('rewardId','Required parameter requestParameters.rewardId was null or undefined when calling cancelCertificate.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/{rewardId}`.replace(`{${"rewardId"}}`, encodeURIComponent(String(requestParameters.rewardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Cancel / Return Certificate with provided Certificate ID to regain points *
     */
    async cancelCertificate(requestParameters: CancelCertificateRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.cancelCertificateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Earn missing points
     */
    async claimMissingPointsRaw(requestParameters: ClaimMissingPointsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IClaimMissingPointsResponseModel>> {
        if (requestParameters.iClaimMissingPointsFromClientModel === null || requestParameters.iClaimMissingPointsFromClientModel === undefined) {
            throw new runtime.RequiredError('iClaimMissingPointsFromClientModel','Required parameter requestParameters.iClaimMissingPointsFromClientModel was null or undefined when calling claimMissingPoints.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/activity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IClaimMissingPointsFromClientModelToJSON(requestParameters.iClaimMissingPointsFromClientModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IClaimMissingPointsResponseModelFromJSON(jsonValue));
    }

    /**
     * Earn missing points
     */
    async claimMissingPoints(requestParameters: ClaimMissingPointsRequest, initOverrides?: RequestInit): Promise<IClaimMissingPointsResponseModel> {
        const response = await this.claimMissingPointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create customer account given customer account details
     */
    async createCustomerAccountRaw(requestParameters: CreateCustomerAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ITransformedCustomerIdResponseForClientModel>> {
        if (requestParameters.iCreateCustomerAccountRequestFromClientModel === null || requestParameters.iCreateCustomerAccountRequestFromClientModel === undefined) {
            throw new runtime.RequiredError('iCreateCustomerAccountRequestFromClientModel','Required parameter requestParameters.iCreateCustomerAccountRequestFromClientModel was null or undefined when calling createCustomerAccount.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/customer/account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateCustomerAccountRequestFromClientModelToJSON(requestParameters.iCreateCustomerAccountRequestFromClientModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ITransformedCustomerIdResponseForClientModelFromJSON(jsonValue));
    }

    /**
     * Create customer account given customer account details
     */
    async createCustomerAccount(requestParameters: CreateCustomerAccountRequest, initOverrides?: RequestInit): Promise<ITransformedCustomerIdResponseForClientModel> {
        const response = await this.createCustomerAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check-in the customer
     */
    async customerCheckinRaw(requestParameters: CustomerCheckinRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ICheckinModel>> {
        if (requestParameters.iCheckInWithLocationModelModel === null || requestParameters.iCheckInWithLocationModelModel === undefined) {
            throw new runtime.RequiredError('iCheckInWithLocationModelModel','Required parameter requestParameters.iCheckInWithLocationModelModel was null or undefined when calling customerCheckin.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/checkin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICheckInWithLocationModelModelToJSON(requestParameters.iCheckInWithLocationModelModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ICheckinModelFromJSON(jsonValue));
    }

    /**
     * Check-in the customer
     */
    async customerCheckin(requestParameters: CustomerCheckinRequest, initOverrides?: RequestInit): Promise<ICheckinModel> {
        const response = await this.customerCheckinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Profanity verification for customer name
     */
    async customerNameProfanityVerificationRaw(requestParameters: CustomerNameProfanityVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.iProfanityVerificationDataFromClientModel === null || requestParameters.iProfanityVerificationDataFromClientModel === undefined) {
            throw new runtime.RequiredError('iProfanityVerificationDataFromClientModel','Required parameter requestParameters.iProfanityVerificationDataFromClientModel was null or undefined when calling customerNameProfanityVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/customer/account/profanity-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IProfanityVerificationDataFromClientModelToJSON(requestParameters.iProfanityVerificationDataFromClientModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Profanity verification for customer name
     */
    async customerNameProfanityVerification(requestParameters: CustomerNameProfanityVerificationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerNameProfanityVerificationRaw(requestParameters, initOverrides);
    }

    /**
     * Customer Sign Up
     */
    async customerSignUpRaw(requestParameters: CustomerSignUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.iSignUpModelModel === null || requestParameters.iSignUpModelModel === undefined) {
            throw new runtime.RequiredError('iSignUpModelModel','Required parameter requestParameters.iSignUpModelModel was null or undefined when calling customerSignUp.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/customer/marketing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ISignUpModelModelToJSON(requestParameters.iSignUpModelModel),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Customer Sign Up
     */
    async customerSignUp(requestParameters: CustomerSignUpRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.customerSignUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer Sign Up with Text Message
     */
    async customerSignUpwithTextMessageRaw(requestParameters: CustomerSignUpwithTextMessageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mobivityRequestModel === null || requestParameters.mobivityRequestModel === undefined) {
            throw new runtime.RequiredError('mobivityRequestModel','Required parameter requestParameters.mobivityRequestModel was null or undefined when calling customerSignUpwithTextMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/customer/text-message-signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobivityRequestModelToJSON(requestParameters.mobivityRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Customer Sign Up with Text Message
     */
    async customerSignUpwithTextMessage(requestParameters: CustomerSignUpwithTextMessageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerSignUpwithTextMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Delete credit | gift card
     */
    async deleteCardRaw(requestParameters: DeleteCardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.iDeleteCardRequestModel === null || requestParameters.iDeleteCardRequestModel === undefined) {
            throw new runtime.RequiredError('iDeleteCardRequestModel','Required parameter requestParameters.iDeleteCardRequestModel was null or undefined when calling deleteCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/payment-method`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: IDeleteCardRequestModelToJSON(requestParameters.iDeleteCardRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete credit | gift card
     */
    async deleteCard(requestParameters: DeleteCardRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.deleteCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete customer account given customer id retrieved from JWT
     */
    async deleteCustomerAccountRaw(requestParameters: DeleteCustomerAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete customer account given customer id retrieved from JWT
     */
    async deleteCustomerAccount(requestParameters: DeleteCustomerAccountRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCustomerAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Pay dine-in order
     */
    async dineInContactlessPaymentRaw(requestParameters: DineInContactlessPaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.iDineInPayOrderRequestModel === null || requestParameters.iDineInPayOrderRequestModel === undefined) {
            throw new runtime.RequiredError('iDineInPayOrderRequestModel','Required parameter requestParameters.iDineInPayOrderRequestModel was null or undefined when calling dineInContactlessPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/order/dine-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IDineInPayOrderRequestModelToJSON(requestParameters.iDineInPayOrderRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pay dine-in order
     */
    async dineInContactlessPayment(requestParameters: DineInContactlessPaymentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.dineInContactlessPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Retrive dine-in order
     */
    async dineInOrderRaw(requestParameters: DineInOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IDineInOrderDetailsModel>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling dineInOrder.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling dineInOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/order/dine-in`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IDineInOrderDetailsModelFromJSON(jsonValue));
    }

    /**
     * Retrive dine-in order
     */
    async dineInOrder(requestParameters: DineInOrderRequest, initOverrides?: RequestInit): Promise<IDineInOrderDetailsModel> {
        const response = await this.dineInOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Discount new reward based on discount or promo code
     */
    async discountCustomerAccountRewardsRaw(requestParameters: DiscountCustomerAccountRewardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ITransformedDiscountRewardsResponseForClientModel>> {
        if (requestParameters.iDiscountRewardsModelModel === null || requestParameters.iDiscountRewardsModelModel === undefined) {
            throw new runtime.RequiredError('iDiscountRewardsModelModel','Required parameter requestParameters.iDiscountRewardsModelModel was null or undefined when calling discountCustomerAccountRewards.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/rewards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IDiscountRewardsModelModelToJSON(requestParameters.iDiscountRewardsModelModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ITransformedDiscountRewardsResponseForClientModelFromJSON(jsonValue));
    }

    /**
     * Discount new reward based on discount or promo code
     */
    async discountCustomerAccountRewards(requestParameters: DiscountCustomerAccountRewardsRequest, initOverrides?: RequestInit): Promise<ITransformedDiscountRewardsResponseForClientModel> {
        const response = await this.discountCustomerAccountRewardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit credit | gift card
     */
    async editCardRaw(requestParameters: EditCardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IAddEditWalletAcountResponseModel>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling editCard.');
        }

        if (requestParameters.iEditCardRequestModel === null || requestParameters.iEditCardRequestModel === undefined) {
            throw new runtime.RequiredError('iEditCardRequestModel','Required parameter requestParameters.iEditCardRequestModel was null or undefined when calling editCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/payment-method/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IEditCardRequestModelToJSON(requestParameters.iEditCardRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IAddEditWalletAcountResponseModelFromJSON(jsonValue));
    }

    /**
     * Edit credit | gift card
     */
    async editCard(requestParameters: EditCardRequest, initOverrides?: RequestInit): Promise<IAddEditWalletAcountResponseModel> {
        const response = await this.editCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Suggested Sell Fetch Recommended Items
     */
    async fetchRecommendedItemsRaw(requestParameters: FetchRecommendedItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductsRecommendationResponseModel>> {
        if (requestParameters.productsRecommendationRequestModel === null || requestParameters.productsRecommendationRequestModel === undefined) {
            throw new runtime.RequiredError('productsRecommendationRequestModel','Required parameter requestParameters.productsRecommendationRequestModel was null or undefined when calling fetchRecommendedItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.menuType !== undefined) {
            queryParameters['menuType'] = requestParameters.menuType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/recommendation/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductsRecommendationRequestModelToJSON(requestParameters.productsRecommendationRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductsRecommendationResponseModelFromJSON(jsonValue));
    }

    /**
     * Suggested Sell Fetch Recommended Items
     */
    async fetchRecommendedItems(requestParameters: FetchRecommendedItemsRequest, initOverrides?: RequestInit): Promise<ProductsRecommendationResponseModel> {
        const response = await this.fetchRecommendedItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve AccountInfoBanner from contentful
     */
    async getAccountBannerRaw(requestParameters: GetAccountBannerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IAccountInfoBannerFieldsModel>> {
        const queryParameters: any = {};

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/account-banner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IAccountInfoBannerFieldsModelFromJSON(jsonValue));
    }

    /**
     * Retrieve AccountInfoBanner from contentful
     */
    async getAccountBanner(requestParameters: GetAccountBannerRequest = {}, initOverrides?: RequestInit): Promise<IAccountInfoBannerFieldsModel> {
        const response = await this.getAccountBannerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get discount on order
     */
    async getAccountRewardsDiscountRaw(requestParameters: GetAccountRewardsDiscountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IRewardsDiscountDSResponseModel>> {
        if (requestParameters.iRewardsDiscountRequestModel === null || requestParameters.iRewardsDiscountRequestModel === undefined) {
            throw new runtime.RequiredError('iRewardsDiscountRequestModel','Required parameter requestParameters.iRewardsDiscountRequestModel was null or undefined when calling getAccountRewardsDiscount.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/discount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IRewardsDiscountRequestModelToJSON(requestParameters.iRewardsDiscountRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IRewardsDiscountDSResponseModelFromJSON(jsonValue));
    }

    /**
     * Get discount on order
     */
    async getAccountRewardsDiscount(requestParameters: GetAccountRewardsDiscountRequest, initOverrides?: RequestInit): Promise<IRewardsDiscountDSResponseModel> {
        const response = await this.getAccountRewardsDiscountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve user balance
     */
    async getBalanceRaw(requestParameters: GetBalanceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGiftCardBalanceResponseModel>> {
        if (requestParameters.iGiftCardBalanceRequestModel === null || requestParameters.iGiftCardBalanceRequestModel === undefined) {
            throw new runtime.RequiredError('iGiftCardBalanceRequestModel','Required parameter requestParameters.iGiftCardBalanceRequestModel was null or undefined when calling getBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/gift-card/balance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IGiftCardBalanceRequestModelToJSON(requestParameters.iGiftCardBalanceRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGiftCardBalanceResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve user balance
     */
    async getBalance(requestParameters: GetBalanceRequest, initOverrides?: RequestInit): Promise<IGiftCardBalanceResponseModel> {
        const response = await this.getBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve beer menu using locationId
     */
    async getBeerMenuRaw(requestParameters: GetBeerMenuRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BeerMenuResponseModel>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getBeerMenu.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/menu/beer/location/{locationId}`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeerMenuResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve beer menu using locationId
     */
    async getBeerMenu(requestParameters: GetBeerMenuRequest, initOverrides?: RequestInit): Promise<BeerMenuResponseModel> {
        const response = await this.getBeerMenuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve customer account details given a customer id
     */
    async getCustomerAccountRaw(requestParameters: GetCustomerAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ITransformedGetCustomerAccountResponseForClientModel>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ITransformedGetCustomerAccountResponseForClientModelFromJSON(jsonValue));
    }

    /**
     * Retrieve customer account details given a customer id
     */
    async getCustomerAccount(requestParameters: GetCustomerAccountRequest = {}, initOverrides?: RequestInit): Promise<ITransformedGetCustomerAccountResponseForClientModel> {
        const response = await this.getCustomerAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve  customer loyalty  points
     */
    async getCustomerAccountLoyaltyPointsRaw(requestParameters: GetCustomerAccountLoyaltyPointsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetCustomerAccountLoyaltyPointsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/loyalty`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetCustomerAccountLoyaltyPointsResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve  customer loyalty  points
     */
    async getCustomerAccountLoyaltyPoints(requestParameters: GetCustomerAccountLoyaltyPointsRequest = {}, initOverrides?: RequestInit): Promise<IGetCustomerAccountLoyaltyPointsResponseModel> {
        const response = await this.getCustomerAccountLoyaltyPointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve customer account rewards given a customer id KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/1543799258/Get+Certificates+and+Offers#SessionM-Get-Offer-Custom-Data-Mapping KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/787374051986/Personal+Deals+for+Authenticated+users
     */
    async getCustomerAccountRewardsRaw(requestParameters: GetCustomerAccountRewardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetCustomerAccountRewardsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetCustomerAccountRewardsResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve customer account rewards given a customer id KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/1543799258/Get+Certificates+and+Offers#SessionM-Get-Offer-Custom-Data-Mapping KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/787374051986/Personal+Deals+for+Authenticated+users
     */
    async getCustomerAccountRewards(requestParameters: GetCustomerAccountRewardsRequest = {}, initOverrides?: RequestInit): Promise<IGetCustomerAccountRewardsResponseModel> {
        const response = await this.getCustomerAccountRewardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve customer account rewards catalog given a customer id
     */
    async getCustomerAccountRewardsCatalogRaw(requestParameters: GetCustomerAccountRewardsCatalogRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetCustomerAccountRewardsCatalogResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.pointsBalance !== undefined) {
            queryParameters['pointsBalance'] = requestParameters.pointsBalance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/catalog`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetCustomerAccountRewardsCatalogResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve customer account rewards catalog given a customer id
     */
    async getCustomerAccountRewardsCatalog(requestParameters: GetCustomerAccountRewardsCatalogRequest = {}, initOverrides?: RequestInit): Promise<IGetCustomerAccountRewardsCatalogResponseModel> {
        const response = await this.getCustomerAccountRewardsCatalogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve active and past rewards, autodiscounts for guests and authorized users
     */
    async getCustomerRewardsRaw(requestParameters: GetCustomerRewardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetCustomerAccountRewardsV2ResponseModel>> {
        if (requestParameters.iCustomerRewardsV2BodyModel === null || requestParameters.iCustomerRewardsV2BodyModel === undefined) {
            throw new runtime.RequiredError('iCustomerRewardsV2BodyModel','Required parameter requestParameters.iCustomerRewardsV2BodyModel was null or undefined when calling getCustomerRewards.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/customer/account/rewards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICustomerRewardsV2BodyModelToJSON(requestParameters.iCustomerRewardsV2BodyModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetCustomerAccountRewardsV2ResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve active and past rewards, autodiscounts for guests and authorized users
     */
    async getCustomerRewards(requestParameters: GetCustomerRewardsRequest, initOverrides?: RequestInit): Promise<IGetCustomerAccountRewardsV2ResponseModel> {
        const response = await this.getCustomerRewardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve rewards activity history
     */
    async getCustomerRewardsActivityHistoryRaw(requestParameters: GetCustomerRewardsActivityHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IGetCustomerRewardsActivityHistoryResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/activity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IGetCustomerRewardsActivityHistoryResponseModelFromJSON));
    }

    /**
     * Retrieve rewards activity history
     */
    async getCustomerRewardsActivityHistory(requestParameters: GetCustomerRewardsActivityHistoryRequest = {}, initOverrides?: RequestInit): Promise<Array<IGetCustomerRewardsActivityHistoryResponseModel>> {
        const response = await this.getCustomerRewardsActivityHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve local menu using menuType and locationId
     */
    async getLocalMenuRaw(requestParameters: GetLocalMenuRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IMenuModel>> {
        if (requestParameters.menuType === null || requestParameters.menuType === undefined) {
            throw new runtime.RequiredError('menuType','Required parameter requestParameters.menuType was null or undefined when calling getLocalMenu.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getLocalMenu.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/menu/type/{menuType}/id/{locationId}`.replace(`{${"menuType"}}`, encodeURIComponent(String(requestParameters.menuType))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IMenuModelFromJSON(jsonValue));
    }

    /**
     * Retrieve local menu using menuType and locationId
     */
    async getLocalMenu(requestParameters: GetLocalMenuRequest, initOverrides?: RequestInit): Promise<IMenuModel> {
        const response = await this.getLocalMenuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve location available time
     */
    async getLocationAvailableTimesRaw(requestParameters: GetLocationAvailableTimesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocationPickupAndDeliveryResponseModel>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getLocationAvailableTimes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location/{locationId}/availableTimes`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationPickupAndDeliveryResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve location available time
     */
    async getLocationAvailableTimes(requestParameters: GetLocationAvailableTimesRequest, initOverrides?: RequestInit): Promise<LocationPickupAndDeliveryResponseModel> {
        const response = await this.getLocationAvailableTimesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve location details a given a location id Location Details data returned in one format/schema or another based off \"isFromLocationDetailsPage\" param
     */
    async getLocationByIdRaw(requestParameters: GetLocationByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ILocationWithDetailsModel>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getLocationById.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeRestaurantSchema !== undefined) {
            queryParameters['includeRestaurantSchema'] = requestParameters.includeRestaurantSchema;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location/{locationId}`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ILocationWithDetailsModelFromJSON(jsonValue));
    }

    /**
     * Retrieve location details a given a location id Location Details data returned in one format/schema or another based off \"isFromLocationDetailsPage\" param
     */
    async getLocationById(requestParameters: GetLocationByIdRequest, initOverrides?: RequestInit): Promise<ILocationWithDetailsModel> {
        const response = await this.getLocationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve national menu using menuType
     */
    async getNationalMenuRaw(requestParameters: GetNationalMenuRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IMenuModel>> {
        if (requestParameters.menuType === null || requestParameters.menuType === undefined) {
            throw new runtime.RequiredError('menuType','Required parameter requestParameters.menuType was null or undefined when calling getNationalMenu.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/menu/type/{menuType}`.replace(`{${"menuType"}}`, encodeURIComponent(String(requestParameters.menuType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IMenuModelFromJSON(jsonValue));
    }

    /**
     * Retrieve national menu using menuType
     */
    async getNationalMenu(requestParameters: GetNationalMenuRequest, initOverrides?: RequestInit): Promise<IMenuModel> {
        const response = await this.getNationalMenuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve location nearest time slot
     */
    async getNearestSlotRaw(requestParameters: GetNearestSlotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getNearestSlot.');
        }

        const queryParameters: any = {};

        if (requestParameters.serviceType !== undefined) {
            queryParameters['serviceType'] = requestParameters.serviceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location/{locationId}/nearest-slot`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Retrieve location nearest time slot
     */
    async getNearestSlot(requestParameters: GetNearestSlotRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getNearestSlotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve order history
     */
    async getOrdersHistoryRaw(requestParameters: GetOrdersHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetCustomerOrderHistoryResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/order/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetCustomerOrderHistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve order history
     */
    async getOrdersHistory(requestParameters: GetOrdersHistoryRequest = {}, initOverrides?: RequestInit): Promise<IGetCustomerOrderHistoryResponseModel> {
        const response = await this.getOrdersHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve account payment methods (wallets)
     */
    async getPaymentMethodRaw(requestParameters: GetPaymentMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetCustomerAccountPaymentMethodsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/payment-method`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetCustomerAccountPaymentMethodsResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve account payment methods (wallets)
     */
    async getPaymentMethod(requestParameters: GetPaymentMethodRequest = {}, initOverrides?: RequestInit): Promise<IGetCustomerAccountPaymentMethodsResponseModel> {
        const response = await this.getPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve personalization actions
     */
    async getPersonalizationActionsRaw(requestParameters: GetPersonalizationActionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ICustomerPersonalizationResponseModel>> {
        if (requestParameters.iCustomerPersonalizationRequestModel === null || requestParameters.iCustomerPersonalizationRequestModel === undefined) {
            throw new runtime.RequiredError('iCustomerPersonalizationRequestModel','Required parameter requestParameters.iCustomerPersonalizationRequestModel was null or undefined when calling getPersonalizationActions.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/customer/personalization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICustomerPersonalizationRequestModelToJSON(requestParameters.iCustomerPersonalizationRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ICustomerPersonalizationResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve personalization actions
     */
    async getPersonalizationActions(requestParameters: GetPersonalizationActionsRequest, initOverrides?: RequestInit): Promise<ICustomerPersonalizationResponseModel> {
        const response = await this.getPersonalizationActionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get external id by email
     */
    async getProfileIdRaw(requestParameters: GetProfileIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IGetProfileIdResponseModel>> {
        if (requestParameters.iGetProfileIdRequestModel === null || requestParameters.iGetProfileIdRequestModel === undefined) {
            throw new runtime.RequiredError('iGetProfileIdRequestModel','Required parameter requestParameters.iGetProfileIdRequestModel was null or undefined when calling getProfileId.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/profile-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IGetProfileIdRequestModelToJSON(requestParameters.iGetProfileIdRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetProfileIdResponseModelFromJSON(jsonValue));
    }

    /**
     * Get external id by email
     */
    async getProfileId(requestParameters: GetProfileIdRequest, initOverrides?: RequestInit): Promise<IGetProfileIdResponseModel> {
        const response = await this.getProfileIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve QR code for customer with the given User Offer ID KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/1543799258/Get+Certificates+and+Offers#SessionM-Get-Offer-Custom-Data-Mapping KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/787374051986/Personal+Deals+for+Authenticated+users
     */
    async getQRCodeForOfferRaw(requestParameters: GetQRCodeForOfferRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.userOfferId === null || requestParameters.userOfferId === undefined) {
            throw new runtime.RequiredError('userOfferId','Required parameter requestParameters.userOfferId was null or undefined when calling getQRCodeForOffer.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/{userOfferId}/qr`.replace(`{${"userOfferId"}}`, encodeURIComponent(String(requestParameters.userOfferId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Retrieve QR code for customer with the given User Offer ID KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/1543799258/Get+Certificates+and+Offers#SessionM-Get-Offer-Custom-Data-Mapping KB https://inspirebrands.atlassian.net/wiki/spaces/DBBP/pages/787374051986/Personal+Deals+for+Authenticated+users
     */
    async getQRCodeForOffer(requestParameters: GetQRCodeForOfferRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getQRCodeForOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve location time slots
     */
    async getSlotsRaw(requestParameters: GetSlotsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ILocationSlotModel>>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getSlots.');
        }

        if (requestParameters.menuId === null || requestParameters.menuId === undefined) {
            throw new runtime.RequiredError('menuId','Required parameter requestParameters.menuId was null or undefined when calling getSlots.');
        }

        if (requestParameters.numberOfDays === null || requestParameters.numberOfDays === undefined) {
            throw new runtime.RequiredError('numberOfDays','Required parameter requestParameters.numberOfDays was null or undefined when calling getSlots.');
        }

        const queryParameters: any = {};

        if (requestParameters.menuId !== undefined) {
            queryParameters['menuId'] = requestParameters.menuId;
        }

        if (requestParameters.numberOfDays !== undefined) {
            queryParameters['numberOfDays'] = requestParameters.numberOfDays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location/{locationId}/slots`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ILocationSlotModelFromJSON));
    }

    /**
     * Retrieve location time slots
     */
    async getSlots(requestParameters: GetSlotsRequest, initOverrides?: RequestInit): Promise<Array<ILocationSlotModel>> {
        const response = await this.getSlotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Survey
     */
    async getSurveyRaw(requestParameters: GetSurveyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ISurveyModel>> {
        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/survey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISurveyModelFromJSON(jsonValue));
    }

    /**
     * Get Survey
     */
    async getSurvey(requestParameters: GetSurveyRequest = {}, initOverrides?: RequestInit): Promise<ISurveyModel> {
        const response = await this.getSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve global configuration from contentful
     */
    async globalConfigurationRaw(requestParameters: GlobalConfigurationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IConfigurationItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IConfigurationItemModelFromJSON));
    }

    /**
     * Retrieve global configuration from contentful
     */
    async globalConfiguration(requestParameters: GlobalConfigurationRequest = {}, initOverrides?: RequestInit): Promise<Array<IConfigurationItemModel>> {
        const response = await this.globalConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async healthRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthCheckResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthCheckResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async health(initOverrides?: RequestInit): Promise<HealthCheckResponseModel> {
        const response = await this.healthRaw(initOverrides);
        return await response.value();
    }

    /**
     * Initialize Apple payment
     */
    async initApplePaymentRaw(requestParameters: InitApplePaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPaymentInitResponseModel>> {
        if (requestParameters.iApplePaymentInitBodyModel === null || requestParameters.iApplePaymentInitBodyModel === undefined) {
            throw new runtime.RequiredError('iApplePaymentInitBodyModel','Required parameter requestParameters.iApplePaymentInitBodyModel was null or undefined when calling initApplePayment.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payment-method/init/apple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IApplePaymentInitBodyModelToJSON(requestParameters.iApplePaymentInitBodyModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPaymentInitResponseModelFromJSON(jsonValue));
    }

    /**
     * Initialize Apple payment
     */
    async initApplePayment(requestParameters: InitApplePaymentRequest, initOverrides?: RequestInit): Promise<IPaymentInitResponseModel> {
        const response = await this.initApplePaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize Apple payment
     */
    async initApplePaymentSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IApplePaySessionResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payment-method/init/applePaymentSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IApplePaySessionResponseModelFromJSON(jsonValue));
    }

    /**
     * Initialize Apple payment
     */
    async initApplePaymentSession(initOverrides?: RequestInit): Promise<IApplePaySessionResponseModel> {
        const response = await this.initApplePaymentSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Initialize Credit Card payment
     */
    async initCreditCardPaymentRaw(requestParameters: InitCreditCardPaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPaymentInitResponseModel>> {
        if (requestParameters.iCreditCardPaymentInitBodyModel === null || requestParameters.iCreditCardPaymentInitBodyModel === undefined) {
            throw new runtime.RequiredError('iCreditCardPaymentInitBodyModel','Required parameter requestParameters.iCreditCardPaymentInitBodyModel was null or undefined when calling initCreditCardPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payment-method/init/cc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICreditCardPaymentInitBodyModelToJSON(requestParameters.iCreditCardPaymentInitBodyModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPaymentInitResponseModelFromJSON(jsonValue));
    }

    /**
     * Initialize Credit Card payment
     */
    async initCreditCardPayment(requestParameters: InitCreditCardPaymentRequest, initOverrides?: RequestInit): Promise<IPaymentInitResponseModel> {
        const response = await this.initCreditCardPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize Gift Card payment
     */
    async initGiftCardPaymentRaw(requestParameters: InitGiftCardPaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPaymentInitResponseModel>> {
        if (requestParameters.iGiftcardPaymentInitBodyModel === null || requestParameters.iGiftcardPaymentInitBodyModel === undefined) {
            throw new runtime.RequiredError('iGiftcardPaymentInitBodyModel','Required parameter requestParameters.iGiftcardPaymentInitBodyModel was null or undefined when calling initGiftCardPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payment-method/init/gc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IGiftcardPaymentInitBodyModelToJSON(requestParameters.iGiftcardPaymentInitBodyModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPaymentInitResponseModelFromJSON(jsonValue));
    }

    /**
     * Initialize Gift Card payment
     */
    async initGiftCardPayment(requestParameters: InitGiftCardPaymentRequest, initOverrides?: RequestInit): Promise<IPaymentInitResponseModel> {
        const response = await this.initGiftCardPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize Google payment
     */
    async initGooglePaymentRaw(requestParameters: InitGooglePaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPaymentInitResponseModel>> {
        if (requestParameters.iGooglePaymentInitBodyModel === null || requestParameters.iGooglePaymentInitBodyModel === undefined) {
            throw new runtime.RequiredError('iGooglePaymentInitBodyModel','Required parameter requestParameters.iGooglePaymentInitBodyModel was null or undefined when calling initGooglePayment.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payment-method/init/google`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IGooglePaymentInitBodyModelToJSON(requestParameters.iGooglePaymentInitBodyModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPaymentInitResponseModelFromJSON(jsonValue));
    }

    /**
     * Initialize Google payment
     */
    async initGooglePayment(requestParameters: InitGooglePaymentRequest, initOverrides?: RequestInit): Promise<IPaymentInitResponseModel> {
        const response = await this.initGooglePaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize Wallet
     */
    async initWalletRaw(requestParameters: InitWalletRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPaymentInitResponseModel>> {
        if (requestParameters.iWalletInitParamsModel === null || requestParameters.iWalletInitParamsModel === undefined) {
            throw new runtime.RequiredError('iWalletInitParamsModel','Required parameter requestParameters.iWalletInitParamsModel was null or undefined when calling initWallet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payment-method/init/wallet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IWalletInitParamsModelToJSON(requestParameters.iWalletInitParamsModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPaymentInitResponseModelFromJSON(jsonValue));
    }

    /**
     * Initialize Wallet
     */
    async initWallet(requestParameters: InitWalletRequest, initOverrides?: RequestInit): Promise<IPaymentInitResponseModel> {
        const response = await this.initWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Order Details
     */
    async internalRaw(requestParameters: InternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IOrderModel>> {
        if (requestParameters.idempotentId === null || requestParameters.idempotentId === undefined) {
            throw new runtime.RequiredError('idempotentId','Required parameter requestParameters.idempotentId was null or undefined when calling internal.');
        }

        const queryParameters: any = {};

        if (requestParameters.idempotentId !== undefined) {
            queryParameters['idempotentId'] = requestParameters.idempotentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/order/internal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IOrderModelFromJSON(jsonValue));
    }

    /**
     * Order Details
     */
    async internal(requestParameters: InternalRequest, initOverrides?: RequestInit): Promise<IOrderModel> {
        const response = await this.internalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Operation for registering Offer.
     */
    async oneClickRegisterOfferRaw(requestParameters: OneClickRegisterOfferRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IOneClickRegisterOfferResponseModel>> {
        if (requestParameters.profileOfferRegisterRequestModel === null || requestParameters.profileOfferRegisterRequestModel === undefined) {
            throw new runtime.RequiredError('profileOfferRegisterRequestModel','Required parameter requestParameters.profileOfferRegisterRequestModel was null or undefined when calling oneClickRegisterOffer.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/customer/profile/register-offer`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileOfferRegisterRequestModelToJSON(requestParameters.profileOfferRegisterRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IOneClickRegisterOfferResponseModelFromJSON(jsonValue));
    }

    /**
     * Operation for registering Offer.
     */
    async oneClickRegisterOffer(requestParameters: OneClickRegisterOfferRequest, initOverrides?: RequestInit): Promise<IOneClickRegisterOfferResponseModel> {
        const response = await this.oneClickRegisterOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate and Process order payment !!!WARNING!!! This endpoint will be deprecated. If you are adding some changes, please sync it with v2/order contoller
     */
    async orderRaw(requestParameters: OrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderResponseModel>> {
        if (requestParameters.orderRequestModel === null || requestParameters.orderRequestModel === undefined) {
            throw new runtime.RequiredError('orderRequestModel','Required parameter requestParameters.orderRequestModel was null or undefined when calling order.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.fulfillmentType !== undefined) {
            queryParameters['fulfillmentType'] = requestParameters.fulfillmentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRequestModelToJSON(requestParameters.orderRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseModelFromJSON(jsonValue));
    }

    /**
     * Validate and Process order payment !!!WARNING!!! This endpoint will be deprecated. If you are adding some changes, please sync it with v2/order contoller
     */
    async order(requestParameters: OrderRequest, initOverrides?: RequestInit): Promise<OrderResponseModel> {
        const response = await this.orderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate and Process order payment (version 2)
     */
    async orderV2Raw(requestParameters: OrderV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderResponseModel>> {
        if (requestParameters.orderV2RequestModel === null || requestParameters.orderV2RequestModel === undefined) {
            throw new runtime.RequiredError('orderV2RequestModel','Required parameter requestParameters.orderV2RequestModel was null or undefined when calling orderV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fulfillmentType !== undefined) {
            queryParameters['fulfillmentType'] = requestParameters.fulfillmentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderV2RequestModelToJSON(requestParameters.orderV2RequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseModelFromJSON(jsonValue));
    }

    /**
     * Validate and Process order payment (version 2)
     */
    async orderV2(requestParameters: OrderV2Request, initOverrides?: RequestInit): Promise<OrderResponseModel> {
        const response = await this.orderV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Post Contact Us Data
     */
    async postContactUsRaw(requestParameters: PostContactUsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.iContactUsRequestModel === null || requestParameters.iContactUsRequestModel === undefined) {
            throw new runtime.RequiredError('iContactUsRequestModel','Required parameter requestParameters.iContactUsRequestModel was null or undefined when calling postContactUs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IContactUsRequestModelToJSON(requestParameters.iContactUsRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post Contact Us Data
     */
    async postContactUs(requestParameters: PostContactUsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postContactUsRaw(requestParameters, initOverrides);
    }

    /**
     * Purchase offer
     */
    async purchaseCustomerOfferRaw(requestParameters: PurchaseCustomerOfferRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPurchaseCustomerAccountRewardResponseModel>> {
        if (requestParameters.rewardId === null || requestParameters.rewardId === undefined) {
            throw new runtime.RequiredError('rewardId','Required parameter requestParameters.rewardId was null or undefined when calling purchaseCustomerOffer.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/rewards/{rewardId}`.replace(`{${"rewardId"}}`, encodeURIComponent(String(requestParameters.rewardId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPurchaseCustomerAccountRewardResponseModelFromJSON(jsonValue));
    }

    /**
     * Purchase offer
     */
    async purchaseCustomerOffer(requestParameters: PurchaseCustomerOfferRequest, initOverrides?: RequestInit): Promise<IPurchaseCustomerAccountRewardResponseModel> {
        const response = await this.purchaseCustomerOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register Stall At Visit
     */
    async registerVisitRaw(requestParameters: RegisterVisitRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IVisitRegisterResponseModel>> {
        if (requestParameters.iVisitRegisterRequestModel === null || requestParameters.iVisitRegisterRequestModel === undefined) {
            throw new runtime.RequiredError('iVisitRegisterRequestModel','Required parameter requestParameters.iVisitRegisterRequestModel was null or undefined when calling registerVisit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/visit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IVisitRegisterRequestModelToJSON(requestParameters.iVisitRegisterRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IVisitRegisterResponseModelFromJSON(jsonValue));
    }

    /**
     * Register Stall At Visit
     */
    async registerVisit(requestParameters: RegisterVisitRequest, initOverrides?: RequestInit): Promise<IVisitRegisterResponseModel> {
        const response = await this.registerVisitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return pick up and drop off location details given specific geo coordinates.
     */
    async searchDeliveryLocationRaw(requestParameters: SearchDeliveryLocationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IDeliveryLocationsSearchResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.googlePlaceId !== undefined) {
            queryParameters['googlePlaceId'] = requestParameters.googlePlaceId;
        }

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/delivery-orders/delivery-locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IDeliveryLocationsSearchResponseModelFromJSON(jsonValue));
    }

    /**
     * Return pick up and drop off location details given specific geo coordinates.
     */
    async searchDeliveryLocation(requestParameters: SearchDeliveryLocationRequest = {}, initOverrides?: RequestInit): Promise<IDeliveryLocationsSearchResponseModel> {
        const response = await this.searchDeliveryLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve location list
     */
    async searchLocationRaw(requestParameters: SearchLocationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ILocationsPageModel>> {
        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling searchLocation.');
        }

        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling searchLocation.');
        }

        const queryParameters: any = {};

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ILocationsPageModelFromJSON(jsonValue));
    }

    /**
     * Retrieve location list
     */
    async searchLocation(requestParameters: SearchLocationRequest, initOverrides?: RequestInit): Promise<ILocationsPageModel> {
        const response = await this.searchLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve location urls list
     */
    async searchLocationUrlsRaw(requestParameters: SearchLocationUrlsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ILocationUrlsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location/list/urls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ILocationUrlsResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve location urls list
     */
    async searchLocationUrls(requestParameters: SearchLocationUrlsRequest = {}, initOverrides?: RequestInit): Promise<ILocationUrlsResponseModel> {
        const response = await this.searchLocationUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve locations list filtered by Country/StateOrProvince/City countryCode ^[a-z,A-Z]{2,2}$
     */
    async searchLocationsRaw(requestParameters: SearchLocationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TAllLocationsResponseDataModel>> {
        const queryParameters: any = {};

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.stateOrProvinceCode !== undefined) {
            queryParameters['stateOrProvinceCode'] = requestParameters.stateOrProvinceCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/location/list/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TAllLocationsResponseDataModelFromJSON(jsonValue));
    }

    /**
     * Retrieve locations list filtered by Country/StateOrProvince/City countryCode ^[a-z,A-Z]{2,2}$
     */
    async searchLocations(requestParameters: SearchLocationsRequest = {}, initOverrides?: RequestInit): Promise<TAllLocationsResponseDataModel> {
        const response = await this.searchLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * send sms
     */
    async sendSmsRaw(requestParameters: SendSmsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationResponseModel>> {
        if (requestParameters.iNotificationBodyRequestModel === null || requestParameters.iNotificationBodyRequestModel === undefined) {
            throw new runtime.RequiredError('iNotificationBodyRequestModel','Required parameter requestParameters.iNotificationBodyRequestModel was null or undefined when calling sendSms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/notification/sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: INotificationBodyRequestModelToJSON(requestParameters.iNotificationBodyRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationResponseModelFromJSON(jsonValue));
    }

    /**
     * send sms
     */
    async sendSms(requestParameters: SendSmsRequest, initOverrides?: RequestInit): Promise<NotificationResponseModel> {
        const response = await this.sendSmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bag Tally Calculate
     */
    async tallyRaw(requestParameters: TallyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TallyResponseModel>> {
        if (requestParameters.tallyRequestModel === null || requestParameters.tallyRequestModel === undefined) {
            throw new runtime.RequiredError('tallyRequestModel','Required parameter requestParameters.tallyRequestModel was null or undefined when calling tally.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.fulfillmentType !== undefined) {
            queryParameters['fulfillmentType'] = requestParameters.fulfillmentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/bag/tally`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TallyRequestModelToJSON(requestParameters.tallyRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TallyResponseModelFromJSON(jsonValue));
    }

    /**
     * Bag Tally Calculate
     */
    async tally(requestParameters: TallyRequest, initOverrides?: RequestInit): Promise<TallyResponseModel> {
        const response = await this.tallyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update customer account given customer account details and customer id
     */
    async updateCustomerAccountRaw(requestParameters: UpdateCustomerAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ITransformedCustomerIdResponseForClientModel>> {
        if (requestParameters.iUpdateCustomerAccountRequestFromClientModel === null || requestParameters.iUpdateCustomerAccountRequestFromClientModel === undefined) {
            throw new runtime.RequiredError('iUpdateCustomerAccountRequestFromClientModel','Required parameter requestParameters.iUpdateCustomerAccountRequestFromClientModel was null or undefined when calling updateCustomerAccount.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IUpdateCustomerAccountRequestFromClientModelToJSON(requestParameters.iUpdateCustomerAccountRequestFromClientModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ITransformedCustomerIdResponseForClientModelFromJSON(jsonValue));
    }

    /**
     * Update customer account given customer account details and customer id
     */
    async updateCustomerAccount(requestParameters: UpdateCustomerAccountRequest, initOverrides?: RequestInit): Promise<ITransformedCustomerIdResponseForClientModel> {
        const response = await this.updateCustomerAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Survey
     */
    async updateSurveyRaw(requestParameters: UpdateSurveyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ISurveyModel>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurvey.');
        }

        if (requestParameters.iSurveyRespondModel === null || requestParameters.iSurveyRespondModel === undefined) {
            throw new runtime.RequiredError('iSurveyRespondModel','Required parameter requestParameters.iSurveyRespondModel was null or undefined when calling updateSurvey.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/account/survey/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.iSurveyRespondModel.map(ISurveyRespondModelToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISurveyModelFromJSON(jsonValue));
    }

    /**
     * Update Survey
     */
    async updateSurvey(requestParameters: UpdateSurveyRequest, initOverrides?: RequestInit): Promise<ISurveyModel> {
        const response = await this.updateSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload customer avatar image
     */
    async uploadCustomerAvatarRaw(requestParameters: UploadCustomerAvatarRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ICustomerAvatarResponseModel>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadCustomerAvatar.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/v1/customer/account/avatar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ICustomerAvatarResponseModelFromJSON(jsonValue));
    }

    /**
     * Upload customer avatar image
     */
    async uploadCustomerAvatar(requestParameters: UploadCustomerAvatarRequest, initOverrides?: RequestInit): Promise<ICustomerAvatarResponseModel> {
        const response = await this.uploadCustomerAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify order
     */
    async verifyRaw(requestParameters: VerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IOrderVerifyResponseModel>> {
        if (requestParameters.iOrderVerifyRequestModel === null || requestParameters.iOrderVerifyRequestModel === undefined) {
            throw new runtime.RequiredError('iOrderVerifyRequestModel','Required parameter requestParameters.iOrderVerifyRequestModel was null or undefined when calling verify.');
        }

        const queryParameters: any = {};

        if (requestParameters.cartId !== undefined) {
            queryParameters['cartId'] = requestParameters.cartId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/order/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IOrderVerifyRequestModelToJSON(requestParameters.iOrderVerifyRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IOrderVerifyResponseModelFromJSON(jsonValue));
    }

    /**
     * Verify order
     */
    async verify(requestParameters: VerifyRequest, initOverrides?: RequestInit): Promise<IOrderVerifyResponseModel> {
        const response = await this.verifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify customer email
     */
    async verifyEmailRaw(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IEmailVerificationAccountResponseModel>> {
        if (requestParameters.cid === null || requestParameters.cid === undefined) {
            throw new runtime.RequiredError('cid','Required parameter requestParameters.cid was null or undefined when calling verifyEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        if (requestParameters.cid !== undefined) {
            queryParameters['cid'] = requestParameters.cid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/customer/account/email-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEmailVerificationAccountResponseModelFromJSON(jsonValue));
    }

    /**
     * Verify customer email
     */
    async verifyEmail(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit): Promise<IEmailVerificationAccountResponseModel> {
        const response = await this.verifyEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify customer phone number is not in use
     */
    async verifyPhoneNumberRaw(requestParameters: VerifyPhoneNumberRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IPhoneVerificationResponseModel>> {
        if (requestParameters.iValidatePhoneNumberRequestModel === null || requestParameters.iValidatePhoneNumberRequestModel === undefined) {
            throw new runtime.RequiredError('iValidatePhoneNumberRequestModel','Required parameter requestParameters.iValidatePhoneNumberRequestModel was null or undefined when calling verifyPhoneNumber.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellingChannel !== undefined) {
            queryParameters['sellingChannel'] = requestParameters.sellingChannel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/customer/account/phone-number-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IValidatePhoneNumberRequestModelToJSON(requestParameters.iValidatePhoneNumberRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IPhoneVerificationResponseModelFromJSON(jsonValue));
    }

    /**
     * Verify customer phone number is not in use
     */
    async verifyPhoneNumber(requestParameters: VerifyPhoneNumberRequest, initOverrides?: RequestInit): Promise<IPhoneVerificationResponseModel> {
        const response = await this.verifyPhoneNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async versionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<VersionResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async version(initOverrides?: RequestInit): Promise<VersionResponseModel> {
        const response = await this.versionRaw(initOverrides);
        return await response.value();
    }

}
