/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationRestrictionsModel
 */
export interface LocationRestrictionsModel {
    /**
     * 
     * @type {boolean}
     * @memberof LocationRestrictionsModel
     */
    isApplicableToAllLocations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocationRestrictionsModel
     */
    exclusion?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationRestrictionsModel
     */
    locations?: Array<string>;
}

export function LocationRestrictionsModelFromJSON(json: any): LocationRestrictionsModel {
    return LocationRestrictionsModelFromJSONTyped(json, false);
}

export function LocationRestrictionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationRestrictionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isApplicableToAllLocations': !exists(json, 'isApplicableToAllLocations') ? undefined : json['isApplicableToAllLocations'],
        'exclusion': !exists(json, 'exclusion') ? undefined : json['exclusion'],
        'locations': !exists(json, 'locations') ? undefined : json['locations'],
    };
}

export function LocationRestrictionsModelToJSON(value?: LocationRestrictionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isApplicableToAllLocations': value.isApplicableToAllLocations,
        'exclusion': value.exclusion,
        'locations': value.locations,
    };
}

