/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICatalogRewardCategoryModel,
    ICatalogRewardCategoryModelFromJSON,
    ICatalogRewardCategoryModelFromJSONTyped,
    ICatalogRewardCategoryModelToJSON,
} from './ICatalogRewardCategoryModel';

/**
 * 
 * @export
 * @interface TCatalogCertificatesByCategoryModel
 */
export interface TCatalogCertificatesByCategoryModel {
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE1?: ICatalogRewardCategoryModel;
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE2?: ICatalogRewardCategoryModel;
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE3?: ICatalogRewardCategoryModel;
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE5?: ICatalogRewardCategoryModel;
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE7?: ICatalogRewardCategoryModel;
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE8?: ICatalogRewardCategoryModel;
    /**
     * 
     * @type {ICatalogRewardCategoryModel}
     * @memberof TCatalogCertificatesByCategoryModel
     */
    cODE9?: ICatalogRewardCategoryModel;
}

export function TCatalogCertificatesByCategoryModelFromJSON(json: any): TCatalogCertificatesByCategoryModel {
    return TCatalogCertificatesByCategoryModelFromJSONTyped(json, false);
}

export function TCatalogCertificatesByCategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCatalogCertificatesByCategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cODE1': !exists(json, 'CODE_1') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_1']),
        'cODE2': !exists(json, 'CODE_2') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_2']),
        'cODE3': !exists(json, 'CODE_3') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_3']),
        'cODE5': !exists(json, 'CODE_5') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_5']),
        'cODE7': !exists(json, 'CODE_7') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_7']),
        'cODE8': !exists(json, 'CODE_8') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_8']),
        'cODE9': !exists(json, 'CODE_9') ? undefined : ICatalogRewardCategoryModelFromJSON(json['CODE_9']),
    };
}

export function TCatalogCertificatesByCategoryModelToJSON(value?: TCatalogCertificatesByCategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CODE_1': ICatalogRewardCategoryModelToJSON(value.cODE1),
        'CODE_2': ICatalogRewardCategoryModelToJSON(value.cODE2),
        'CODE_3': ICatalogRewardCategoryModelToJSON(value.cODE3),
        'CODE_5': ICatalogRewardCategoryModelToJSON(value.cODE5),
        'CODE_7': ICatalogRewardCategoryModelToJSON(value.cODE7),
        'CODE_8': ICatalogRewardCategoryModelToJSON(value.cODE8),
        'CODE_9': ICatalogRewardCategoryModelToJSON(value.cODE9),
    };
}

