/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardMenuIdModel,
    IRewardMenuIdModelFromJSON,
    IRewardMenuIdModelFromJSONTyped,
    IRewardMenuIdModelToJSON,
} from './IRewardMenuIdModel';
import {
    IRewardMenuIdsRuleEnumModel,
    IRewardMenuIdsRuleEnumModelFromJSON,
    IRewardMenuIdsRuleEnumModelFromJSONTyped,
    IRewardMenuIdsRuleEnumModelToJSON,
} from './IRewardMenuIdsRuleEnumModel';

/**
 * 
 * @export
 * @interface IRewardMenuIdsModel
 */
export interface IRewardMenuIdsModel {
    /**
     * 
     * @type {IRewardMenuIdsRuleEnumModel}
     * @memberof IRewardMenuIdsModel
     */
    rule: IRewardMenuIdsRuleEnumModel;
    /**
     * 
     * @type {Array<IRewardMenuIdModel>}
     * @memberof IRewardMenuIdsModel
     */
    menuIds: Array<IRewardMenuIdModel>;
}

export function IRewardMenuIdsModelFromJSON(json: any): IRewardMenuIdsModel {
    return IRewardMenuIdsModelFromJSONTyped(json, false);
}

export function IRewardMenuIdsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardMenuIdsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rule': IRewardMenuIdsRuleEnumModelFromJSON(json['rule']),
        'menuIds': ((json['menuIds'] as Array<any>).map(IRewardMenuIdModelFromJSON)),
    };
}

export function IRewardMenuIdsModelToJSON(value?: IRewardMenuIdsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rule': IRewardMenuIdsRuleEnumModelToJSON(value.rule),
        'menuIds': ((value.menuIds as Array<any>).map(IRewardMenuIdModelToJSON)),
    };
}

