/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalorieRangeModel,
    CalorieRangeModelFromJSON,
    CalorieRangeModelFromJSONTyped,
    CalorieRangeModelToJSON,
} from './CalorieRangeModel';
import {
    MacroNutrientModel,
    MacroNutrientModelFromJSON,
    MacroNutrientModelFromJSONTyped,
    MacroNutrientModelToJSON,
} from './MacroNutrientModel';
import {
    NumericValueModel,
    NumericValueModelFromJSON,
    NumericValueModelFromJSONTyped,
    NumericValueModelToJSON,
} from './NumericValueModel';

/**
 * 
 * @export
 * @interface NutritionModel
 */
export interface NutritionModel {
    /**
     * 
     * @type {number}
     * @memberof NutritionModel
     */
    totalCalories?: number;
    /**
     * 
     * @type {CalorieRangeModel}
     * @memberof NutritionModel
     */
    calorieRange?: CalorieRangeModel;
    /**
     * 
     * @type {number}
     * @memberof NutritionModel
     */
    caloriesPerServing?: number;
    /**
     * 
     * @type {number}
     * @memberof NutritionModel
     */
    servingsPerItem?: number;
    /**
     * 
     * @type {NumericValueModel}
     * @memberof NutritionModel
     */
    servingSizePrimary?: NumericValueModel;
    /**
     * 
     * @type {NumericValueModel}
     * @memberof NutritionModel
     */
    servingSizeSecondary?: NumericValueModel;
    /**
     * 
     * @type {{ [key: string]: MacroNutrientModel; }}
     * @memberof NutritionModel
     */
    macroNutrients?: { [key: string]: MacroNutrientModel; };
    /**
     * 
     * @type {string}
     * @memberof NutritionModel
     */
    allergenInformation?: string;
}

export function NutritionModelFromJSON(json: any): NutritionModel {
    return NutritionModelFromJSONTyped(json, false);
}

export function NutritionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NutritionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCalories': !exists(json, 'totalCalories') ? undefined : json['totalCalories'],
        'calorieRange': !exists(json, 'calorieRange') ? undefined : CalorieRangeModelFromJSON(json['calorieRange']),
        'caloriesPerServing': !exists(json, 'caloriesPerServing') ? undefined : json['caloriesPerServing'],
        'servingsPerItem': !exists(json, 'servingsPerItem') ? undefined : json['servingsPerItem'],
        'servingSizePrimary': !exists(json, 'servingSizePrimary') ? undefined : NumericValueModelFromJSON(json['servingSizePrimary']),
        'servingSizeSecondary': !exists(json, 'servingSizeSecondary') ? undefined : NumericValueModelFromJSON(json['servingSizeSecondary']),
        'macroNutrients': !exists(json, 'macroNutrients') ? undefined : (mapValues(json['macroNutrients'], MacroNutrientModelFromJSON)),
        'allergenInformation': !exists(json, 'allergenInformation') ? undefined : json['allergenInformation'],
    };
}

export function NutritionModelToJSON(value?: NutritionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCalories': value.totalCalories,
        'calorieRange': CalorieRangeModelToJSON(value.calorieRange),
        'caloriesPerServing': value.caloriesPerServing,
        'servingsPerItem': value.servingsPerItem,
        'servingSizePrimary': NumericValueModelToJSON(value.servingSizePrimary),
        'servingSizeSecondary': NumericValueModelToJSON(value.servingSizeSecondary),
        'macroNutrients': value.macroNutrients === undefined ? undefined : (mapValues(value.macroNutrients, MacroNutrientModelToJSON)),
        'allergenInformation': value.allergenInformation,
    };
}

