/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Offer Status
 * @export
 * @enum {string}
 */
export enum TOfferStatusModel {
    Open = 'OPEN',
    Locked = 'LOCKED',
    Redeemed = 'REDEEMED',
    Past = 'PAST'
}

export function TOfferStatusModelFromJSON(json: any): TOfferStatusModel {
    return TOfferStatusModelFromJSONTyped(json, false);
}

export function TOfferStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TOfferStatusModel {
    return json as TOfferStatusModel;
}

export function TOfferStatusModelToJSON(value?: TOfferStatusModel | null): any {
    return value as any;
}

