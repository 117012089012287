/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IClaimMissingPointsFromClientModel
 */
export interface IClaimMissingPointsFromClientModel {
    /**
     * 
     * @type {string}
     * @memberof IClaimMissingPointsFromClientModel
     */
    claimNumber?: string;
    /**
     * location Id
     * @type {string}
     * @memberof IClaimMissingPointsFromClientModel
     */
    locationId?: string;
    /**
     * date
     * @type {string}
     * @memberof IClaimMissingPointsFromClientModel
     */
    date?: string;
    /**
     * Check Number
     * @type {string}
     * @memberof IClaimMissingPointsFromClientModel
     */
    checkNumber?: string;
    /**
     * Sub total
     * @type {number}
     * @memberof IClaimMissingPointsFromClientModel
     */
    subTotal?: number;
}

export function IClaimMissingPointsFromClientModelFromJSON(json: any): IClaimMissingPointsFromClientModel {
    return IClaimMissingPointsFromClientModelFromJSONTyped(json, false);
}

export function IClaimMissingPointsFromClientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IClaimMissingPointsFromClientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'claimNumber': !exists(json, 'claimNumber') ? undefined : json['claimNumber'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'checkNumber': !exists(json, 'checkNumber') ? undefined : json['checkNumber'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
    };
}

export function IClaimMissingPointsFromClientModelToJSON(value?: IClaimMissingPointsFromClientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'claimNumber': value.claimNumber,
        'locationId': value.locationId,
        'date': value.date,
        'checkNumber': value.checkNumber,
        'subTotal': value.subTotal,
    };
}

