/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationAllowedPaymentMethodsModel,
    LocationAllowedPaymentMethodsModelFromJSON,
    LocationAllowedPaymentMethodsModelFromJSONTyped,
    LocationAllowedPaymentMethodsModelToJSON,
} from './LocationAllowedPaymentMethodsModel';

/**
 * 
 * @export
 * @interface ILocationAdditionalFeaturesModel
 */
export interface ILocationAdditionalFeaturesModel {
    /**
     * 
     * @type {number}
     * @memberof ILocationAdditionalFeaturesModel
     */
    prepTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocationAdditionalFeaturesModel
     */
    maxOrderAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationAdditionalFeaturesModel
     */
    isPayAtStoreEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ILocationAdditionalFeaturesModel
     */
    payAtStoreMaxAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationAdditionalFeaturesModel
     */
    isGoLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ILocationAdditionalFeaturesModel
     */
    isOffline?: boolean;
    /**
     * 
     * @type {LocationAllowedPaymentMethodsModel}
     * @memberof ILocationAdditionalFeaturesModel
     */
    paymentMethod?: LocationAllowedPaymentMethodsModel;
}

export function ILocationAdditionalFeaturesModelFromJSON(json: any): ILocationAdditionalFeaturesModel {
    return ILocationAdditionalFeaturesModelFromJSONTyped(json, false);
}

export function ILocationAdditionalFeaturesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationAdditionalFeaturesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prepTime': !exists(json, 'prepTime') ? undefined : json['prepTime'],
        'maxOrderAmount': json['maxOrderAmount'],
        'isPayAtStoreEnabled': json['isPayAtStoreEnabled'],
        'payAtStoreMaxAmount': !exists(json, 'payAtStoreMaxAmount') ? undefined : json['payAtStoreMaxAmount'],
        'isGoLocation': !exists(json, 'isGoLocation') ? undefined : json['isGoLocation'],
        'isOffline': !exists(json, 'isOffline') ? undefined : json['isOffline'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : LocationAllowedPaymentMethodsModelFromJSON(json['paymentMethod']),
    };
}

export function ILocationAdditionalFeaturesModelToJSON(value?: ILocationAdditionalFeaturesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prepTime': value.prepTime,
        'maxOrderAmount': value.maxOrderAmount,
        'isPayAtStoreEnabled': value.isPayAtStoreEnabled,
        'payAtStoreMaxAmount': value.payAtStoreMaxAmount,
        'isGoLocation': value.isGoLocation,
        'isOffline': value.isOffline,
        'paymentMethod': LocationAllowedPaymentMethodsModelToJSON(value.paymentMethod),
    };
}

