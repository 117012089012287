/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IPurchaseCustomerAccountRewardResponseModel
 */
export interface IPurchaseCustomerAccountRewardResponseModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof IPurchaseCustomerAccountRewardResponseModel
     */
    certificateNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IPurchaseCustomerAccountRewardResponseModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IPurchaseCustomerAccountRewardResponseModel
     */
    effectiveDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IPurchaseCustomerAccountRewardResponseModel
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof IPurchaseCustomerAccountRewardResponseModel
     */
    pointBalanceAfter?: number;
}

export function IPurchaseCustomerAccountRewardResponseModelFromJSON(json: any): IPurchaseCustomerAccountRewardResponseModel {
    return IPurchaseCustomerAccountRewardResponseModelFromJSONTyped(json, false);
}

export function IPurchaseCustomerAccountRewardResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPurchaseCustomerAccountRewardResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'certificateNumber': !exists(json, 'certificateNumber') ? undefined : json['certificateNumber'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'effectiveDate': !exists(json, 'effectiveDate') ? undefined : json['effectiveDate'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'pointBalanceAfter': !exists(json, 'pointBalanceAfter') ? undefined : json['pointBalanceAfter'],
    };
}

export function IPurchaseCustomerAccountRewardResponseModelToJSON(value?: IPurchaseCustomerAccountRewardResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'certificateNumber': value.certificateNumber,
        'startDate': value.startDate,
        'effectiveDate': value.effectiveDate,
        'currencyCode': value.currencyCode,
        'pointBalanceAfter': value.pointBalanceAfter,
    };
}

