/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRewardCertificateModel
 */
export interface IRewardCertificateModel {
    /**
     * Reward Certificate ID
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    certificateNumber?: string;
    /**
     * Price paid in points for this certificate
     * @type {number}
     * @memberof IRewardCertificateModel
     */
    priceInPoints?: number;
    /**
     * Certificate expiry date
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    expirationDate?: string;
    /**
     * Reward Certificate Effective Date
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    effectiveDate?: string;
    /**
     * 20 Traditional + 20 Boneless + Fries
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    title?: string;
    /**
     * Reward Certificate Start Date
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    startDate?: string;
    /**
     * Reward Certificate Start Date
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    endDate?: string;
    /**
     * ISO 3 char currency code
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    currencyCode?: string;
    /**
     * Certificate Status
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    status?: string;
    /**
     * Reward Certificate Start Date
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    rewardId: string;
    /**
     * Reward Type
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    rewardType?: string;
    /**
     * Certificate Status Description
     * @type {string}
     * @memberof IRewardCertificateModel
     */
    statusDescription?: IRewardCertificateModelStatusDescriptionEnum;
}

/**
* @export
* @enum {string}
*/
export enum IRewardCertificateModelStatusDescriptionEnum {
    Active = 'Active'
}

export function IRewardCertificateModelFromJSON(json: any): IRewardCertificateModel {
    return IRewardCertificateModelFromJSONTyped(json, false);
}

export function IRewardCertificateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardCertificateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'certificateNumber': !exists(json, 'certificateNumber') ? undefined : json['certificateNumber'],
        'priceInPoints': !exists(json, 'priceInPoints') ? undefined : json['priceInPoints'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : json['expirationDate'],
        'effectiveDate': !exists(json, 'effectiveDate') ? undefined : json['effectiveDate'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'rewardId': json['rewardId'],
        'rewardType': !exists(json, 'rewardType') ? undefined : json['rewardType'],
        'statusDescription': !exists(json, 'statusDescription') ? undefined : json['statusDescription'],
    };
}

export function IRewardCertificateModelToJSON(value?: IRewardCertificateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'certificateNumber': value.certificateNumber,
        'priceInPoints': value.priceInPoints,
        'expirationDate': value.expirationDate,
        'effectiveDate': value.effectiveDate,
        'title': value.title,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'currencyCode': value.currencyCode,
        'status': value.status,
        'rewardId': value.rewardId,
        'rewardType': value.rewardType,
        'statusDescription': value.statusDescription,
    };
}

