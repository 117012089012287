/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductsRecommendationResponseModel
 */
export interface ProductsRecommendationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ProductsRecommendationResponseModel
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsRecommendationResponseModel
     */
    productIds?: Array<string>;
}

export function ProductsRecommendationResponseModelFromJSON(json: any): ProductsRecommendationResponseModel {
    return ProductsRecommendationResponseModelFromJSONTyped(json, false);
}

export function ProductsRecommendationResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsRecommendationResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productIds': !exists(json, 'productIds') ? undefined : json['productIds'],
    };
}

export function ProductsRecommendationResponseModelToJSON(value?: ProductsRecommendationResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productIds': value.productIds,
    };
}

