/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRewardsDiscountOrderDSResponseFulfillmentModel
 */
export interface IRewardsDiscountOrderDSResponseFulfillmentModel {
    /**
     * 
     * @type {Date}
     * @memberof IRewardsDiscountOrderDSResponseFulfillmentModel
     */
    pickupDateAndTime: Date;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOrderDSResponseFulfillmentModel
     */
    locationId: string;
}

export function IRewardsDiscountOrderDSResponseFulfillmentModelFromJSON(json: any): IRewardsDiscountOrderDSResponseFulfillmentModel {
    return IRewardsDiscountOrderDSResponseFulfillmentModelFromJSONTyped(json, false);
}

export function IRewardsDiscountOrderDSResponseFulfillmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountOrderDSResponseFulfillmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pickupDateAndTime': (new Date(json['pickupDateAndTime'])),
        'locationId': json['locationId'],
    };
}

export function IRewardsDiscountOrderDSResponseFulfillmentModelToJSON(value?: IRewardsDiscountOrderDSResponseFulfillmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pickupDateAndTime': (value.pickupDateAndTime.toISOString()),
        'locationId': value.locationId,
    };
}

