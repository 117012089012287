/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductsRecommendationRequestModel
 */
export interface ProductsRecommendationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ProductsRecommendationRequestModel
     */
    customerType: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsRecommendationRequestModel
     */
    correlationId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsRecommendationRequestModel
     */
    locationId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsRecommendationRequestModel
     */
    productIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductsRecommendationRequestModel
     */
    section?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductsRecommendationRequestModel
     */
    numberOfRecommendations?: number;
}

export function ProductsRecommendationRequestModelFromJSON(json: any): ProductsRecommendationRequestModel {
    return ProductsRecommendationRequestModelFromJSONTyped(json, false);
}

export function ProductsRecommendationRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsRecommendationRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerType': json['customerType'],
        'correlationId': json['correlationId'],
        'locationId': json['locationId'],
        'productIds': !exists(json, 'productIds') ? undefined : json['productIds'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'numberOfRecommendations': !exists(json, 'numberOfRecommendations') ? undefined : json['numberOfRecommendations'],
    };
}

export function ProductsRecommendationRequestModelToJSON(value?: ProductsRecommendationRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerType': value.customerType,
        'correlationId': value.correlationId,
        'locationId': value.locationId,
        'productIds': value.productIds,
        'section': value.section,
        'numberOfRecommendations': value.numberOfRecommendations,
    };
}

