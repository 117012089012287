/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TallyFulfillmentTypeModel {
    PickUp = 'PickUp',
    Delivery = 'Delivery'
}

export function TallyFulfillmentTypeModelFromJSON(json: any): TallyFulfillmentTypeModel {
    return TallyFulfillmentTypeModelFromJSONTyped(json, false);
}

export function TallyFulfillmentTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyFulfillmentTypeModel {
    return json as TallyFulfillmentTypeModel;
}

export function TallyFulfillmentTypeModelToJSON(value?: TallyFulfillmentTypeModel | null): any {
    return value as any;
}

