/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyRequestModel,
    TallyRequestModelFromJSON,
    TallyRequestModelFromJSONTyped,
    TallyRequestModelToJSON,
} from './TallyRequestModel';

/**
 * 
 * @export
 * @interface IRewardsDiscountRequestModel
 */
export interface IRewardsDiscountRequestModel {
    /**
     * 
     * @type {TallyRequestModel}
     * @memberof IRewardsDiscountRequestModel
     */
    tallyRequest: TallyRequestModel;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountRequestModel
     */
    subTotal: string;
}

export function IRewardsDiscountRequestModelFromJSON(json: any): IRewardsDiscountRequestModel {
    return IRewardsDiscountRequestModelFromJSONTyped(json, false);
}

export function IRewardsDiscountRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tallyRequest': TallyRequestModelFromJSON(json['tallyRequest']),
        'subTotal': json['subTotal'],
    };
}

export function IRewardsDiscountRequestModelToJSON(value?: IRewardsDiscountRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tallyRequest': TallyRequestModelToJSON(value.tallyRequest),
        'subTotal': value.subTotal,
    };
}

