/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IWalletGiftCardModel
 */
export interface IWalletGiftCardModel {
    /**
     * 
     * @type {string}
     * @memberof IWalletGiftCardModel
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletGiftCardModel
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletGiftCardModel
     */
    pin?: string;
}

export function IWalletGiftCardModelFromJSON(json: any): IWalletGiftCardModel {
    return IWalletGiftCardModelFromJSONTyped(json, false);
}

export function IWalletGiftCardModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IWalletGiftCardModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
    };
}

export function IWalletGiftCardModelToJSON(value?: IWalletGiftCardModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nickname': value.nickname,
        'number': value.number,
        'pin': value.pin,
    };
}

