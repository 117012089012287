/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalTypeEnumModel,
    IntervalTypeEnumModelFromJSON,
    IntervalTypeEnumModelFromJSONTyped,
    IntervalTypeEnumModelToJSON,
} from './IntervalTypeEnumModel';

/**
 * 
 * @export
 * @interface IIntervalModel
 */
export interface IIntervalModel {
    /**
     * 
     * @type {string}
     * @memberof IIntervalModel
     */
    value?: string;
    /**
     * 
     * @type {IntervalTypeEnumModel}
     * @memberof IIntervalModel
     */
    intervalType?: IntervalTypeEnumModel;
    /**
     * 
     * @type {string}
     * @memberof IIntervalModel
     */
    dayOfTheWeek?: string;
}

export function IIntervalModelFromJSON(json: any): IIntervalModel {
    return IIntervalModelFromJSONTyped(json, false);
}

export function IIntervalModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IIntervalModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'intervalType': !exists(json, 'intervalType') ? undefined : IntervalTypeEnumModelFromJSON(json['intervalType']),
        'dayOfTheWeek': !exists(json, 'dayOfTheWeek') ? undefined : json['dayOfTheWeek'],
    };
}

export function IIntervalModelToJSON(value?: IIntervalModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'intervalType': IntervalTypeEnumModelToJSON(value.intervalType),
        'dayOfTheWeek': value.dayOfTheWeek,
    };
}

