/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ISlotServiceTypeModel {
    Pickup = 'PICKUP',
    Delivery = 'DELIVERY',
    CurbSide = 'CURB_SIDE',
    SvcDeposit = 'SVC_DEPOSIT',
    Dinein = 'DINEIN',
    DriveThru = 'DRIVE_THRU',
    Undefined = 'UNDEFINED',
    WalkIn = 'WALK_IN'
}

export function ISlotServiceTypeModelFromJSON(json: any): ISlotServiceTypeModel {
    return ISlotServiceTypeModelFromJSONTyped(json, false);
}

export function ISlotServiceTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ISlotServiceTypeModel {
    return json as ISlotServiceTypeModel;
}

export function ISlotServiceTypeModelToJSON(value?: ISlotServiceTypeModel | null): any {
    return value as any;
}

