/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountAppliedItemDSResponseModel,
    DiscountAppliedItemDSResponseModelFromJSON,
    DiscountAppliedItemDSResponseModelFromJSONTyped,
    DiscountAppliedItemDSResponseModelToJSON,
} from './DiscountAppliedItemDSResponseModel';
import {
    DiscountDetailsDSResponseAppliedModel,
    DiscountDetailsDSResponseAppliedModelFromJSON,
    DiscountDetailsDSResponseAppliedModelFromJSONTyped,
    DiscountDetailsDSResponseAppliedModelToJSON,
} from './DiscountDetailsDSResponseAppliedModel';

/**
 * 
 * @export
 * @interface DiscountDetailsDSResponseModel
 */
export interface DiscountDetailsDSResponseModel {
    /**
     * 
     * @type {number}
     * @memberof DiscountDetailsDSResponseModel
     */
    loyaltyId?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsDSResponseModel
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsDSResponseModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDetailsDSResponseModel
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDetailsDSResponseModel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDetailsDSResponseModel
     */
    amount?: number;
    /**
     * 
     * @type {Array<DiscountAppliedItemDSResponseModel>}
     * @memberof DiscountDetailsDSResponseModel
     */
    appliedItems?: Array<DiscountAppliedItemDSResponseModel>;
    /**
     * 
     * @type {DiscountDetailsDSResponseAppliedModel}
     * @memberof DiscountDetailsDSResponseModel
     */
    applied?: DiscountDetailsDSResponseAppliedModel;
}

export function DiscountDetailsDSResponseModelFromJSON(json: any): DiscountDetailsDSResponseModel {
    return DiscountDetailsDSResponseModelFromJSONTyped(json, false);
}

export function DiscountDetailsDSResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDetailsDSResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loyaltyId': !exists(json, 'loyaltyId') ? undefined : json['loyaltyId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'appliedItems': !exists(json, 'appliedItems') ? undefined : ((json['appliedItems'] as Array<any>).map(DiscountAppliedItemDSResponseModelFromJSON)),
        'applied': !exists(json, 'applied') ? undefined : DiscountDetailsDSResponseAppliedModelFromJSON(json['applied']),
    };
}

export function DiscountDetailsDSResponseModelToJSON(value?: DiscountDetailsDSResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loyaltyId': value.loyaltyId,
        'type': value.type,
        'code': value.code,
        'name': value.name,
        'id': value.id,
        'amount': value.amount,
        'appliedItems': value.appliedItems === undefined ? undefined : ((value.appliedItems as Array<any>).map(DiscountAppliedItemDSResponseModelToJSON)),
        'applied': DiscountDetailsDSResponseAppliedModelToJSON(value.applied),
    };
}

