/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DiscountDetailsTypeModel {
    OfferCode = 'OFFER_CODE',
    Certificate = 'CERTIFICATE',
    PromoCode = 'PROMO_CODE',
    AutoDiscount = 'AUTO_DISCOUNT',
    OfferReward = 'OFFER_REWARD',
    OnDemand = 'ON_DEMAND'
}

export function DiscountDetailsTypeModelFromJSON(json: any): DiscountDetailsTypeModel {
    return DiscountDetailsTypeModelFromJSONTyped(json, false);
}

export function DiscountDetailsTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDetailsTypeModel {
    return json as DiscountDetailsTypeModel;
}

export function DiscountDetailsTypeModelToJSON(value?: DiscountDetailsTypeModel | null): any {
    return value as any;
}

