/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactDetailDtoModelModel,
    ContactDetailDtoModelModelFromJSON,
    ContactDetailDtoModelModelFromJSONTyped,
    ContactDetailDtoModelModelToJSON,
} from './ContactDetailDtoModelModel';

/**
 * 
 * @export
 * @interface IDineInOrderDetailsLocationModel
 */
export interface IDineInOrderDetailsLocationModel {
    /**
     * 
     * @type {ContactDetailDtoModelModel}
     * @memberof IDineInOrderDetailsLocationModel
     */
    contactDetails?: ContactDetailDtoModelModel;
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsLocationModel
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsLocationModel
     */
    id?: string;
}

export function IDineInOrderDetailsLocationModelFromJSON(json: any): IDineInOrderDetailsLocationModel {
    return IDineInOrderDetailsLocationModelFromJSONTyped(json, false);
}

export function IDineInOrderDetailsLocationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDineInOrderDetailsLocationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactDetails': !exists(json, 'contactDetails') ? undefined : ContactDetailDtoModelModelFromJSON(json['contactDetails']),
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function IDineInOrderDetailsLocationModelToJSON(value?: IDineInOrderDetailsLocationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactDetails': ContactDetailDtoModelModelToJSON(value.contactDetails),
        'displayName': value.displayName,
        'id': value.id,
    };
}

