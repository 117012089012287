/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IWalletAccountModel,
    IWalletAccountModelFromJSON,
    IWalletAccountModelFromJSONTyped,
    IWalletAccountModelToJSON,
} from './IWalletAccountModel';
import {
    IWalletBillingAddressModel,
    IWalletBillingAddressModelFromJSON,
    IWalletBillingAddressModelFromJSONTyped,
    IWalletBillingAddressModelToJSON,
} from './IWalletBillingAddressModel';

/**
 * 
 * @export
 * @interface IAddEditWalletAcountResponseModel
 */
export interface IAddEditWalletAcountResponseModel {
    /**
     * 
     * @type {IWalletAccountModel}
     * @memberof IAddEditWalletAcountResponseModel
     */
    account: IWalletAccountModel;
    /**
     * 
     * @type {IWalletBillingAddressModel}
     * @memberof IAddEditWalletAcountResponseModel
     */
    billingAddress?: IWalletBillingAddressModel;
}

export function IAddEditWalletAcountResponseModelFromJSON(json: any): IAddEditWalletAcountResponseModel {
    return IAddEditWalletAcountResponseModelFromJSONTyped(json, false);
}

export function IAddEditWalletAcountResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAddEditWalletAcountResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': IWalletAccountModelFromJSON(json['account']),
        'billingAddress': !exists(json, 'billingAddress') ? undefined : IWalletBillingAddressModelFromJSON(json['billingAddress']),
    };
}

export function IAddEditWalletAcountResponseModelToJSON(value?: IAddEditWalletAcountResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': IWalletAccountModelToJSON(value.account),
        'billingAddress': IWalletBillingAddressModelToJSON(value.billingAddress),
    };
}

