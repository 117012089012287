/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDtoModelModel,
    AddressDtoModelModelFromJSON,
    AddressDtoModelModelFromJSONTyped,
    AddressDtoModelModelToJSON,
} from './AddressDtoModelModel';
import {
    ContactDetailDtoModelTypeEnumModel,
    ContactDetailDtoModelTypeEnumModelFromJSON,
    ContactDetailDtoModelTypeEnumModelFromJSONTyped,
    ContactDetailDtoModelTypeEnumModelToJSON,
} from './ContactDetailDtoModelTypeEnumModel';

/**
 * 
 * @export
 * @interface ContactDetailDtoModelModel
 */
export interface ContactDetailDtoModelModel {
    /**
     * 
     * @type {string}
     * @memberof ContactDetailDtoModelModel
     */
    id?: string;
    /**
     * 
     * @type {ContactDetailDtoModelTypeEnumModel}
     * @memberof ContactDetailDtoModelModel
     */
    type?: ContactDetailDtoModelTypeEnumModel;
    /**
     * 
     * @type {string}
     * @memberof ContactDetailDtoModelModel
     */
    description?: string;
    /**
     * 
     * @type {AddressDtoModelModel}
     * @memberof ContactDetailDtoModelModel
     */
    address?: AddressDtoModelModel;
    /**
     * 
     * @type {string}
     * @memberof ContactDetailDtoModelModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetailDtoModelModel
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetailDtoModelModel
     */
    fax?: string;
}

export function ContactDetailDtoModelModelFromJSON(json: any): ContactDetailDtoModelModel {
    return ContactDetailDtoModelModelFromJSONTyped(json, false);
}

export function ContactDetailDtoModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDetailDtoModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ContactDetailDtoModelTypeEnumModelFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : AddressDtoModelModelFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
    };
}

export function ContactDetailDtoModelModelToJSON(value?: ContactDetailDtoModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ContactDetailDtoModelTypeEnumModelToJSON(value.type),
        'description': value.description,
        'address': AddressDtoModelModelToJSON(value.address),
        'email': value.email,
        'phone': value.phone,
        'fax': value.fax,
    };
}

