/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NumericValueModel
 */
export interface NumericValueModel {
    /**
     * 
     * @type {number}
     * @memberof NumericValueModel
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof NumericValueModel
     */
    unit?: string;
}

export function NumericValueModelFromJSON(json: any): NumericValueModel {
    return NumericValueModelFromJSONTyped(json, false);
}

export function NumericValueModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumericValueModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function NumericValueModelToJSON(value?: NumericValueModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'unit': value.unit,
    };
}

