/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reward Category Code Description
 * @export
 * @enum {string}
 */
export enum TCatalogRewardCategoryCodeDescriptionModel {
    Shareables = 'Shareables',
    Wings = 'Wings',
    Mains = 'Mains',
    Desserts = 'Desserts',
    SidesAndSauces = 'Sides and Sauces',
    KidsAndLunches = 'Kids and Lunches',
    Drinks = 'Drinks'
}

export function TCatalogRewardCategoryCodeDescriptionModelFromJSON(json: any): TCatalogRewardCategoryCodeDescriptionModel {
    return TCatalogRewardCategoryCodeDescriptionModelFromJSONTyped(json, false);
}

export function TCatalogRewardCategoryCodeDescriptionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCatalogRewardCategoryCodeDescriptionModel {
    return json as TCatalogRewardCategoryCodeDescriptionModel;
}

export function TCatalogRewardCategoryCodeDescriptionModelToJSON(value?: TCatalogRewardCategoryCodeDescriptionModel | null): any {
    return value as any;
}

