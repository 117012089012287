/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderPaymentModel,
    OrderPaymentModelFromJSON,
    OrderPaymentModelFromJSONTyped,
    OrderPaymentModelToJSON,
} from './OrderPaymentModel';
import {
    OrderV2RequestFulfillmentModel,
    OrderV2RequestFulfillmentModelFromJSON,
    OrderV2RequestFulfillmentModelFromJSONTyped,
    OrderV2RequestFulfillmentModelToJSON,
} from './OrderV2RequestFulfillmentModel';
import {
    OrderV2RequestTipsModel,
    OrderV2RequestTipsModelFromJSON,
    OrderV2RequestTipsModelFromJSONTyped,
    OrderV2RequestTipsModelToJSON,
} from './OrderV2RequestTipsModel';
import {
    TallyResponseModel,
    TallyResponseModelFromJSON,
    TallyResponseModelFromJSONTyped,
    TallyResponseModelToJSON,
} from './TallyResponseModel';

/**
 * 
 * @export
 * @interface OrderV2RequestModel
 */
export interface OrderV2RequestModel {
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestModel
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestModel
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestModel
     */
    cartId?: string;
    /**
     * 
     * @type {OrderV2RequestFulfillmentModel}
     * @memberof OrderV2RequestModel
     */
    fulfillment: OrderV2RequestFulfillmentModel;
    /**
     * 
     * @type {OrderV2RequestTipsModel}
     * @memberof OrderV2RequestModel
     */
    tips?: OrderV2RequestTipsModel;
    /**
     * 
     * @type {Array<OrderPaymentModel>}
     * @memberof OrderV2RequestModel
     */
    payments: Array<OrderPaymentModel>;
    /**
     * 
     * @type {TallyResponseModel}
     * @memberof OrderV2RequestModel
     */
    tallyResponse: TallyResponseModel;
}

export function OrderV2RequestModelFromJSON(json: any): OrderV2RequestModel {
    return OrderV2RequestModelFromJSONTyped(json, false);
}

export function OrderV2RequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderV2RequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'cartId': !exists(json, 'cartId') ? undefined : json['cartId'],
        'fulfillment': OrderV2RequestFulfillmentModelFromJSON(json['fulfillment']),
        'tips': !exists(json, 'tips') ? undefined : OrderV2RequestTipsModelFromJSON(json['tips']),
        'payments': ((json['payments'] as Array<any>).map(OrderPaymentModelFromJSON)),
        'tallyResponse': TallyResponseModelFromJSON(json['tallyResponse']),
    };
}

export function OrderV2RequestModelToJSON(value?: OrderV2RequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'customerId': value.customerId,
        'cartId': value.cartId,
        'fulfillment': OrderV2RequestFulfillmentModelToJSON(value.fulfillment),
        'tips': OrderV2RequestTipsModelToJSON(value.tips),
        'payments': ((value.payments as Array<any>).map(OrderPaymentModelToJSON)),
        'tallyResponse': TallyResponseModelToJSON(value.tallyResponse),
    };
}

