/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorTypeGENERICModel {
    Generic = 'GENERIC'
}

export function TErrorTypeGENERICModelFromJSON(json: any): TErrorTypeGENERICModel {
    return TErrorTypeGENERICModelFromJSONTyped(json, false);
}

export function TErrorTypeGENERICModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorTypeGENERICModel {
    return json as TErrorTypeGENERICModel;
}

export function TErrorTypeGENERICModelToJSON(value?: TErrorTypeGENERICModel | null): any {
    return value as any;
}

