/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDtoModelModel
 */
export interface AddressDtoModelModel {
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    line3?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    stateProvinceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDtoModelModel
     */
    referencePoint?: string;
}

export function AddressDtoModelModelFromJSON(json: any): AddressDtoModelModel {
    return AddressDtoModelModelFromJSONTyped(json, false);
}

export function AddressDtoModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDtoModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalCode': json['postalCode'],
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'line3': !exists(json, 'line3') ? undefined : json['line3'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'stateProvinceCode': !exists(json, 'stateProvinceCode') ? undefined : json['stateProvinceCode'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'referencePoint': !exists(json, 'referencePoint') ? undefined : json['referencePoint'],
    };
}

export function AddressDtoModelModelToJSON(value?: AddressDtoModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postalCode': value.postalCode,
        'line1': value.line1,
        'line2': value.line2,
        'line3': value.line3,
        'cityName': value.cityName,
        'stateProvinceCode': value.stateProvinceCode,
        'countryName': value.countryName,
        'countryCode': value.countryCode,
        'referencePoint': value.referencePoint,
    };
}

