/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VisitLocationStatusTypeModel {
    Unchecked = 'UNCHECKED',
    CheckedIn = 'CHECKED_IN',
    CheckedOut = 'CHECKED_OUT',
    DeliveringOrder = 'DELIVERING_ORDER',
    PickingupUp = 'PICKINGUP_UP'
}

export function VisitLocationStatusTypeModelFromJSON(json: any): VisitLocationStatusTypeModel {
    return VisitLocationStatusTypeModelFromJSONTyped(json, false);
}

export function VisitLocationStatusTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitLocationStatusTypeModel {
    return json as VisitLocationStatusTypeModel;
}

export function VisitLocationStatusTypeModelToJSON(value?: VisitLocationStatusTypeModel | null): any {
    return value as any;
}

