/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TallyDSModifierMetadataModel
 */
export interface TallyDSModifierMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof TallyDSModifierMetadataModel
     */
    sOURCEPRODUCTID?: string;
}

export function TallyDSModifierMetadataModelFromJSON(json: any): TallyDSModifierMetadataModel {
    return TallyDSModifierMetadataModelFromJSONTyped(json, false);
}

export function TallyDSModifierMetadataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyDSModifierMetadataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sOURCEPRODUCTID': !exists(json, 'SOURCE_PRODUCT_ID') ? undefined : json['SOURCE_PRODUCT_ID'],
    };
}

export function TallyDSModifierMetadataModelToJSON(value?: TallyDSModifierMetadataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SOURCE_PRODUCT_ID': value.sOURCEPRODUCTID,
    };
}

