/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IOneClickRegisterOfferDescriptionModel,
    IOneClickRegisterOfferDescriptionModelFromJSON,
    IOneClickRegisterOfferDescriptionModelFromJSONTyped,
    IOneClickRegisterOfferDescriptionModelToJSON,
} from './IOneClickRegisterOfferDescriptionModel';
import {
    IOneClickRegisterOfferImageModel,
    IOneClickRegisterOfferImageModelFromJSON,
    IOneClickRegisterOfferImageModelFromJSONTyped,
    IOneClickRegisterOfferImageModelToJSON,
} from './IOneClickRegisterOfferImageModel';
import {
    IOneClickRegisterOfferRegistrationModel,
    IOneClickRegisterOfferRegistrationModelFromJSON,
    IOneClickRegisterOfferRegistrationModelFromJSONTyped,
    IOneClickRegisterOfferRegistrationModelToJSON,
} from './IOneClickRegisterOfferRegistrationModel';
import {
    IOneClickRegisterOfferTermsAndConditionsModel,
    IOneClickRegisterOfferTermsAndConditionsModelFromJSON,
    IOneClickRegisterOfferTermsAndConditionsModelFromJSONTyped,
    IOneClickRegisterOfferTermsAndConditionsModelToJSON,
} from './IOneClickRegisterOfferTermsAndConditionsModel';

/**
 * 
 * @export
 * @interface IOneClickRegisterOfferResponseModel
 */
export interface IOneClickRegisterOfferResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    sysOfferId: string;
    /**
     * 
     * @type {IOneClickRegisterOfferDescriptionModel}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    description: IOneClickRegisterOfferDescriptionModel;
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    optInDate: string;
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    shortDescription: string;
    /**
     * 
     * @type {number}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    daysValid: number;
    /**
     * 
     * @type {boolean}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    isOptInRequired: boolean;
    /**
     * 
     * @type {IOneClickRegisterOfferTermsAndConditionsModel}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    termsAndConditions: IOneClickRegisterOfferTermsAndConditionsModel;
    /**
     * 
     * @type {IOneClickRegisterOfferImageModel}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    image: IOneClickRegisterOfferImageModel;
    /**
     * 
     * @type {IOneClickRegisterOfferRegistrationModel}
     * @memberof IOneClickRegisterOfferResponseModel
     */
    registration: IOneClickRegisterOfferRegistrationModel;
}

export function IOneClickRegisterOfferResponseModelFromJSON(json: any): IOneClickRegisterOfferResponseModel {
    return IOneClickRegisterOfferResponseModelFromJSONTyped(json, false);
}

export function IOneClickRegisterOfferResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOneClickRegisterOfferResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sysOfferId': json['sysOfferId'],
        'description': IOneClickRegisterOfferDescriptionModelFromJSON(json['description']),
        'startDate': json['startDate'],
        'endDate': json['endDate'],
        'optInDate': json['optInDate'],
        'code': json['code'],
        'name': json['name'],
        'shortDescription': json['shortDescription'],
        'daysValid': json['daysValid'],
        'isOptInRequired': json['isOptInRequired'],
        'termsAndConditions': IOneClickRegisterOfferTermsAndConditionsModelFromJSON(json['termsAndConditions']),
        'image': IOneClickRegisterOfferImageModelFromJSON(json['image']),
        'registration': IOneClickRegisterOfferRegistrationModelFromJSON(json['registration']),
    };
}

export function IOneClickRegisterOfferResponseModelToJSON(value?: IOneClickRegisterOfferResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sysOfferId': value.sysOfferId,
        'description': IOneClickRegisterOfferDescriptionModelToJSON(value.description),
        'startDate': value.startDate,
        'endDate': value.endDate,
        'optInDate': value.optInDate,
        'code': value.code,
        'name': value.name,
        'shortDescription': value.shortDescription,
        'daysValid': value.daysValid,
        'isOptInRequired': value.isOptInRequired,
        'termsAndConditions': IOneClickRegisterOfferTermsAndConditionsModelToJSON(value.termsAndConditions),
        'image': IOneClickRegisterOfferImageModelToJSON(value.image),
        'registration': IOneClickRegisterOfferRegistrationModelToJSON(value.registration),
    };
}

