/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRewardsDiscountOrderDSResponseDiscountNationalOfferModel
 */
export interface IRewardsDiscountOrderDSResponseDiscountNationalOfferModel {
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOrderDSResponseDiscountNationalOfferModel
     */
    discountNumber?: string;
}

export function IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSON(json: any): IRewardsDiscountOrderDSResponseDiscountNationalOfferModel {
    return IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSONTyped(json, false);
}

export function IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountOrderDSResponseDiscountNationalOfferModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountNumber': !exists(json, 'discountNumber') ? undefined : json['discountNumber'],
    };
}

export function IRewardsDiscountOrderDSResponseDiscountNationalOfferModelToJSON(value?: IRewardsDiscountOrderDSResponseDiscountNationalOfferModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountNumber': value.discountNumber,
    };
}

