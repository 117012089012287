/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardsDiscountOrderDSResponseDiscountNationalOfferModel,
    IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSON,
    IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSONTyped,
    IRewardsDiscountOrderDSResponseDiscountNationalOfferModelToJSON,
} from './IRewardsDiscountOrderDSResponseDiscountNationalOfferModel';

/**
 * 
 * @export
 * @interface IRewardsDiscountOrderDSResponseDiscountModel
 */
export interface IRewardsDiscountOrderDSResponseDiscountModel {
    /**
     * 
     * @type {IRewardsDiscountOrderDSResponseDiscountNationalOfferModel}
     * @memberof IRewardsDiscountOrderDSResponseDiscountModel
     */
    nationalOffer?: IRewardsDiscountOrderDSResponseDiscountNationalOfferModel;
    /**
     * 
     * @type {IRewardsDiscountOrderDSResponseDiscountNationalOfferModel}
     * @memberof IRewardsDiscountOrderDSResponseDiscountModel
     */
    offer?: IRewardsDiscountOrderDSResponseDiscountNationalOfferModel;
    /**
     * 
     * @type {IRewardsDiscountOrderDSResponseDiscountNationalOfferModel}
     * @memberof IRewardsDiscountOrderDSResponseDiscountModel
     */
    loyalty?: IRewardsDiscountOrderDSResponseDiscountNationalOfferModel;
}

export function IRewardsDiscountOrderDSResponseDiscountModelFromJSON(json: any): IRewardsDiscountOrderDSResponseDiscountModel {
    return IRewardsDiscountOrderDSResponseDiscountModelFromJSONTyped(json, false);
}

export function IRewardsDiscountOrderDSResponseDiscountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountOrderDSResponseDiscountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nationalOffer': !exists(json, 'nationalOffer') ? undefined : IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSON(json['nationalOffer']),
        'offer': !exists(json, 'offer') ? undefined : IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSON(json['offer']),
        'loyalty': !exists(json, 'loyalty') ? undefined : IRewardsDiscountOrderDSResponseDiscountNationalOfferModelFromJSON(json['loyalty']),
    };
}

export function IRewardsDiscountOrderDSResponseDiscountModelToJSON(value?: IRewardsDiscountOrderDSResponseDiscountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nationalOffer': IRewardsDiscountOrderDSResponseDiscountNationalOfferModelToJSON(value.nationalOffer),
        'offer': IRewardsDiscountOrderDSResponseDiscountNationalOfferModelToJSON(value.offer),
        'loyalty': IRewardsDiscountOrderDSResponseDiscountNationalOfferModelToJSON(value.loyalty),
    };
}

