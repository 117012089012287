/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountRequestModel,
    DiscountRequestModelFromJSON,
    DiscountRequestModelFromJSONTyped,
    DiscountRequestModelToJSON,
} from './DiscountRequestModel';
import {
    TallyProductModel,
    TallyProductModelFromJSON,
    TallyProductModelFromJSONTyped,
    TallyProductModelToJSON,
} from './TallyProductModel';
import {
    TallyRequestDeliveryAddressModel,
    TallyRequestDeliveryAddressModelFromJSON,
    TallyRequestDeliveryAddressModelFromJSONTyped,
    TallyRequestDeliveryAddressModelToJSON,
} from './TallyRequestDeliveryAddressModel';

/**
 * 
 * @export
 * @interface TallyRequestModel
 */
export interface TallyRequestModel {
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    menuType?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    fulfillmentType: string;
    /**
     * 
     * @type {boolean}
     * @memberof TallyRequestModel
     */
    isAsap: boolean;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof TallyRequestModel
     */
    requestedDateTime?: Date;
    /**
     * 
     * @type {Array<TallyProductModel>}
     * @memberof TallyRequestModel
     */
    products: Array<TallyProductModel>;
    /**
     * 
     * @type {DiscountRequestModel}
     * @memberof TallyRequestModel
     */
    discounts?: DiscountRequestModel;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    cartId?: string;
    /**
     * Stringified UUID.
     * @type {string}
     * @memberof TallyRequestModel
     */
    ssCorrelationId?: string;
    /**
     * 
     * @type {number}
     * @memberof TallyRequestModel
     */
    driverTip?: number;
    /**
     * 
     * @type {number}
     * @memberof TallyRequestModel
     */
    serverTip?: number;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyRequestModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TallyRequestModel
     */
    instructions?: Array<string>;
    /**
     * 
     * @type {TallyRequestDeliveryAddressModel}
     * @memberof TallyRequestModel
     */
    deliveryAddress?: TallyRequestDeliveryAddressModel;
}

export function TallyRequestModelFromJSON(json: any): TallyRequestModel {
    return TallyRequestModelFromJSONTyped(json, false);
}

export function TallyRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'menuType': !exists(json, 'menuType') ? undefined : json['menuType'],
        'fulfillmentType': json['fulfillmentType'],
        'isAsap': json['isAsap'],
        'requestedDateTime': !exists(json, 'requestedDateTime') ? undefined : (new Date(json['requestedDateTime'])),
        'products': ((json['products'] as Array<any>).map(TallyProductModelFromJSON)),
        'discounts': !exists(json, 'discounts') ? undefined : DiscountRequestModelFromJSON(json['discounts']),
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'cartId': !exists(json, 'cartId') ? undefined : json['cartId'],
        'ssCorrelationId': !exists(json, 'ssCorrelationId') ? undefined : json['ssCorrelationId'],
        'driverTip': !exists(json, 'driverTip') ? undefined : json['driverTip'],
        'serverTip': !exists(json, 'serverTip') ? undefined : json['serverTip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : TallyRequestDeliveryAddressModelFromJSON(json['deliveryAddress']),
    };
}

export function TallyRequestModelToJSON(value?: TallyRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'menuType': value.menuType,
        'fulfillmentType': value.fulfillmentType,
        'isAsap': value.isAsap,
        'requestedDateTime': value.requestedDateTime === undefined ? undefined : (value.requestedDateTime.toISOString()),
        'products': ((value.products as Array<any>).map(TallyProductModelToJSON)),
        'discounts': DiscountRequestModelToJSON(value.discounts),
        'customerId': value.customerId,
        'cartId': value.cartId,
        'ssCorrelationId': value.ssCorrelationId,
        'driverTip': value.driverTip,
        'serverTip': value.serverTip,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'instructions': value.instructions,
        'deliveryAddress': TallyRequestDeliveryAddressModelToJSON(value.deliveryAddress),
    };
}

