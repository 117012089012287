/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICertificateModel,
    ICertificateModelFromJSON,
    ICertificateModelFromJSONTyped,
    ICertificateModelToJSON,
} from './ICertificateModel';
import {
    TOffersUnionModel,
    TOffersUnionModelFromJSON,
    TOffersUnionModelFromJSONTyped,
    TOffersUnionModelToJSON,
} from './TOffersUnionModel';

/**
 * 
 * @export
 * @interface IGetCustomerAccountRewardsResponseModel
 */
export interface IGetCustomerAccountRewardsResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IGetCustomerAccountRewardsResponseModel
     */
    totalCount: number;
    /**
     * 
     * @type {Array<TOffersUnionModel>}
     * @memberof IGetCustomerAccountRewardsResponseModel
     */
    offers?: Array<TOffersUnionModel>;
    /**
     * 
     * @type {Array<ICertificateModel>}
     * @memberof IGetCustomerAccountRewardsResponseModel
     */
    certificates?: Array<ICertificateModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGetCustomerAccountRewardsResponseModel
     */
    locationsWithOffers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGetCustomerAccountRewardsResponseModel
     */
    locationsWithoutOffers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IGetCustomerAccountRewardsResponseModel
     */
    allLocationsHaveOffers?: boolean;
}

export function IGetCustomerAccountRewardsResponseModelFromJSON(json: any): IGetCustomerAccountRewardsResponseModel {
    return IGetCustomerAccountRewardsResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerAccountRewardsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerAccountRewardsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'offers': !exists(json, 'offers') ? undefined : ((json['offers'] as Array<any>).map(TOffersUnionModelFromJSON)),
        'certificates': !exists(json, 'certificates') ? undefined : ((json['certificates'] as Array<any>).map(ICertificateModelFromJSON)),
        'locationsWithOffers': !exists(json, 'locationsWithOffers') ? undefined : json['locationsWithOffers'],
        'locationsWithoutOffers': !exists(json, 'locationsWithoutOffers') ? undefined : json['locationsWithoutOffers'],
        'allLocationsHaveOffers': !exists(json, 'allLocationsHaveOffers') ? undefined : json['allLocationsHaveOffers'],
    };
}

export function IGetCustomerAccountRewardsResponseModelToJSON(value?: IGetCustomerAccountRewardsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'offers': value.offers === undefined ? undefined : ((value.offers as Array<any>).map(TOffersUnionModelToJSON)),
        'certificates': value.certificates === undefined ? undefined : ((value.certificates as Array<any>).map(ICertificateModelToJSON)),
        'locationsWithOffers': value.locationsWithOffers,
        'locationsWithoutOffers': value.locationsWithoutOffers,
        'allLocationsHaveOffers': value.allLocationsHaveOffers,
    };
}

