/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IVisitRegisterResponseOrderModel
 */
export interface IVisitRegisterResponseOrderModel {
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterResponseOrderModel
     */
    status: string;
}

export function IVisitRegisterResponseOrderModelFromJSON(json: any): IVisitRegisterResponseOrderModel {
    return IVisitRegisterResponseOrderModelFromJSONTyped(json, false);
}

export function IVisitRegisterResponseOrderModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterResponseOrderModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function IVisitRegisterResponseOrderModelToJSON(value?: IVisitRegisterResponseOrderModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

