/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ITermsAndConditionsModel
 */
export interface ITermsAndConditionsModel {
    /**
     * 
     * @type {string}
     * @memberof ITermsAndConditionsModel
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ITermsAndConditionsModel
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITermsAndConditionsModel
     */
    isAccepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITermsAndConditionsModel
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof ITermsAndConditionsModel
     */
    acceptedTime: string;
}

export function ITermsAndConditionsModelFromJSON(json: any): ITermsAndConditionsModel {
    return ITermsAndConditionsModelFromJSONTyped(json, false);
}

export function ITermsAndConditionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ITermsAndConditionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'version': json['version'],
        'isAccepted': json['isAccepted'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'acceptedTime': json['acceptedTime'],
    };
}

export function ITermsAndConditionsModelToJSON(value?: ITermsAndConditionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'version': value.version,
        'isAccepted': value.isAccepted,
        'link': value.link,
        'acceptedTime': value.acceptedTime,
    };
}

