/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ILocationDetailsModel
 */
export interface ILocationDetailsModel {
    /**
     * 
     * @type {number}
     * @memberof ILocationDetailsModel
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocationDetailsModel
     */
    longitude?: number;
}

export function ILocationDetailsModelFromJSON(json: any): ILocationDetailsModel {
    return ILocationDetailsModelFromJSONTyped(json, false);
}

export function ILocationDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function ILocationDetailsModelToJSON(value?: ILocationDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

