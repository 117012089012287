/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyFulfillmentTypeModel,
    TallyFulfillmentTypeModelFromJSON,
    TallyFulfillmentTypeModelFromJSONTyped,
    TallyFulfillmentTypeModelToJSON,
} from './TallyFulfillmentTypeModel';

/**
 * 
 * @export
 * @interface TallyResponseFulfillmentModel
 */
export interface TallyResponseFulfillmentModel {
    /**
     * 
     * @type {boolean}
     * @memberof TallyResponseFulfillmentModel
     */
    hasTimeChanged: boolean;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof TallyResponseFulfillmentModel
     */
    time?: Date;
    /**
     * 
     * @type {TallyFulfillmentTypeModel}
     * @memberof TallyResponseFulfillmentModel
     */
    type: TallyFulfillmentTypeModel;
}

export function TallyResponseFulfillmentModelFromJSON(json: any): TallyResponseFulfillmentModel {
    return TallyResponseFulfillmentModelFromJSONTyped(json, false);
}

export function TallyResponseFulfillmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyResponseFulfillmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasTimeChanged': json['hasTimeChanged'],
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'type': TallyFulfillmentTypeModelFromJSON(json['type']),
    };
}

export function TallyResponseFulfillmentModelToJSON(value?: TallyResponseFulfillmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasTimeChanged': value.hasTimeChanged,
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'type': TallyFulfillmentTypeModelToJSON(value.type),
    };
}

