/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ItemModifierModel,
    ItemModifierModelFromJSON,
    ItemModifierModelFromJSONTyped,
    ItemModifierModelToJSON,
} from './ItemModifierModel';

/**
 * 
 * @export
 * @interface ItemModifierGroupModel
 */
export interface ItemModifierGroupModel {
    /**
     * 
     * @type {string}
     * @memberof ItemModifierGroupModel
     */
    productGroupId?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ItemModifierGroupModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ItemModifierGroupModel
     */
    sequence?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierGroupModel
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierGroupModel
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemModifierGroupModel
     */
    freeQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemModifierGroupModel
     */
    defaultItemModifierId?: string;
    /**
     * 
     * @type {{ [key: string]: ItemModifierModel; }}
     * @memberof ItemModifierGroupModel
     */
    itemModifiers?: { [key: string]: ItemModifierModel; };
}

export function ItemModifierGroupModelFromJSON(json: any): ItemModifierGroupModel {
    return ItemModifierGroupModelFromJSONTyped(json, false);
}

export function ItemModifierGroupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemModifierGroupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productGroupId': !exists(json, 'productGroupId') ? undefined : json['productGroupId'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'freeQuantity': !exists(json, 'freeQuantity') ? undefined : json['freeQuantity'],
        'defaultItemModifierId': !exists(json, 'defaultItemModifierId') ? undefined : json['defaultItemModifierId'],
        'itemModifiers': !exists(json, 'itemModifiers') ? undefined : (mapValues(json['itemModifiers'], ItemModifierModelFromJSON)),
    };
}

export function ItemModifierGroupModelToJSON(value?: ItemModifierGroupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productGroupId': value.productGroupId,
        'metadata': value.metadata,
        'sequence': value.sequence,
        'max': value.max,
        'min': value.min,
        'freeQuantity': value.freeQuantity,
        'defaultItemModifierId': value.defaultItemModifierId,
        'itemModifiers': value.itemModifiers === undefined ? undefined : (mapValues(value.itemModifiers, ItemModifierModelToJSON)),
    };
}

