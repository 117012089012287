/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reward Type
 * @export
 * @enum {string}
 */
export enum TCatalogRewardTypeModel {
    OnDemand = 'ON_DEMAND'
}

export function TCatalogRewardTypeModelFromJSON(json: any): TCatalogRewardTypeModel {
    return TCatalogRewardTypeModelFromJSONTyped(json, false);
}

export function TCatalogRewardTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCatalogRewardTypeModel {
    return json as TCatalogRewardTypeModel;
}

export function TCatalogRewardTypeModelToJSON(value?: TCatalogRewardTypeModel | null): any {
    return value as any;
}

