/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WalletAccountTypesModel,
    WalletAccountTypesModelFromJSON,
    WalletAccountTypesModelFromJSONTyped,
    WalletAccountTypesModelToJSON,
} from './WalletAccountTypesModel';

/**
 * DELETE CREDIT|GIFT CARD
 * @export
 * @interface IDeleteCardRequestModel
 */
export interface IDeleteCardRequestModel {
    /**
     * 
     * @type {string}
     * @memberof IDeleteCardRequestModel
     */
    token: string;
    /**
     * 
     * @type {WalletAccountTypesModel}
     * @memberof IDeleteCardRequestModel
     */
    accountType?: WalletAccountTypesModel;
}

export function IDeleteCardRequestModelFromJSON(json: any): IDeleteCardRequestModel {
    return IDeleteCardRequestModelFromJSONTyped(json, false);
}

export function IDeleteCardRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDeleteCardRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'accountType': !exists(json, 'accountType') ? undefined : WalletAccountTypesModelFromJSON(json['accountType']),
    };
}

export function IDeleteCardRequestModelToJSON(value?: IDeleteCardRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'accountType': WalletAccountTypesModelToJSON(value.accountType),
    };
}

