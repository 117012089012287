/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IOrderHistoryItemModifierGroupItemModifierModel,
    IOrderHistoryItemModifierGroupItemModifierModelFromJSON,
    IOrderHistoryItemModifierGroupItemModifierModelFromJSONTyped,
    IOrderHistoryItemModifierGroupItemModifierModelToJSON,
} from './IOrderHistoryItemModifierGroupItemModifierModel';

/**
 * 
 * @export
 * @interface IOrderHistoryItemModifierGroupItemModel
 */
export interface IOrderHistoryItemModifierGroupItemModel {
    /**
     * 
     * @type {string}
     * @memberof IOrderHistoryItemModifierGroupItemModel
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderHistoryItemModifierGroupItemModel
     */
    description?: string;
    /**
     * 
     * @type {Array<IOrderHistoryItemModifierGroupItemModifierModel>}
     * @memberof IOrderHistoryItemModifierGroupItemModel
     */
    modifiers?: Array<IOrderHistoryItemModifierGroupItemModifierModel>;
}

export function IOrderHistoryItemModifierGroupItemModelFromJSON(json: any): IOrderHistoryItemModifierGroupItemModel {
    return IOrderHistoryItemModifierGroupItemModelFromJSONTyped(json, false);
}

export function IOrderHistoryItemModifierGroupItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderHistoryItemModifierGroupItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'modifiers': !exists(json, 'modifiers') ? undefined : ((json['modifiers'] as Array<any>).map(IOrderHistoryItemModifierGroupItemModifierModelFromJSON)),
    };
}

export function IOrderHistoryItemModifierGroupItemModelToJSON(value?: IOrderHistoryItemModifierGroupItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'description': value.description,
        'modifiers': value.modifiers === undefined ? undefined : ((value.modifiers as Array<any>).map(IOrderHistoryItemModifierGroupItemModifierModelToJSON)),
    };
}

