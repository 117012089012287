/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailabilityModel,
    AvailabilityModelFromJSON,
    AvailabilityModelFromJSONTyped,
    AvailabilityModelToJSON,
} from './AvailabilityModel';

/**
 * 
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CategoryModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof CategoryModel
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    parentCategoryId?: string;
    /**
     * 
     * @type {AvailabilityModel}
     * @memberof CategoryModel
     */
    availability?: AvailabilityModel;
}

export function CategoryModelFromJSON(json: any): CategoryModel {
    return CategoryModelFromJSONTyped(json, false);
}

export function CategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'parentCategoryId': !exists(json, 'parentCategoryId') ? undefined : json['parentCategoryId'],
        'availability': !exists(json, 'availability') ? undefined : AvailabilityModelFromJSON(json['availability']),
    };
}

export function CategoryModelToJSON(value?: CategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'metadata': value.metadata,
        'sequence': value.sequence,
        'parentCategoryId': value.parentCategoryId,
        'availability': AvailabilityModelToJSON(value.availability),
    };
}

