/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderV2RequestTipsModel
 */
export interface OrderV2RequestTipsModel {
    /**
     * 
     * @type {number}
     * @memberof OrderV2RequestTipsModel
     */
    serverTip?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2RequestTipsModel
     */
    driverTip?: number;
}

export function OrderV2RequestTipsModelFromJSON(json: any): OrderV2RequestTipsModel {
    return OrderV2RequestTipsModelFromJSONTyped(json, false);
}

export function OrderV2RequestTipsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderV2RequestTipsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serverTip': !exists(json, 'serverTip') ? undefined : json['serverTip'],
        'driverTip': !exists(json, 'driverTip') ? undefined : json['driverTip'],
    };
}

export function OrderV2RequestTipsModelToJSON(value?: OrderV2RequestTipsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serverTip': value.serverTip,
        'driverTip': value.driverTip,
    };
}

