/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardsDiscountDSPriceTypeEnumModel,
    IRewardsDiscountDSPriceTypeEnumModelFromJSON,
    IRewardsDiscountDSPriceTypeEnumModelFromJSONTyped,
    IRewardsDiscountDSPriceTypeEnumModelToJSON,
} from './IRewardsDiscountDSPriceTypeEnumModel';
import {
    IRewardsDiscountItemDSResponseModifierGroupsModel,
    IRewardsDiscountItemDSResponseModifierGroupsModelFromJSON,
    IRewardsDiscountItemDSResponseModifierGroupsModelFromJSONTyped,
    IRewardsDiscountItemDSResponseModifierGroupsModelToJSON,
} from './IRewardsDiscountItemDSResponseModifierGroupsModel';
import {
    IRewardsDiscountOfferItemDSResponseModel,
    IRewardsDiscountOfferItemDSResponseModelFromJSON,
    IRewardsDiscountOfferItemDSResponseModelFromJSONTyped,
    IRewardsDiscountOfferItemDSResponseModelToJSON,
} from './IRewardsDiscountOfferItemDSResponseModel';

/**
 * 
 * @export
 * @interface IRewardsDiscountChildItemDSResponseModel
 */
export interface IRewardsDiscountChildItemDSResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    lineItemId: number;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    posProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    menuId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    description?: string;
    /**
     * 
     * @type {IRewardsDiscountDSPriceTypeEnumModel}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    priceType?: IRewardsDiscountDSPriceTypeEnumModel;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    price: string;
    /**
     * 
     * @type {number}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    note?: string;
    /**
     * 
     * @type {Array<IRewardsDiscountItemDSResponseModifierGroupsModel>}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    modifierGroups?: Array<IRewardsDiscountItemDSResponseModifierGroupsModel>;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    discountedPrice?: string;
    /**
     * 
     * @type {number}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    discountableQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    isDiscountedByLoyalty?: boolean;
    /**
     * 
     * @type {Array<IRewardsDiscountOfferItemDSResponseModel>}
     * @memberof IRewardsDiscountChildItemDSResponseModel
     */
    offers?: Array<IRewardsDiscountOfferItemDSResponseModel>;
}

export function IRewardsDiscountChildItemDSResponseModelFromJSON(json: any): IRewardsDiscountChildItemDSResponseModel {
    return IRewardsDiscountChildItemDSResponseModelFromJSONTyped(json, false);
}

export function IRewardsDiscountChildItemDSResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountChildItemDSResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': json['lineItemId'],
        'posProductId': !exists(json, 'posProductId') ? undefined : json['posProductId'],
        'menuId': !exists(json, 'menuId') ? undefined : json['menuId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'priceType': !exists(json, 'priceType') ? undefined : IRewardsDiscountDSPriceTypeEnumModelFromJSON(json['priceType']),
        'productId': json['productId'],
        'price': json['price'],
        'quantity': json['quantity'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(IRewardsDiscountItemDSResponseModifierGroupsModelFromJSON)),
        'discountedPrice': !exists(json, 'discountedPrice') ? undefined : json['discountedPrice'],
        'discountableQuantity': !exists(json, 'discountableQuantity') ? undefined : json['discountableQuantity'],
        'isDiscountedByLoyalty': !exists(json, 'isDiscountedByLoyalty') ? undefined : json['isDiscountedByLoyalty'],
        'offers': !exists(json, 'offers') ? undefined : ((json['offers'] as Array<any>).map(IRewardsDiscountOfferItemDSResponseModelFromJSON)),
    };
}

export function IRewardsDiscountChildItemDSResponseModelToJSON(value?: IRewardsDiscountChildItemDSResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'posProductId': value.posProductId,
        'menuId': value.menuId,
        'description': value.description,
        'priceType': IRewardsDiscountDSPriceTypeEnumModelToJSON(value.priceType),
        'productId': value.productId,
        'price': value.price,
        'quantity': value.quantity,
        'note': value.note,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(IRewardsDiscountItemDSResponseModifierGroupsModelToJSON)),
        'discountedPrice': value.discountedPrice,
        'discountableQuantity': value.discountableQuantity,
        'isDiscountedByLoyalty': value.isDiscountedByLoyalty,
        'offers': value.offers === undefined ? undefined : ((value.offers as Array<any>).map(IRewardsDiscountOfferItemDSResponseModelToJSON)),
    };
}

