/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationsByCityModel,
    ILocationsByCityModelFromJSON,
    ILocationsByCityModelFromJSONTyped,
    ILocationsByCityModelToJSON,
} from './ILocationsByCityModel';

/**
 * 
 * @export
 * @interface ILocationsByStateOrProvinceModel
 */
export interface ILocationsByStateOrProvinceModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationsByStateOrProvinceModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationsByStateOrProvinceModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ILocationsByStateOrProvinceModel
     */
    count: number;
    /**
     * 
     * @type {Array<ILocationsByCityModel>}
     * @memberof ILocationsByStateOrProvinceModel
     */
    cities: Array<ILocationsByCityModel>;
}

export function ILocationsByStateOrProvinceModelFromJSON(json: any): ILocationsByStateOrProvinceModel {
    return ILocationsByStateOrProvinceModelFromJSONTyped(json, false);
}

export function ILocationsByStateOrProvinceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationsByStateOrProvinceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'count': json['count'],
        'cities': ((json['cities'] as Array<any>).map(ILocationsByCityModelFromJSON)),
    };
}

export function ILocationsByStateOrProvinceModelToJSON(value?: ILocationsByStateOrProvinceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'count': value.count,
        'cities': ((value.cities as Array<any>).map(ILocationsByCityModelToJSON)),
    };
}

