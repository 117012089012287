/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IActivityHistoryTransactionModel,
    IActivityHistoryTransactionModelFromJSON,
    IActivityHistoryTransactionModelFromJSONTyped,
    IActivityHistoryTransactionModelToJSON,
} from './IActivityHistoryTransactionModel';
import {
    ICertificateAppliedModel,
    ICertificateAppliedModelFromJSON,
    ICertificateAppliedModelFromJSONTyped,
    ICertificateAppliedModelToJSON,
} from './ICertificateAppliedModel';
import {
    IOfferAppliedModel,
    IOfferAppliedModelFromJSON,
    IOfferAppliedModelFromJSONTyped,
    IOfferAppliedModelToJSON,
} from './IOfferAppliedModel';
import {
    TActivityTypeModel,
    TActivityTypeModelFromJSON,
    TActivityTypeModelFromJSONTyped,
    TActivityTypeModelToJSON,
} from './TActivityTypeModel';

/**
 * 
 * @export
 * @interface IGetCustomerRewardsActivityHistoryResponseModel
 */
export interface IGetCustomerRewardsActivityHistoryResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    description: string;
    /**
     * 
     * @type {TActivityTypeModel}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    type: TActivityTypeModel;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    dateTime: Date;
    /**
     * 
     * @type {number}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    pointsBalanceAfter: number;
    /**
     * 
     * @type {string}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    storeId: string;
    /**
     * 
     * @type {Array<IOfferAppliedModel>}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    offersApplied: Array<IOfferAppliedModel>;
    /**
     * 
     * @type {Array<ICertificateAppliedModel>}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    certificatesApplied: Array<ICertificateAppliedModel>;
    /**
     * 
     * @type {IActivityHistoryTransactionModel}
     * @memberof IGetCustomerRewardsActivityHistoryResponseModel
     */
    transaction?: IActivityHistoryTransactionModel;
}

export function IGetCustomerRewardsActivityHistoryResponseModelFromJSON(json: any): IGetCustomerRewardsActivityHistoryResponseModel {
    return IGetCustomerRewardsActivityHistoryResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerRewardsActivityHistoryResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerRewardsActivityHistoryResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'type': TActivityTypeModelFromJSON(json['type']),
        'dateTime': (new Date(json['dateTime'])),
        'points': json['points'],
        'pointsBalanceAfter': json['pointsBalanceAfter'],
        'storeId': json['storeId'],
        'offersApplied': ((json['offersApplied'] as Array<any>).map(IOfferAppliedModelFromJSON)),
        'certificatesApplied': ((json['certificatesApplied'] as Array<any>).map(ICertificateAppliedModelFromJSON)),
        'transaction': !exists(json, 'transaction') ? undefined : IActivityHistoryTransactionModelFromJSON(json['transaction']),
    };
}

export function IGetCustomerRewardsActivityHistoryResponseModelToJSON(value?: IGetCustomerRewardsActivityHistoryResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'type': TActivityTypeModelToJSON(value.type),
        'dateTime': (value.dateTime.toISOString()),
        'points': value.points,
        'pointsBalanceAfter': value.pointsBalanceAfter,
        'storeId': value.storeId,
        'offersApplied': ((value.offersApplied as Array<any>).map(IOfferAppliedModelToJSON)),
        'certificatesApplied': ((value.certificatesApplied as Array<any>).map(ICertificateAppliedModelToJSON)),
        'transaction': IActivityHistoryTransactionModelToJSON(value.transaction),
    };
}

