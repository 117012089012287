/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VisitLocationTypeModel,
    VisitLocationTypeModelFromJSON,
    VisitLocationTypeModelFromJSONTyped,
    VisitLocationTypeModelToJSON,
} from './VisitLocationTypeModel';

/**
 * 
 * @export
 * @interface IVisitRegisterRequestFullfillmentVisitModel
 */
export interface IVisitRegisterRequestFullfillmentVisitModel {
    /**
     * 
     * @type {Date}
     * @memberof IVisitRegisterRequestFullfillmentVisitModel
     */
    time?: Date;
    /**
     * 
     * @type {number}
     * @memberof IVisitRegisterRequestFullfillmentVisitModel
     */
    locationIdentifier?: number;
    /**
     * 
     * @type {VisitLocationTypeModel}
     * @memberof IVisitRegisterRequestFullfillmentVisitModel
     */
    locationType?: VisitLocationTypeModel;
}

export function IVisitRegisterRequestFullfillmentVisitModelFromJSON(json: any): IVisitRegisterRequestFullfillmentVisitModel {
    return IVisitRegisterRequestFullfillmentVisitModelFromJSONTyped(json, false);
}

export function IVisitRegisterRequestFullfillmentVisitModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterRequestFullfillmentVisitModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'locationIdentifier': !exists(json, 'locationIdentifier') ? undefined : json['locationIdentifier'],
        'locationType': !exists(json, 'locationType') ? undefined : VisitLocationTypeModelFromJSON(json['locationType']),
    };
}

export function IVisitRegisterRequestFullfillmentVisitModelToJSON(value?: IVisitRegisterRequestFullfillmentVisitModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'locationIdentifier': value.locationIdentifier,
        'locationType': VisitLocationTypeModelToJSON(value.locationType),
    };
}

