/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IOrderHistoryItemModifierGroupItemModel,
    IOrderHistoryItemModifierGroupItemModelFromJSON,
    IOrderHistoryItemModifierGroupItemModelFromJSONTyped,
    IOrderHistoryItemModifierGroupItemModelToJSON,
} from './IOrderHistoryItemModifierGroupItemModel';
import {
    IOrderProductModifiersModel,
    IOrderProductModifiersModelFromJSON,
    IOrderProductModifiersModelFromJSONTyped,
    IOrderProductModifiersModelToJSON,
} from './IOrderProductModifiersModel';
import {
    TallyDiscountModel,
    TallyDiscountModelFromJSON,
    TallyDiscountModelFromJSONTyped,
    TallyDiscountModelToJSON,
} from './TallyDiscountModel';

/**
 * 
 * @export
 * @interface IOrderProductModel
 */
export interface IOrderProductModel {
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModel
     */
    lineItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof IOrderProductModel
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderProductModel
     */
    productKind?: string;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModel
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModel
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof IOrderProductModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderProductModel
     */
    note?: string;
    /**
     * 
     * @type {Array<IOrderHistoryItemModifierGroupItemModel>}
     * @memberof IOrderProductModel
     */
    modifierGroups?: Array<IOrderHistoryItemModifierGroupItemModel>;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModel
     */
    priceWithModifiersBeforeDiscounts?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductModel
     */
    priceWithModifiersAfterDiscounts?: number;
    /**
     * 
     * @type {Array<TallyDiscountModel>}
     * @memberof IOrderProductModel
     */
    discounts?: Array<TallyDiscountModel>;
    /**
     * 
     * @type {Array<IOrderProductModifiersModel>}
     * @memberof IOrderProductModel
     */
    modifiers?: Array<IOrderProductModifiersModel>;
    /**
     * 
     * @type {Array<IOrderProductModel>}
     * @memberof IOrderProductModel
     */
    childItems?: Array<IOrderProductModel>;
}

export function IOrderProductModelFromJSON(json: any): IOrderProductModel {
    return IOrderProductModelFromJSONTyped(json, false);
}

export function IOrderProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productKind': !exists(json, 'productKind') ? undefined : json['productKind'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'modifierGroups': !exists(json, 'modifierGroups') ? undefined : ((json['modifierGroups'] as Array<any>).map(IOrderHistoryItemModifierGroupItemModelFromJSON)),
        'priceWithModifiersBeforeDiscounts': !exists(json, 'priceWithModifiersBeforeDiscounts') ? undefined : json['priceWithModifiersBeforeDiscounts'],
        'priceWithModifiersAfterDiscounts': !exists(json, 'priceWithModifiersAfterDiscounts') ? undefined : json['priceWithModifiersAfterDiscounts'],
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(TallyDiscountModelFromJSON)),
        'modifiers': !exists(json, 'modifiers') ? undefined : ((json['modifiers'] as Array<any>).map(IOrderProductModifiersModelFromJSON)),
        'childItems': !exists(json, 'childItems') ? undefined : ((json['childItems'] as Array<any>).map(IOrderProductModelFromJSON)),
    };
}

export function IOrderProductModelToJSON(value?: IOrderProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'productId': value.productId,
        'productKind': value.productKind,
        'price': value.price,
        'quantity': value.quantity,
        'description': value.description,
        'note': value.note,
        'modifierGroups': value.modifierGroups === undefined ? undefined : ((value.modifierGroups as Array<any>).map(IOrderHistoryItemModifierGroupItemModelToJSON)),
        'priceWithModifiersBeforeDiscounts': value.priceWithModifiersBeforeDiscounts,
        'priceWithModifiersAfterDiscounts': value.priceWithModifiersAfterDiscounts,
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(TallyDiscountModelToJSON)),
        'modifiers': value.modifiers === undefined ? undefined : ((value.modifiers as Array<any>).map(IOrderProductModifiersModelToJSON)),
        'childItems': value.childItems === undefined ? undefined : ((value.childItems as Array<any>).map(IOrderProductModelToJSON)),
    };
}

