/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorCodeModel {
    Validation = 'VALIDATION',
    EmailNotVerified = 'EMAIL_NOT_VERIFIED',
    External = 'EXTERNAL',
    Internal = 'INTERNAL',
    NotFound = 'NOT_FOUND',
    MissingCredentials = 'MISSING_CREDENTIALS',
    RedeemedReward = 'REDEEMED_REWARD',
    ExpiredReward = 'EXPIRED_REWARD',
    RewardNotFound = 'REWARD_NOT_FOUND',
    MenuNotFound = 'MENU_NOT_FOUND',
    Generic = 'GENERIC',
    StatusNotRecognized = 'STATUS_NOT_RECOGNIZED',
    NoLocationsAround = 'NO_LOCATIONS_AROUND',
    Cms = 'CMS',
    CheckNotFound = 'CHECK_NOT_FOUND',
    PaymentError = 'PAYMENT_ERROR',
    DeliveryOrderInvalidPhone = 'DELIVERY_ORDER_INVALID_PHONE',
    PaymentFailure = 'PAYMENT_FAILURE',
    PaymentAmountLower = 'PAYMENT_AMOUNT_LOWER',
    PaymentAmountHigher = 'PAYMENT_AMOUNT_HIGHER',
    PaymentNotValid = 'PAYMENT_NOT_VALID',
    PaymentNotPresented = 'PAYMENT_NOT_PRESENTED',
    InvalidFutureDate = 'INVALID_FUTURE_DATE',
    LocationNotAvailable = 'LOCATION_NOT_AVAILABLE',
    InvalidPastDate = 'INVALID_PAST_DATE',
    InvalidPastDateBeforeEnrollment = 'INVALID_PAST_DATE_BEFORE_ENROLLMENT',
    AlreadyAwarded = 'ALREADY_AWARDED',
    PhoneNumberAlreadyUsed = 'PHONE_NUMBER_ALREADY_USED',
    CheckAmountMismatch = 'CHECK_AMOUNT_MISMATCH',
    InvalidSession = 'INVALID_SESSION',
    CustomerAlreadySignedUp = 'CUSTOMER_ALREADY_SIGNED_UP',
    OrderFailed = 'ORDER_FAILED',
    CloudinaryFailed = 'CLOUDINARY_FAILED',
    UserIsAlreadySubscribed = 'USER_IS_ALREADY_SUBSCRIBED',
    GiftCardZeroBalance = 'GIFT_CARD_ZERO_BALANCE',
    GiftCardIsInUse = 'GIFT_CARD_IS_IN_USE',
    GiftCardInvalidCardOrPin = 'GIFT_CARD_INVALID_CARD_OR_PIN',
    StallUnavailable = 'STALL_UNAVAILABLE',
    InvalidSpotNumber = 'INVALID_SPOT_NUMBER',
    ProfanityValidation = 'PROFANITY_VALIDATION',
    PromocodeValidation = 'PROMOCODE_VALIDATION',
    DeleteAccountValidation = 'DELETE_ACCOUNT_VALIDATION_ERROR',
}

export function TErrorCodeModelFromJSON(json: any): TErrorCodeModel {
    return TErrorCodeModelFromJSONTyped(json, false);
}

export function TErrorCodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorCodeModel {
    return json as TErrorCodeModel;
}

export function TErrorCodeModelToJSON(value?: TErrorCodeModel | null): any {
    return value as any;
}

