/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ILocationUrlPartsModel
 */
export interface ILocationUrlPartsModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationUrlPartsModel
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationUrlPartsModel
     */
    stateOrProvinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationUrlPartsModel
     */
    cityName: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationUrlPartsModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationUrlPartsModel
     */
    storeId: string;
}

export function ILocationUrlPartsModelFromJSON(json: any): ILocationUrlPartsModel {
    return ILocationUrlPartsModelFromJSONTyped(json, false);
}

export function ILocationUrlPartsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationUrlPartsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': json['countryCode'],
        'stateOrProvinceCode': json['stateOrProvinceCode'],
        'cityName': json['cityName'],
        'address': json['address'],
        'storeId': json['storeId'],
    };
}

export function ILocationUrlPartsModelToJSON(value?: ILocationUrlPartsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': value.countryCode,
        'stateOrProvinceCode': value.stateOrProvinceCode,
        'cityName': value.cityName,
        'address': value.address,
        'storeId': value.storeId,
    };
}

