/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalorieRangeModel
 */
export interface CalorieRangeModel {
    /**
     * 
     * @type {string}
     * @memberof CalorieRangeModel
     */
    min: string;
    /**
     * 
     * @type {string}
     * @memberof CalorieRangeModel
     */
    max: string;
}

export function CalorieRangeModelFromJSON(json: any): CalorieRangeModel {
    return CalorieRangeModelFromJSONTyped(json, false);
}

export function CalorieRangeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalorieRangeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': json['min'],
        'max': json['max'],
    };
}

export function CalorieRangeModelToJSON(value?: CalorieRangeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
    };
}

