/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IClaimMissingPointsResponseModel
 */
export interface IClaimMissingPointsResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IClaimMissingPointsResponseModel
     */
    basePoints: number;
    /**
     * 
     * @type {number}
     * @memberof IClaimMissingPointsResponseModel
     */
    bonusPoints: number;
    /**
     * 
     * @type {number}
     * @memberof IClaimMissingPointsResponseModel
     */
    pointsBalanceAfter: number;
}

export function IClaimMissingPointsResponseModelFromJSON(json: any): IClaimMissingPointsResponseModel {
    return IClaimMissingPointsResponseModelFromJSONTyped(json, false);
}

export function IClaimMissingPointsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IClaimMissingPointsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basePoints': json['basePoints'],
        'bonusPoints': json['bonusPoints'],
        'pointsBalanceAfter': json['pointsBalanceAfter'],
    };
}

export function IClaimMissingPointsResponseModelToJSON(value?: IClaimMissingPointsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basePoints': value.basePoints,
        'bonusPoints': value.bonusPoints,
        'pointsBalanceAfter': value.pointsBalanceAfter,
    };
}

