/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IApplePaySessionResponseModel
 */
export interface IApplePaySessionResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IApplePaySessionResponseModel
     */
    epochTimestamp: number;
    /**
     * 
     * @type {number}
     * @memberof IApplePaySessionResponseModel
     */
    expiresAt: number;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    merchantSessionIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    merchantIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    domainName: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    operationalAnalyticsIdentifier: string;
    /**
     * 
     * @type {number}
     * @memberof IApplePaySessionResponseModel
     */
    retries: number;
    /**
     * 
     * @type {string}
     * @memberof IApplePaySessionResponseModel
     */
    pspId: string;
}

export function IApplePaySessionResponseModelFromJSON(json: any): IApplePaySessionResponseModel {
    return IApplePaySessionResponseModelFromJSONTyped(json, false);
}

export function IApplePaySessionResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApplePaySessionResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epochTimestamp': json['epochTimestamp'],
        'expiresAt': json['expiresAt'],
        'merchantSessionIdentifier': json['merchantSessionIdentifier'],
        'nonce': json['nonce'],
        'merchantIdentifier': json['merchantIdentifier'],
        'domainName': json['domainName'],
        'displayName': json['displayName'],
        'signature': json['signature'],
        'operationalAnalyticsIdentifier': json['operationalAnalyticsIdentifier'],
        'retries': json['retries'],
        'pspId': json['pspId'],
    };
}

export function IApplePaySessionResponseModelToJSON(value?: IApplePaySessionResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epochTimestamp': value.epochTimestamp,
        'expiresAt': value.expiresAt,
        'merchantSessionIdentifier': value.merchantSessionIdentifier,
        'nonce': value.nonce,
        'merchantIdentifier': value.merchantIdentifier,
        'domainName': value.domainName,
        'displayName': value.displayName,
        'signature': value.signature,
        'operationalAnalyticsIdentifier': value.operationalAnalyticsIdentifier,
        'retries': value.retries,
        'pspId': value.pspId,
    };
}

