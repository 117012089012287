/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ICustomerRewardsV2BodyModel
 */
export interface ICustomerRewardsV2BodyModel {
    /**
     * 
     * @type {string}
     * @memberof ICustomerRewardsV2BodyModel
     */
    idpCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomerRewardsV2BodyModel
     */
    location: string;
}

export function ICustomerRewardsV2BodyModelFromJSON(json: any): ICustomerRewardsV2BodyModel {
    return ICustomerRewardsV2BodyModelFromJSONTyped(json, false);
}

export function ICustomerRewardsV2BodyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICustomerRewardsV2BodyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpCustomerId': !exists(json, 'idpCustomerId') ? undefined : json['idpCustomerId'],
        'location': json['location'],
    };
}

export function ICustomerRewardsV2BodyModelToJSON(value?: ICustomerRewardsV2BodyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpCustomerId': value.idpCustomerId,
        'location': value.location,
    };
}

