/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IDeliveryGeoCoordinatesModel
 */
export interface IDeliveryGeoCoordinatesModel {
    /**
     * 
     * @type {number}
     * @memberof IDeliveryGeoCoordinatesModel
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof IDeliveryGeoCoordinatesModel
     */
    longitude: number;
}

export function IDeliveryGeoCoordinatesModelFromJSON(json: any): IDeliveryGeoCoordinatesModel {
    return IDeliveryGeoCoordinatesModelFromJSONTyped(json, false);
}

export function IDeliveryGeoCoordinatesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDeliveryGeoCoordinatesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function IDeliveryGeoCoordinatesModelToJSON(value?: IDeliveryGeoCoordinatesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

