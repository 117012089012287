/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRewardsDiscountItemDSResponseModel,
    IRewardsDiscountItemDSResponseModelFromJSON,
    IRewardsDiscountItemDSResponseModelFromJSONTyped,
    IRewardsDiscountItemDSResponseModelToJSON,
} from './IRewardsDiscountItemDSResponseModel';
import {
    IRewardsDiscountOfferDSResponseModel,
    IRewardsDiscountOfferDSResponseModelFromJSON,
    IRewardsDiscountOfferDSResponseModelFromJSONTyped,
    IRewardsDiscountOfferDSResponseModelToJSON,
} from './IRewardsDiscountOfferDSResponseModel';
import {
    IRewardsDiscountOrderDSResponseDiscountModel,
    IRewardsDiscountOrderDSResponseDiscountModelFromJSON,
    IRewardsDiscountOrderDSResponseDiscountModelFromJSONTyped,
    IRewardsDiscountOrderDSResponseDiscountModelToJSON,
} from './IRewardsDiscountOrderDSResponseDiscountModel';
import {
    IRewardsDiscountOrderDSResponseFulfillmentModel,
    IRewardsDiscountOrderDSResponseFulfillmentModelFromJSON,
    IRewardsDiscountOrderDSResponseFulfillmentModelFromJSONTyped,
    IRewardsDiscountOrderDSResponseFulfillmentModelToJSON,
} from './IRewardsDiscountOrderDSResponseFulfillmentModel';

/**
 * 
 * @export
 * @interface IRewardsDiscountOrderDSResponseModel
 */
export interface IRewardsDiscountOrderDSResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    idempotentId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    sellingChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    subTotal: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    discountedPrice: string;
    /**
     * 
     * @type {boolean}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    isDiscountedByLoyalty?: boolean;
    /**
     * 
     * @type {Array<IRewardsDiscountOfferDSResponseModel>}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    offers?: Array<IRewardsDiscountOfferDSResponseModel>;
    /**
     * 
     * @type {Array<IRewardsDiscountItemDSResponseModel>}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    items: Array<IRewardsDiscountItemDSResponseModel>;
    /**
     * 
     * @type {IRewardsDiscountOrderDSResponseFulfillmentModel}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    fulfillment?: IRewardsDiscountOrderDSResponseFulfillmentModel;
    /**
     * 
     * @type {IRewardsDiscountOrderDSResponseDiscountModel}
     * @memberof IRewardsDiscountOrderDSResponseModel
     */
    discount?: IRewardsDiscountOrderDSResponseDiscountModel;
}

export function IRewardsDiscountOrderDSResponseModelFromJSON(json: any): IRewardsDiscountOrderDSResponseModel {
    return IRewardsDiscountOrderDSResponseModelFromJSONTyped(json, false);
}

export function IRewardsDiscountOrderDSResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountOrderDSResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idempotentId': !exists(json, 'idempotentId') ? undefined : json['idempotentId'],
        'sellingChannel': !exists(json, 'sellingChannel') ? undefined : json['sellingChannel'],
        'subTotal': json['subTotal'],
        'discountedPrice': json['discountedPrice'],
        'isDiscountedByLoyalty': !exists(json, 'isDiscountedByLoyalty') ? undefined : json['isDiscountedByLoyalty'],
        'offers': !exists(json, 'offers') ? undefined : ((json['offers'] as Array<any>).map(IRewardsDiscountOfferDSResponseModelFromJSON)),
        'items': ((json['items'] as Array<any>).map(IRewardsDiscountItemDSResponseModelFromJSON)),
        'fulfillment': !exists(json, 'fulfillment') ? undefined : IRewardsDiscountOrderDSResponseFulfillmentModelFromJSON(json['fulfillment']),
        'discount': !exists(json, 'discount') ? undefined : IRewardsDiscountOrderDSResponseDiscountModelFromJSON(json['discount']),
    };
}

export function IRewardsDiscountOrderDSResponseModelToJSON(value?: IRewardsDiscountOrderDSResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idempotentId': value.idempotentId,
        'sellingChannel': value.sellingChannel,
        'subTotal': value.subTotal,
        'discountedPrice': value.discountedPrice,
        'isDiscountedByLoyalty': value.isDiscountedByLoyalty,
        'offers': value.offers === undefined ? undefined : ((value.offers as Array<any>).map(IRewardsDiscountOfferDSResponseModelToJSON)),
        'items': ((value.items as Array<any>).map(IRewardsDiscountItemDSResponseModelToJSON)),
        'fulfillment': IRewardsDiscountOrderDSResponseFulfillmentModelToJSON(value.fulfillment),
        'discount': IRewardsDiscountOrderDSResponseDiscountModelToJSON(value.discount),
    };
}

