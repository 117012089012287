/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IWalletBillingAddressModel
 */
export interface IWalletBillingAddressModel {
    /**
     * 
     * @type {string}
     * @memberof IWalletBillingAddressModel
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletBillingAddressModel
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletBillingAddressModel
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletBillingAddressModel
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletBillingAddressModel
     */
    state?: string;
}

export function IWalletBillingAddressModelFromJSON(json: any): IWalletBillingAddressModel {
    return IWalletBillingAddressModelFromJSONTyped(json, false);
}

export function IWalletBillingAddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IWalletBillingAddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function IWalletBillingAddressModelToJSON(value?: IWalletBillingAddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postalCode': value.postalCode,
        'line1': value.line1,
        'line2': value.line2,
        'city': value.city,
        'state': value.state,
    };
}

