/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDetailsTypeModel,
    DiscountDetailsTypeModelFromJSON,
    DiscountDetailsTypeModelFromJSONTyped,
    DiscountDetailsTypeModelToJSON,
} from './DiscountDetailsTypeModel';
import {
    ICheckRestrictionsModel,
    ICheckRestrictionsModelFromJSON,
    ICheckRestrictionsModelFromJSONTyped,
    ICheckRestrictionsModelToJSON,
} from './ICheckRestrictionsModel';
import {
    IHoursModel,
    IHoursModelFromJSON,
    IHoursModelFromJSONTyped,
    IHoursModelToJSON,
} from './IHoursModel';
import {
    IPastRewardModel,
    IPastRewardModelFromJSON,
    IPastRewardModelFromJSONTyped,
    IPastRewardModelToJSON,
} from './IPastRewardModel';
import {
    IRewardApplicabilityModel,
    IRewardApplicabilityModelFromJSON,
    IRewardApplicabilityModelFromJSONTyped,
    IRewardApplicabilityModelToJSON,
} from './IRewardApplicabilityModel';
import {
    LocationRestrictionsModel,
    LocationRestrictionsModelFromJSON,
    LocationRestrictionsModelFromJSONTyped,
    LocationRestrictionsModelToJSON,
} from './LocationRestrictionsModel';
import {
    TOfferTypeModel,
    TOfferTypeModelFromJSON,
    TOfferTypeModelFromJSONTyped,
    TOfferTypeModelToJSON,
} from './TOfferTypeModel';
import {
    TOffersUnionModel,
    TOffersUnionModelFromJSON,
    TOffersUnionModelFromJSONTyped,
    TOffersUnionModelToJSON,
} from './TOffersUnionModel';
import {
    TRewardOfferStatusModel,
    TRewardOfferStatusModelFromJSON,
    TRewardOfferStatusModelFromJSONTyped,
    TRewardOfferStatusModelToJSON,
} from './TRewardOfferStatusModel';

/**
 * 
 * @export
 * @interface TOffersByStatusModel
 */
export interface TOffersByStatusModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    id: string;
    /**
     * User specific Offer Id, generated from Vendor system/api.
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    userOfferId: string;
    /**
     * POS discount id.
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    posDiscountId?: string;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    name: string;
    /**
     * 
     * @type {TOfferTypeModel}
     * @memberof TOffersByStatusModel
     */
    type: TOfferTypeModel;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    startDateTime: string;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    endDateTime: string;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    terms: string;
    /**
     * Is this a store only offer. Cannot be redeemed through difital channels if set to true. Only redeemable through store POS
     * @type {boolean}
     * @memberof TOffersByStatusModel
     */
    isRedeemableInStoreOnly: boolean;
    /**
     * Is this a online only offer.
     * @type {boolean}
     * @memberof TOffersByStatusModel
     */
    isRedeemableOnlineOnly: boolean;
    /**
     * 
     * @type {TRewardOfferStatusModel}
     * @memberof TOffersByStatusModel
     */
    status?: TRewardOfferStatusModel;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    termsLanguageCode: string;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    description?: string;
    /**
     * 
     * @type {IRewardApplicabilityModel}
     * @memberof TOffersByStatusModel
     */
    applicability?: IRewardApplicabilityModel;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    imageUrl?: string;
    /**
     * 
     * @type {LocationRestrictionsModel}
     * @memberof TOffersByStatusModel
     */
    locationRestrictions?: LocationRestrictionsModel;
    /**
     * 
     * @type {ICheckRestrictionsModel}
     * @memberof TOffersByStatusModel
     */
    checkRestrictions?: ICheckRestrictionsModel;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    displayExpirationDate?: string;
    /**
     * 
     * @type {DiscountDetailsTypeModel}
     * @memberof TOffersByStatusModel
     */
    discountDetailsType?: DiscountDetailsTypeModel;
    /**
     * 
     * @type {Array<IHoursModel>}
     * @memberof TOffersByStatusModel
     */
    redemptionTimeRanges?: Array<IHoursModel>;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    optInDate?: string;
    /**
     * 
     * @type {number}
     * @memberof TOffersByStatusModel
     */
    daysValid?: number;
    /**
     * 
     * @type {string}
     * @memberof TOffersByStatusModel
     */
    offerPriority?: string;
    /**
     * 
     * @type {number}
     * @memberof TOffersByStatusModel
     */
    points?: number;
    /**
     * 
     * @type {Date}
     * @memberof TOffersByStatusModel
     */
    endDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TOffersByStatusModel
     */
    isUsed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TOffersByStatusModel
     */
    isExpired: boolean;
}

export function TOffersByStatusModelFromJSON(json: any): TOffersByStatusModel {
    return TOffersByStatusModelFromJSONTyped(json, false);
}

export function TOffersByStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TOffersByStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userOfferId': json['userOfferId'],
        'posDiscountId': !exists(json, 'posDiscountId') ? undefined : json['posDiscountId'],
        'name': json['name'],
        'type': TOfferTypeModelFromJSON(json['type']),
        'startDateTime': json['startDateTime'],
        'endDateTime': json['endDateTime'],
        'terms': json['terms'],
        'isRedeemableInStoreOnly': json['isRedeemableInStoreOnly'],
        'isRedeemableOnlineOnly': json['isRedeemableOnlineOnly'],
        'status': !exists(json, 'status') ? undefined : TRewardOfferStatusModelFromJSON(json['status']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'termsLanguageCode': json['termsLanguageCode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicability': !exists(json, 'applicability') ? undefined : IRewardApplicabilityModelFromJSON(json['applicability']),
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'locationRestrictions': !exists(json, 'locationRestrictions') ? undefined : LocationRestrictionsModelFromJSON(json['locationRestrictions']),
        'checkRestrictions': !exists(json, 'checkRestrictions') ? undefined : ICheckRestrictionsModelFromJSON(json['checkRestrictions']),
        'displayExpirationDate': !exists(json, 'displayExpirationDate') ? undefined : json['displayExpirationDate'],
        'discountDetailsType': !exists(json, 'discountDetailsType') ? undefined : DiscountDetailsTypeModelFromJSON(json['discountDetailsType']),
        'redemptionTimeRanges': !exists(json, 'redemptionTimeRanges') ? undefined : ((json['redemptionTimeRanges'] as Array<any>).map(IHoursModelFromJSON)),
        'optInDate': !exists(json, 'optInDate') ? undefined : json['optInDate'],
        'daysValid': !exists(json, 'daysValid') ? undefined : json['daysValid'],
        'offerPriority': !exists(json, 'offerPriority') ? undefined : json['offerPriority'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'endDate': (new Date(json['endDate'])),
        'isUsed': json['isUsed'],
        'isExpired': json['isExpired'],
    };
}

export function TOffersByStatusModelToJSON(value?: TOffersByStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userOfferId': value.userOfferId,
        'posDiscountId': value.posDiscountId,
        'name': value.name,
        'type': TOfferTypeModelToJSON(value.type),
        'startDateTime': value.startDateTime,
        'endDateTime': value.endDateTime,
        'terms': value.terms,
        'isRedeemableInStoreOnly': value.isRedeemableInStoreOnly,
        'isRedeemableOnlineOnly': value.isRedeemableOnlineOnly,
        'status': TRewardOfferStatusModelToJSON(value.status),
        'code': value.code,
        'termsLanguageCode': value.termsLanguageCode,
        'description': value.description,
        'applicability': IRewardApplicabilityModelToJSON(value.applicability),
        'imageUrl': value.imageUrl,
        'locationRestrictions': LocationRestrictionsModelToJSON(value.locationRestrictions),
        'checkRestrictions': ICheckRestrictionsModelToJSON(value.checkRestrictions),
        'displayExpirationDate': value.displayExpirationDate,
        'discountDetailsType': DiscountDetailsTypeModelToJSON(value.discountDetailsType),
        'redemptionTimeRanges': value.redemptionTimeRanges === undefined ? undefined : ((value.redemptionTimeRanges as Array<any>).map(IHoursModelToJSON)),
        'optInDate': value.optInDate,
        'daysValid': value.daysValid,
        'offerPriority': value.offerPriority,
        'points': value.points,
        'endDate': (value.endDate.toISOString()),
        'isUsed': value.isUsed,
        'isExpired': value.isExpired,
    };
}

