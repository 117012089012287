/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IOrderingTimeSlotModel,
    IOrderingTimeSlotModelFromJSON,
    IOrderingTimeSlotModelFromJSONTyped,
    IOrderingTimeSlotModelToJSON,
} from './IOrderingTimeSlotModel';

/**
 * 
 * @export
 * @interface ILocationSlotModel
 */
export interface ILocationSlotModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationSlotModel
     */
    key: string;
    /**
     * 
     * @type {Array<IOrderingTimeSlotModel>}
     * @memberof ILocationSlotModel
     */
    value: Array<IOrderingTimeSlotModel>;
}

export function ILocationSlotModelFromJSON(json: any): ILocationSlotModel {
    return ILocationSlotModelFromJSONTyped(json, false);
}

export function ILocationSlotModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationSlotModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': ((json['value'] as Array<any>).map(IOrderingTimeSlotModelFromJSON)),
    };
}

export function ILocationSlotModelToJSON(value?: ILocationSlotModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': ((value.value as Array<any>).map(IOrderingTimeSlotModelToJSON)),
    };
}

