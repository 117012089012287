/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HealthCheckResponseModel
 */
export interface HealthCheckResponseModel {
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResponseModel
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof HealthCheckResponseModel
     */
    uptime: number;
    /**
     * 
     * @type {number}
     * @memberof HealthCheckResponseModel
     */
    timestamp: number;
}

export function HealthCheckResponseModelFromJSON(json: any): HealthCheckResponseModel {
    return HealthCheckResponseModelFromJSONTyped(json, false);
}

export function HealthCheckResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthCheckResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'uptime': json['uptime'],
        'timestamp': json['timestamp'],
    };
}

export function HealthCheckResponseModelToJSON(value?: HealthCheckResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'uptime': value.uptime,
        'timestamp': value.timestamp,
    };
}

