/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DeliveryProviderNameEnumModel {
    UberEats = 'UberEats',
    DoorDash = 'DoorDash',
    Postmates = 'Postmates',
    GrubHub = 'GrubHub'
}

export function DeliveryProviderNameEnumModelFromJSON(json: any): DeliveryProviderNameEnumModel {
    return DeliveryProviderNameEnumModelFromJSONTyped(json, false);
}

export function DeliveryProviderNameEnumModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryProviderNameEnumModel {
    return json as DeliveryProviderNameEnumModel;
}

export function DeliveryProviderNameEnumModelToJSON(value?: DeliveryProviderNameEnumModel | null): any {
    return value as any;
}

