/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDineInPayOrderPaymentModel,
    IDineInPayOrderPaymentModelFromJSON,
    IDineInPayOrderPaymentModelFromJSONTyped,
    IDineInPayOrderPaymentModelToJSON,
} from './IDineInPayOrderPaymentModel';

/**
 * 
 * @export
 * @interface IDineInPayOrderRequestModel
 */
export interface IDineInPayOrderRequestModel {
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderRequestModel
     */
    orderId: string;
    /**
     * 
     * @type {number}
     * @memberof IDineInPayOrderRequestModel
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderRequestModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInPayOrderRequestModel
     */
    deviceId?: string;
    /**
     * 
     * @type {number}
     * @memberof IDineInPayOrderRequestModel
     */
    tipAmount?: number;
    /**
     * 
     * @type {Array<IDineInPayOrderPaymentModel>}
     * @memberof IDineInPayOrderRequestModel
     */
    payments: Array<IDineInPayOrderPaymentModel>;
}

export function IDineInPayOrderRequestModelFromJSON(json: any): IDineInPayOrderRequestModel {
    return IDineInPayOrderRequestModelFromJSONTyped(json, false);
}

export function IDineInPayOrderRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDineInPayOrderRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderId': json['orderId'],
        'amount': json['amount'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'tipAmount': !exists(json, 'tipAmount') ? undefined : json['tipAmount'],
        'payments': ((json['payments'] as Array<any>).map(IDineInPayOrderPaymentModelFromJSON)),
    };
}

export function IDineInPayOrderRequestModelToJSON(value?: IDineInPayOrderRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderId': value.orderId,
        'amount': value.amount,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'deviceId': value.deviceId,
        'tipAmount': value.tipAmount,
        'payments': ((value.payments as Array<any>).map(IDineInPayOrderPaymentModelToJSON)),
    };
}

