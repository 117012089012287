/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * TYPES
 * @export
 * @enum {string}
 */
export enum WalletAccountTypesModel {
    Card = 'CARD',
    GiftCard = 'GIFT_CARD'
}

export function WalletAccountTypesModelFromJSON(json: any): WalletAccountTypesModel {
    return WalletAccountTypesModelFromJSONTyped(json, false);
}

export function WalletAccountTypesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletAccountTypesModel {
    return json as WalletAccountTypesModel;
}

export function WalletAccountTypesModelToJSON(value?: WalletAccountTypesModel | null): any {
    return value as any;
}

