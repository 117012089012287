/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ISurveyAnswerModel,
    ISurveyAnswerModelFromJSON,
    ISurveyAnswerModelFromJSONTyped,
    ISurveyAnswerModelToJSON,
} from './ISurveyAnswerModel';
import {
    QuestionResponseTypeModel,
    QuestionResponseTypeModelFromJSON,
    QuestionResponseTypeModelFromJSONTyped,
    QuestionResponseTypeModelToJSON,
} from './QuestionResponseTypeModel';

/**
 * 
 * @export
 * @interface ISurveyQuestionModel
 */
export interface ISurveyQuestionModel {
    /**
     * 
     * @type {string}
     * @memberof ISurveyQuestionModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISurveyQuestionModel
     */
    text: string;
    /**
     * 
     * @type {QuestionResponseTypeModel}
     * @memberof ISurveyQuestionModel
     */
    type: QuestionResponseTypeModel;
    /**
     * 
     * @type {Array<ISurveyAnswerModel>}
     * @memberof ISurveyQuestionModel
     */
    answers: Array<ISurveyAnswerModel>;
}

export function ISurveyQuestionModelFromJSON(json: any): ISurveyQuestionModel {
    return ISurveyQuestionModelFromJSONTyped(json, false);
}

export function ISurveyQuestionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ISurveyQuestionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': json['text'],
        'type': QuestionResponseTypeModelFromJSON(json['type']),
        'answers': ((json['answers'] as Array<any>).map(ISurveyAnswerModelFromJSON)),
    };
}

export function ISurveyQuestionModelToJSON(value?: ISurveyQuestionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'type': QuestionResponseTypeModelToJSON(value.type),
        'answers': ((value.answers as Array<any>).map(ISurveyAnswerModelToJSON)),
    };
}

