/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IAddCardRequestAllOfModel,
    IAddCardRequestAllOfModelFromJSON,
    IAddCardRequestAllOfModelFromJSONTyped,
    IAddCardRequestAllOfModelToJSON,
} from './IAddCardRequestAllOfModel';
import {
    IWalletAccountModel,
    IWalletAccountModelFromJSON,
    IWalletAccountModelFromJSONTyped,
    IWalletAccountModelToJSON,
} from './IWalletAccountModel';
import {
    IWalletBillingAddressModel,
    IWalletBillingAddressModelFromJSON,
    IWalletBillingAddressModelFromJSONTyped,
    IWalletBillingAddressModelToJSON,
} from './IWalletBillingAddressModel';
import {
    IWalletGiftCardModel,
    IWalletGiftCardModelFromJSON,
    IWalletGiftCardModelFromJSONTyped,
    IWalletGiftCardModelToJSON,
} from './IWalletGiftCardModel';
import {
    IWalletPaymentCardModel,
    IWalletPaymentCardModelFromJSON,
    IWalletPaymentCardModelFromJSONTyped,
    IWalletPaymentCardModelToJSON,
} from './IWalletPaymentCardModel';
import {
    WalletAccountTypesModel,
    WalletAccountTypesModelFromJSON,
    WalletAccountTypesModelFromJSONTyped,
    WalletAccountTypesModelToJSON,
} from './WalletAccountTypesModel';

/**
 * ADD CREDIT|GIFT CARD
 * @export
 * @interface IAddCardRequestModel
 */
export interface IAddCardRequestModel {
    /**
     * 
     * @type {WalletAccountTypesModel}
     * @memberof IAddCardRequestModel
     */
    accountType: WalletAccountTypesModel;
    /**
     * 
     * @type {IWalletPaymentCardModel}
     * @memberof IAddCardRequestModel
     */
    paymentCard?: IWalletPaymentCardModel;
    /**
     * 
     * @type {IWalletGiftCardModel}
     * @memberof IAddCardRequestModel
     */
    giftCard?: IWalletGiftCardModel;
    /**
     * 
     * @type {boolean}
     * @memberof IAddCardRequestModel
     */
    preferred?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IAddCardRequestModel
     */
    deviceId?: string;
    /**
     * 
     * @type {IWalletBillingAddressModel}
     * @memberof IAddCardRequestModel
     */
    billingAddress?: IWalletBillingAddressModel;
    /**
     * 
     * @type {string}
     * @memberof IAddCardRequestModel
     */
    paymentKey?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddCardRequestModel
     */
    sessionKey?: string;
}

export function IAddCardRequestModelFromJSON(json: any): IAddCardRequestModel {
    return IAddCardRequestModelFromJSONTyped(json, false);
}

export function IAddCardRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAddCardRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountType': WalletAccountTypesModelFromJSON(json['accountType']),
        'paymentCard': !exists(json, 'paymentCard') ? undefined : IWalletPaymentCardModelFromJSON(json['paymentCard']),
        'giftCard': !exists(json, 'giftCard') ? undefined : IWalletGiftCardModelFromJSON(json['giftCard']),
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : IWalletBillingAddressModelFromJSON(json['billingAddress']),
        'paymentKey': !exists(json, 'paymentKey') ? undefined : json['paymentKey'],
        'sessionKey': !exists(json, 'sessionKey') ? undefined : json['sessionKey'],
    };
}

export function IAddCardRequestModelToJSON(value?: IAddCardRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountType': WalletAccountTypesModelToJSON(value.accountType),
        'paymentCard': IWalletPaymentCardModelToJSON(value.paymentCard),
        'giftCard': IWalletGiftCardModelToJSON(value.giftCard),
        'preferred': value.preferred,
        'deviceId': value.deviceId,
        'billingAddress': IWalletBillingAddressModelToJSON(value.billingAddress),
        'paymentKey': value.paymentKey,
        'sessionKey': value.sessionKey,
    };
}

