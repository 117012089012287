/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderV2RequestFulfillmentDeliveryAddressModel,
    OrderV2RequestFulfillmentDeliveryAddressModelFromJSON,
    OrderV2RequestFulfillmentDeliveryAddressModelFromJSONTyped,
    OrderV2RequestFulfillmentDeliveryAddressModelToJSON,
} from './OrderV2RequestFulfillmentDeliveryAddressModel';

/**
 * 
 * @export
 * @interface OrderV2RequestFulfillmentModel
 */
export interface OrderV2RequestFulfillmentModel {
    /**
     * 
     * @type {OrderV2RequestFulfillmentDeliveryAddressModel}
     * @memberof OrderV2RequestFulfillmentModel
     */
    deliveryAddress?: OrderV2RequestFulfillmentDeliveryAddressModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderV2RequestFulfillmentModel
     */
    instructions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestFulfillmentModel
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestFulfillmentModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestFulfillmentModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2RequestFulfillmentModel
     */
    email: string;
}

export function OrderV2RequestFulfillmentModelFromJSON(json: any): OrderV2RequestFulfillmentModel {
    return OrderV2RequestFulfillmentModelFromJSONTyped(json, false);
}

export function OrderV2RequestFulfillmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderV2RequestFulfillmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : OrderV2RequestFulfillmentDeliveryAddressModelFromJSON(json['deliveryAddress']),
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'phoneNumber': json['phoneNumber'],
        'lastName': json['lastName'],
        'firstName': json['firstName'],
        'email': json['email'],
    };
}

export function OrderV2RequestFulfillmentModelToJSON(value?: OrderV2RequestFulfillmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryAddress': OrderV2RequestFulfillmentDeliveryAddressModelToJSON(value.deliveryAddress),
        'instructions': value.instructions,
        'phoneNumber': value.phoneNumber,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'email': value.email,
    };
}

