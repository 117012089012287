/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICustomerPersonalizationActionModel,
    ICustomerPersonalizationActionModelFromJSON,
    ICustomerPersonalizationActionModelFromJSONTyped,
    ICustomerPersonalizationActionModelToJSON,
} from './ICustomerPersonalizationActionModel';

/**
 * 
 * @export
 * @interface ICustomerPersonalizationResponseModel
 */
export interface ICustomerPersonalizationResponseModel {
    /**
     * 
     * @type {{ [key: string]: ICustomerPersonalizationActionModel; }}
     * @memberof ICustomerPersonalizationResponseModel
     */
    actions: { [key: string]: ICustomerPersonalizationActionModel; };
}

export function ICustomerPersonalizationResponseModelFromJSON(json: any): ICustomerPersonalizationResponseModel {
    return ICustomerPersonalizationResponseModelFromJSONTyped(json, false);
}

export function ICustomerPersonalizationResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICustomerPersonalizationResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': (mapValues(json['actions'], ICustomerPersonalizationActionModelFromJSON)),
    };
}

export function ICustomerPersonalizationResponseModelToJSON(value?: ICustomerPersonalizationResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': (mapValues(value.actions, ICustomerPersonalizationActionModelToJSON)),
    };
}

