/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IPaymentInitResponseModel
 */
export interface IPaymentInitResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    fdCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    sessionKey?: string;
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    iframeHtml?: string;
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    encryptionKey?: string;
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    pageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IPaymentInitResponseModel
     */
    redirectUrl?: string;
}

export function IPaymentInitResponseModelFromJSON(json: any): IPaymentInitResponseModel {
    return IPaymentInitResponseModelFromJSONTyped(json, false);
}

export function IPaymentInitResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPaymentInitResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fdCustomerId': !exists(json, 'fdCustomerId') ? undefined : json['fdCustomerId'],
        'sessionKey': !exists(json, 'sessionKey') ? undefined : json['sessionKey'],
        'iframeHtml': !exists(json, 'iframeHtml') ? undefined : json['iframeHtml'],
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'encryptionKey': !exists(json, 'encryptionKey') ? undefined : json['encryptionKey'],
        'pageUrl': !exists(json, 'pageUrl') ? undefined : json['pageUrl'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
    };
}

export function IPaymentInitResponseModelToJSON(value?: IPaymentInitResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fdCustomerId': value.fdCustomerId,
        'sessionKey': value.sessionKey,
        'iframeHtml': value.iframeHtml,
        'accessToken': value.accessToken,
        'encryptionKey': value.encryptionKey,
        'pageUrl': value.pageUrl,
        'redirectUrl': value.redirectUrl,
    };
}

