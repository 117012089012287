/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ItemModel,
    ItemModelFromJSON,
    ItemModelFromJSONTyped,
    ItemModelToJSON,
} from './ItemModel';

/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProductModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    isSaleable?: boolean;
    /**
     * 
     * @type {{ [key: string]: ItemModel; }}
     * @memberof ProductModel
     */
    items?: { [key: string]: ItemModel; };
}

export function ProductModelFromJSON(json: any): ProductModel {
    return ProductModelFromJSONTyped(json, false);
}

export function ProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'isSaleable': !exists(json, 'isSaleable') ? undefined : json['isSaleable'],
        'items': !exists(json, 'items') ? undefined : (mapValues(json['items'], ItemModelFromJSON)),
    };
}

export function ProductModelToJSON(value?: ProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'metadata': value.metadata,
        'type': value.type,
        'isSaleable': value.isSaleable,
        'items': value.items === undefined ? undefined : (mapValues(value.items, ItemModelToJSON)),
    };
}

