/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IGiftCardBalanceErrorResponseModel,
    IGiftCardBalanceErrorResponseModelFromJSON,
    IGiftCardBalanceErrorResponseModelFromJSONTyped,
    IGiftCardBalanceErrorResponseModelToJSON,
} from './IGiftCardBalanceErrorResponseModel';
import {
    IGiftCardBalanceSuccessResponseModel,
    IGiftCardBalanceSuccessResponseModelFromJSON,
    IGiftCardBalanceSuccessResponseModelFromJSONTyped,
    IGiftCardBalanceSuccessResponseModelToJSON,
} from './IGiftCardBalanceSuccessResponseModel';

/**
 * 
 * @export
 * @interface IGiftCardBalanceResponseModel
 */
export interface IGiftCardBalanceResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IGiftCardBalanceResponseModel
     */
    balance: number;
    /**
     * 
     * @type {string}
     * @memberof IGiftCardBalanceResponseModel
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof IGiftCardBalanceResponseModel
     */
    code?: string;
}

export function IGiftCardBalanceResponseModelFromJSON(json: any): IGiftCardBalanceResponseModel {
    return IGiftCardBalanceResponseModelFromJSONTyped(json, false);
}

export function IGiftCardBalanceResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGiftCardBalanceResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'message': json['message'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function IGiftCardBalanceResponseModelToJSON(value?: IGiftCardBalanceResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance': value.balance,
        'message': value.message,
        'code': value.code,
    };
}

