/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GooglePayInfoModel
 */
export interface GooglePayInfoModel {
    /**
     * 
     * @type {string}
     * @memberof GooglePayInfoModel
     */
    signedMessage: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePayInfoModel
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePayInfoModel
     */
    protocolVersion?: string;
}

export function GooglePayInfoModelFromJSON(json: any): GooglePayInfoModel {
    return GooglePayInfoModelFromJSONTyped(json, false);
}

export function GooglePayInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GooglePayInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signedMessage': json['signedMessage'],
        'signature': json['signature'],
        'protocolVersion': !exists(json, 'protocolVersion') ? undefined : json['protocolVersion'],
    };
}

export function GooglePayInfoModelToJSON(value?: GooglePayInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signedMessage': value.signedMessage,
        'signature': value.signature,
        'protocolVersion': value.protocolVersion,
    };
}

