/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICountryLocationsResponseModel,
    ICountryLocationsResponseModelFromJSON,
    ICountryLocationsResponseModelFromJSONTyped,
    ICountryLocationsResponseModelToJSON,
} from './ICountryLocationsResponseModel';
import {
    ILocationsByCountryModel,
    ILocationsByCountryModelFromJSON,
    ILocationsByCountryModelFromJSONTyped,
    ILocationsByCountryModelToJSON,
} from './ILocationsByCountryModel';
import {
    ILocationsByStateOrProvinceModel,
    ILocationsByStateOrProvinceModelFromJSON,
    ILocationsByStateOrProvinceModelFromJSONTyped,
    ILocationsByStateOrProvinceModelToJSON,
} from './ILocationsByStateOrProvinceModel';
import {
    IStateLocationsResponseModel,
    IStateLocationsResponseModelFromJSON,
    IStateLocationsResponseModelFromJSONTyped,
    IStateLocationsResponseModelToJSON,
} from './IStateLocationsResponseModel';

/**
 * 
 * @export
 * @interface TAllLocationsResponseDataModel
 */
export interface TAllLocationsResponseDataModel {
    /**
     * 
     * @type {number}
     * @memberof TAllLocationsResponseDataModel
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ILocationsByCountryModel>}
     * @memberof TAllLocationsResponseDataModel
     */
    locationsByCountry?: Array<ILocationsByCountryModel>;
    /**
     * 
     * @type {string}
     * @memberof TAllLocationsResponseDataModel
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof TAllLocationsResponseDataModel
     */
    countryName: string;
    /**
     * 
     * @type {ILocationsByStateOrProvinceModel}
     * @memberof TAllLocationsResponseDataModel
     */
    stateOrProvince?: ILocationsByStateOrProvinceModel;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TAllLocationsResponseDataModel
     */
    locationDescriptionMapping?: { [key: string]: string; };
}

export function TAllLocationsResponseDataModelFromJSON(json: any): TAllLocationsResponseDataModel {
    return TAllLocationsResponseDataModelFromJSONTyped(json, false);
}

export function TAllLocationsResponseDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TAllLocationsResponseDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'locationsByCountry': !exists(json, 'locationsByCountry') ? undefined : ((json['locationsByCountry'] as Array<any>).map(ILocationsByCountryModelFromJSON)),
        'countryCode': json['countryCode'],
        'countryName': json['countryName'],
        'stateOrProvince': !exists(json, 'stateOrProvince') ? undefined : ILocationsByStateOrProvinceModelFromJSON(json['stateOrProvince']),
        'locationDescriptionMapping': !exists(json, 'locationDescriptionMapping') ? undefined : json['locationDescriptionMapping'],
    };
}

export function TAllLocationsResponseDataModelToJSON(value?: TAllLocationsResponseDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'locationsByCountry': value.locationsByCountry === undefined ? undefined : ((value.locationsByCountry as Array<any>).map(ILocationsByCountryModelToJSON)),
        'countryCode': value.countryCode,
        'countryName': value.countryName,
        'stateOrProvince': ILocationsByStateOrProvinceModelToJSON(value.stateOrProvince),
        'locationDescriptionMapping': value.locationDescriptionMapping,
    };
}

