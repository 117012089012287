/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IActivityHistoryTransactionProductsModel,
    IActivityHistoryTransactionProductsModelFromJSON,
    IActivityHistoryTransactionProductsModelFromJSONTyped,
    IActivityHistoryTransactionProductsModelToJSON,
} from './IActivityHistoryTransactionProductsModel';

/**
 * 
 * @export
 * @interface IActivityHistoryTransactionModel
 */
export interface IActivityHistoryTransactionModel {
    /**
     * 
     * @type {string}
     * @memberof IActivityHistoryTransactionModel
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionModel
     */
    subTotal: number;
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionModel
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionModel
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof IActivityHistoryTransactionModel
     */
    totalEligible: number;
    /**
     * 
     * @type {Array<IActivityHistoryTransactionProductsModel>}
     * @memberof IActivityHistoryTransactionModel
     */
    products: Array<IActivityHistoryTransactionProductsModel>;
}

export function IActivityHistoryTransactionModelFromJSON(json: any): IActivityHistoryTransactionModel {
    return IActivityHistoryTransactionModelFromJSONTyped(json, false);
}

export function IActivityHistoryTransactionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IActivityHistoryTransactionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'subTotal': json['subTotal'],
        'tax': json['tax'],
        'total': json['total'],
        'totalEligible': json['totalEligible'],
        'products': ((json['products'] as Array<any>).map(IActivityHistoryTransactionProductsModelFromJSON)),
    };
}

export function IActivityHistoryTransactionModelToJSON(value?: IActivityHistoryTransactionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'subTotal': value.subTotal,
        'tax': value.tax,
        'total': value.total,
        'totalEligible': value.totalEligible,
        'products': ((value.products as Array<any>).map(IActivityHistoryTransactionProductsModelToJSON)),
    };
}

