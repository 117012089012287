/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TallyDSModifierModel,
    TallyDSModifierModelFromJSON,
    TallyDSModifierModelFromJSONTyped,
    TallyDSModifierModelToJSON,
} from './TallyDSModifierModel';

/**
 * 
 * @export
 * @interface TallyDSModifierGroupModel
 */
export interface TallyDSModifierGroupModel {
    /**
     * 
     * @type {string}
     * @memberof TallyDSModifierGroupModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDSModifierGroupModel
     */
    productId: string;
    /**
     * 
     * @type {Array<TallyDSModifierModel>}
     * @memberof TallyDSModifierGroupModel
     */
    modifiers?: Array<TallyDSModifierModel>;
}

export function TallyDSModifierGroupModelFromJSON(json: any): TallyDSModifierGroupModel {
    return TallyDSModifierGroupModelFromJSONTyped(json, false);
}

export function TallyDSModifierGroupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TallyDSModifierGroupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'productId': json['productId'],
        'modifiers': !exists(json, 'modifiers') ? undefined : ((json['modifiers'] as Array<any>).map(TallyDSModifierModelFromJSON)),
    };
}

export function TallyDSModifierGroupModelToJSON(value?: TallyDSModifierGroupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'productId': value.productId,
        'modifiers': value.modifiers === undefined ? undefined : ((value.modifiers as Array<any>).map(TallyDSModifierModelToJSON)),
    };
}

