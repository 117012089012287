/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TApplePaymentSellingChannelsModel {
    Weboa = 'WEBOA',
    Store = 'STORE',
    Ecommerce = 'ECOMMERCE',
    Uber = 'UBER'
}

export function TApplePaymentSellingChannelsModelFromJSON(json: any): TApplePaymentSellingChannelsModel {
    return TApplePaymentSellingChannelsModelFromJSONTyped(json, false);
}

export function TApplePaymentSellingChannelsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TApplePaymentSellingChannelsModel {
    return json as TApplePaymentSellingChannelsModel;
}

export function TApplePaymentSellingChannelsModelToJSON(value?: TApplePaymentSellingChannelsModel | null): any {
    return value as any;
}

