/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IBeerModel,
    IBeerModelFromJSON,
    IBeerModelFromJSONTyped,
    IBeerModelToJSON,
} from './IBeerModel';

/**
 * 
 * @export
 * @interface IBeersModel
 */
export interface IBeersModel {
    /**
     * 
     * @type {number}
     * @memberof IBeersModel
     */
    count: number;
    /**
     * 
     * @type {Array<IBeerModel>}
     * @memberof IBeersModel
     */
    items: Array<IBeerModel>;
}

export function IBeersModelFromJSON(json: any): IBeersModel {
    return IBeersModelFromJSONTyped(json, false);
}

export function IBeersModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IBeersModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'items': ((json['items'] as Array<any>).map(IBeerModelFromJSON)),
    };
}

export function IBeersModelToJSON(value?: IBeersModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'items': ((value.items as Array<any>).map(IBeerModelToJSON)),
    };
}

