/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IPhoneModel
 */
export interface IPhoneModel {
    /**
     * 
     * @type {string}
     * @memberof IPhoneModel
     */
    number: string;
    /**
     * 
     * @type {boolean}
     * @memberof IPhoneModel
     */
    isPreferred: boolean;
}

export function IPhoneModelFromJSON(json: any): IPhoneModel {
    return IPhoneModelFromJSONTyped(json, false);
}

export function IPhoneModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPhoneModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'isPreferred': json['isPreferred'],
    };
}

export function IPhoneModelToJSON(value?: IPhoneModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'isPreferred': value.isPreferred,
    };
}

