/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ILocationAddressDetailsModel
 */
export interface ILocationAddressDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressDetailsModel
     */
    line?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressDetailsModel
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressDetailsModel
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressDetailsModel
     */
    stateProvinceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationAddressDetailsModel
     */
    referencePoint?: string;
}

export function ILocationAddressDetailsModelFromJSON(json: any): ILocationAddressDetailsModel {
    return ILocationAddressDetailsModelFromJSONTyped(json, false);
}

export function ILocationAddressDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationAddressDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line': !exists(json, 'line') ? undefined : json['line'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'stateProvinceCode': !exists(json, 'stateProvinceCode') ? undefined : json['stateProvinceCode'],
        'referencePoint': !exists(json, 'referencePoint') ? undefined : json['referencePoint'],
    };
}

export function ILocationAddressDetailsModelToJSON(value?: ILocationAddressDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line': value.line,
        'postalCode': value.postalCode,
        'cityName': value.cityName,
        'stateProvinceCode': value.stateProvinceCode,
        'referencePoint': value.referencePoint,
    };
}

