/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentTypeModel {
    GiftCard = 'GIFT_CARD',
    Card = 'CARD',
    ApplePay = 'APPLE_PAY',
    GooglePay = 'GOOGLE_PAY',
    Wallet = 'WALLET',
    PayAtLocation = 'PAY_AT_LOCATION',
    NoPayment = 'NO_PAYMENT'
}

export function PaymentTypeModelFromJSON(json: any): PaymentTypeModel {
    return PaymentTypeModelFromJSONTyped(json, false);
}

export function PaymentTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTypeModel {
    return json as PaymentTypeModel;
}

export function PaymentTypeModelToJSON(value?: PaymentTypeModel | null): any {
    return value as any;
}

