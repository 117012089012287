/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IWalletPaymentCardModel
 */
export interface IWalletPaymentCardModel {
    /**
     * 
     * @type {string}
     * @memberof IWalletPaymentCardModel
     */
    nickname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IWalletPaymentCardModel
     */
    preferred?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IWalletPaymentCardModel
     */
    cardType?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletPaymentCardModel
     */
    nameOnCard?: string;
    /**
     * 
     * @type {string}
     * @memberof IWalletPaymentCardModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof IWalletPaymentCardModel
     */
    expiryYear?: number;
    /**
     * 
     * @type {number}
     * @memberof IWalletPaymentCardModel
     */
    expiryMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof IWalletPaymentCardModel
     */
    cardToken?: string;
    /**
     * Date ISO.
     * @type {Date}
     * @memberof IWalletPaymentCardModel
     */
    tokenExpirationDate?: Date;
}

export function IWalletPaymentCardModelFromJSON(json: any): IWalletPaymentCardModel {
    return IWalletPaymentCardModelFromJSONTyped(json, false);
}

export function IWalletPaymentCardModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IWalletPaymentCardModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'nameOnCard': !exists(json, 'nameOnCard') ? undefined : json['nameOnCard'],
        'maskedCardNumber': !exists(json, 'maskedCardNumber') ? undefined : json['maskedCardNumber'],
        'expiryYear': !exists(json, 'expiryYear') ? undefined : json['expiryYear'],
        'expiryMonth': !exists(json, 'expiryMonth') ? undefined : json['expiryMonth'],
        'cardToken': !exists(json, 'cardToken') ? undefined : json['cardToken'],
        'tokenExpirationDate': !exists(json, 'tokenExpirationDate') ? undefined : (new Date(json['tokenExpirationDate'])),
    };
}

export function IWalletPaymentCardModelToJSON(value?: IWalletPaymentCardModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nickname': value.nickname,
        'preferred': value.preferred,
        'cardType': value.cardType,
        'nameOnCard': value.nameOnCard,
        'maskedCardNumber': value.maskedCardNumber,
        'expiryYear': value.expiryYear,
        'expiryMonth': value.expiryMonth,
        'cardToken': value.cardToken,
        'tokenExpirationDate': value.tokenExpirationDate === undefined ? undefined : (value.tokenExpirationDate.toISOString()),
    };
}

