/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IIntervalModel,
    IIntervalModelFromJSON,
    IIntervalModelFromJSONTyped,
    IIntervalModelToJSON,
} from './IIntervalModel';
import {
    OtherPriceTypeEnumModel,
    OtherPriceTypeEnumModelFromJSON,
    OtherPriceTypeEnumModelFromJSONTyped,
    OtherPriceTypeEnumModelToJSON,
} from './OtherPriceTypeEnumModel';

/**
 * 
 * @export
 * @interface OtherPriceModel
 */
export interface OtherPriceModel {
    /**
     * 
     * @type {number}
     * @memberof OtherPriceModel
     */
    price?: number;
    /**
     * 
     * @type {OtherPriceTypeEnumModel}
     * @memberof OtherPriceModel
     */
    priceType?: OtherPriceTypeEnumModel;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OtherPriceModel
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: IIntervalModel; }}
     * @memberof OtherPriceModel
     */
    validity?: { [key: string]: IIntervalModel; };
    /**
     * 
     * @type {{ [key: string]: Array<IIntervalModel>; }}
     * @memberof OtherPriceModel
     */
    validityIntervals?: { [key: string]: Array<IIntervalModel>; };
}

export function OtherPriceModelFromJSON(json: any): OtherPriceModel {
    return OtherPriceModelFromJSONTyped(json, false);
}

export function OtherPriceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherPriceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': !exists(json, 'price') ? undefined : json['price'],
        'priceType': !exists(json, 'priceType') ? undefined : OtherPriceTypeEnumModelFromJSON(json['priceType']),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'validity': !exists(json, 'validity') ? undefined : (mapValues(json['validity'], IIntervalModelFromJSON)),
        'validityIntervals': !exists(json, 'validityIntervals') ? undefined : json['validityIntervals'],
    };
}

export function OtherPriceModelToJSON(value?: OtherPriceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': value.price,
        'priceType': OtherPriceTypeEnumModelToJSON(value.priceType),
        'metadata': value.metadata,
        'validity': value.validity === undefined ? undefined : (mapValues(value.validity, IIntervalModelToJSON)),
        'validityIntervals': value.validityIntervals,
    };
}

