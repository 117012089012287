/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorCodeEXPIREDREWARDModel {
    ExpiredReward = 'EXPIRED_REWARD'
}

export function TErrorCodeEXPIREDREWARDModelFromJSON(json: any): TErrorCodeEXPIREDREWARDModel {
    return TErrorCodeEXPIREDREWARDModelFromJSONTyped(json, false);
}

export function TErrorCodeEXPIREDREWARDModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorCodeEXPIREDREWARDModel {
    return json as TErrorCodeEXPIREDREWARDModel;
}

export function TErrorCodeEXPIREDREWARDModelToJSON(value?: TErrorCodeEXPIREDREWARDModel | null): any {
    return value as any;
}

