/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationAddressDetailsModel,
    ILocationAddressDetailsModelFromJSON,
    ILocationAddressDetailsModelFromJSONTyped,
    ILocationAddressDetailsModelToJSON,
} from './ILocationAddressDetailsModel';

/**
 * 
 * @export
 * @interface ILocationContactInfoModel
 */
export interface ILocationContactInfoModel {
    /**
     * 
     * @type {ILocationAddressDetailsModel}
     * @memberof ILocationContactInfoModel
     */
    address: ILocationAddressDetailsModel;
    /**
     * 
     * @type {string}
     * @memberof ILocationContactInfoModel
     */
    phone?: string;
}

export function ILocationContactInfoModelFromJSON(json: any): ILocationContactInfoModel {
    return ILocationContactInfoModelFromJSONTyped(json, false);
}

export function ILocationContactInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationContactInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': ILocationAddressDetailsModelFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function ILocationContactInfoModelToJSON(value?: ILocationContactInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': ILocationAddressDetailsModelToJSON(value.address),
        'phone': value.phone,
    };
}

