/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationRestrictionsModelLocationsModel,
    LocationRestrictionsModelLocationsModelFromJSON,
    LocationRestrictionsModelLocationsModelFromJSONTyped,
    LocationRestrictionsModelLocationsModelToJSON,
} from './LocationRestrictionsModelLocationsModel';

/**
 * 
 * @export
 * @interface LocationRestrictionsModelModel
 */
export interface LocationRestrictionsModelModel {
    /**
     * 
     * @type {boolean}
     * @memberof LocationRestrictionsModelModel
     */
    isAllLocations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocationRestrictionsModelModel
     */
    exclusion?: boolean;
    /**
     * 
     * @type {Array<LocationRestrictionsModelLocationsModel>}
     * @memberof LocationRestrictionsModelModel
     */
    locations?: Array<LocationRestrictionsModelLocationsModel>;
}

export function LocationRestrictionsModelModelFromJSON(json: any): LocationRestrictionsModelModel {
    return LocationRestrictionsModelModelFromJSONTyped(json, false);
}

export function LocationRestrictionsModelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationRestrictionsModelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAllLocations': !exists(json, 'isAllLocations') ? undefined : json['isAllLocations'],
        'exclusion': !exists(json, 'exclusion') ? undefined : json['exclusion'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(LocationRestrictionsModelLocationsModelFromJSON)),
    };
}

export function LocationRestrictionsModelModelToJSON(value?: LocationRestrictionsModelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isAllLocations': value.isAllLocations,
        'exclusion': value.exclusion,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(LocationRestrictionsModelLocationsModelToJSON)),
    };
}

