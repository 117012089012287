/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IMigrationDetailModel
 */
export interface IMigrationDetailModel {
    /**
     * 
     * @type {boolean}
     * @memberof IMigrationDetailModel
     */
    isMigrated: boolean;
    /**
     * 
     * @type {string}
     * @memberof IMigrationDetailModel
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof IMigrationDetailModel
     */
    memberSince?: string;
}

export function IMigrationDetailModelFromJSON(json: any): IMigrationDetailModel {
    return IMigrationDetailModelFromJSONTyped(json, false);
}

export function IMigrationDetailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMigrationDetailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMigrated': json['isMigrated'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'memberSince': !exists(json, 'memberSince') ? undefined : json['memberSince'],
    };
}

export function IMigrationDetailModelToJSON(value?: IMigrationDetailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMigrated': value.isMigrated,
        'source': value.source,
        'memberSince': value.memberSince,
    };
}

