/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IVisitRegisterRequestCustomerModel,
    IVisitRegisterRequestCustomerModelFromJSON,
    IVisitRegisterRequestCustomerModelFromJSONTyped,
    IVisitRegisterRequestCustomerModelToJSON,
} from './IVisitRegisterRequestCustomerModel';
import {
    IVisitRegisterRequestFullfillmentModel,
    IVisitRegisterRequestFullfillmentModelFromJSON,
    IVisitRegisterRequestFullfillmentModelFromJSONTyped,
    IVisitRegisterRequestFullfillmentModelToJSON,
} from './IVisitRegisterRequestFullfillmentModel';
import {
    IVisitRegisterRequestOrderModel,
    IVisitRegisterRequestOrderModelFromJSON,
    IVisitRegisterRequestOrderModelFromJSONTyped,
    IVisitRegisterRequestOrderModelToJSON,
} from './IVisitRegisterRequestOrderModel';

/**
 * 
 * @export
 * @interface IVisitRegisterRequestModel
 */
export interface IVisitRegisterRequestModel {
    /**
     * 
     * @type {IVisitRegisterRequestOrderModel}
     * @memberof IVisitRegisterRequestModel
     */
    order: IVisitRegisterRequestOrderModel;
    /**
     * 
     * @type {IVisitRegisterRequestFullfillmentModel}
     * @memberof IVisitRegisterRequestModel
     */
    fullfillment: IVisitRegisterRequestFullfillmentModel;
    /**
     * 
     * @type {IVisitRegisterRequestCustomerModel}
     * @memberof IVisitRegisterRequestModel
     */
    customer: IVisitRegisterRequestCustomerModel;
    /**
     * 
     * @type {string}
     * @memberof IVisitRegisterRequestModel
     */
    locationId: string;
}

export function IVisitRegisterRequestModelFromJSON(json: any): IVisitRegisterRequestModel {
    return IVisitRegisterRequestModelFromJSONTyped(json, false);
}

export function IVisitRegisterRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVisitRegisterRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': IVisitRegisterRequestOrderModelFromJSON(json['order']),
        'fullfillment': IVisitRegisterRequestFullfillmentModelFromJSON(json['fullfillment']),
        'customer': IVisitRegisterRequestCustomerModelFromJSON(json['customer']),
        'locationId': json['locationId'],
    };
}

export function IVisitRegisterRequestModelToJSON(value?: IVisitRegisterRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': IVisitRegisterRequestOrderModelToJSON(value.order),
        'fullfillment': IVisitRegisterRequestFullfillmentModelToJSON(value.fullfillment),
        'customer': IVisitRegisterRequestCustomerModelToJSON(value.customer),
        'locationId': value.locationId,
    };
}

