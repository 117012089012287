/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IGenericPageMetadataModel,
    IGenericPageMetadataModelFromJSON,
    IGenericPageMetadataModelFromJSONTyped,
    IGenericPageMetadataModelToJSON,
} from './IGenericPageMetadataModel';
import {
    ILocationWithDetailsModel,
    ILocationWithDetailsModelFromJSON,
    ILocationWithDetailsModelFromJSONTyped,
    ILocationWithDetailsModelToJSON,
} from './ILocationWithDetailsModel';

/**
 * 
 * @export
 * @interface ILocationsPageModel
 */
export interface ILocationsPageModel {
    /**
     * 
     * @type {IGenericPageMetadataModel}
     * @memberof ILocationsPageModel
     */
    metadata: IGenericPageMetadataModel;
    /**
     * 
     * @type {Array<ILocationWithDetailsModel>}
     * @memberof ILocationsPageModel
     */
    locations: Array<ILocationWithDetailsModel>;
}

export function ILocationsPageModelFromJSON(json: any): ILocationsPageModel {
    return ILocationsPageModelFromJSONTyped(json, false);
}

export function ILocationsPageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ILocationsPageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': IGenericPageMetadataModelFromJSON(json['metadata']),
        'locations': ((json['locations'] as Array<any>).map(ILocationWithDetailsModelFromJSON)),
    };
}

export function ILocationsPageModelToJSON(value?: ILocationsPageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': IGenericPageMetadataModelToJSON(value.metadata),
        'locations': ((value.locations as Array<any>).map(ILocationWithDetailsModelToJSON)),
    };
}

