/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRewardsDiscountOfferItemDSResponseModel
 */
export interface IRewardsDiscountOfferItemDSResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOfferItemDSResponseModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOfferItemDSResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOfferItemDSResponseModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOfferItemDSResponseModel
     */
    discountAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof IRewardsDiscountOfferItemDSResponseModel
     */
    compCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IRewardsDiscountOfferItemDSResponseModel
     */
    isCompApplied?: boolean;
}

export function IRewardsDiscountOfferItemDSResponseModelFromJSON(json: any): IRewardsDiscountOfferItemDSResponseModel {
    return IRewardsDiscountOfferItemDSResponseModelFromJSONTyped(json, false);
}

export function IRewardsDiscountOfferItemDSResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRewardsDiscountOfferItemDSResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'compCode': !exists(json, 'compCode') ? undefined : json['compCode'],
        'isCompApplied': !exists(json, 'isCompApplied') ? undefined : json['isCompApplied'],
    };
}

export function IRewardsDiscountOfferItemDSResponseModelToJSON(value?: IRewardsDiscountOfferItemDSResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'id': value.id,
        'description': value.description,
        'discountAmount': value.discountAmount,
        'compCode': value.compCode,
        'isCompApplied': value.isCompApplied,
    };
}

