/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDineInOrderDetailsItemModel,
    IDineInOrderDetailsItemModelFromJSON,
    IDineInOrderDetailsItemModelFromJSONTyped,
    IDineInOrderDetailsItemModelToJSON,
} from './IDineInOrderDetailsItemModel';
import {
    IDineInOrderDetailsLocationModel,
    IDineInOrderDetailsLocationModelFromJSON,
    IDineInOrderDetailsLocationModelFromJSONTyped,
    IDineInOrderDetailsLocationModelToJSON,
} from './IDineInOrderDetailsLocationModel';
import {
    IDineInOrderPaymentModel,
    IDineInOrderPaymentModelFromJSON,
    IDineInOrderPaymentModelFromJSONTyped,
    IDineInOrderPaymentModelToJSON,
} from './IDineInOrderPaymentModel';

/**
 * 
 * @export
 * @interface IDineInOrderDetailsModel
 */
export interface IDineInOrderDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsModel
     */
    posOrderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDineInOrderDetailsModel
     */
    isOpen: boolean;
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsModel
     */
    dateTime: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsModel
     */
    table?: string;
    /**
     * 
     * @type {string}
     * @memberof IDineInOrderDetailsModel
     */
    serverName?: string;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    guestsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    subTotalBeforeDiscounts: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    subTotalAfterDiscounts: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    tip?: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    otherDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IDineInOrderDetailsModel
     */
    certificateDiscount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IDineInOrderDetailsModel
     */
    isLoyaltyMember: boolean;
    /**
     * 
     * @type {Array<IDineInOrderDetailsItemModel>}
     * @memberof IDineInOrderDetailsModel
     */
    products: Array<IDineInOrderDetailsItemModel>;
    /**
     * 
     * @type {IDineInOrderDetailsLocationModel}
     * @memberof IDineInOrderDetailsModel
     */
    location?: IDineInOrderDetailsLocationModel;
    /**
     * 
     * @type {Array<IDineInOrderPaymentModel>}
     * @memberof IDineInOrderDetailsModel
     */
    payments?: Array<IDineInOrderPaymentModel>;
}

export function IDineInOrderDetailsModelFromJSON(json: any): IDineInOrderDetailsModel {
    return IDineInOrderDetailsModelFromJSONTyped(json, false);
}

export function IDineInOrderDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDineInOrderDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'posOrderId': json['posOrderId'],
        'isOpen': json['isOpen'],
        'dateTime': json['dateTime'],
        'table': !exists(json, 'table') ? undefined : json['table'],
        'serverName': !exists(json, 'serverName') ? undefined : json['serverName'],
        'guestsCount': !exists(json, 'guestsCount') ? undefined : json['guestsCount'],
        'subTotalBeforeDiscounts': json['subTotalBeforeDiscounts'],
        'subTotalAfterDiscounts': json['subTotalAfterDiscounts'],
        'tax': json['tax'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'tip': !exists(json, 'tip') ? undefined : json['tip'],
        'otherDiscount': !exists(json, 'otherDiscount') ? undefined : json['otherDiscount'],
        'certificateDiscount': !exists(json, 'certificateDiscount') ? undefined : json['certificateDiscount'],
        'isLoyaltyMember': json['isLoyaltyMember'],
        'products': ((json['products'] as Array<any>).map(IDineInOrderDetailsItemModelFromJSON)),
        'location': !exists(json, 'location') ? undefined : IDineInOrderDetailsLocationModelFromJSON(json['location']),
        'payments': !exists(json, 'payments') ? undefined : ((json['payments'] as Array<any>).map(IDineInOrderPaymentModelFromJSON)),
    };
}

export function IDineInOrderDetailsModelToJSON(value?: IDineInOrderDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'posOrderId': value.posOrderId,
        'isOpen': value.isOpen,
        'dateTime': value.dateTime,
        'table': value.table,
        'serverName': value.serverName,
        'guestsCount': value.guestsCount,
        'subTotalBeforeDiscounts': value.subTotalBeforeDiscounts,
        'subTotalAfterDiscounts': value.subTotalAfterDiscounts,
        'tax': value.tax,
        'total': value.total,
        'tip': value.tip,
        'otherDiscount': value.otherDiscount,
        'certificateDiscount': value.certificateDiscount,
        'isLoyaltyMember': value.isLoyaltyMember,
        'products': ((value.products as Array<any>).map(IDineInOrderDetailsItemModelToJSON)),
        'location': IDineInOrderDetailsLocationModelToJSON(value.location),
        'payments': value.payments === undefined ? undefined : ((value.payments as Array<any>).map(IDineInOrderPaymentModelToJSON)),
    };
}

