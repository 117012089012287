/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ILocationAddressModel,
    ILocationAddressModelFromJSON,
    ILocationAddressModelFromJSONTyped,
    ILocationAddressModelToJSON,
} from './ILocationAddressModel';

/**
 * 
 * @export
 * @interface IOrderFulfillmentContactDetailsModel
 */
export interface IOrderFulfillmentContactDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof IOrderFulfillmentContactDetailsModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderFulfillmentContactDetailsModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderFulfillmentContactDetailsModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderFulfillmentContactDetailsModel
     */
    phone?: string;
    /**
     * 
     * @type {ILocationAddressModel}
     * @memberof IOrderFulfillmentContactDetailsModel
     */
    deliveryAddress?: ILocationAddressModel;
}

export function IOrderFulfillmentContactDetailsModelFromJSON(json: any): IOrderFulfillmentContactDetailsModel {
    return IOrderFulfillmentContactDetailsModelFromJSONTyped(json, false);
}

export function IOrderFulfillmentContactDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOrderFulfillmentContactDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : ILocationAddressModelFromJSON(json['deliveryAddress']),
    };
}

export function IOrderFulfillmentContactDetailsModelToJSON(value?: IOrderFulfillmentContactDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'deliveryAddress': ILocationAddressModelToJSON(value.deliveryAddress),
    };
}

