/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDSOfferModel,
    IDSOfferModelFromJSON,
    IDSOfferModelFromJSONTyped,
    IDSOfferModelToJSON,
} from './IDSOfferModel';
import {
    IRewardCertificateModel,
    IRewardCertificateModelFromJSON,
    IRewardCertificateModelFromJSONTyped,
    IRewardCertificateModelToJSON,
} from './IRewardCertificateModel';
import {
    TallyDSProductModel,
    TallyDSProductModelFromJSON,
    TallyDSProductModelFromJSONTyped,
    TallyDSProductModelToJSON,
} from './TallyDSProductModel';

/**
 * 
 * @export
 * @interface ITransformedDiscountRewardsResponseForClientModel
 */
export interface ITransformedDiscountRewardsResponseForClientModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof ITransformedDiscountRewardsResponseForClientModel
     */
    idempotentId: string;
    /**
     * 
     * @type {string}
     * @memberof ITransformedDiscountRewardsResponseForClientModel
     */
    subTotal: string;
    /**
     * 
     * @type {string}
     * @memberof ITransformedDiscountRewardsResponseForClientModel
     */
    discountedPrice: string;
    /**
     * 
     * @type {Array<IDSOfferModel>}
     * @memberof ITransformedDiscountRewardsResponseForClientModel
     */
    offersApplied: Array<IDSOfferModel>;
    /**
     * 
     * @type {Array<IRewardCertificateModel>}
     * @memberof ITransformedDiscountRewardsResponseForClientModel
     */
    certificatesApplied?: Array<IRewardCertificateModel>;
    /**
     * 
     * @type {Array<TallyDSProductModel>}
     * @memberof ITransformedDiscountRewardsResponseForClientModel
     */
    items: Array<TallyDSProductModel>;
}

export function ITransformedDiscountRewardsResponseForClientModelFromJSON(json: any): ITransformedDiscountRewardsResponseForClientModel {
    return ITransformedDiscountRewardsResponseForClientModelFromJSONTyped(json, false);
}

export function ITransformedDiscountRewardsResponseForClientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ITransformedDiscountRewardsResponseForClientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idempotentId': json['idempotentId'],
        'subTotal': json['subTotal'],
        'discountedPrice': json['discountedPrice'],
        'offersApplied': ((json['offersApplied'] as Array<any>).map(IDSOfferModelFromJSON)),
        'certificatesApplied': !exists(json, 'certificatesApplied') ? undefined : ((json['certificatesApplied'] as Array<any>).map(IRewardCertificateModelFromJSON)),
        'items': ((json['items'] as Array<any>).map(TallyDSProductModelFromJSON)),
    };
}

export function ITransformedDiscountRewardsResponseForClientModelToJSON(value?: ITransformedDiscountRewardsResponseForClientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idempotentId': value.idempotentId,
        'subTotal': value.subTotal,
        'discountedPrice': value.discountedPrice,
        'offersApplied': ((value.offersApplied as Array<any>).map(IDSOfferModelToJSON)),
        'certificatesApplied': value.certificatesApplied === undefined ? undefined : ((value.certificatesApplied as Array<any>).map(IRewardCertificateModelToJSON)),
        'items': ((value.items as Array<any>).map(TallyDSProductModelToJSON)),
    };
}

