/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDetailsTypeModel,
    DiscountDetailsTypeModelFromJSON,
    DiscountDetailsTypeModelFromJSONTyped,
    DiscountDetailsTypeModelToJSON,
} from './DiscountDetailsTypeModel';
import {
    ICheckRestrictionsModel,
    ICheckRestrictionsModelFromJSON,
    ICheckRestrictionsModelFromJSONTyped,
    ICheckRestrictionsModelToJSON,
} from './ICheckRestrictionsModel';
import {
    IHoursModel,
    IHoursModelFromJSON,
    IHoursModelFromJSONTyped,
    IHoursModelToJSON,
} from './IHoursModel';
import {
    IRewardApplicabilityModel,
    IRewardApplicabilityModelFromJSON,
    IRewardApplicabilityModelFromJSONTyped,
    IRewardApplicabilityModelToJSON,
} from './IRewardApplicabilityModel';
import {
    LocationRestrictionsModel,
    LocationRestrictionsModelFromJSON,
    LocationRestrictionsModelFromJSONTyped,
    LocationRestrictionsModelToJSON,
} from './LocationRestrictionsModel';
import {
    TOfferStatusUnionModel,
    TOfferStatusUnionModelFromJSON,
    TOfferStatusUnionModelFromJSONTyped,
    TOfferStatusUnionModelToJSON,
} from './TOfferStatusUnionModel';
import {
    TOfferTypeModel,
    TOfferTypeModelFromJSON,
    TOfferTypeModelFromJSONTyped,
    TOfferTypeModelToJSON,
} from './TOfferTypeModel';

/**
 * Offer Details
 * @export
 * @interface IOfferModel
 */
export interface IOfferModel {
    /**
     * Domain service id type.
     * @type {string}
     * @memberof IOfferModel
     */
    id: string;
    /**
     * User specific Offer Id, generated from Vendor system/api.
     * @type {string}
     * @memberof IOfferModel
     */
    userOfferId: string;
    /**
     * POS discount id.
     * @type {string}
     * @memberof IOfferModel
     */
    posDiscountId?: string;
    /**
     * Offer Name with high level detail about offer.
     * @type {string}
     * @memberof IOfferModel
     */
    name: string;
    /**
     * 
     * @type {TOfferTypeModel}
     * @memberof IOfferModel
     */
    type: TOfferTypeModel;
    /**
     * Offer Start Date and time in UTC
     * @type {Date}
     * @memberof IOfferModel
     */
    startDateTime: Date;
    /**
     * Offer End Date and time in UTC
     * @type {Date}
     * @memberof IOfferModel
     */
    endDateTime: Date;
    /**
     * Terms and Conditions
     * @type {string}
     * @memberof IOfferModel
     */
    terms: string;
    /**
     * Is this a store only offer. Cannot be redeemed through difital channels if set to true. Only redeemable through store POS
     * @type {boolean}
     * @memberof IOfferModel
     */
    isRedeemableInStoreOnly: boolean;
    /**
     * Is this a online only offer.
     * @type {boolean}
     * @memberof IOfferModel
     */
    isRedeemableOnlineOnly: boolean;
    /**
     * 
     * @type {TOfferStatusUnionModel}
     * @memberof IOfferModel
     */
    status?: TOfferStatusUnionModel;
    /**
     * 
     * @type {string}
     * @memberof IOfferModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof IOfferModel
     */
    termsLanguageCode: string;
    /**
     * Description
     * @type {string}
     * @memberof IOfferModel
     */
    description?: string;
    /**
     * 
     * @type {IRewardApplicabilityModel}
     * @memberof IOfferModel
     */
    applicability?: IRewardApplicabilityModel;
    /**
     * 
     * @type {string}
     * @memberof IOfferModel
     */
    imageUrl?: string;
    /**
     * 
     * @type {LocationRestrictionsModel}
     * @memberof IOfferModel
     */
    locationRestrictions?: LocationRestrictionsModel;
    /**
     * 
     * @type {ICheckRestrictionsModel}
     * @memberof IOfferModel
     */
    checkRestrictions?: ICheckRestrictionsModel;
    /**
     * Offer End Date to display to the customer
     * @type {string}
     * @memberof IOfferModel
     */
    displayExpirationDate?: string;
    /**
     * 
     * @type {DiscountDetailsTypeModel}
     * @memberof IOfferModel
     */
    discountDetailsType?: DiscountDetailsTypeModel;
    /**
     * 
     * @type {Array<IHoursModel>}
     * @memberof IOfferModel
     */
    redemptionTimeRanges?: Array<IHoursModel>;
}

export function IOfferModelFromJSON(json: any): IOfferModel {
    return IOfferModelFromJSONTyped(json, false);
}

export function IOfferModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IOfferModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userOfferId': json['userOfferId'],
        'posDiscountId': !exists(json, 'posDiscountId') ? undefined : json['posDiscountId'],
        'name': json['name'],
        'type': TOfferTypeModelFromJSON(json['type']),
        'startDateTime': (new Date(json['startDateTime'])),
        'endDateTime': (new Date(json['endDateTime'])),
        'terms': json['terms'],
        'isRedeemableInStoreOnly': json['isRedeemableInStoreOnly'],
        'isRedeemableOnlineOnly': json['isRedeemableOnlineOnly'],
        'status': !exists(json, 'status') ? undefined : TOfferStatusUnionModelFromJSON(json['status']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'termsLanguageCode': json['termsLanguageCode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicability': !exists(json, 'applicability') ? undefined : IRewardApplicabilityModelFromJSON(json['applicability']),
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'locationRestrictions': !exists(json, 'locationRestrictions') ? undefined : LocationRestrictionsModelFromJSON(json['locationRestrictions']),
        'checkRestrictions': !exists(json, 'checkRestrictions') ? undefined : ICheckRestrictionsModelFromJSON(json['checkRestrictions']),
        'displayExpirationDate': !exists(json, 'displayExpirationDate') ? undefined : json['displayExpirationDate'],
        'discountDetailsType': !exists(json, 'discountDetailsType') ? undefined : DiscountDetailsTypeModelFromJSON(json['discountDetailsType']),
        'redemptionTimeRanges': !exists(json, 'redemptionTimeRanges') ? undefined : ((json['redemptionTimeRanges'] as Array<any>).map(IHoursModelFromJSON)),
    };
}

export function IOfferModelToJSON(value?: IOfferModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userOfferId': value.userOfferId,
        'posDiscountId': value.posDiscountId,
        'name': value.name,
        'type': TOfferTypeModelToJSON(value.type),
        'startDateTime': (value.startDateTime.toISOString()),
        'endDateTime': (value.endDateTime.toISOString()),
        'terms': value.terms,
        'isRedeemableInStoreOnly': value.isRedeemableInStoreOnly,
        'isRedeemableOnlineOnly': value.isRedeemableOnlineOnly,
        'status': TOfferStatusUnionModelToJSON(value.status),
        'code': value.code,
        'termsLanguageCode': value.termsLanguageCode,
        'description': value.description,
        'applicability': IRewardApplicabilityModelToJSON(value.applicability),
        'imageUrl': value.imageUrl,
        'locationRestrictions': LocationRestrictionsModelToJSON(value.locationRestrictions),
        'checkRestrictions': ICheckRestrictionsModelToJSON(value.checkRestrictions),
        'displayExpirationDate': value.displayExpirationDate,
        'discountDetailsType': DiscountDetailsTypeModelToJSON(value.discountDetailsType),
        'redemptionTimeRanges': value.redemptionTimeRanges === undefined ? undefined : ((value.redemptionTimeRanges as Array<any>).map(IHoursModelToJSON)),
    };
}

