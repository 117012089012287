/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IDeliveryGeoCoordinatesModel,
    IDeliveryGeoCoordinatesModelFromJSON,
    IDeliveryGeoCoordinatesModelFromJSONTyped,
    IDeliveryGeoCoordinatesModelToJSON,
} from './IDeliveryGeoCoordinatesModel';
import {
    IDeliveryLocationAddressModel,
    IDeliveryLocationAddressModelFromJSON,
    IDeliveryLocationAddressModelFromJSONTyped,
    IDeliveryLocationAddressModelToJSON,
} from './IDeliveryLocationAddressModel';

/**
 * Dropoff location of the delivery
 * @export
 * @interface IDropOffModel
 */
export interface IDropOffModel {
    /**
     * 
     * @type {IDeliveryGeoCoordinatesModel}
     * @memberof IDropOffModel
     */
    details: IDeliveryGeoCoordinatesModel;
    /**
     * 
     * @type {IDeliveryLocationAddressModel}
     * @memberof IDropOffModel
     */
    address: IDeliveryLocationAddressModel;
}

export function IDropOffModelFromJSON(json: any): IDropOffModel {
    return IDropOffModelFromJSONTyped(json, false);
}

export function IDropOffModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDropOffModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': IDeliveryGeoCoordinatesModelFromJSON(json['details']),
        'address': IDeliveryLocationAddressModelFromJSON(json['address']),
    };
}

export function IDropOffModelToJSON(value?: IDropOffModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': IDeliveryGeoCoordinatesModelToJSON(value.details),
        'address': IDeliveryLocationAddressModelToJSON(value.address),
    };
}

