/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplePayInfoHeaderModel
 */
export interface ApplePayInfoHeaderModel {
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoHeaderModel
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoHeaderModel
     */
    publicKeyHash: string;
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoHeaderModel
     */
    ephemeralPublicKey: string;
    /**
     * 
     * @type {string}
     * @memberof ApplePayInfoHeaderModel
     */
    applicationData?: string;
}

export function ApplePayInfoHeaderModelFromJSON(json: any): ApplePayInfoHeaderModel {
    return ApplePayInfoHeaderModelFromJSONTyped(json, false);
}

export function ApplePayInfoHeaderModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplePayInfoHeaderModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'publicKeyHash': json['publicKeyHash'],
        'ephemeralPublicKey': json['ephemeralPublicKey'],
        'applicationData': !exists(json, 'applicationData') ? undefined : json['applicationData'],
    };
}

export function ApplePayInfoHeaderModelToJSON(value?: ApplePayInfoHeaderModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'publicKeyHash': value.publicKeyHash,
        'ephemeralPublicKey': value.ephemeralPublicKey,
        'applicationData': value.applicationData,
    };
}

