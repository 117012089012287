/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IAccountPaymentMethodModel,
    IAccountPaymentMethodModelFromJSON,
    IAccountPaymentMethodModelFromJSONTyped,
    IAccountPaymentMethodModelToJSON,
} from './IAccountPaymentMethodModel';
import {
    IWalletGiftCardWithBalanceModel,
    IWalletGiftCardWithBalanceModelFromJSON,
    IWalletGiftCardWithBalanceModelFromJSONTyped,
    IWalletGiftCardWithBalanceModelToJSON,
} from './IWalletGiftCardWithBalanceModel';

/**
 * 
 * @export
 * @interface IGetCustomerAccountPaymentMethodsResponseModel
 */
export interface IGetCustomerAccountPaymentMethodsResponseModel {
    /**
     * 
     * @type {Array<IAccountPaymentMethodModel>}
     * @memberof IGetCustomerAccountPaymentMethodsResponseModel
     */
    cREDITCARDS?: Array<IAccountPaymentMethodModel>;
    /**
     * 
     * @type {Array<IWalletGiftCardWithBalanceModel>}
     * @memberof IGetCustomerAccountPaymentMethodsResponseModel
     */
    gIFTCARDS?: Array<IWalletGiftCardWithBalanceModel>;
}

export function IGetCustomerAccountPaymentMethodsResponseModelFromJSON(json: any): IGetCustomerAccountPaymentMethodsResponseModel {
    return IGetCustomerAccountPaymentMethodsResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerAccountPaymentMethodsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerAccountPaymentMethodsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cREDITCARDS': !exists(json, 'CREDIT_CARDS') ? undefined : ((json['CREDIT_CARDS'] as Array<any>).map(IAccountPaymentMethodModelFromJSON)),
        'gIFTCARDS': !exists(json, 'GIFT_CARDS') ? undefined : ((json['GIFT_CARDS'] as Array<any>).map(IWalletGiftCardWithBalanceModelFromJSON)),
    };
}

export function IGetCustomerAccountPaymentMethodsResponseModelToJSON(value?: IGetCustomerAccountPaymentMethodsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CREDIT_CARDS': value.cREDITCARDS === undefined ? undefined : ((value.cREDITCARDS as Array<any>).map(IAccountPaymentMethodModelToJSON)),
        'GIFT_CARDS': value.gIFTCARDS === undefined ? undefined : ((value.gIFTCARDS as Array<any>).map(IWalletGiftCardWithBalanceModelToJSON)),
    };
}

