/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IGetCustomerAccountLoyaltyPointsResponseModel
 */
export interface IGetCustomerAccountLoyaltyPointsResponseModel {
    /**
     * 
     * @type {number}
     * @memberof IGetCustomerAccountLoyaltyPointsResponseModel
     */
    pointsBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof IGetCustomerAccountLoyaltyPointsResponseModel
     */
    pointsExpiring?: number;
    /**
     * 
     * @type {string}
     * @memberof IGetCustomerAccountLoyaltyPointsResponseModel
     */
    pointsExpiringDate?: string;
}

export function IGetCustomerAccountLoyaltyPointsResponseModelFromJSON(json: any): IGetCustomerAccountLoyaltyPointsResponseModel {
    return IGetCustomerAccountLoyaltyPointsResponseModelFromJSONTyped(json, false);
}

export function IGetCustomerAccountLoyaltyPointsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetCustomerAccountLoyaltyPointsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pointsBalance': !exists(json, 'pointsBalance') ? undefined : json['pointsBalance'],
        'pointsExpiring': !exists(json, 'pointsExpiring') ? undefined : json['pointsExpiring'],
        'pointsExpiringDate': !exists(json, 'pointsExpiringDate') ? undefined : json['pointsExpiringDate'],
    };
}

export function IGetCustomerAccountLoyaltyPointsResponseModelToJSON(value?: IGetCustomerAccountLoyaltyPointsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pointsBalance': value.pointsBalance,
        'pointsExpiring': value.pointsExpiring,
        'pointsExpiringDate': value.pointsExpiringDate,
    };
}

