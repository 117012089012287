/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TErrorMessageModel {
    RequestIsNotValidPleaseModifyRequestDataAndTryAgain = 'Request is not valid. Please modify request data and try again',
    YouHaveNotVerifiedYourEmailYetPleaseVerifyYourEmailAndTryAgainAfterThat = 'You have not verified your email yet. Please verify your email and try again after that',
    ErrorReturnedFromDownlineSystem = 'Error returned from downline system',
    ResourceNotFound = 'Resource not found',
    NoCredentialsProvidedPleaseIncludeThemAndTryAgain = 'No credentials provided. Please include them and try again',
    ErrorOccurredWhileProcessingRequest = 'Error occurred while processing request',
    ItDoesntLookLikeYouAreCurrentlyAtAParticipatingRestaurant = 'It doesn\'t look like you are currently at a participating restaurant',
    PleaseCheckYourPhoneNumberAndTryAgain = 'Please check your phone number and try again.',
    ThePaymentDidNotGoThroughPleaseUseADifferentPaymentMethodAndTryAgain = 'The payment did not go through. Please use a different payment method and try again.',
    ThePaymentAmountDoesNotMatchTheTotalOrderAmountPleaseReviewThePaymentsMethodsAmountsAndTryAgain = 'The payment amount does not match the total order amount. Please review the payments methods amounts and try again.',
    TheSelectedPaymentMethodSAreNotValidPleaseSelectDifferentPaymentMethodSAndTryAgain = 'The selected payment method(s) are not valid. Please select different payment method(s) and try again.',
    YourPaymentWasDeclinedAndYourOrderWasNotSubmittedPleaseTryAgain = 'Your payment was declined and your order was not submitted. Please try again.',
    TheCheckNumberEnteredIsNotFoundPleaseTryAgain = 'The check number entered is not found, please try again.',
    TransactionDateCannotBeInFuture = 'Transaction date cannot be in future.',
    TheStoreNumberYouEnteredIsNotValidPleaseTryAgain = 'The Store number you entered is not valid, please try again.',
    TransactionDateCannotBeOlderThan60Days = 'Transaction date cannot be older than 60 days.',
    TransactionCannotBeMoreThan30DaysPriorToYourEnrollmentDatePleaseContactCustomerService = 'Transaction cannot be more than 30 days prior to your enrollment date, please contact Customer Service.',
    ThisTransactionHasAlreadyBeenAwardedPleaseContactCustomerService = 'This transaction has already been awarded. Please contact Customer Service.',
    PhoneNumberAlreadyInUsePleaseChangeTheNumberAndTryAgain = 'Phone number already in use. Please change the number and try again.',
    SessionHasExpiredPleaseTryAgain = 'Session has expired. Please try again.',
    CustomerAlreadyOptedInForMarketingPromotions = 'Customer already Opted in for Marketing Promotions.',
    WereSorryThereWasAProblemSubmittingYourOrderAnyPendingChargeOnYourCardWillBeAutomaticallyVoidedWithin37BusinessDaysWedBeHappyToServeYouAtOurRestaurantOrYouMayTryResubmittingYourOnlineOrderIn10Minutes = 'We’re sorry, there was a problem submitting your order. Any pending charge on your card will be automatically voided within 3-7 business days. We’d be happy to serve you at our restaurant or you may try resubmitting your online order in 10 minutes.',
    ErrorUploadingProfilePictureToCloudinary = 'Error uploading profile picture to cloudinary',
    CardHasZeroBalance = 'Card has zero balance',
    CardIsInUse = 'Card is in use',
    WrongCardNumberOrSecurityCode = 'Wrong card number or security code',
    ThisSpotIsCurrentlyUnavailable = 'This spot is currently unavailable',
    YouHaveEnteredAnInvalidSpotNumberPleaseEnterAValidOne = 'You have entered an invalid spot number. Please enter a valid one',
    LooksLikeThereIsProfanityInYourEntryPleaseEnterAnAppropriateNameToProceed = 'Looks like there is profanity in your entry. Please enter an appropriate name to proceed.',
    PromocodeIsNotValid = 'Promocode is not valid'
}

export function TErrorMessageModelFromJSON(json: any): TErrorMessageModel {
    return TErrorMessageModelFromJSONTyped(json, false);
}

export function TErrorMessageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TErrorMessageModel {
    return json as TErrorMessageModel;
}

export function TErrorMessageModelToJSON(value?: TErrorMessageModel | null): any {
    return value as any;
}

