/* tslint:disable */
/* eslint-disable */
/**
 * web-exp-api
 * BFF (backend for frontend) for Order Ahead (OA)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IGetProfileIdResponseModel
 */
export interface IGetProfileIdResponseModel {
    /**
     * Stringified UUID.
     * @type {string}
     * @memberof IGetProfileIdResponseModel
     */
    externalId: string;
}

export function IGetProfileIdResponseModelFromJSON(json: any): IGetProfileIdResponseModel {
    return IGetProfileIdResponseModelFromJSONTyped(json, false);
}

export function IGetProfileIdResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetProfileIdResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': json['externalId'],
    };
}

export function IGetProfileIdResponseModelToJSON(value?: IGetProfileIdResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
    };
}

